import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import "./Solution.css";
import Card from "react-bootstrap/Card";
import {images} from "../../constant/AppConfig";
import { useSelector, useDispatch } from "react-redux";
import {
  getSolutionsStatus,
  fetchSolutions,
  selectAllSolutions,
  getSolutionsError,
} from "../../features/solutions/solutionsSlice";
import { decrementAction, incrementAction } from "../../features/navigationHome/navhomeSlice";


import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Menubarone from "../../Component/Navbar/Menubarone";
import MenuBar from "../../Component/Navbar/MenuBar";

import { Desktop, Mobile, Tablet } from "../../constant/AppConfig";
import { useLocation, useNavigate ,useParams } from "react-router-dom";
import Carousel from "react-material-ui-carousel";
import CarouselSlide from "react-material-ui-carousel";

import Modal1 from "./Modal";
import { useTranslation } from "react-i18next";
import useAuth from "../../Api/hooks/useAuth";
import FormulaireC from "../Contact/FormulaireContact";
import { useState } from "react";

const OneSolution = (props) => {




  //redux
  const dispatch = useDispatch();
  const solutionStatus = useSelector(getSolutionsStatus);
  const solutions = useSelector(selectAllSolutions);
  const error = useSelector(getSolutionsError);
  const navHome = useSelector(state => state.counter);
  useEffect(() => {
    if (solutionStatus === "idle") {
      dispatch(fetchSolutions());
    }
  }, [solutionStatus, dispatch]);
  const { id } = useParams();

  console.log(solutions,"solutions ==>",Number(id)
 
  )
  const navigate = useNavigate();
  const {postContact} = useAuth()
  const { state } = useLocation();
  const [t] = useTranslation("common");
  const [open, setOpen] =useState(false);
  const [nameficher, setNameficher] = useState("");
  const [opennew, setOpennew] = useState(false);

  const [openTest, setOpenTest] =useState(false);
  const [nameficherTest, setNameficherTest] = useState("");
  const [opennewTest, setOpennewTest] = useState(false);

  const [opennewQos, setOpennewQos] = useState(false);
  const [openQos, setOpenQos] = useState(false);
  const [nameficherQos, setNameficherQos] = useState("");

  const [opennewCyber, setOpennewCyber] = useState(false);
  const [openCyber, setOpenCyber] = useState(false);
  const [nameficherCyber, setNameficherCyber] = useState("");
  const position = "solution";
  
  
  const [datasolution, setDatasolution] = React.useState({
    nom: "",
    email: "",
    telephone: "",
    ville: "",
    pays: "",
    societe: "",
    message: "",
    error: "",
    service: "",
    erro: "",
  });



  const handleCloseQos = () => setOpenQos(false);
  const handleCloseTest = () => setOpenTest(false);
  const handleCloseCyber = () => setOpenCyber(false);

  const handleClose = () => setOpen(false);

  useEffect(() => {
    console.log(props,"sol========================= props")
    window.scrollTo(0, 0);
  }, []);


//   useEffect(()=>{

// oneSolutiont = solutions.filter((solution,i)=>(
//    solution.id  === Number(id))
// )

//   },[])
return (
  <>

{
  (Number(id) === 3) ? 
      <>
        <Desktop>
       
          <Row>
            <Row
              style={{
                height: "222px",
                background: "rgba(48, 192, 198, 0.8)",
                backdropFilter: "blur(10px)}",
              }}
            >
             <Col>
             
               <>
                <div className="titlesolutionresaux">
                  {t("SolutionsDetails.Synchronisation des Réseaux")}
                 {/* {solutions && solutions[2]&& solutions[2].titre && solutions[2].titre }  */}
                
                </div>
       
                <div className="navsolutionresaux">
                  <button
                    className="navsolutionresauxbutton"
                    style={{ marginLeft: "53px" }}
                  >
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <div
                        className="navsolutionresauxtext"
                        style={{ width: "100px" }}
                        onClick={() =>
                          document.getElementById("Tp4100").scrollIntoView()
                        }
                      >
                        {t("SolutionsDetails.TP 4100")}
                      </div>{" "}
                    </div>
                  </button>
                  <button className="navsolutionresauxbutton">
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <div
                        className="navsolutionresauxtext"
                        style={{ width: "150px" }}
                        onClick={() =>
                          document.getElementById("S600").scrollIntoView()
                        }
                      >
                        {t("SolutionsDetails.S600/S650")}
                      </div>{" "}
                    </div>
                  </button>
                  <button className="navsolutionresauxbutton">
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      {" "}
                      <div
                        className="navsolutionresauxtext"
                        style={{ width: "130px" }}
                        onClick={() =>
                          document.getElementById("TimeCesium").scrollIntoView()
                        }
                      >
                        {t("SolutionsDetails.TimeCesium 4400")}
                      </div>{" "}
                    </div>
                  </button>
                  <button className="navsolutionresauxbutton">
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      {" "}
                      <div
                        className="navsolutionresauxtext"
                        style={{ width: "113px" }}
                        onClick={() =>
                          document.getElementById("TP5000").scrollIntoView()
                        }
                      >
                        {t("SolutionsDetails.TP 5000")}
                      </div>{" "}
                    </div>
                  </button>
                  <button className="navsolutionresauxbutton">
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <div
                        className="navsolutionresauxtext"
                        style={{ width: "120px" }}
                        onClick={() =>
                          document.getElementById("SSU 2000").scrollIntoView()
                        }
                      >
                        {t("SolutionsDetails.SSU 2000")}
                      </div>{" "}
                    </div>
                  </button>
                  <button
                    className="navsolutionresauxbutton"
                    style={{ width: "300px", heigth: "200px" }}
                  >
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <div
                        className="navsolutionresauxtext"
                        style={{ width: "130px" }}
                        onClick={() =>
                          document
                            .getElementById("BlueSky GNSS Firewall")
                            .scrollIntoView()
                        }
                      >
                        {t("SolutionsDetails.BlueSky GNSS Firewall")}
                      </div>{" "}
                    </div>
                  </button>
                  <button className="navsolutionresauxbutton">
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <div
                        className="navsolutionresauxtext"
                        style={{ width: "50px" }}
                        onClick={() =>
                          document
                            .getElementById("SyncServer 80")
                            .scrollIntoView()
                        }
                      >
                        {t("SolutionsDetails.S80")}
                      </div>{" "}
                    </div>
                  </button>{" "}
                </div></>
                
                </Col>
            </Row>
          </Row>
          <Row
            style={{
              marginTop: "89px",
              position: "relative",
              marginRight: "121px",
            }}
            id="Tp4100"
          >
            <>
              <Col
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div className="imageresauxbox">
                    <img alt="logo" src={images.image8} />{" "}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <button
                      className="buttonAutreservice"
                      style={{ marginTop: "133px", width: "300px" }}
                    >
                      {" "}
                      <div
                        className="textbuttoncardservice"
                        onClick={() => {
                          setNameficher("TP 4100");

                          setOpen(true);
                        }}
                      >
                         {t("Solutions.Téléchargez le Data sheet")}
                      </div>
                    </button>
                    <button
                      className="buttonAutreservice"
                      style={{ marginTop: "133px", width: "200px" }}
                    >
                      {" "}
                      <div
                        className="textbuttoncardservice"
                        onClick={() => {
                          setOpennew(true);
                        }}
                      >
                        {t("Serivces.contact")}
                      </div>
                    </button>
                  </div>
                </div>
              </Col>
              <Col>
                <div className="titlesolutionresauxbox">
                  {" "}
                  {t("SolutionsDetails.TP 4100")}
                </div>
                <div
                  className="textsolutionresauxbox"
                  style={{ marginTop: "30px" }}
                >
                  {t("SolutionsDetails.text TP 4100")}
                </div>
              </Col>
            </>
          </Row>
          <Row style={{ marginTop: "87px", marginRight: "121px" }} id="S600">
            <>
              <Col>
                <div
                  className="titlesolutionresauxbox"
                  style={{ marginLeft: "95px" }}
                >
                  {t("SolutionsDetails.S600/S650")}
                </div>
                <div
                  className="textsolutionresauxbox"
                  style={{ marginLeft: "95px", marginTop: "30px" }}
                >
                  {t("SolutionsDetails.text S600/S650")}
                </div>
              </Col>
              <Col>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div
                    className="imageresauxbox"
                    style={{ marginTop: "-10px" }}
                  >
                    <img alt="logo" src={images.image9} />{" "}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      marginTop: "-122px",
                    }}
                  >
                    <button
                      className="buttonAutreservice"
                      style={{ marginTop: "133px", width: "300px" }}
                    >
                      {" "}
                      <div
                        className="textbuttoncardservice"
                        onClick={() => {
                          setNameficher("S600/S650");

                          setOpen(true);
                        }}
                      >
                         {t("Solutions.Téléchargez le Data sheet")}
                      </div>
                    </button>
                    <button
                      className="buttonAutreservice"
                      style={{
                        marginTop: "133px",
                        width: "200px",
                        marginLeft: "50px",
                      }}
                    >
                      {" "}
                      <div
                        className="textbuttoncardservice"
                        onClick={() => {
                          setOpennew(true);
                        }}
                      >
                        {t("Serivces.contact")}
                      </div>
                    </button>
                  </div>
                </div>
              </Col>
            </>
          </Row>
          <Row
            style={{ marginTop: "87px", marginRight: "121px" }}
            id="TimeCesium"
          >
            <>
              <Col
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div className="imageresauxbox">
                    <img alt="logo" src={images.image10} />{" "}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      marginTop: "-122px",
                    }}
                  >
                    <button
                      className="buttonAutreservice"
                      style={{ marginTop: "133px", width: "300px" }}
                    >
                      {" "}
                      <div
                        className="textbuttoncardservice"
                        onClick={() => {
                          setNameficher("TimeCesium 4400");

                          setOpen(true);
                        }}
                      >
                         {t("Solutions.Téléchargez le Data sheet")}
                      </div>
                    </button>
                    <button
                      className="buttonAutreservice"
                      style={{
                        marginTop: "133px",
                        width: "200px",
                        marginLeft: "50px",
                      }}
                    >
                      {" "}
                      <div
                        className="textbuttoncardservice"
                        onClick={() => {
                          setOpennew(true);
                        }}
                      >
                        {t("Serivces.contact")}
                      </div>
                    </button>
                  </div>
                </div>
              </Col>
              <Col>
                <div className="titlesolutionresauxbox">
                  {t("SolutionsDetails.TimeCesium 4400")}
                </div>
                <div
                  className="textsolutionresauxbox"
                  style={{ marginTop: "30px" }}
                >
                  {t("SolutionsDetails.text TimeCesium 4400")}
                </div>
              </Col>
            </>
          </Row>
          <Row style={{ marginTop: "87px", marginRight: "121px" }} id="TP5000">
            <>
              <Col>
                <div
                  className="titlesolutionresauxbox"
                  style={{ marginLeft: "95px" }}
                >
                  {t("SolutionsDetails.TP 5000")}
                </div>
                <div
                  className="textsolutionresauxbox"
                  style={{ marginLeft: "95px", marginTop: "30px" }}
                >
                  {t("SolutionsDetails.text TP 5000")}
                </div>
              </Col>
              <Col>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div
                    className="imageresauxbox"
                    style={{ marginTop: "-10px" }}
                  >
                    <img alt="logo" src={images.image11} />{" "}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",

                      marginTop: "-122px",
                    }}
                  >
                    <button
                      className="buttonAutreservice"
                      style={{ marginTop: "133px", width: "300px" }}
                    >
                      {" "}
                      <div
                        className="textbuttoncardservice"
                        onClick={() => {
                          setNameficher("TP 5000");

                          setOpen(true);
                        }}
                      >
                         {t("Solutions.Téléchargez le Data sheet")}
                      </div>
                    </button>
                    <button
                      className="buttonAutreservice"
                      style={{
                        marginTop: "133px",
                        width: "200px",
                        marginLeft: "50px",
                      }}
                    >
                      {" "}
                      <div
                        className="textbuttoncardservice"
                        onClick={() => {
                          setOpennew(true);
                        }}
                      >
                        {t("Serivces.contact")}
                      </div>
                    </button>
                  </div>
                </div>
              </Col>
            </>
          </Row>
          <Row
            style={{ marginTop: "87px", marginRight: "121px" }}
            id="SSU 2000"
          >
            <>
              <Col>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div className="imageresauxbox">
                    <img alt="logo" src={images.image12} />{" "}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      marginTop: "-122px",
                    }}
                  >
                    <button
                      className="buttonAutreservice"
                      style={{ marginTop: "133px", width: "300px" }}
                    >
                      {" "}
                      <div
                        className="textbuttoncardservice"
                        onClick={() => {
                          setNameficher("SSU 2000");

                          setOpen(true);
                        }}
                      >
                         {t("Solutions.Téléchargez le Data sheet")}
                      </div>
                    </button>
                    <button
                      className="buttonAutreservice"
                      style={{
                        marginTop: "133px",
                        width: "200px",
                        marginLeft: "50px",
                      }}
                    >
                      {" "}
                      <div
                        className="textbuttoncardservice"
                        onClick={() => {
                          setOpennew(true);
                        }}
                      >
                        {t("Serivces.contact")}
                      </div>
                    </button>
                  </div>
                </div>
              </Col>
              <Col>
                <div className="titlesolutionresauxbox">
                  {" "}
                  {t("SolutionsDetails.SSU 2000")}
                </div>
                <div
                  className="textsolutionresauxbox"
                  style={{ marginTop: "30px" }}
                >
                  {t("SolutionsDetails.text SSU 2000")}
                </div>
              </Col>
            </>
          </Row>
          <Row
            style={{ marginTop: "87px", marginRight: "121px" }}
            id="BlueSky GNSS Firewall"
          >
            <>
              <Col>
                <div
                  className="titlesolutionresauxbox"
                  style={{ marginLeft: "95px" }}
                >
                  {t("SolutionsDetails.BlueSky GNSS Firewall")}
                </div>
                <div
                  className="textsolutionresauxbox"
                  style={{ marginLeft: "95px", marginTop: "30px" }}
                >
                  {t("SolutionsDetails.text BlueSky GNSS Firewall")}
                </div>
              </Col>
              <Col>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div
                    className="imageresauxbox"
                    style={{ marginTop: "-10px" }}
                  >
                    <img alt="logo" src={images.image13} />{" "}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      marginTop: "-122px",
                    }}
                  >
                    <button
                      className="buttonAutreservice"
                      style={{ marginTop: "133px", width: "300px" }}
                    >
                      {" "}
                      <div
                        className="textbuttoncardservice"
                        onClick={() => {
                          setNameficher("Bluesky");

                          setOpen(true);
                        }}
                      >
                         {t("Solutions.Téléchargez le Data sheet")}
                      </div>
                    </button>
                    <button
                      className="buttonAutreservice"
                      style={{
                        marginTop: "133px",
                        width: "200px",
                        marginLeft: "50px",
                      }}
                    >
                      {" "}
                      <div
                        className="textbuttoncardservice"
                        onClick={() => {
                          setOpennew(true);
                        }}
                      >
                        {t("Serivces.contact")}
                      </div>
                    </button>
                  </div>
                </div>
              </Col>
            </>
          </Row>
          <Row
            style={{ marginTop: "87px", marginRight: "121px" }}
            id="SyncServer 80"
          >
            <>
              <Col>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div className="imageresauxbox">
                    <img alt="logo" src={images.image14} />{" "}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      marginTop: "-122px",
                    }}
                  >
                    <button
                      className="buttonAutreservice"
                      style={{ marginTop: "133px", width: "300px" }}
                    >
                      {" "}
                      <div
                        className="textbuttoncardservice"
                        onClick={() => {
                          setNameficher("S80");

                          setOpen(true);
                        }}
                      >
                         {t("Solutions.Téléchargez le Data sheet")}
                      </div>
                    </button>
                    <button
                      className="buttonAutreservice"
                      style={{
                        marginTop: "133px",
                        width: "200px",
                        marginLeft: "50px",
                      }}
                    >
                      {" "}
                      <div
                        className="textbuttoncardservice"
                        onClick={() => {
                          setOpennew(true);
                        }}
                      >
                        {t("Serivces.contact")}
                      </div>
                    </button>
                  </div>
                </div>
              </Col>
              <Col>
                <div className="titlesolutionresauxbox">
                  {" "}
                  {t("SolutionsDetails.S80")}
                </div>
                <div
                  className="textsolutionresauxbox"
                  style={{ marginTop: "30px" }}
                >
                  {t("SolutionsDetails.text S80")}
                </div>
              </Col>
            </>
          </Row>

          <div style={{ marginTop: "100px" }}>
            <img
              alt="logo"
              className="lineserviceconsulting"
              src={images.Rectangle13}
            />
          </div>
          {/* //Autres Solutions  */}
          <div className="title2serviceconsulting">{t('Solutions.Autres solutions')}</div>
          <Col style={{display:'flex' ,justifyContent:"center"}}>
     
                {solutions &&
                  solutions
                    .filter((e, index) => e.id !== Number(id)  ) 
                    .map((solutionCaroussel, key) => (
            // solutions.filter((solutionCaroussel, index) => solutionCaroussel.id !== Number(id))
           
              <>
              {/* <Row style={{justifyContent: "center",display: "flex",}}> */}
               
              <Card key={key}>
                <Card.Body
                  style={{
                    marginTop: "20px",
                  }}
                >
                  <Card.Text>
                    <p>{solutionCaroussel.titre}</p>
                  </Card.Text>
                  <button className="buttoncardservice2">
                    {" "}
                    <div
                      className="textbuttoncardservice"
                      onClick={() => {
                        navigate(`/solution/${solutionCaroussel.id}`);
                      
                      }}
                    >
                      {t("Voirs.title")}
                    </div>
                  </button>
                </Card.Body>
              </Card>
        
              {/* </Row> */}
        
              </>
            ))}
             
            {/* </Carousel> */}
            {/* </Col> */}
           
          </Col>
          {/* //Contact Form  */}
          <Row>
            {/* <div> */}
              {/* <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "100px",
                }}
              > */}
                {/* <div className="titleserviceconsultingcontact">
                  {t("Serivces.contact")}
                </div> */}
              {/* </div> */}
            {/* </div> */}
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div style={{ width: "100%" }}>
             <FormulaireC/>
              </div>
            </div>
          </Row>
          <Modal1
            handleClose={handleClose}
            open={open}
            opennew={opennew}
            setOpennew={setOpennew}
            setOpen={setOpen}
            nameficher={nameficher}
          />
        </Desktop>
        <Tablet>
          <Row>
            {/* <Col
              xs={12}
              style={{
                backgroundColor: "#253D8A",
                minHeight: "60px",
                height: "auto",
              }}
            >
              <Menubarone />
            </Col>

            <Col
              xs={12}
              style={{ minHeight: "120px", height: "auto", width: "100%" }}
            >
              <MenuBar home={1} setHome={1}/>
            </Col> */}
          </Row>
          <Row>
            <Row
              style={{
                height: "222px",
                zIndex: 2,
                marginTop: "0",
                marginLeft: "10px",
                background: "rgba(48, 192, 198, 0.8)",
                backdropFilter: "blur(10px)}",
              }}
            >
              <Col>
                <div>
                  <div className="titlesolutionresaux">
                    Synchronisation des Réseaux
                  </div>
                </div>
   {}
                <div className="navsolutionresaux">
                  <button
                    className="navsolutionresauxbutton"
                    onClick={() => {
                      document.getElementById("Tp4100").scrollIntoView();
                    }}
                  >
                    <div className="navsolutionresauxtext">TP 4100</div>
                  </button>
                  <button className="navsolutionresauxbutton">
                    <div
                      className="navsolutionresauxtext"
                      onClick={() =>
                        document.getElementById("S600").scrollIntoView()
                      }
                    >
                      S600/S650
                    </div>
                  </button>
                  <button className="navsolutionresauxbutton">
                    <div
                      className="navsolutionresauxtext"
                      onClick={() =>
                        document.getElementById("TimeCesium").scrollIntoView()
                      }
                    >
                      TimeCesium 4400
                    </div>
                  </button>
                  <button className="navsolutionresauxbutton">
                    <div
                      className="navsolutionresauxtext"
                      onClick={() =>
                        document.getElementById("TP5000").scrollIntoView()
                      }
                    >
                      TP 5000
                    </div>
                  </button>
                  <button className="navsolutionresauxbutton">
                    <div
                      className="navsolutionresauxtext"
                      onClick={() =>
                        document.getElementById("SSU 2000").scrollIntoView()
                      }
                    >
                      SSU 2000
                    </div>
                  </button>
                  <button className="navsolutionresauxbutton">
                    <div
                      className="navsolutionresauxtext"
                      onClick={() =>
                        document
                          .getElementById("BlueSky GNSS Firewall")
                          .scrollIntoView()
                      }
                    >
                      BlueSky GNSS Firewall
                    </div>
                  </button>
                  <button className="navsolutionresauxbutton">
                    <div
                      className="navsolutionresauxtext"
                      onClick={() =>
                        document
                          .getElementById("SyncServer 80")
                          .scrollIntoView()
                      }
                    >
                      S80
                    </div>
                  </button>
                </div>
              </Col>
            </Row>
          </Row>


          <Row style={{ marginTop: "89px", position: "relative" }} id="Right">
            <>
              <Col xs={12}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div className="titlesolutionresauxbox">TP 4100</div>
                </div>

                <div
                  className="textsolutionresauxbox"
                  style={{ marginTop: "30px" }}
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div style={{ width: "80%" }}>
                      Basé sur la dernière plateforme technologique de Microchip
                      (Microsemi), le serveur 1U TP4100 permet d’accompagner
                      l’évolution des réseaux vers la 4G et la 5G tout en
                      fournissant des ports TDM pour les anciens équipements
                      avec : 8 ports PTP/ SyncE et NTP, Un module d’extension 16
                      ports E1/ 2MHz.
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={12}>
                <div className="imageresauxbox">
                  <img alt="logo" src={images.image8} style={{ width: " 250px" }} />{" "}
                </div>
              </Col>
            </>
          </Row>

          <Row style={{ marginTop: "87px" }} id="left">
            <>
              <Col xs={12}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div className="titlesolutionresauxbox">S600/S650</div>
                </div>

                <div
                  className="textsolutionresauxbox"
                  style={{ marginTop: "30px" }}
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div style={{ width: "80%" }}>
                      Basé sur la dernière plateforme technologique de Microchip
                      (Microsemi), le serveur 1U TP4100 permet d’accompagner
                      l’évolution des réseaux vers la 4G et la 5G tout en
                      fournissant des ports TDM pour les anciens équipements
                      avec : 8 ports PTP/ SyncE et NTP, Un module d’extension 16
                      ports E1/ 2MHz.
                    </div>{" "}
                  </div>
                </div>
              </Col>
              <Col xs={12}>
                <div className="imageresauxbox">
                  <img alt="logo" src={images.image9} style={{ width: " 250px" }} />{" "}
                </div>
              </Col>
            </>
          </Row>


          <Row style={{ marginTop: "87px" }} id="TimeCesium">
            <>
              <Col xs={12}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div className="titlesolutionresauxbox">TimeCesium 4400</div>{" "}
                </div>

                <div
                  className="textsolutionresauxbox"
                  style={{ marginTop: "30px" }}
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div style={{ width: "80%" }}>
                      Basé sur la dernière plateforme technologique de Microchip
                      (Microsemi), le serveur 1U TP4100 permet d’accompagner
                      l’évolution des réseaux vers la 4G et la 5G tout en
                      fournissant des ports TDM pour les anciens équipements
                      avec : 8 ports PTP/ SyncE et NTP, Un module d’extension 16
                      ports E1/ 2MHz.
                    </div>{" "}
                  </div>
                </div>
              </Col>
              <Col xs={12}>
                <div className="imageresauxbox">
                  <img alt="logo" src={images.image10} style={{ width: " 250px" }} />{" "}
                </div>
              </Col>
            </>
          </Row>
          <Row style={{ marginTop: "87px" }} id="TP5000">
            <>
              <Col xs={12}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div className="titlesolutionresauxbox">TP 5000</div>{" "}
                </div>

                <div
                  className="textsolutionresauxbox"
                  style={{ marginTop: "30px" }}
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div style={{ width: "80%" }}>
                      Serveur PTP le plus vendu au monde, déployé dans plus de
                      400 réseaux, le TP 5000 est un serveur de haute capacité,
                      entièrement redondant, destiné au cœur du réseau pour les
                      besoins de synchronisation en fréquence ou en phase. Il
                      supporte tous les profils PTP définis par l’ITU, ainsi que
                      la SyncE et le NTP
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={12}>
                <div className="imageresauxbox">
                  <img alt="logo" src={images.image11} style={{ width: " 250px" }} />{" "}
                </div>
              </Col>
            </>
          </Row>
          <Row style={{ marginTop: "87px" }} id="SSU 2000">
            <>
              <Col xs={12}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div className="titlesolutionresauxbox">SSU 2000</div>{" "}
                </div>

                <div
                  className="textsolutionresauxbox"
                  style={{ marginTop: "30px" }}
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div style={{ width: "80%" }}>
                      Les SSU 2000 et SSU 2000e sont conçus pour répondre aux
                      normes les plus récentes et en constante évolution de
                      l'industrie et sont utilisés par les opérateurs de réseaux
                      de communication pour générer et distribuer des signaux de
                      synchronisation pour leurs réseaux. Les deux systèmes
                      utilisent les mêmes cartes enfichables et fournissent des
                      capacités de synchronisation de réseau de fréquences pour
                      les réseaux SDH/SONET.
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={12}>
                <div className="imageresauxbox">
                  <img alt="logo" src={images.image12} style={{ width: " 250px" }} />{" "}
                </div>
              </Col>
            </>
          </Row>
          <Row style={{ marginTop: "87px" }} id="BlueSky GNSS Firewall">
            <>
              <Col xs={12}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div className="titlesolutionresauxbox">
                    BlueSky GNSS Firewall
                  </div>{" "}
                </div>

                <div
                  className="textsolutionresauxbox"
                  style={{ marginTop: "30px" }}
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div style={{ width: "80%" }}>
                      Le Firewall GNSS BlueSky protège les systèmes GNSS déjà
                      déployés en fournissant une solution rentable installée
                      entre les antennes GNSS existantes et les systèmes GNSS.
                      Semblable à un pare-feu réseau, BlueSky protège les
                      systèmes à l'intérieur du pare-feu contre les signaux non
                      fiables à l'extérieur du pare-feu.
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={12}>
                <div className="imageresauxbox">
                  <img alt="logo" src={images.image13} style={{ width: " 250px" }} />{" "}
                </div>
              </Col>
            </>
          </Row>
          <Row style={{ marginTop: "87px" }} id="SyncServer 80">
            <>
              <Col xs={12}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div className="titlesolutionresauxbox">SyncServer 80</div>{" "}
                </div>

                <div
                  className="textsolutionresauxbox"
                  style={{ marginTop: "30px" }}
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div style={{ width: "80%" }}>
                      Le SyncServer S80 est une antenne GPS/GLONASS composée par
                      un récepteur, un serveur NTP et une interface Power over
                      Ethernet (PoE) qui s'intègre facilement dans
                      l'infrastructure PoE existante pour être immédiatement la
                      source d'horodatages précis, sécurisés et fiables pour
                      tous les appareils connectés au réseau. Les systèmes de
                      sécurité physique isolés du réseau en bénéficient car le
                      serveur de temps réseau renforcé Stratum 1 est idéal pour
                      la synchronisation temporelle des caméras de sécurité IP,
                      des dispositifs de contrôle d'accès et des enregistreurs
                      vidéo numériques/réseau.
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={12}>
                <div className="imageresauxbox">
                  <img alt="logo" src={images.image14} style={{ width: " 250px" }} />{" "}
                </div>
              </Col>
            </>
          </Row>
        
          <Row style={{ justifyContent: "center" }}>
            <div style={{ width: "248px", marginTop: "50px" }}>
              <div className="textAutreservice">Outils de mesures</div>
              <button
                className="buttonAutreservice"
                style={{ marginTop: "99px" }}
              >
                {" "}
                <div
                  className="textbuttoncardservice"
                  onClick={() => {
                   
                    navigate("/solution/2");
                  }}
                >
                  {t("Voirs.title")}
                </div>
              </button>
            </div>

            <div style={{ width: "248px", marginTop: "50px" }}>
              <div className="textAutreservice">Mobile Finance</div>
              <button
                className="buttonAutreservice"
                style={{ marginTop: "131px" }}
              >
                {" "}
                <div
                  className="textbuttoncardservice"
                  onClick={() => {
                   
                    navigate("/solution/4");
                  }}
                >
                  {t("Voirs.title")}
                </div>
              </button>
            </div>
            <div style={{ width: "248px", marginTop: "50px" }}>
              <div className="textAutreservice">
                {t("Solutions.QoS / QoE et Mesure des performances")}{" "}
              </div>
              <button
                className="buttonAutreservice"
                style={{ marginTop: "67px" }}
              >
                {" "}
                <div
                  className="textbuttoncardservice"
                  onClick={() => {
                  
                    navigate("/solution/3");
                  }}
                >
                  {t("Voirs.title")}
                </div>
              </button>
            </div>
            <div style={{ width: "248px", marginTop: "50px" }}>
              <div className="textAutreservice">
                Analyse, optimisation et monétisation du trafic - DPI
              </div>
              <button className="buttonAutreservice">
                {" "}
                <div
                  className="textbuttoncardservice"
                  onClick={() => {
             
                    navigate("/solution/5");
                  }}
                >
                  {t("Voirs.title")}
                </div>
              </button>
            </div>
          </Row>

          <Row>
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "100px",
                }}
              >
                <div className="titleserviceconsultingcontact">
                  {t("Serivces.contact")}
                </div>
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div style={{ width: "80%" }}>
                <FormulaireC position={position}/>
              </div>
            </div>
          </Row>
        </Tablet>
        <Mobile>
          {/* <Row>
            <Col
              xs={12}
              style={{
                backgroundColor: "#253D8A",
                minHeight: "60px",
                height: "auto",
              }}
            >
              <Menubarone />
            </Col>

            <Col
              xs={12}
              style={{
                minHeight: "120px",
                zIndex: 2,
                height: "auto",
                width: "100%",
                marginTop: "0px",
                backgroundColor: "white",
              }}
            >
              <MenuBar home={1} setHome={1}/>
            </Col>
          </Row> */}
          <Row>
            {/* <Row style={{height: "222px",zIndex:2,position:"fixed",marginTop:"180px",marginLeft:"10px",paddingLeft:"0px",paddingRight:"0px"
      ,
background: "rgba(48, 192, 198, 0.8)",
backdropFilter: "blur(10px)}"}}> */}
            <Row
              style={{
                height: "222px",
                marginTop: "0px",
                marginLeft: "10px",
                paddingLeft: "0px",
                paddingRight: "0px",
                background: "rgba(48, 192, 198, 0.8)",
                backdropFilter: "blur(10px)}",
              }}
            >
              <Col>
                <div>
                  <div className="titlesolutionresaux">
                    Synchronisation des Réseaux
                  </div>
                </div>

                <div className="navsolutionresaux">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginLeft: "332px",
                    }}
                  >
                    <button
                      className="navsolutionresauxbutton"
                      onClick={() => {
                        document.getElementById("Tp4100").scrollIntoView();
                      }}
                    >
                      <div className="navsolutionresauxtext">TP 4100</div>
                    </button>
                    <button className="navsolutionresauxbutton">
                      <div
                        className="navsolutionresauxtext"
                        onClick={() =>
                          document.getElementById("S600").scrollIntoView()
                        }
                      >
                        S600/S650
                      </div>
                    </button>
                    <button className="navsolutionresauxbutton">
                      <div
                        className="navsolutionresauxtext"
                        onClick={() =>
                          document.getElementById("TimeCesium").scrollIntoView()
                        }
                      >
                        TimeCesium 4400
                      </div>
                    </button>
                    <button className="navsolutionresauxbutton">
                      <div
                        className="navsolutionresauxtext"
                        onClick={() =>
                          document.getElementById("TP5000").scrollIntoView()
                        }
                      >
                        TP 5000
                      </div>
                    </button>
                    <button className="navsolutionresauxbutton">
                      <div
                        className="navsolutionresauxtext"
                        onClick={() =>
                          document.getElementById("SSU 2000").scrollIntoView()
                        }
                      >
                        SSU 2000
                      </div>
                    </button>
                    <button className="navsolutionresauxbutton">
                      <div
                        className="navsolutionresauxtext"
                        onClick={() =>
                          document
                            .getElementById("BlueSky GNSS Firewall")
                            .scrollIntoView()
                        }
                      >
                        BlueSky GNSS Firewall
                      </div>
                    </button>
                    <button className="navsolutionresauxbutton">
                      <div
                        className="navsolutionresauxtext"
                        onClick={() =>
                          document
                            .getElementById("SyncServer 80")
                            .scrollIntoView()
                        }
                      >
                        SyncServer 80
                      </div>
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </Row>
          <Row style={{ marginTop: "89px", position: "relative" }} id="Tp4100">
            <>
              <Col xs={12}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div className="titlesolutionresauxbox">TP 4100</div>
                </div>

                <div
                  className="textsolutionresauxbox"
                  style={{ marginTop: "30px" }}
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div style={{ width: "80%" }}>
                      Basé sur la dernière plateforme technologique de Microchip
                      (Microsemi), le serveur 1U TP4100 permet d’accompagner
                      l’évolution des réseaux vers la 4G et la 5G tout en
                      fournissant des ports TDM pour les anciens équipements
                      avec : 8 ports PTP/ SyncE et NTP, Un module d’extension 16
                      ports E1/ 2MHz.
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={12}>
                <div className="imageresauxbox">
                  <img alt="logo" src={images.image8} style={{ width: " 250px" }} />{" "}
                </div>
              </Col>
            </>
          </Row>
          <Row style={{ marginTop: "87px" }} id="S600">
            <>
              <Col xs={12}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div className="titlesolutionresauxbox">S600/S650</div>
                </div>

                <div
                  className="textsolutionresauxbox"
                  style={{ marginTop: "30px" }}
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div style={{ width: "80%" }}>
                      Basé sur la dernière plateforme technologique de Microchip
                      (Microsemi), le serveur 1U TP4100 permet d’accompagner
                      l’évolution des réseaux vers la 4G et la 5G tout en
                      fournissant des ports TDM pour les anciens équipements
                      avec : 8 ports PTP/ SyncE et NTP, Un module d’extension 16
                      ports E1/ 2MHz.
                    </div>{" "}
                  </div>
                </div>
              </Col>
              <Col xs={12}>
                <div className="imageresauxbox">
                  <img alt="logo" src={images.image9} style={{ width: " 250px" }} />{" "}
                </div>
              </Col>
            </>
          </Row>
          <Row style={{ marginTop: "87px" }} id="TimeCesium">
            <>
              <Col xs={12}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div className="titlesolutionresauxbox">TimeCesium 4400</div>{" "}
                </div>

                <div
                  className="textsolutionresauxbox"
                  style={{ marginTop: "30px" }}
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div style={{ width: "80%" }}>
                      Basé sur la dernière plateforme technologique de Microchip
                      (Microsemi), le serveur 1U TP4100 permet d’accompagner
                      l’évolution des réseaux vers la 4G et la 5G tout en
                      fournissant des ports TDM pour les anciens équipements
                      avec : 8 ports PTP/ SyncE et NTP, Un module d’extension 16
                      ports E1/ 2MHz.
                    </div>{" "}
                  </div>
                </div>
              </Col>
              <Col xs={12}>
                <div className="imageresauxbox">
                  <img alt="logo" src={images.image10} style={{ width: " 250px" }} />{" "}
                </div>
              </Col>
            </>
          </Row>
          <Row style={{ marginTop: "87px" }} id="TP5000">
            <>
              <Col xs={12}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div className="titlesolutionresauxbox">TP 5000</div>{" "}
                </div>

                <div
                  className="textsolutionresauxbox"
                  style={{ marginTop: "30px" }}
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div style={{ width: "80%" }}>
                      Serveur PTP le plus vendu au monde, déployé dans plus de
                      400 réseaux, le TP 5000 est un serveur de haute capacité,
                      entièrement redondant, destiné au cœur du réseau pour les
                      besoins de synchronisation en fréquence ou en phase. Il
                      supporte tous les profils PTP définis par l’ITU, ainsi que
                      la SyncE et le NTP
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={12}>
                <div className="imageresauxbox">
                  <img alt="logo" src={images.image11} style={{ width: " 250px" }} />{" "}
                </div>
              </Col>
            </>
          </Row>
          <Row style={{ marginTop: "87px" }} id="SSU 2000">
            <>
              <Col xs={12}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div className="titlesolutionresauxbox">SSU 2000</div>{" "}
                </div>

                <div
                  className="textsolutionresauxbox"
                  style={{ marginTop: "30px" }}
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div style={{ width: "80%" }}>
                      Les SSU 2000 et SSU 2000e sont conçus pour répondre aux
                      normes les plus récentes et en constante évolution de
                      l'industrie et sont utilisés par les opérateurs de réseaux
                      de communication pour générer et distribuer des signaux de
                      synchronisation pour leurs réseaux. Les deux systèmes
                      utilisent les mêmes cartes enfichables et fournissent des
                      capacités de synchronisation de réseau de fréquences pour
                      les réseaux SDH/SONET.
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={12}>
                <div className="imageresauxbox">
                  <img alt="logo" src={images.image12} style={{ width: " 250px" }} />{" "}
                </div>
              </Col>
            </>
          </Row>
          <Row style={{ marginTop: "87px" }} id="BlueSky GNSS Firewall">
            <>
              <Col xs={12}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div className="titlesolutionresauxbox">
                    BlueSky GNSS Firewall
                  </div>{" "}
                </div>

                <div
                  className="textsolutionresauxbox"
                  style={{ marginTop: "30px" }}
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div style={{ width: "80%" }}>
                      Le Firewall GNSS BlueSky protège les systèmes GNSS déjà
                      déployés en fournissant une solution rentable installée
                      entre les antennes GNSS existantes et les systèmes GNSS.
                      Semblable à un pare-feu réseau, BlueSky protège les
                      systèmes à l'intérieur du pare-feu contre les signaux non
                      fiables à l'extérieur du pare-feu.
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={12}>
                <div className="imageresauxbox">
                  <img alt="logo" src={images.image13} style={{ width: " 250px" }} />{" "}
                </div>
              </Col>
            </>
          </Row>
          <Row style={{ marginTop: "87px" }} id="SyncServer 80">
            <>
              <Col xs={12}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div className="titlesolutionresauxbox">SyncServer 80</div>{" "}
                </div>

                <div
                  className="textsolutionresauxbox"
                  style={{ marginTop: "30px" }}
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div style={{ width: "80%" }}>
                      Le SyncServer S80 est une antenne GPS/GLONASS composée par
                      un récepteur, un serveur NTP et une interface Power over
                      Ethernet (PoE) qui s'intègre facilement dans
                      l'infrastructure PoE existante pour être immédiatement la
                      source d'horodatages précis, sécurisés et fiables pour
                      tous les appareils connectés au réseau. Les systèmes de
                      sécurité physique isolés du réseau en bénéficient car le
                      serveur de temps réseau renforcé Stratum 1 est idéal pour
                      la synchronisation temporelle des caméras de sécurité IP,
                      des dispositifs de contrôle d'accès et des enregistreurs
                      vidéo numériques/réseau.
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={12}>
                <div className="imageresauxbox">
                  <img alt="logo" src={images.image14} style={{ width: " 250px" }} />{" "}
                </div>
              </Col>
            </>
          </Row>
          <div style={{ marginTop: "100px" }}>
            <div className="title2serviceconsulting">
              <img
                className="lineserviceconsulting"
                style={{ marginLeft: "-30px" }}
                src={images.Rectangle13}
                alt="logo"
              />
              <div style={{ marginLeft: "30px" }}>{t("Solutions.Autre")}</div>
            </div>
          </div>
          <Row style={{ justifyContent: "center", marginTop: "100px" }}>
            <div style={{ width: "50%" }}>
              <div className="textAutreservice">Outils de mesures</div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button className="buttonAutreservice">
                  {" "}
                  <div className="textbuttoncardservice">
                    {t("Voirs.title")}
                  </div>
                </button>
              </div>
            </div>

            <div
              style={{
                width: "50%",
                marginLeft: "30.31px",
                marginTop: "100px",
              }}
            >
              <div className="textAutreservice">Mobile Finance</div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button className="buttonAutreservice">
                  {" "}
                  <div className="textbuttoncardservice">
                    {t("Voirs.title")}
                  </div>
                </button>
              </div>
            </div>
            <div
              style={{
                width: "50%",
                marginLeft: "30.31px",
                marginTop: "100px",
              }}
            >
              <div className="textAutreservice">
                {t("Solutions.QoS / QoE et Mesure des performances")}
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button className="buttonAutreservice">
                  {" "}
                  <div className="textbuttoncardservice">
                    {t("Voirs.title")}
                  </div>
                </button>
              </div>
            </div>
            <div
              style={{
                width: "50%",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                marginTop: "100px",
              }}
            >
              <div className="textAutreservice">
                Analyse, optimisation et monétisation du trafic - DPI
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button className="buttonAutreservice">
                  {" "}
                  <div className="textbuttoncardservice">
                    {t("Voirs.title")}
                  </div>
                </button>
              </div>
            </div>
          </Row>
          <Row>
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "100px",
                }}
              >
                <div className="titleserviceconsultingcontact">
                  {t("Serivces.contact")}
                </div>
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div style={{ width: "80%" }}>
                <FormulaireC position={position}/>
              </div>
            </div>
          </Row>
        </Mobile>

      </>
  :  (Number(id) === 1) ?
      <>
        <Desktop>
       
          <Row>
            <Row
              style={{
                height: "222px",
                background: "rgba(48, 192, 198, 0.8)",
                backdropFilter: "blur(10px)}",
              }}
            >
              <Col>
                <div className="titlesolutionresaux">
                  {/* {t("Solutions.QoS / QoE et Mesure des performances")} */}
                  {solutions && solutions[0]&& solutions[0].titre && solutions[0].titre } 
                </div>
                <div className="navsolutionresaux">
                  <button
                    className="navsolutionresauxbutton"
                    onClick={() =>
                      document.getElementById("Proboscope").scrollIntoView()
                    }
                  >
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <div className="navsolutionresauxtext">
                        {t("SolutionsDetails.Proboscope")}
                      </div>
                    </div>
                  </button>
                  <button className="navsolutionresauxbutton">
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <div
                        className="navsolutionresauxtext"
                        onClick={() =>
                          document.getElementById("Qual IT").scrollIntoView()
                        }
                      >
                        {t("SolutionsDetails.Qual IT")}
                      </div>{" "}
                    </div>
                  </button>
                  <button className="navsolutionresauxbutton">
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <div
                        className="navsolutionresauxtext"
                        onClick={() =>
                          document.getElementById("Hawkeye").scrollIntoView()
                        }
                      >
                        {t("SolutionsDetails.Hawkeye")}
                      </div>{" "}
                    </div>
                  </button>
                </div>
              </Col>
            </Row>
          </Row>
          <Row
            style={{ marginTop: "87px", position: "relative" }}
            id="Proboscope"
          >
            <>
              <Col
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div className="imageresauxbox">
                  <img alt="logo" src={images.image16} />{" "}
                </div>
              </Col>
              <Col>
                <div className="titlesolutionresauxbox">
                  {t("SolutionsDetails.Proboscope")}
                </div>
                <div
                  className="textsolutionresauxbox"
                  style={{ marginTop: "30px", marginRight: "121px" }}
                >
                  {t("SolutionsDetails.text Proboscope")}
                </div>
              </Col>
            </>
          </Row>
          <Row style={{ marginTop: "87px" }} id="Qual IT">
            <>
              <Col>
                <div
                  className="titlesolutionresauxbox"
                  style={{ marginLeft: "95px" }}
                >
                  {t("SolutionsDetails.Qual IT")}
                </div>
                <div
                  className="textsolutionresauxbox"
                  style={{ marginLeft: "95px", marginTop: "30px" }}
                >
                  {t("SolutionsDetails.text Qual IT")}
                </div>
              </Col>
              <Col
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div className="imageresauxbox">
                    <img alt="logo" src={images.image17} />{" "}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      width: "565px",
                    }}
                  >
                    <button
                      className="buttonAutreservice"
                      style={{ marginTop: "133px", width: "300px" }}
                    >
                      {" "}
                      <div
                        className="textbuttoncardservice"
                        onClick={() => {
                          setNameficherQos("QUALIT");

                          setOpenQos(true);
                        }}
                      >
                         {t("Solutions.Téléchargez le Data sheet")}
                      </div>
                    </button>
                    <button
                      className="buttonAutreservice"
                      style={{ marginTop: "133px", width: "200px" }}
                    >
                      {" "}
                      <div
                        className="textbuttoncardservice"
                        onClick={() => {
                          setOpennewQos(true);
                        }}
                      >
                        {t("Serivces.contact")}
                      </div>
                    </button>
                  </div>
                </div>
              </Col>
            </>
          </Row>
          <Row style={{ marginTop: "87px" }} id="Hawkeye">
            <>
              <Col
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div className="imageresauxbox">
                    <img alt="logo" src={images.image18} />{" "}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      width: "565px",
                    }}
                  >
                    <button
                      className="buttonAutreservice"
                      style={{ marginTop: "133px", width: "300px" }}
                    >
                      {" "}
                      <div
                        className="textbuttoncardservice"
                        onClick={() => {
                          setNameficherQos("Hawkeye");

                          setOpenQos(true);
                        }}
                      >
                         {t("Solutions.Téléchargez le Data sheet")}
                      </div>
                    </button>
                    <button
                      className="buttonAutreservice"
                      style={{ marginTop: "133px", width: "200px" }}
                    >
                      {" "}
                      <div
                        className="textbuttoncardservice"
                        onClick={() => {
                          setOpennewQos(true);
                        }}
                      >
                        {t("Serivces.contact")}
                      </div>
                    </button>
                  </div>
                </div>
              </Col>
              <Col>
                <div className="titlesolutionresauxbox">
                  {t("SolutionsDetails.Hawkeye")}
                </div>
                <div
                  className="textsolutionresauxbox"
                  style={{ marginTop: "30px", marginRight: "121px" }}
                >
                  {t("SolutionsDetails.text Hawkeye")}
                </div>
              </Col>
            </>
          </Row>

          <div style={{ marginTop: "100px" }}>
            <img
              alt="logo"
              className="lineserviceconsulting"
              src={images.Rectangle13}
            />
          </div>
          <div className="title2serviceconsulting">{t("Solutions.Autre")}</div>
          <Col style={{display:'flex', justifyContent: "center"}}>
      
                {solutions &&
                  solutions
                    .filter((e, index) => e.id !== Number(id)  ) 
                    .map((solutionCaroussel, key) => (
            // solutions.filter((solutionCaroussel, index) => solutionCaroussel.id !== Number(id))
           
              <>
              {/* <Row style={{justifyContent: "center",display: "flex",}}> */}
               
              <Card key={key}>
                <Card.Body
                  style={{
                    marginTop: "20px",
                  }}
                >
                  <Card.Text>
                    <p>{solutionCaroussel.titre}</p>
                  </Card.Text>
                  <button className="buttoncardservice2">
                    {" "}
                    <div
                      className="textbuttoncardservice"
                      onClick={() => {
                        navigate(`/solution/${solutionCaroussel.id}`);
                      
                      }}
                    >
                      {t("Voirs.title")}
                    </div>
                  </button>
                </Card.Body>
              </Card>
        
              {/* </Row> */}
        
              </>
            ))}
             
            {/* </Carousel> */}
            {/* </Col> */}
           
          </Col>
          <Row>
            {/* <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "100px",
                }}
              >
                <div className="titleserviceconsultingcontact">
                  {t("Serivces.contact")}
                </div>
              </div>
            </div> */}
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div style={{ width: "100%" }}>
                <FormulaireC position={position}/>
              </div>
            </div>
          </Row>
          <Modal1
            handleClose={handleCloseQos}
            open={openQos}
            opennew={opennewQos}
            setOpennew={setOpennewQos}
            setOpen={setOpenQos}
            nameficher={nameficherQos}
          />
        </Desktop>
        <Tablet>
          <Row>
            <Col
              xs={12}
              style={{
                backgroundColor: "#253D8A",
                minHeight: "60px",
                height: "auto",
                position: "fixed",
                zIndex: 2,
              }}
            >
              <Menubarone />
            </Col>

            <Col
              xs={12}
              style={{
                minHeight: "120px",
                zIndex: 2,
                height: "auto",
                width: "100%",
                position: "fixed",
                marginTop: "60px",
                backgroundColor: "white",
              }}
            >
              <MenuBar home={1} setHome={1}/>
            </Col>
          </Row>
          <Row>
            <Row
              style={{
                height: "222px",
                zIndex: 2,
                position: "fixed",
                marginTop: "180px",
                marginLeft: "10px",
                background: "rgba(48, 192, 198, 0.8)",
                backdropFilter: "blur(10px)}",
              }}
            >
              <Col>
                <div>
                  <div className="titlesolutionresaux">
                    Synchronisation des Réseaux
                  </div>
                </div>

                <div className="navsolutionresaux">
                  <button
                    className="navsolutionresauxbutton"
                    onClick={() => {
                      document.getElementById("Proboscope").scrollIntoView();
                    }}
                  >
                    <div className="navsolutionresauxtext">Proboscope</div>
                  </button>
                  <button className="navsolutionresauxbutton">
                    <div
                      className="navsolutionresauxtext"
                      onClick={() =>
                        document.getElementById("Qual IT").scrollIntoView()
                      }
                    >
                      Qual IT
                    </div>
                  </button>
                  <button className="navsolutionresauxbutton">
                    <div
                      className="navsolutionresauxtext"
                      onClick={() =>
                        document.getElementById("Hawkeye").scrollIntoView()
                      }
                    >
                      Hawkeye
                    </div>
                  </button>
                </div>
              </Col>
            </Row>
          </Row>
          <Row
            style={{ marginTop: "489px", position: "relative" }}
            id="Proboscope"
          >
            <>
              <Col xs={12}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div className="titlesolutionresauxbox">Proboscope</div>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div
                    className="textsolutionresauxbox"
                    style={{ marginTop: "30px" }}
                  >
                    Proboscope est un produit développé à 100% par les
                    ingénieurs de PRISMA. Il assure la supervision de vos
                    applications et due votre réseau ainsi que le monitoring
                    proactif des performances de vos infrastructures. La
                    solution permet de valider la QoS et les SLA des opérateurs
                    aussi bien Que la capacité du réseau à supporter de
                    nouvelles applications avant déploiement tout en
                    représentant les résultats dans des tableaux de bords et
                    rapports personnalisés.
                  </div>
                </div>
              </Col>
              <Col xs={12}>
                <div className="imageresauxbox">
                  <img alt="logo" src={images.image16} style={{ width: " 250px" }} />{" "}
                </div>
              </Col>
            </>
          </Row>
          <Row style={{ marginTop: "87px" }} id="Qual IT">
            <>
              <Col xs={12}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div className="titlesolutionresauxbox">Qual IT</div>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div
                    className="textsolutionresauxbox"
                    style={{ marginTop: "30px" }}
                  >
                    La solution mesure la performance et la disponibilité de
                    votre infrastructure réseau et de vos applications
                    critiques. Elle est basée sur des sondes passives ayant pour
                    fonction d’émuler un client ou un serveur. Chaque agent
                    reçoit de la console QUAL’IT des scripts de simulation de
                    l’application à exécuter. Ils calculent ensuite le ressenti
                    utilisateur aussi bien pour des applications métiers que
                    pour des applications temps réelles, telles que la VoIP ou
                    la Vidéo.
                  </div>
                </div>
              </Col>
              <Col xs={12}>
                <div className="imageresauxbox">
                  <img alt="logo" src={images.image17} style={{ width: " 250px" }} />{" "}
                </div>
              </Col>
            </>
          </Row>
          <Row style={{ marginTop: "87px" }} id="Hawkeye">
            <>
              <Col xs={12}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div className="titlesolutionresauxbox">Hawkeye</div>{" "}
                </div>

                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div
                    className="textsolutionresauxbox"
                    style={{ marginTop: "30px" }}
                  >
                    Monitorant votre réseau de manière active, Hawkeye effectue
                    des évaluations continues en injectant du trafic
                    "prévisionnel" afin de mesurer, contrôler, résoudre et
                    vérifier votre infrastructure réseau. Hawkeye est utilisé
                    par de nombreux opérateurs et entreprises à travers le monde
                    pour assurer la qualité et les performances des opérations
                    réseaux quotidiennes.
                  </div>
                </div>
              </Col>
              <Col xs={12}>
                <div className="imageresauxbox">
                  <img alt="logo" src={images.image18} style={{ width: " 250px" }} />{" "}
                </div>
              </Col>
            </>
          </Row>

          <div style={{ marginTop: "100px" }}>
            <div className="title2serviceconsulting">
              <img
                className="lineserviceconsulting"
                style={{ marginLeft: "-30px" }}
                src={images.Rectangle13}
                alt="logo"
              />
              <div style={{ marginLeft: "30px", marginTop: "-60px" }}>
                {t("Solutions.Autre")}
              </div>
            </div>
          </div>
          <Col style={{display:'flex', justifyContent: "center"}}>
       
                {solutions &&
                  solutions
                    .filter((e, index) => e.id !== Number(id) &&  index >= 3 ) 
                    .map((solutionCaroussel, key) => (
            // solutions.filter((solutionCaroussel, index) => solutionCaroussel.id !== Number(id))
           
              <>
              {/* <Row style={{justifyContent: "center",display: "flex",}}> */}
               
              <Card key={key}>
                <Card.Body
                  style={{
                    marginTop: "20px",
                  }}
                >
                  <Card.Text>
                    <p>{solutionCaroussel.titre}</p>
                  </Card.Text>
                  <button className="buttoncardservice2">
                    {" "}
                    <div
                      className="textbuttoncardservice"
                      onClick={() => {
                        navigate(`/solution/${solutionCaroussel.id}`);
                      
                      }}
                    >
                      {t("Voirs.title")}
                    </div>
                  </button>
                </Card.Body>
              </Card>
        
              {/* </Row> */}
        
              </>
            ))}
             
            {/* </Carousel> */}
            {/* </Col> */}
           
          </Col>
          <Row>
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "100px",
                }}
              >
                <div className="titleserviceconsultingcontact">
                  {t("Serivces.contact")}
                </div>
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div style={{ width: "100%" }}>
                <FormulaireC position={position}/>
              </div>
            </div>
          </Row>
        </Tablet>
        <Mobile>
          <Row>
            <Col
              xs={12}
              style={{
                backgroundColor: "#253D8A",
                minHeight: "60px",
                height: "auto",
              }}
            >
              <Menubarone />
            </Col>

            <Col
              xs={12}
              style={{
                minHeight: "120px",
                zIndex: 2,
                height: "auto",
                width: "100%",
                marginTop: "0px",
                backgroundColor: "white",
              }}
            >
              <MenuBar home={1} setHome={1}/>
            </Col>
          </Row>
          <Row>
            {/* <Row style={{height: "222px",zIndex:2,position:"fixed",marginTop:"180px",marginLeft:"10px",paddingLeft:"0px",paddingRight:"0px"
      ,
background: "rgba(48, 192, 198, 0.8)",
backdropFilter: "blur(10px)}"}}> */}
            <Row
              style={{
                height: "222px",
                marginTop: "0px",
                marginLeft: "10px",
                paddingLeft: "0px",
                paddingRight: "0px",
                background: "rgba(48, 192, 198, 0.8)",
                backdropFilter: "blur(10px)}",
              }}
            >
              <Col>
                <div>
                  <div className="titlesolutionresaux">
                    Synchronisation des Réseaux
                  </div>
                </div>

                <div className="navsolutionresaux">
                  <button
                    className="navsolutionresauxbutton"
                    onClick={() => {
                      document.getElementById("Proboscope").scrollIntoView();
                    }}
                  >
                    <div className="navsolutionresauxtext">Proboscope</div>
                  </button>
                  <button className="navsolutionresauxbutton">
                    <div
                      className="navsolutionresauxtext"
                      onClick={() =>
                        document.getElementById("Qual IT").scrollIntoView()
                      }
                    >
                      Qual IT
                    </div>
                  </button>
                  <button className="navsolutionresauxbutton">
                    <div
                      className="navsolutionresauxtext"
                      onClick={() =>
                        document.getElementById("Hawkeye").scrollIntoView()
                      }
                    >
                      Hawkeye
                    </div>
                  </button>
                </div>
              </Col>
            </Row>
          </Row>
          <Row
            style={{ marginTop: "89px", position: "relative" }}
            id="Proboscope"
          >
            <>
              <Col xs={12}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div className="titlesolutionresauxbox">Proboscope</div>
                </div>
                <div
                  className="textsolutionresauxbox"
                  style={{ marginTop: "30px" }}
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div style={{ width: "80%" }}>
                      Proboscope est un produit développé à 100% par les
                      ingénieurs de PRISMA. Il assure la supervision de vos
                      applications et due votre réseau ainsi que le monitoring
                      proactif des performances de vos infrastructures. La
                      solution permet de valider la QoS et les SLA des
                      opérateurs aussi bien Que la capacité du réseau à
                      supporter de nouvelles applications avant déploiement tout
                      en représentant les résultats dans des tableaux de bords
                      et rapports personnalisés.
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={12}>
                <div className="imageresauxbox">
                  <img alt="logo" src={images.image16} style={{ width: " 250px" }} />{" "}
                </div>
              </Col>
            </>
          </Row>
          <Row style={{ marginTop: "87px" }} id="Qual IT">
            <>
              <Col xs={12}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div className="titlesolutionresauxbox">Qual IT</div>
                </div>
                <div
                  className="textsolutionresauxbox"
                  style={{ marginTop: "30px" }}
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div style={{ width: "80%" }}>
                      La solution mesure la performance et la disponibilité de
                      votre infrastructure réseau et de vos applications
                      critiques. Elle est basée sur des sondes passives ayant
                      pour fonction d’émuler un client ou un serveur. Chaque
                      agent reçoit de la console QUAL’IT des scripts de
                      simulation de l’application à exécuter. Ils calculent
                      ensuite le ressenti utilisateur aussi bien pour des
                      applications métiers que pour des applications temps
                      réelles, telles que la VoIP ou la Vidéo.
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={12}>
                <div className="imageresauxbox">
                  <img alt="logo" src={images.image17} style={{ width: " 250px" }} />{" "}
                </div>
              </Col>
            </>
          </Row>
          <Row style={{ marginTop: "87px" }} id="Hawkeye">
            <>
              <Col xs={12}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div className="titlesolutionresauxbox">Hawkeye</div>{" "}
                </div>

                <div
                  className="textsolutionresauxbox"
                  style={{ marginTop: "30px" }}
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div style={{ width: "80%" }}>
                      Monitorant votre réseau de manière active, Hawkeye
                      effectue des évaluations continues en injectant du trafic
                      "prévisionnel" afin de mesurer, contrôler, résoudre et
                      vérifier votre infrastructure réseau. Hawkeye est utilisé
                      par de nombreux opérateurs et entreprises à travers le
                      monde pour assurer la qualité et les performances des
                      opérations réseaux quotidiennes.
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={12}>
                <div className="imageresauxbox">
                  <img alt="logo" src={images.image18} style={{ width: " 250px" }} />{" "}
                </div>
              </Col>
            </>
          </Row>

          <div style={{ marginTop: "100px" }}>
            <div className="title2serviceconsulting">
              <img
                className="lineserviceconsulting"
                style={{ marginLeft: "-30px" }}
                src={images.Rectangle13}
                alt="logo"
              />
              <div style={{ marginLeft: "30px" }}>{t("Solutions.Autre")}</div>
            </div>
          </div>
          <Row style={{ justifyContent: "center", marginTop: "100px" }}>
            <div style={{ width: "50%" }}>
              <div className="textAutreservice">Outils de mesures</div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button className="buttonAutreservice">
                  {" "}
                  <div className="textbuttoncardservice">
                    {t("Voirs.title")}
                  </div>
                </button>
              </div>
            </div>

            <div
              style={{
                width: "50%",
                marginLeft: "30.31px",
                marginTop: "100px",
              }}
            >
              <div className="textAutreservice">Mobile Finance</div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button className="buttonAutreservice">
                  {" "}
                  <div className="textbuttoncardservice">
                    {t("Voirs.title")}
                  </div>
                </button>
              </div>
            </div>
            <div
              style={{
                width: "50%",
                marginLeft: "30.31px",
                marginTop: "100px",
              }}
            >
              <div className="textAutreservice">
                QoS / QoE et Mesure des performances
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button className="buttonAutreservice">
                  {" "}
                  <div className="textbuttoncardservice">
                    {t("Voirs.title")}
                  </div>
                </button>
              </div>
            </div>
            <div
              style={{
                width: "50%",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                marginTop: "100px",
              }}
            >
              <div className="textAutreservice">
                Analyse, optimisation et monétisation du trafic - DPI
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button className="buttonAutreservice">
                  {" "}
                  <div className="textbuttoncardservice">
                    {t("Voirs.title")}
                  </div>
                </button>
              </div>
            </div>
          </Row>
          <Row>
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "100px",
                }}
              >
                <div className="titleserviceconsultingcontact">
                  {t("Serivces.contact")}
                </div>
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div style={{ width: "80%" }}>
                <FormulaireC position={position}/>
              </div>
            </div>
          </Row>
        </Mobile>
       
      </>
  :  (Number(id) === 4) ?
      <>
        <Desktop>
       
          <Row>
            <Row
              style={{
                height: "222px",
                background: "rgba(48, 192, 198, 0.8)",
                backdropFilter: "blur(10px)}",
              }}
            >
              <Col>
                <div className="titlesolutionresaux">
                  {/* {t("SolutionsDetails.Test de charges et des performances")} */}
                  {solutions && solutions[3]&& solutions[3].titre && solutions[3].titre } 
                </div>
                <div className="navsolutionresaux">
                  <button
                    className="navsolutionresauxbutton"
                    onClick={() =>
                      document.getElementById("IxLoad").scrollIntoView()
                    }
                  >
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      {" "}
                      <div className="navsolutionresauxtext">
                        {" "}
                        {t("SolutionsDetails.IxLoad​")}
                      </div>
                    </div>
                  </button>
                  <button className="navsolutionresauxbutton">
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      {" "}
                      <div
                        className="navsolutionresauxtext"
                        onClick={() =>
                          document.getElementById("IxNetwork").scrollIntoView()
                        }
                      >
                        {t("SolutionsDetails.IxNetwork")}
                      </div>{" "}
                    </div>
                  </button>
                </div>
              </Col>
            </Row>
          </Row>
          <Row style={{ marginTop: "87px", position: "relative" }} id="IxLoad">
            <>
              <Col>
                <div className="imageresauxbox">
                  <img alt="logo" src={images.image19} />{" "}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "565px",
                    marginLeft: "120px",
                  }}
                >
                  <button
                    className="buttonAutreservice"
                    style={{ marginTop: "133px", width: "300px" }}
                  >
                    {" "}
                    <div
                      className="textbuttoncardservice"
                      onClick={() => {
                        setNameficherTest("IxLoad");

                        setOpenTest(true);
                      }}
                    >
                       {t("Solutions.Téléchargez le Data sheet")}
                    </div>
                  </button>
                  <button
                    className="buttonAutreservice"
                    style={{ marginTop: "133px", width: "200px" }}
                  >
                    {" "}
                    <div
                      className="textbuttoncardservice"
                      onClick={() => {
                        setOpennewTest(true);
                      }}
                    >
                      {t("Serivces.contact")}
                    </div>
                  </button>
                </div>
              </Col>
              <Col>
                <div className="titlesolutionresauxbox">
                  {" "}
                  {t("SolutionsDetails.IxLoad​")}
                </div>
                <div
                  className="textsolutionresauxbox"
                  style={{ marginTop: "30px", marginRight: "121px" }}
                >
                  {t("SolutionsDetails.text IxLoad​")}
                </div>
              </Col>
            </>
          </Row>
          <Row style={{ marginTop: "87px" }} id="IxNetwork">
            <>
              <Col>
                <div
                  className="titlesolutionresauxbox"
                  style={{ marginLeft: "95px" }}
                >
                  {t("SolutionsDetails.IxNetwork")}
                </div>
                <div
                  className="textsolutionresauxbox"
                  style={{ marginLeft: "95px", marginTop: "30px" }}
                >
                  {t("SolutionsDetails.text IxNetwork")}
                </div>
              </Col>
              <Col>
                <div className="imageresauxbox" style={{ marginTop: "-10px" }}>
                  <img alt="logo" src={images.image20} />{" "}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "565px",
                  }}
                >
                  <button
                    className="buttonAutreservice"
                    style={{ marginTop: "133px", width: "300px" }}
                  >
                    {" "}
                    <div
                      className="textbuttoncardservice"
                      onClick={() => {
                        setNameficherTest("IxNetwork");

                        setOpenTest(true);
                      }}
                    >
                       {t("Solutions.Téléchargez le Data sheet")}
                    </div>
                  </button>
                  <button
                    className="buttonAutreservice"
                    style={{ marginTop: "133px", width: "200px" }}
                  >
                    {" "}
                    <div
                      className="textbuttoncardservice"
                      onClick={() => {
                        setOpennewTest(true);
                      }}
                    >
                      {t("Serivces.contact")}
                    </div>
                  </button>
                </div>
              </Col>
            </>
          </Row>

          <div style={{ marginTop: "100px" }}>
            <img
              alt="logo"
              className="lineserviceconsulting"
              src={images.Rectangle13}
            />
          </div>
          <div className="title2serviceconsulting">{t("Solutions.Autre")}</div>
       
          <Col style={{display:'flex', justifyContent: "center"}}>

                {solutions &&
                  solutions
                    .filter((e, index) => e.id !== Number(id)  ) 
                    .map((solutionCaroussel, key) => (
            // solutions.filter((solutionCaroussel, index) => solutionCaroussel.id !== Number(id))
           
              <>
              {/* <Row style={{justifyContent: "center",display: "flex",}}> */}
               
              <Card key={key}>
                <Card.Body
                  style={{
                    marginTop: "20px",
                  }}
                >
                  <Card.Text>
                    <p>{solutionCaroussel.titre}</p>
                  </Card.Text>
                  <button className="buttoncardservice2">
                    {" "}
                    <div
                      className="textbuttoncardservice"
                      onClick={() => {
                        navigate(`/solution/${solutionCaroussel.id}`);
                      
                      }}
                    >
                      {t("Voirs.title")}
                    </div>
                  </button>
                </Card.Body>
              </Card>
              </>
            ))}
             
   
          </Col>
          {/* //FormulaireContact  */}
          <Row>
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "100px",
                }}
              >
                <div className="titleserviceconsultingcontact">
                  {t("Serivces.contact")}
                </div>
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div style={{ width: "100%" }}>
                <FormulaireC position={position}/>
              </div>
            </div>
          </Row>
          <Modal1
            handleClose={handleCloseTest}
            open={openTest}
            opennew={opennewTest}
            setOpennew={setOpennewTest}
            setOpen={setOpenTest}
            nameficher={nameficherTest}
          />
        </Desktop>
        <Tablet>
          <Row>
            <Col
              xs={12}
              style={{
                backgroundColor: "#253D8A",
                minHeight: "60px",
                height: "auto",
                position: "fixed",
                zIndex: 2,
              }}
            >
              <Menubarone />
            </Col>

            <Col
              xs={12}
              style={{
                minHeight: "120px",
                zIndex: 2,
                height: "auto",
                width: "100%",
                position: "fixed",
                marginTop: "60px",
                backgroundColor: "white",
              }}
            >
              <MenuBar home={1} setHome={1}/>
            </Col>
          </Row>
          <Row>
            <Row
              style={{
                height: "222px",
                zIndex: 2,
                position: "fixed",
                marginTop: "180px",
                marginLeft: "10px",
                background: "rgba(48, 192, 198, 0.8)",
                backdropFilter: "blur(10px)}",
              }}
            >
              <Col>
                <div>
                  <div className="titlesolutionresaux">
                    Synchronisation des Réseaux
                  </div>
                </div>

                <div className="navsolutionresaux">
                  <button className="navsolutionresauxbutton">
                    <div
                      className="navsolutionresauxtext"
                      onClick={() =>
                        document.getElementById("IxLoad").scrollIntoView()
                      }
                    >
                      IxLoad
                    </div>
                  </button>
                  <button className="navsolutionresauxbutton">
                    <div
                      className="navsolutionresauxtext"
                      onClick={() =>
                        document.getElementById("IxNetwork").scrollIntoView()
                      }
                    >
                      IxNetwork
                    </div>
                  </button>
                </div>
              </Col>
            </Row>
          </Row>

          <Row style={{ marginTop: "489px", position: "relative" }} id="IxLoad">
            <>
              <Col xs={12}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div className="titlesolutionresauxbox">IxLoad</div>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div
                    className="textsolutionresauxbox"
                    style={{ marginTop: "30px" }}
                  >
                    Test de performance L4-7 IxLoad émule des centaines de
                    milliers d'abonnés utilisant la gamme complète de services
                    voix, vidéo et données. Sa modélisation unique des abonnés
                    fournit des mélanges de trafic utilisateur, permettant aux
                    fournisseurs de services d'assurer la qualité de
                    l'expérience tout au long de la journée. Le châssis et les
                    modules de charge offrent l'échelle nécessaire pour
                    correspondre à la capacité de n'importe quel appareil ou
                    système.
                  </div>
                </div>
              </Col>
              <Col xs={12}>
                <div className="imageresauxbox">
                  <img alt="logo" src={images.image19} style={{ width: " 250px" }} />{" "}
                </div>
              </Col>
            </>
          </Row>
          <Row style={{ marginTop: "87px" }} id="IxNetwork">
            <>
              <Col xs={12}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div className="titlesolutionresauxbox">IxNetwork</div>{" "}
                </div>

                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div
                    className="textsolutionresauxbox"
                    style={{ marginTop: "30px" }}
                  >
                    Test de performance de l'infrastructure réseau L2-3
                    IxNetwork est capable de générer plusieurs téraoctets de
                    données et d'analyser jusqu'à 4 millions de flux de trafic
                    simultanément. Cette solution puissante émule tout, du
                    routage à la commutation, le LAN, le SDN et l'accès à large
                    bande pour réaliser des tests complets.
                  </div>
                </div>
              </Col>
              <Col xs={12}>
                <div className="imageresauxbox">
                  <img alt="logo" src={images.image20} style={{ width: " 250px" }} />{" "}
                </div>
              </Col>
            </>
          </Row>

          <div style={{ marginTop: "100px" }}>
            <div className="title2serviceconsulting">
              <img
                className="lineserviceconsulting"
                style={{ marginLeft: "-30px" }}
                src={images.Rectangle13}
                alt="logo"
              />
              <div style={{ marginLeft: "30px", marginTop: "-60px" }}>
                {t("Solutions.Autre")}
              </div>
            </div>
          </div>

          <Row style={{ justifyContent: "center" }}>
            <div style={{ width: "248px", marginTop: "50px" }}>
              <div className="textAutreservice">Outils de mesures</div>
              <button
                className="buttonAutreservice"
                style={{ marginTop: "99px" }}
              >
                {" "}
                <div
                  className="textbuttoncardservice"
                  onClick={() => {
                    // props.setSol(2);
                    navigate("/solution/2");
                  }}
                >
                  {t("Voirs.title")}
                </div>
              </button>
            </div>

            <div style={{ width: "248px", marginTop: "50px" }}>
              <div className="textAutreservice">Mobile Finance</div>
              <button
                className="buttonAutreservice"
                style={{ marginTop: "131px" }}
              >
                {" "}
                <div
                  className="textbuttoncardservice"
                  onClick={() => {
                    // props.setSol(4);
                    navigate("/solution/4");
                  }}
                >
                  {t("Voirs.title")}
                </div>
              </button>
            </div>
            <div style={{ width: "248px", marginTop: "50px" }}>
              <div className="textAutreservice">
                QoS / QoE et Mesure des performances
              </div>
              <button
                className="buttonAutreservice"
                style={{ marginTop: "67px" }}
              >
                {" "}
                <div
                  className="textbuttoncardservice"
                  onClick={() => {
                    // props.setSol(3);
                    navigate("/solution/3");
                  }}
                >
                  {t("Voirs.title")}
                </div>
              </button>
            </div>
            <div style={{ width: "248px", marginTop: "50px" }}>
              <div className="textAutreservice">
                Analyse, optimisation et monétisation du trafic - DPI
              </div>
              <button className="buttonAutreservice">
                {" "}
                <div
                  className="textbuttoncardservice"
                  onClick={() => {
                    // props.setSol(5);
                    navigate("/solution/5");
                  }}
                >
                  {t("Voirs.title")}
                </div>
              </button>
            </div>
          </Row>
        
                <Row>
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "100px",
                }}
              >
                <div className="titleserviceconsultingcontact">
                  {t("Serivces.contact")}
                </div>
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div style={{ width: "80%" }}>
                <FormulaireC position={position}/>
              </div>
            </div>
          </Row>
         
        </Tablet>
        <Mobile>
          <Row>
            <Col
              xs={12}
              style={{
                backgroundColor: "#253D8A",
                minHeight: "60px",
                height: "auto",
              }}
            >
              <Menubarone />
            </Col>

            <Col
              xs={12}
              style={{
                minHeight: "120px",
                zIndex: 2,
                height: "auto",
                width: "100%",
                marginTop: "0px",
                backgroundColor: "white",
              }}
            >
              <MenuBar home={1} setHome={1}/>
            </Col>
          </Row>
          <Row>
            {/* <Row style={{height: "222px",zIndex:2,position:"fixed",marginTop:"180px",marginLeft:"10px",paddingLeft:"0px",paddingRight:"0px"
      ,
background: "rgba(48, 192, 198, 0.8)",
backdropFilter: "blur(10px)}"}}> */}
            <Row
              style={{
                height: "222px",
                marginTop: "0px",
                marginLeft: "10px",
                paddingLeft: "0px",
                paddingRight: "0px",
                background: "rgba(48, 192, 198, 0.8)",
                backdropFilter: "blur(10px)}",
              }}
            >
              <Col>
                <div>
                  <div className="titlesolutionresaux">
                    Synchronisation des Réseaux
                  </div>
                </div>

                <div className="navsolutionresaux">
                  <button className="navsolutionresauxbutton">
                    <div
                      className="navsolutionresauxtext"
                      onClick={() =>
                        document.getElementById("IxLoad").scrollIntoView()
                      }
                    >
                      IxLoad
                    </div>
                  </button>
                  <button className="navsolutionresauxbutton">
                    <div
                      className="navsolutionresauxtext"
                      onClick={() =>
                        document.getElementById("IxNetwork").scrollIntoView()
                      }
                    >
                      IxNetwork
                    </div>
                  </button>
                </div>
              </Col>
            </Row>
          </Row>

          <Row style={{ marginTop: "89px", position: "relative" }} id="IxLoad">
            <>
              <Col xs={12}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div className="titlesolutionresauxbox">IxLoad</div>
                </div>
                <div
                  className="textsolutionresauxbox"
                  style={{ marginTop: "30px" }}
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div style={{ width: "80%" }}>
                      Test de performance L4-7 IxLoad émule des centaines de
                      milliers d'abonnés utilisant la gamme complète de services
                      voix, vidéo et données. Sa modélisation unique des abonnés
                      fournit des mélanges de trafic utilisateur, permettant aux
                      fournisseurs de services d'assurer la qualité de
                      l'expérience tout au long de la journée. Le châssis et les
                      modules de charge offrent l'échelle nécessaire pour
                      correspondre à la capacité de n'importe quel appareil ou
                      système.
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={12}>
                <div className="imageresauxbox">
                  <img alt="logo" src={images.image19} style={{ width: " 250px" }} />{" "}
                </div>
              </Col>
            </>
          </Row>
          <Row style={{ marginTop: "87px" }} id="IxNetwork">
            <>
              <Col xs={12}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div className="titlesolutionresauxbox">IxNetwork</div>{" "}
                </div>

                <div
                  className="textsolutionresauxbox"
                  style={{ marginTop: "30px" }}
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div style={{ width: "80%" }}>
                      Test de performance de l'infrastructure réseau L2-3
                      IxNetwork est capable de générer plusieurs téraoctets de
                      données et d'analyser jusqu'à 4 millions de flux de trafic
                      simultanément. Cette solution puissante émule tout, du
                      routage à la commutation, le LAN, le SDN et l'accès à
                      large bande pour réaliser des tests complets.
                    </div>{" "}
                  </div>
                </div>
              </Col>
              <Col xs={12}>
                <div className="imageresauxbox">
                  <img alt="logo" src={images.image20} style={{ width: " 250px" }} />{" "}
                </div>
              </Col>
            </>
          </Row>

          <div style={{ marginTop: "100px" }}>
            <div className="title2serviceconsulting">
              <img
                className="lineserviceconsulting"
                style={{ marginLeft: "-30px" }}
                src={images.Rectangle13}
                alt="logo"
              />
              <div style={{ marginLeft: "30px" }}>{t("Solutions.Autre")}</div>
            </div>
          </div>
          <Row style={{ justifyContent: "center", marginTop: "100px" }}>
            <div style={{ width: "50%" }}>
              <div className="textAutreservice">Outils de mesures</div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button className="buttonAutreservice">
                  {" "}
                  <div className="textbuttoncardservice">
                    {t("Voirs.title")}
                  </div>
                </button>
              </div>
            </div>

            <div
              style={{
                width: "50%",
                marginLeft: "30.31px",
                marginTop: "100px",
              }}
            >
              <div className="textAutreservice">Mobile Finance</div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button className="buttonAutreservice">
                  {" "}
                  <div className="textbuttoncardservice">
                    {t("Voirs.title")}
                  </div>
                </button>
              </div>
            </div>
            <div
              style={{
                width: "50%",
                marginLeft: "30.31px",
                marginTop: "100px",
              }}
            >
              <div className="textAutreservice">
                QoS / QoE et Mesure des performances
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button className="buttonAutreservice">
                  {" "}
                  <div className="textbuttoncardservice">
                    {t("Voirs.title")}
                  </div>
                </button>
              </div>
            </div>
            <div
              style={{
                width: "50%",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                marginTop: "100px",
              }}
            >
              <div className="textAutreservice">
                Analyse, optimisation et monétisation du trafic - DPI
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button className="buttonAutreservice">
                  {" "}
                  <div className="textbuttoncardservice">
                    {t("Voirs.title")}
                  </div>
                </button>
              </div>
            </div>
          </Row>
          <Row>
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "100px",
                }}
              >
                {/* <div className="titleserviceconsultingcontact">
                  {t("Serivces.contact")}
                </div> */}
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div style={{ width: "80%" }}>
                <FormulaireC position={position}/>
              </div>
            </div>
          </Row>
        </Mobile>
  
      </>
 : (Number(id) === 7) ?
      <>
        <Desktop>
     
          <Row>
            <Row
              style={{
                height: "222px",
                background: "rgba(48, 192, 198, 0.8)",
                backdropFilter: "blur(10px)}",
              }}
            >
              <Col>
                <div className="titlesolutionresaux">
                  {" "}
                  {t("SolutionsDetails.Cyber Securité")}
                  {/* {solutions && solutions[5] &&  solutions[5].titre }  */}
                </div>
                <div className="navsolutionresaux">
                  <button
                    className="navsolutionresauxbutton"
                    onClick={() =>
                      document
                        .getElementById("Threat Simulator")
                        .scrollIntoView()
                    }
                  >
                    <div className="navsolutionresauxtext">
                      {" "}
                      {t("SolutionsDetails.Threat Simulator")}
                    </div>
                  </button>
                  <button
                    className="navsolutionresauxbutton"
                    onClick={() =>
                      document.getElementById("Threat Armor").scrollIntoView()
                    }
                  >
                    <div className="navsolutionresauxtext">
                      {" "}
                      {t("SolutionsDetails.Threat Armor")}
                    </div>
                  </button>
                  <button className="navsolutionresauxbutton">
                    <div
                      className="navsolutionresauxtext"
                      onClick={() =>
                        document
                          .getElementById("Breaking point")
                          .scrollIntoView()
                      }
                    >
                      {t("SolutionsDetails.Breaking point")}
                    </div>
                  </button>
                </div>
              </Col>
            </Row>
          </Row>
          <Row
            style={{ marginTop: "87px", position: "relative" }}
            id="Threat Simulator"
          >
            <>
              <Col>
                <div className="imageresauxbox">
                  <img alt="logo" src={images.Threat} />{" "}
                </div>
              </Col>
              <Col>
                <div className="titlesolutionresauxbox">
                  {" "}
                  {t("SolutionsDetails.Threat Simulator")}
                </div>
                <div
                  className="textsolutionresauxbox"
                  style={{ marginTop: "30px", marginRight: "121px" }}
                >
                  {t("SolutionsDetails.text Threat Simulator")}
                </div>
              </Col>
            </>
          </Row>
          <Row
            style={{ marginTop: "87px", position: "relative" }}
            id="Threat Armor"
          >
            <>
              <Col>
                <div
                  className="titlesolutionresauxbox"
                  style={{ marginLeft: "95px" }}
                >
                  {" "}
                  {t("SolutionsDetails.Threat Armor")}
                </div>
                <div
                  className="textsolutionresauxbox"
                  style={{ marginLeft: "95px", marginTop: "30px" }}
                >
                  {t("SolutionsDetails.text Threat Armor")}
                </div>
              </Col>
              <Col>
                <div className="imageresauxbox">
                  <img alt="logo" src={images.image21} />{" "}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "565px",
                  }}
                >
                  <button
                    className="buttonAutreservice"
                    style={{ marginTop: "133px", width: "300px" }}
                  >
                    {" "}
                    <div
                      className="textbuttoncardservice"
                      onClick={() => {
                        setNameficherCyber("Threat Armor");

                        setOpenCyber(true);
                      }}
                    >
                       {t("Solutions.Téléchargez le Data sheet")}
                    </div>
                  </button>
                  <button
                    className="buttonAutreservice"
                    style={{ marginTop: "133px", width: "200px" }}
                  >
                    {" "}
                    <div
                      className="textbuttoncardservice"
                      onClick={() => {
                        setOpennewCyber(true);
                      }}
                    >
                      {t("Serivces.contact")}
                    </div>
                  </button>
                </div>
              </Col>
            </>
          </Row>
          <Row style={{ marginTop: "87px" }} id="Breaking point">
            <>
              <Col>
                <div className="imageresauxbox" style={{ marginTop: "-10px" }}>
                  <img alt="logo" src={images.image22} />{" "}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "565px",
                    marginLeft: "120px",
                  }}
                >
                  <button
                    className="buttonAutreservice"
                    style={{ marginTop: "133px", width: "300px" }}
                  >
                    {" "}
                    <div
                      className="textbuttoncardservice"
                      onClick={() => {
                        setNameficherCyber("BreakingPoint");

                        setOpenCyber(true);
                      }}
                    >
                       {t("Solutions.Téléchargez le Data sheet")}
                    </div>
                  </button>
                  <button
                    className="buttonAutreservice"
                    style={{ marginTop: "133px", width: "200px" }}
                  >
                    {" "}
                    <div
                      className="textbuttoncardservice"
                      onClick={() => {
                        setOpennewCyber(true);
                      }}
                    >
                      {t("Serivces.contact")}
                    </div>
                  </button>
                </div>
              </Col>
              <Col>
                <div className="titlesolutionresauxbox">
                  {t("SolutionsDetails.Breaking point")}
                </div>
                <div
                  className="textsolutionresauxbox"
                  style={{ marginTop: "30px", marginRight: "121px" }}
                >
                  {t("SolutionsDetails.text Breaking point")}
                </div>
              </Col>
            </>
          </Row>

          <div style={{ marginTop: "100px" }}>
            <img
              alt="logo"
              className="lineserviceconsulting"
              src={images.Rectangle13}
            />
          </div>
          <div className="title2serviceconsulting">{t('Solutions.Autres solutions')}</div>
          <Col style={{display:'flex', justifyContent: "center" }}>
     
                {solutions &&
                  solutions
                    .filter((e, index) => e.id !== Number(id)  ) 
                    .map((solutionCaroussel, key) => (
            // solutions.filter((solutionCaroussel, index) => solutionCaroussel.id !== Number(id))
           
              <>
              {/* <Row style={{justifyContent: "center",display: "flex",}}> */}
               
              <Card key={key}>
                <Card.Body
                  style={{
                    marginTop: "20px",
                  }}
                >
                  <Card.Text>
                    <p>{solutionCaroussel.titre}</p>
                  </Card.Text>
                  <button className="buttoncardservice2">
                    {" "}
                    <div
                      className="textbuttoncardservice"
                      onClick={() => {
                        navigate(`/solution/${solutionCaroussel.id}`);
                      
                      }}
                    >
                      {t("Voirs.title")}
                    </div>
                  </button>
                </Card.Body>
              </Card>
        
              {/* </Row> */}
        
              </>
            ))}
             
            {/* </Carousel> */}
            {/* </Col> */}
           
          </Col>
          <Row>
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "100px",
                }}
              >
                {/* <div className="titleserviceconsultingcontact">
                  {t("Serivces.contact")}
                </div> */}
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div style={{ width: "100%" }}>
                <FormulaireC position={position}/>
              </div>
            </div>
          </Row>
          <Modal1
            handleClose={handleCloseCyber}
            open={openCyber}
            opennew={opennewCyber}
            setOpennew={setOpennewCyber}
            setOpen={setOpenCyber}
            nameficher={nameficherCyber}
          />
        </Desktop>
        <Tablet>
          <Row>
            <Col
              xs={12}
              style={{
                backgroundColor: "#253D8A",
                minHeight: "60px",
                height: "auto",
                position: "fixed",
                zIndex: 2,
              }}
            >
              <Menubarone />
            </Col>

            <Col
              xs={12}
              style={{
                minHeight: "120px",
                zIndex: 2,
                height: "auto",
                width: "100%",
                position: "fixed",
                marginTop: "60px",
                backgroundColor: "white",
              }}
            >
              <MenuBar />
            </Col>
          </Row>
          <Row>
            <Row
              style={{
                height: "222px",
                zIndex: 2,
                position: "fixed",
                marginTop: "180px",
                marginLeft: "10px",
                background: "rgba(48, 192, 198, 0.8)",
                backdropFilter: "blur(10px)}",
              }}
            >
              <Col>
                <div>
                  <div className="titlesolutionresaux">
                    Synchronisation des Réseaux
                  </div>
                </div>

                <div className="navsolutionresaux">
                  <button className="navsolutionresauxbutton">
                    <div
                      className="navsolutionresauxtext"
                      onClick={() =>
                        document.getElementById("Threat Armor").scrollIntoView()
                      }
                    >
                      Threat Armor
                    </div>
                  </button>
                  <button className="navsolutionresauxbutton">
                    <div
                      className="navsolutionresauxtext"
                      onClick={() =>
                        document
                          .getElementById("Breaking Point")
                          .scrollIntoView()
                      }
                    >
                      BreakingPoint
                    </div>
                  </button>
                </div>
              </Col>
            </Row>
          </Row>

          <Row
            style={{ marginTop: "489px", position: "relative" }}
            id="Threat Armor"
          >
            <>
              <Col xs={12}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div className="titlesolutionresauxbox">Threat Armor</div>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div
                    className="textsolutionresauxbox"
                    style={{ marginTop: "30px" }}
                  >
                    Threat Armor est une passerelle intelligente de
                    renseignement sur les menaces. Elle bloque les IP connues et
                    élimine les pays non approuvés, réduisant ainsi la fatigue
                    liée aux alertes et les alertes faux positifs. Elle empêche
                    jusqu'à 80 % du trafic malveillant d'accéder à votre réseau
                    et vous permet de garder une longueur d'avance sur les
                    attaquants avec des renseignements sur les menaces 24/7/365.
                  </div>
                </div>
              </Col>
              <Col xs={12}>
                <div className="imageresauxbox">
                  <img alt="logo" src={images.image21} style={{ width: " 250px" }} />{" "}
                </div>
              </Col>
            </>
          </Row>
          <Row style={{ marginTop: "87px" }} id="Breaking Point">
            <>
              <Col xs={12}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div className="titlesolutionresauxbox">Breaking Point</div>{" "}
                </div>

                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div
                    className="textsolutionresauxbox"
                    style={{ marginTop: "30px" }}
                  >
                    BreakingPoint est une plateforme tout-en-un de tests de
                    sécurité des applications et des réseaux qui simule plus de
                    490 protocoles d’application du monde réel et prend en
                    charge plus de 60 000 attaques et logiciels malveillants. Il
                    garantit que les utilisateurs disposent des dernières
                    applications et menaces grâce aux mises à jour bimensuelles
                    de l’abonnement à la base de connaissance Application and
                    Threat Intelligence (ATI) de Keysight.
                  </div>
                </div>
              </Col>
              <Col xs={12}>
                <div className="imageresauxbox">
                  <img alt="logo" src={images.image22} style={{ width: " 250px" }} />{" "}
                </div>
              </Col>
            </>
          </Row>

          <div style={{ marginTop: "100px" }}>
            <div className="title2serviceconsulting">
              <img
                className="lineserviceconsulting"
                style={{ marginLeft: "-30px" }}
                src={images.Rectangle13}
                alt="logo"
              />
              <div style={{ marginLeft: "30px", marginTop: "-60px" }}>
              {t('Solutions.Autres solutions')}
              </div>
            </div>
          </div>

          <Row style={{ justifyContent: "center" }}>
            <div style={{ width: "248px", marginTop: "50px" }}>
              <div className="textAutreservice">Outils de mesures</div>
              <button
                className="buttonAutreservice"
                style={{ marginTop: "99px" }}
              >
                {" "}
                <div
                  className="textbuttoncardservice"
                  onClick={() => {
                    // props.setSol(2);
                    navigate("/solution/2");
                  }}
                >
                  {t("Voirs.title")}
                </div>
              </button>
            </div>

            <div style={{ width: "248px", marginTop: "50px" }}>
              <div className="textAutreservice">Mobile Finance</div>
              <button
                className="buttonAutreservice"
                style={{ marginTop: "131px" }}
              >
                {" "}
                <div
                  className="textbuttoncardservice"
                  onClick={() => {
                    // props.setSol(4);
                    navigate("/solution/4");
                  }}
                >
                  {t("Voirs.title")}
                </div>
              </button>
            </div>
            <div style={{ width: "248px", marginTop: "50px" }}>
              <div className="textAutreservice">
                QoS / QoE et Mesure des performances
              </div>
              <button
                className="buttonAutreservice"
                style={{ marginTop: "67px" }}
              >
                {" "}
                <div
                  className="textbuttoncardservice"
                  onClick={() => {
                    // props.setSol(3);
                    navigate("/solution/3");
                  }}
                >
                  {t("Voirs.title")}
                </div>
              </button>
            </div>
            <div style={{ width: "248px", marginTop: "50px" }}>
              <div className="textAutreservice">
                Analyse, optimisation et monétisation du trafic - DPI
              </div>
              <button className="buttonAutreservice">
                {" "}
                <div
                  className="textbuttoncardservice"
                  onClick={() => {
                    // props.setSol(5);
                    navigate("/solution/5");
                  }}
                >
                  {t("Voirs.title")}
                </div>
              </button>
            </div>
          </Row>
          <Row>
            {/* <div style={{ marginTop: "100px" }}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div className="titleserviceconsultingcontact">
                  {t("Serivces.contact")}
                </div>
              </div>
            </div> */}
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div style={{ width: "100%" }}>
                {" "}
                <FormulaireC position={position}/>
              </div>
            </div>
          </Row>
        </Tablet>
        <Mobile>
          <Row>
            <Col
              xs={12}
              style={{
                backgroundColor: "#253D8A",
                minHeight: "60px",
                height: "auto",
              }}
            >
              <Menubarone />
            </Col>

            <Col
              xs={12}
              style={{
                minHeight: "120px",
                zIndex: 2,
                height: "auto",
                width: "100%",
                marginTop: "0px",
                backgroundColor: "white",
              }}
            >
              <MenuBar />
            </Col>
          </Row>
          <Row>
            {/* <Row style={{height: "222px",zIndex:2,position:"fixed",marginTop:"180px",marginLeft:"10px",paddingLeft:"0px",paddingRight:"0px"
      ,
background: "rgba(48, 192, 198, 0.8)",
backdropFilter: "blur(10px)}"}}> */}
            <Row
              style={{
                height: "222px",
                marginTop: "0px",
                marginLeft: "10px",
                paddingLeft: "0px",
                paddingRight: "0px",
                background: "rgba(48, 192, 198, 0.8)",
                backdropFilter: "blur(10px)}",
              }}
            >
              <Col>
                <div>
                  <div className="titlesolutionresaux">
                    Synchronisation des Réseaux
                  </div>
                </div>

                <div className="navsolutionresaux">
                  <button className="navsolutionresauxbutton">
                    <div
                      className="navsolutionresauxtext"
                      onClick={() =>
                        document.getElementById("Threat Armor").scrollIntoView()
                      }
                    >
                      Threat Armor
                    </div>
                  </button>
                  <button className="navsolutionresauxbutton">
                    <div
                      className="navsolutionresauxtext"
                      onClick={() =>
                        document
                          .getElementById("Breaking Point")
                          .scrollIntoView()
                      }
                    >
                      BreakingPoint
                    </div>
                  </button>
                </div>
              </Col>
            </Row>
          </Row>

          <Row
            style={{ marginTop: "89px", position: "relative" }}
            id="Threat Armor"
          >
            <>
              <Col xs={12}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div className="titlesolutionresauxbox">Threat Armor</div>
                </div>
                <div
                  className="textsolutionresauxbox"
                  style={{ marginTop: "30px" }}
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div style={{ width: "80%" }}>
                      Threat Armor est une passerelle intelligente de
                      renseignement sur les menaces. Elle bloque les IP connues
                      et élimine les pays non approuvés, réduisant ainsi la
                      fatigue liée aux alertes et les alertes faux positifs.
                      Elle empêche jusqu'à 80 % du trafic malveillant d'accéder
                      à votre réseau et vous permet de garder une longueur
                      d'avance sur les attaquants avec des renseignements sur
                      les menaces 24/7/365.
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={12}>
                <div className="imageresauxbox">
                  <img alt="logo" src={images.image21} style={{ width: " 250px" }} />{" "}
                </div>
              </Col>
            </>
          </Row>
          <Row style={{ marginTop: "87px" }} id="Breaking Point">
            <>
              <Col xs={12}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div className="titlesolutionresauxbox">Breaking Point</div>{" "}
                </div>

                <div
                  className="textsolutionresauxbox"
                  style={{ marginTop: "30px" }}
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div style={{ width: "80%" }}>
                      BreakingPoint est une plateforme tout-en-un de tests de
                      sécurité des applications et des réseaux qui simule plus
                      de 490 protocoles d’application du monde réel et prend en
                      charge plus de 60 000 attaques et logiciels malveillants.
                      Il garantit que les utilisateurs disposent des dernières
                      applications et menaces grâce aux mises à jour
                      bimensuelles de l’abonnement à la base de connaissance
                      Application and Threat Intelligence (ATI) de Keysight.
                    </div>{" "}
                  </div>
                </div>
              </Col>
              <Col xs={12}>
                <div className="imageresauxbox">
                  <img alt="logo" src={images.image22} style={{ width: " 250px" }} />{" "}
                </div>
              </Col>
            </>
          </Row>

          <div style={{ marginTop: "100px" }}>
            <div className="title2serviceconsulting">
              <img
                className="lineserviceconsulting"
                style={{ marginLeft: "-30px" }}
                src={images.Rectangle13}
                alt="logo"
              />
              <div style={{ marginLeft: "30px" }}>{t('Solutions.Autres solutions')}</div>
            </div>
          </div>
          <Row style={{ justifyContent: "center", marginTop: "100px" }}>
            <div style={{ width: "50%" }}>
              <div className="textAutreservice">Outils de mesures</div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button className="buttonAutreservice">
                  {" "}
                  <div className="textbuttoncardservice">
                    {t("Voirs.title")}
                  </div>
                </button>
              </div>
            </div>

            <div
              style={{
                width: "50%",
                marginLeft: "30.31px",
                marginTop: "100px",
              }}
            >
              <div className="textAutreservice">Mobile Finance</div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button className="buttonAutreservice">
                  {" "}
                  <div className="textbuttoncardservice">
                    {t("Voirs.title")}
                  </div>
                </button>
              </div>
            </div>
            <div
              style={{
                width: "50%",
                marginLeft: "30.31px",
                marginTop: "100px",
              }}
            >
              <div className="textAutreservice">
                QoS / QoE et Mesure des performances
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button className="buttonAutreservice">
                  {" "}
                  <div className="textbuttoncardservice">
                    {t("Voirs.title")}
                  </div>
                </button>
              </div>
            </div>
            <div
              style={{
                width: "50%",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                marginTop: "100px",
              }}
            >
              <div className="textAutreservice">
                Analyse, optimisation et monétisation du trafic - DPI
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button className="buttonAutreservice">
                  {" "}
                  <div className="textbuttoncardservice">
                    {t("Voirs.title")}
                  </div>
                </button>
              </div>
            </div>
          </Row>
          <Row>
            {/* <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "100px",
                }}
              >
                <div className="titleserviceconsultingcontact">
                  {t("Serivces.contact")}
                </div>
              </div>
            </div> */}
            {/* //Formulatire contact */}
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div style={{ width: "100%" }}>
                <FormulaireC position={position}/>
              </div>
            </div>
          </Row>
        </Mobile>
   
      </>
   : (Number(id) === 5) ?
      <>
        <Desktop>
       
          <Row>
            <Row
              style={{
                height: "222px",
                background: "rgba(48, 192, 198, 0.8)",
                backdropFilter: "blur(10px)}",
              }}
            >
              <Col>
                <div className="titlesolutionresaux">
                  {/* {t("SolutionsDetails.Outils de mesures d’Anritsu")} */}
                  {solutions && solutions[4]&& solutions[4].titre && solutions[4].titre } 
                </div>
              </Col>
            </Row>
          </Row>
          <Row
            style={{ marginTop: "87px", position: "relative" }}
            id="Outils de mesures d’Anritsu"
          >
            <>
              <Col>
                <div className="imageresauxbox">
                  <img alt="logo" src={images.image23} />{" "}
                </div>
              </Col>
              <Col>
                <div
                  className="textsolutionresauxbox"
                  style={{ marginTop: "30px", marginRight: "121px" }}
                >
                  {t("SolutionsDetails.text Outils de mesures d’Anritsu")}
                </div>
              </Col>
            </>
          </Row>

          <div style={{ marginTop: "100px" }}>
            <img
              alt="logo"
              className="lineserviceconsulting"
              src={images.Rectangle13}
            />
          </div>
          <div className="title2serviceconsulting">{t('Solutions.Autres solutions')}</div>
          <Col style={{display:'flex' ,justifyContent:"center"}}>
          
                {solutions &&
                  solutions
                    .filter((e, index) => e.id !== Number(id) ) 
                    .map((solutionCaroussel, key) => (
            // solutions.filter((solutionCaroussel, index) => solutionCaroussel.id !== Number(id))
           
              <>
              {/* <Row style={{justifyContent: "center",display: "flex",}}> */}
              <Card key={key}>
                <Card.Body
                  style={{
                    marginTop: "20px",
                  }}
                >
                  <Card.Text>
                    <p>{solutionCaroussel.titre}</p>
                  </Card.Text>
                  <button className="buttoncardservice2">
                    {" "}
                    <div
                      className="textbuttoncardservice"
                      onClick={() => {
                        navigate(`/solution/${solutionCaroussel.id}`);
                      
                      }}
                    >
                      {t("Voirs.title")}
                    </div>
                  </button>
                </Card.Body>
              </Card>
              {/* <div style={{  width: "158px", height:"100px"}}>
                    <div className="textAutreservice">
                      {solutionCaroussel.titre.slice(0,20)+"..."}
                    </div>
                    <button
                      className="buttonAutreservice"
                      style={{ }}
                      onClick={() => {
                       
                        navigate(`/solution/${solutionCaroussel.id}`);
                      }}
                    >
                      {" "}
                      <div className="textbuttoncardservice" >
                       {t("Voirs.title")}
                      </div>
                    </button>
                  </div> */}
        
              {/* </Row> */}
        
              </>
            ))}
             
            {/* </Carousel> */}
            {/* </Col> */}
           
          </Col>
          <FormulaireC position={position}/>
      
        </Desktop>
        <Tablet>
          <Row>
            <Row
              style={{
                height: "222px",
                zIndex: 2,
                position: "fixed",
                marginTop: "180px",
                marginLeft: "10px",
                background: "rgba(48, 192, 198, 0.8)",
                backdropFilter: "blur(10px)}",
              }}
            >
              <Col>
                <div>
                  <div className="titlesolutionresaux">
                    Outils de mesures d’Anritsu
                  </div>
                </div>
              </Col>
            </Row>
          </Row>

          <Row
            style={{ marginTop: "489px", position: "relative" }}
            id="Outils de mesures d’Anritsu"
          >
            <>
              <Col xs={12}>
                <div className="imageresauxbox">
                  <img alt="logo" src={images.image23} style={{ width: " 250px" }} />{" "}
                </div>
              </Col>
              <Col xs={12}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div
                    className="textsolutionresauxbox"
                    style={{ marginTop: "30px" }}
                  >
                    PRISMA est un partenaire stratégique d’Anritsu qui est un
                    leader mondial des produits de tests et d’équipements de
                    mesures. Les experts de PRISMA sont en mesure d’installer
                    les outils Anritsu dans vos environnements, former vos
                    équipes et les supporter lors de l’utilisation de la gamme
                    de ces outils
                  </div>
                </div>
              </Col>
            </>
          </Row>

          <div style={{ marginTop: "100px" }}>
            <div className="title2serviceconsulting">
              <img
                className="lineserviceconsulting"
                style={{ marginLeft: "-30px" }}
                src={images.Rectangle13}
                alt="logo"
              />
              <div style={{ marginLeft: "30px", marginTop: "-60px" }}>
              {t('Solutions.Autres solutions')}
              </div>
            </div>
          </div>

          <Row style={{ justifyContent: "center" }}>
            <div style={{ width: "248px", marginTop: "50px" }}>
              <div className="textAutreservice">Outils de mesures</div>
              <button
                className="buttonAutreservice"
                style={{ marginTop: "99px" }}
              >
                {" "}
                <div
                  className="textbuttoncardservice"
                  onClick={() => {
                    // props.setSol(2);
                    navigate("/solution/2");
                  }}
                >
                  {t("Voirs.title")}
                </div>
              </button>
            </div>

            <div style={{ width: "248px", marginTop: "50px" }}>
              <div className="textAutreservice">Mobile Finance</div>
              <button
                className="buttonAutreservice"
                style={{ marginTop: "131px" }}
              >
                {" "}
                <div
                  className="textbuttoncardservice"
                  onClick={() => {
                    // props.setSol(4);
                    navigate("/solution/4");
                  }}
                >
                  {t("Voirs.title")}
                </div>
              </button>
            </div>
            <div style={{ width: "248px", marginTop: "50px" }}>
              <div className="textAutreservice">
                QoS / QoE et Mesure des performances
              </div>
              <button
                className="buttonAutreservice"
                style={{ marginTop: "67px" }}
              >
                {" "}
                <div
                  className="textbuttoncardservice"
                  onClick={() => {
                    // props.setSol(3);
                    navigate("/solution/3");
                  }}
                >
                  {t("Voirs.title")}
                </div>
              </button>
            </div>
            <div style={{ width: "248px", marginTop: "50px" }}>
              <div className="textAutreservice">
                Analyse, optimisation et monétisation du trafic - DPI
              </div>
              <button className="buttonAutreservice">
                {" "}
                <div
                  className="textbuttoncardservice"
                  onClick={() => {
                    // props.setSol(5);
                    navigate("/solution/5");
                  }}
                >
                  {t("Voirs.title")}
                </div>
              </button>
            </div>
          </Row>
          <Row>
            {/* <div style={{ marginTop: "100px" }}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div className="titleserviceconsultingcontact">
                  {t("Serivces.contact")}
                </div>
              </div>
            </div> */}
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div style={{ width: "100%" }}>
                {" "}
        
                <FormulaireC position={position}/>
              </div>
            </div>
          </Row>
        </Tablet>
        <Mobile>
        
          <Row>
            {/* <Row style={{height: "222px",zIndex:2,position:"fixed",marginTop:"180px",marginLeft:"10px",paddingLeft:"0px",paddingRight:"0px"
      ,
background: "rgba(48, 192, 198, 0.8)",
backdropFilter: "blur(10px)}"}}> */}
            <Row
              style={{
                height: "222px",
                marginTop: "0px",
                marginLeft: "10px",
                paddingLeft: "0px",
                paddingRight: "0px",
                background: "rgba(48, 192, 198, 0.8)",
                backdropFilter: "blur(10px)}",
              }}
            >
              <Col>
                <div>
                  <div className="titlesolutionresaux">
                    Outils de mesures d’Anritsu
                  </div>
                </div>
              </Col>
            </Row>
          </Row>

          <Row
            style={{ marginTop: "89px", position: "relative" }}
            id="Outils de mesures d’Anritsu"
          >
            <>
              <Col xs={12}>
                <div className="imageresauxbox">
                  <img alt="logo" src={images.image23} style={{ width: " 250px" }} />{" "}
                </div>
              </Col>
              <Col xs={12}>
                <div
                  className="textsolutionresauxbox"
                  style={{ marginTop: "30px" }}
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div style={{ width: "80%" }}>
                      PRISMA est un partenaire stratégique d’Anritsu qui est un
                      leader mondial des produits de tests et d’équipements de
                      mesures. Les experts de PRISMA sont en mesure d’installer
                      les outils Anritsu dans vos environnements, former vos
                      équipes et les supporter lors de l’utilisation de la gamme
                      de ces outils
                    </div>
                  </div>
                </div>
              </Col>
            </>
          </Row>

          <div style={{ marginTop: "100px" }}>
            <div className="title2serviceconsulting">
              <img
                className="lineserviceconsulting"
                style={{ marginLeft: "-30px" }}
                src={images.Rectangle13}
                alt="logo"
              />
              <div style={{ marginLeft: "30px" }}>{t('Solutions.Autres solutions')}</div>
            </div>
          </div>
          <Row style={{ justifyContent: "center", marginTop: "100px" }}>
            <div style={{ width: "50%" }}>
              <div className="textAutreservice">Outils de mesures</div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button className="buttonAutreservice">
                  {" "}
                  <div className="textbuttoncardservice">
                    {t("Voirs.title")}
                  </div>
                </button>
              </div>
            </div>

            <div
              style={{
                width: "50%",
                marginLeft: "30.31px",
                marginTop: "100px",
              }}
            >
              <div className="textAutreservice">Mobile Finance</div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button className="buttonAutreservice">
                  {" "}
                  <div className="textbuttoncardservice">
                    {t("Voirs.title")}
                  </div>
                </button>
              </div>
            </div>
            <div
              style={{
                width: "50%",
                marginLeft: "30.31px",
                marginTop: "100px",
              }}
            >
              <div className="textAutreservice">
                QoS / QoE et Mesure des performances
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button className="buttonAutreservice">
                  {" "}
                  <div className="textbuttoncardservice">
                    {t("Voirs.title")}
                  </div>
                </button>
              </div>
            </div>
            <div
              style={{
                width: "50%",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                marginTop: "100px",
              }}
            >
              <div className="textAutreservice">
                Analyse, optimisation et monétisation du trafic - DPI
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button className="buttonAutreservice">
                  {" "}
                  <div className="textbuttoncardservice">
                    {t("Voirs.title")}
                  </div>
                </button>
              </div>
            </div>
          </Row>
          <Row>
            {/* <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "100px",
                }}
              >
                <div className="titleserviceconsultingcontact">
                  {t("Serivces.contact")}
                </div>
              </div>
            </div> */}
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div style={{ width: "80%" }}>
              <FormulaireC position={position}/>
              </div>
            </div>
          </Row>
        </Mobile>
       
      </>
  : (Number(id) === 6) ?
      <>
        <Desktop>
          
          <Row>
            <Row
              style={{
                height: "222px",
                background: "rgba(48, 192, 198, 0.8)",
                backdropFilter: "blur(10px)}",
              }}
            >
              <Col>
                <div className="titlesolutionresaux">
                  {" "}
                  {/* {t("SolutionsDetails.Mobile Finance")} */}
                  {solutions && solutions[5]&& solutions[5].titre && solutions[5].titre } 
                </div>
              </Col>
            </Row>
          </Row>
          <Row
            style={{ marginTop: "87px", position: "relative" }}
            id="Mobile Finance"
          >
            <>
              <Col
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className="imageresauxbox">
                  <img alt="logo" src={images.image24} style={{ width: "85%" }} />{" "}
                </div>
              </Col>
              <Col>
                <div className="titlesolutionresauxbox">
                  {t("SolutionsDetails.Crédit de communication")}
                </div>
                <div
                  className="textsolutionresauxbox"
                  style={{ marginTop: "30px", marginRight: "121px" }}
                >
                  {t("SolutionsDetails.text Crédit de communication")}
                </div>
              </Col>
            </>
          </Row>

          <div style={{ marginTop: "100px" }}>
            <img
              alt="logo"
              className="lineserviceconsulting"
              src={images.Rectangle13}
            />
          </div>
          <div className="title2serviceconsulting">{t('Solutions.Autres solutions')}</div>
          <Col style={{display:'flex', justifyContent: "center"}}>
      
                {solutions &&
                  solutions
                    .filter((e, index) => e.id !== Number(id) &&  index >= 3 ) 
                    .map((solutionCaroussel, key) => (
            // solutions.filter((solutionCaroussel, index) => solutionCaroussel.id !== Number(id))
           
              <>
              {/* <Row style={{justifyContent: "center",display: "flex",}}> */}
              <Card key={key}>
                <Card.Body
                  style={{
                    marginTop: "20px",
                  }}
                >
                  <Card.Text>
                    <p>{solutionCaroussel.titre}</p>
                  </Card.Text>
                  <button className="buttoncardservice2">
                    {" "}
                    <div
                      className="textbuttoncardservice"
                      onClick={() => {
                        navigate(`/solution/${solutionCaroussel.id}`);
                      
                      }}
                    >
                      {t("Voirs.title")}
                    </div>
                  </button>
                </Card.Body>
              </Card>
              {/* </Row> */}
        
              </>
            ))}
             
            {/* </Carousel> */}
            {/* </Col> */}
           
          </Col>
           {/* //Contact Form  */}
           <Row>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <div style={{ width: "100%" }}>
             <FormulaireC/>
              </div>
            </div>
          </Row>
        </Desktop>
        <Tablet>
          <Row>
            <Col
              xs={12}
              style={{
                backgroundColor: "#253D8A",
                minHeight: "60px",
                height: "auto",
                position: "fixed",
                zIndex: 2,
              }}
            >
              <Menubarone />
            </Col>

            <Col
              xs={12}
              style={{
                minHeight: "120px",
                zIndex: 2,
                height: "auto",
                width: "100%",
                position: "fixed",
                marginTop: "60px",
                backgroundColor: "white",
              }}
            >
              <MenuBar />
            </Col>
          </Row>
          <Row>
            <Row
              style={{
                height: "222px",
                zIndex: 2,
                position: "fixed",
                marginTop: "180px",
                marginLeft: "10px",
                background: "rgba(48, 192, 198, 0.8)",
                backdropFilter: "blur(10px)}",
              }}
            >
              <Col>
                <div>
                  <div className="titlesolutionresaux">Mobile Finance</div>
                </div>
              </Col>
            </Row>
          </Row>

          <Row
            style={{ marginTop: "489px", position: "relative" }}
            id="Mobile Finance"
          >
            <>
              <Col xs={12}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div className="titlesolutionresauxbox">Credit Airtime</div>
                </div>

                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div
                    className="textsolutionresauxbox"
                    style={{ marginTop: "30px" }}
                  >
                    La solution de Credit Airtime permet d’accorder des prêts
                    data et communication à vos utilisateurs. Afin d’atteindre
                    un taux inférieur à 1% de Mauvaise dette, la solution se
                    base sur l’intelligence artificielle et le Machine learning
                    pour effectuer une notation du crédit (Utilisation des
                    Scorecards au lieu des simples règles). Elle génère aussi
                    une augmentation dans la distribution et une meilleure
                    expérience client.
                  </div>
                </div>
              </Col>
              <Col xs={12}>
                <div className="imageresauxbox">
                  <img alt="logo" src={images.image24} style={{ width: " 250px" }} />{" "}
                </div>
              </Col>
            </>
          </Row>

          <div style={{ marginTop: "100px" }}>
            <div className="title2serviceconsulting">
              <img
                className="lineserviceconsulting"
                style={{ marginLeft: "-30px" }}
                src={images.Rectangle13}
                alt="logo"
              />
              <div style={{ marginLeft: "30px", marginTop: "-60px" }}>
              {t('Solutions.Autres solutions')}
              </div>
            </div>
          </div>

          <Row style={{ justifyContent: "center" }}>
            <div style={{ width: "248px", marginTop: "50px" }}>
              <div className="textAutreservice">Outils de mesures</div>
              <button
                className="buttonAutreservice"
                style={{ marginTop: "99px" }}
              >
                {" "}
                <div
                  className="textbuttoncardservice"
                  onClick={() => {
                    props.setSol(2);
                    navigate("/solution");
                  }}
                >
                  {t("Voirs.title")}
                </div>
              </button>
            </div>

            <div style={{ width: "248px", marginTop: "50px" }}>
              <div className="textAutreservice">Mobile Finance</div>
              <button
                className="buttonAutreservice"
                style={{ marginTop: "131px" }}
              >
                {" "}
                <div
                  className="textbuttoncardservice"
                  onClick={() => {
                    props.setSol(4);
                    navigate("/solution");
                  }}
                >
                  {t("Voirs.title")}
                </div>
              </button>
            </div>
            <div style={{ width: "248px", marginTop: "50px" }}>
              <div className="textAutreservice">
                QoS / QoE et Mesure des performances
              </div>
              <button
                className="buttonAutreservice"
                style={{ marginTop: "67px" }}
              >
                {" "}
                <div
                  className="textbuttoncardservice"
                  onClick={() => {
                    props.setSol(3);
                    navigate("/solution");
                  }}
                >
                  {t("Voirs.title")}
                </div>
              </button>
            </div>
            <div style={{ width: "248px", marginTop: "50px" }}>
              <div className="textAutreservice">
                Analyse, optimisation et monétisation du trafic - DPI
              </div>
              <button className="buttonAutreservice">
                {" "}
                <div
                  className="textbuttoncardservice"
                  onClick={() => {
                    props.setSol(5);
                    navigate("/solution");
                  }}
                >
                  {t("Voirs.title")}
                </div>
              </button>
            </div>
          </Row>
          <Row>
         
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div style={{ width: "100%" }}>
                {" "}
            
                <FormulaireC position={position}/>
              </div>
            </div>
          </Row>
        </Tablet>
        <Mobile>
          <Row>
            <Col
              xs={12}
              style={{
                backgroundColor: "#253D8A",
                minHeight: "60px",
                height: "auto",
              }}
            >
              <Menubarone />
            </Col>

            <Col
              xs={12}
              style={{
                minHeight: "120px",
                zIndex: 2,
                height: "auto",
                width: "100%",
                marginTop: "0px",
                backgroundColor: "white",
              }}
            >
              <MenuBar />
            </Col>
          </Row>
          <Row>
            {/* <Row style={{height: "222px",zIndex:2,position:"fixed",marginTop:"180px",marginLeft:"10px",paddingLeft:"0px",paddingRight:"0px"
      ,
background: "rgba(48, 192, 198, 0.8)",
backdropFilter: "blur(10px)}"}}> */}
            <Row
              style={{
                height: "222px",
                marginTop: "0px",
                marginLeft: "10px",
                paddingLeft: "0px",
                paddingRight: "0px",
                background: "rgba(48, 192, 198, 0.8)",
                backdropFilter: "blur(10px)}",
              }}
            >
              <Col>
                <div>
                  <div className="titlesolutionresaux">Mobile Finance</div>
                </div>
              </Col>
            </Row>
          </Row>

          <Row
            style={{ marginTop: "89px", position: "relative" }}
            id="Mobile Finance"
          >
            <>
              <Col xs={12}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div className="titlesolutionresauxbox">Credit Airtime</div>
                </div>

                <div
                  className="textsolutionresauxbox"
                  style={{ marginTop: "30px" }}
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div style={{ width: "80%" }}>
                      La solution de Credit Airtime permet d’accorder des prêts
                      data et communication à vos utilisateurs. Afin d’atteindre
                      un taux inférieur à 1% de Mauvaise dette, la solution se
                      base sur l’intelligence artificielle et le Machine
                      learning pour effectuer une notation du crédit
                      (Utilisation des Scorecards au lieu des simples règles).
                      Elle génère aussi une augmentation dans la distribution et
                      une meilleure expérience client.
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={12}>
                <div className="imageresauxbox">
                  <img alt="logo" src={images.image24} style={{ width: " 250px" }} />{" "}
                </div>
              </Col>
            </>
          </Row>

          <div style={{ marginTop: "100px" }}>
            <div className="title2serviceconsulting">
              <img
                className="lineserviceconsulting"
                style={{ marginLeft: "-30px" }}
                src={images.Rectangle13}
                alt="logo"
              />
              <div style={{ marginLeft: "30px" }}>{t('Solutions.Autres solutions')}res solutions</div>
            </div>
          </div>
          <Row style={{ justifyContent: "center", marginTop: "100px" }}>
            <div style={{ width: "50%" }}>
              <div className="textAutreservice">Outils de mesures</div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button className="buttonAutreservice">
                  {" "}
                  <div className="textbuttoncardservice">
                    {t("Voirs.title")}
                  </div>
                </button>
              </div>
            </div>

            <div
              style={{
                width: "50%",
                marginLeft: "30.31px",
                marginTop: "100px",
              }}
            >
              <div className="textAutreservice">Mobile Finance</div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button className="buttonAutreservice">
                  {" "}
                  <div className="textbuttoncardservice">
                    {t("Voirs.title")}
                  </div>
                </button>
              </div>
            </div>
            <div
              style={{
                width: "50%",
                marginLeft: "30.31px",
                marginTop: "100px",
              }}
            >
              <div className="textAutreservice">
                QoS / QoE et Mesure des performances
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button className="buttonAutreservice">
                  {" "}
                  <div className="textbuttoncardservice">
                    {t("Voirs.title")}
                  </div>
                </button>
              </div>
            </div>
            <div
              style={{
                width: "50%",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                marginTop: "100px",
              }}
            >
              <div className="textAutreservice">
                Analyse, optimisation et monétisation du trafic - DPI
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button className="buttonAutreservice">
                  {" "}
                  <div className="textbuttoncardservice">
                    {t("Voirs.title")}
                  </div>
                </button>
              </div>
            </div>
          </Row>
          <Row>
            {/* <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "100px",
                }}
              >
                <div className="titleserviceconsultingcontact">
                  {t("Serivces.contact")} */}
                  {/* {solutions && solutions[2]&& solutions[2].titre && solutions[2].titre }  */}
                {/* </div>
              </div>
            </div> */}
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div style={{ width: "80%" }}>
                <FormulaireC position={position}/>
              </div>
            </div>
          </Row>
        </Mobile>
      
      </>
  : (Number(id) === 2) ?
      <>
        <Desktop>
         
          <Row>
            <Row
              style={{
                height: "222px",
                background: "rgba(48, 192, 198, 0.8)",
                backdropFilter: "blur(10px)}",
              }}
            >
              <Col>
                <div className="titlesolutionresaux">
                  {/* {t(
                    "SolutionsDetails.Analyse, optimisation et monétisation du trafic - DPI"
                  )} */}
                   {solutions && solutions[1]&& solutions[1].titre && solutions[1].titre } 
                </div>
              </Col>
            </Row>
          </Row>
          <Row
            style={{ marginTop: "87px", position: "relative" }}
            id="Analyse, optimisation et monétisation du trafic - DPI"
          >
            <>
              <Col>
                <div className="imageresauxbox">
                  <img alt="logo" src={images.DPI} style={{ width: "85%" }} />{" "}
                </div>
              </Col>
              <Col>
                <div className="titlesolutionresauxbox">
                  {t("SolutionsDetails.Active Network Intelligence")}
                </div>
                <div
                  className="textsolutionresauxbox"
                  style={{ marginTop: "30px", marginRight: "121px" }}
                >
                  {t("SolutionsDetails.text Active Network Intelligence")}
                </div>
              </Col>
            </>
          </Row>

          <div style={{ marginTop: "100px" }}>
            <img
              alt="logo"
              className="lineserviceconsulting"
              src={images.Rectangle13}
            />
          </div>
          <div className="title2serviceconsulting">{t('Solutions.Autres solutions')}</div>
          <Col style={{display:'flex', justifyContent: "center"}}>
    
                {solutions &&
                  solutions
                    .filter((e, index) => e.id !== Number(id) ) 
                    .map((solutionCaroussel, key) => (
            // solutions.filter((solutionCaroussel, index) => solutionCaroussel.id !== Number(id))
           
              <>
              {/* <Row style={{justifyContent: "center",display: "flex",}}> */}
               
              <Card key={key}>
                <Card.Body
                  style={{
                    marginTop: "20px",
                  }}
                >
                  <Card.Text>
                    <p>{solutionCaroussel.titre}</p>
                  </Card.Text>
                  <button className="buttoncardservice2">
                    {" "}
                    <div
                      className="textbuttoncardservice"
                      onClick={() => {
                        navigate(`/solution/${solutionCaroussel.id}`);
                      
                      }}
                    >
                      {t("Voirs.title")}
                    </div>
                  </button>
                </Card.Body>
              </Card>
        
              {/* </Row> */}
        
              </>
            ))}
             
            {/* </Carousel> */}
            {/* </Col> */}
           
          </Col>
          <Row>
            {/* <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "100px",
                }}
              >
                <div className="titleserviceconsultingcontact">
                  {t("Serivces.contact")}
                </div>
              </div>
            </div> */}
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div style={{ width: "100%" }}>
                <FormulaireC position={position}/>
              </div>
            </div>
          </Row>
        </Desktop>
        <Tablet>
          <Row>
            <Col
              xs={12}
              style={{
                backgroundColor: "#253D8A",
                minHeight: "60px",
                height: "auto",
                position: "fixed",
                zIndex: 2,
              }}
            >
              <Menubarone />
            </Col>

            <Col
              xs={12}
              style={{
                minHeight: "120px",
                zIndex: 2,
                height: "auto",
                width: "100%",
                position: "fixed",
                marginTop: "60px",
                backgroundColor: "white",
              }}
            >
              <MenuBar />
            </Col>
          </Row>
          <Row>
            <Row
              style={{
                height: "222px",
                zIndex: 2,
                position: "fixed",
                marginTop: "180px",
                marginLeft: "10px",
                background: "rgba(48, 192, 198, 0.8)",
                backdropFilter: "blur(10px)}",
              }}
            >
              <Col>
                <div>
                  <div className="titlesolutionresaux">
                    Analyse, optimisation et monétisation du trafic - DPI
                  </div>
                </div>
              </Col>
            </Row>
          </Row>

          <Row
            style={{ marginTop: "489px", position: "relative" }}
            id="Mobile Finance"
          >
            <>
              <Col xs={12}>
                <div className="titlesolutionresauxbox">
                  Active Network Intelligence
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div
                    className="textsolutionresauxbox"
                    style={{ marginTop: "30px" }}
                  >
                    La solution Application and Network Intelligence (ANI) de
                    Sandvine offre aux opérateur un portail unique pour
                    analyser, optimiser et monétiser les applications. Elle
                    apporte une visibilité sur la qualité d'expérience (QoE) des
                    applications nécessaire pour exécuter avec succès des
                    réseaux, où la vidéo, les jeux, le partage social et les
                    services d'entreprise et IoT gérés sont fournis efficacement
                    pour répondre aux attentes des consommateurs et des
                    entreprises.
                  </div>
                </div>
              </Col>
              <Col xs={12}>
                <div className="imageresauxbox">
                  <img alt="logo" src={images.DPI} style={{ width: " 250px" }} />{" "}
                </div>
              </Col>
            </>
          </Row>

          <div style={{ marginTop: "100px" }}>
            <div className="title2serviceconsulting">
              <img
                className="lineserviceconsulting"
                style={{ marginLeft: "-30px" }}
                src={images.Rectangle13}
                alt="logo"
              />
              <div style={{ marginLeft: "30px", marginTop: "-60px" }}>
              {t('Solutions.Autres solutions')}
              </div>
            </div>
          </div>

          <Row style={{ justifyContent: "center" }}>
            <div style={{ width: "248px", marginTop: "50px" }}>
              <div className="textAutreservice">Outils de mesures</div>
              <button
                className="buttonAutreservice"
                style={{ marginTop: "99px" }}
              >
                {" "}
                <div
                  className="textbuttoncardservice"
                  onClick={() => {
                    props.setSol(2);
                    navigate("/solution");
                  }}
                >
                  {t("Voirs.title")}
                </div>
              </button>
            </div>

            <div style={{ width: "248px", marginTop: "50px" }}>
              <div className="textAutreservice">Mobile Finance</div>
              <button
                className="buttonAutreservice"
                style={{ marginTop: "131px" }}
              >
                {" "}
                <div
                  className="textbuttoncardservice"
                  onClick={() => {
                    props.setSol(4);
                    navigate("/solution");
                  }}
                >
                  {t("Voirs.title")}
                </div>
              </button>
            </div>
            <div style={{ width: "248px", marginTop: "50px" }}>
              <div className="textAutreservice">
                QoS / QoE et Mesure des performances
              </div>
              <button
                className="buttonAutreservice"
                style={{ marginTop: "67px" }}
              >
                {" "}
                <div
                  className="textbuttoncardservice"
                  onClick={() => {
                    props.setSol(3);
                    navigate("/solution");
                  }}
                >
                  {t("Voirs.title")}
                </div>
              </button>
            </div>
            <div style={{ width: "248px", marginTop: "50px" }}>
              <div className="textAutreservice">
                Analyse, optimisation et monétisation du trafic - DPI
              </div>
              <button className="buttonAutreservice">
                {" "}
                <div
                  className="textbuttoncardservice"
                  onClick={() => {
                    props.setSol(5);
                    navigate("/solution");
                  }}
                >
                  {t("Voirs.title")}
                </div>
              </button>
            </div>
          </Row>
          {/* //Fomulaire contact  */}
          <Row>
            {/* <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "100px",
                }}
              >
                <div className="titleserviceconsultingcontact">
                  {t("Serivces.contact")}
                </div>
              </div>
            </div> */}
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div style={{ width: "100%" }}>
                <FormulaireC position={position}/>
              </div>
            </div>
          </Row>
        </Tablet>
        <Mobile>

          <Row>
            {/* <Row style={{height: "222px",zIndex:2,position:"fixed",marginTop:"180px",marginLeft:"10px",paddingLeft:"0px",paddingRight:"0px"
      ,
background: "rgba(48, 192, 198, 0.8)",
backdropFilter: "blur(10px)}"}}> */}
            <Row
              style={{
                height: "222px",
                marginTop: "0px",
                marginLeft: "10px",
                paddingLeft: "0px",
                paddingRight: "0px",
                background: "rgba(48, 192, 198, 0.8)",
                backdropFilter: "blur(10px)}",
              }}
            >
              <Col>
                <div>
                  <div className="titlesolutionresaux">
                    Analyse, optimisation et monétisation du trafic - DPI
                  </div>
                </div>
              </Col>
            </Row>
          </Row>

          <Row
            style={{ marginTop: "89px", position: "relative" }}
            id="Mobile Finance"
          >
            <>
              <Col xs={12}>
                <div className="titlesolutionresauxbox">
                  Active Network Intelligence
                </div>
                <div
                  className="textsolutionresauxbox"
                  style={{ marginTop: "30px" }}
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div style={{ width: "80%" }}>
                      La solution Application and Network Intelligence (ANI) de
                      Sandvine offre aux opérateur un portail unique pour
                      analyser, optimiser et monétiser les applications. Elle
                      apporte une visibilité sur la qualité d'expérience (QoE)
                      des applications nécessaire pour exécuter avec succès des
                      réseaux, où la vidéo, les jeux, le partage social et les
                      services d'entreprise et IoT gérés sont fournis
                      efficacement pour répondre aux attentes des consommateurs
                      et des entreprises.
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={12}>
                <div className="imageresauxbox">
                  <img alt="logo" src={images.DPI} />{" "}
                </div>
              </Col>
            </>
          </Row>

          <div style={{ marginTop: "100px" }}>
            <div className="title2serviceconsulting">
              <img
                className="lineserviceconsulting"
                style={{ marginLeft: "-30px" }}
                src={images.Rectangle13}
                alt="logo"
              />
              <div style={{ marginLeft: "30px" }}>{t('Solutions.Autres solutions')}</div>
            </div>
          </div>
          <Row style={{ justifyContent: "center", marginTop: "100px" }}>
            <div style={{ width: "50%" }}>
              <div className="textAutreservice">Outils de mesures</div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button className="buttonAutreservice">
                  {" "}
                  <div className="textbuttoncardservice">
                    {t("Voirs.title")}
                  </div>
                </button>
              </div>
            </div>

            <div
              style={{
                width: "50%",
                marginLeft: "30.31px",
                marginTop: "100px",
              }}
            >
              <div className="textAutreservice">Mobile Finance</div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button className="buttonAutreservice">
                  {" "}
                  <div className="textbuttoncardservice">
                    {t("Voirs.title")}
                  </div>
                </button>
              </div>
            </div>
            <div
              style={{
                width: "50%",
                marginLeft: "30.31px",
                marginTop: "100px",
              }}
            >
              <div className="textAutreservice">
                QoS / QoE et Mesure des performances
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button className="buttonAutreservice">
                  {" "}
                  <div className="textbuttoncardservice">
                    {t("Voirs.title")}
                  </div>
                </button>
              </div>
            </div>
            <div
              style={{
                width: "50%",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                marginTop: "100px",
              }}
            >
              <div className="textAutreservice">
                Analyse, optimisation et monétisation du trafic - DPI
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button className="buttonAutreservice">
                  {" "}
                  <div className="textbuttoncardservice">
                    {t("Voirs.title")}
                  </div>
                </button>
              </div>
            </div>
          </Row>
          <Row>
            {/* <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "100px",
                }}
              >
                <div className="titleserviceconsultingcontact">
                  {t("Serivces.contact")}
                </div>
              </div>
            </div> */}
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div style={{ width: "100%" }}>
                <FormulaireC position={position}/>
              </div>
            </div>
          </Row>
        </Mobile>
       
      </>
  :<>Loading... Error</>} 
 
    

</>
)
};

export default OneSolution;
