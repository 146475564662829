import React from "react";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";

import { BrowserRouter } from "react-router-dom";


import { CssBaseline } from "@material-ui/core/styles";

import App from "./App";
// import { Provider } from "react-redux";
import common_fr from "./Translation/Fr/common.json";
import common_en from "./Translation/En/common.json";
import "bootstrap/dist/css/bootstrap.min.css";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import { AuthProvider } from "./Api/context/AuthProvider";
//Redux 
import { Provider } from 'react-redux';
import store from '../src/app/store';

i18next.init({
  interpolation: { escapeValue: false },
  lng: "fr", // language to use
  resources: {
    fr: {
      common: common_fr, // 'common' is our custom namespace
    },
    en: {
      common: common_en,
    },
  }, // React already does escaping
});


const root = createRoot(document.getElementById("root"));

root.render(
  <StrictMode>

    <Provider store={store}>
    <BrowserRouter basename="/" >
      <AuthProvider >
        <I18nextProvider i18n={i18next}>
          <App />
        </I18nextProvider>
      </AuthProvider>
    </BrowserRouter>
    </Provider>

  </StrictMode>,
  document.getElementById("root")
);
