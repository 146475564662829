import React, { useState, useEffect } from "react";
import { Offcanvas } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
//REdux
import { useSelector, useDispatch } from "react-redux";
import {
  homeAction,
  notHomeAction,homeNavAction , ContactNavAction ,RefNavAction,RecrutNavAction,ActuNavAction,AboutNavAction
} from "../../features/navigationHome/navhomeSlice";
import logo from "../../assets/logo.svg";
import { Desktop, Mobile, Tablet } from "../../constant/AppConfig";

const MenuBar = ({ selectedFunct }) => {
  const navigate = useNavigate();
  const [t] = useTranslation("common");
  const [selectedPart, setSelectedPart] = useState("");
  const [offset, setOffset] = useState(0);
  const [fixed, setFixed] = useState("");
      //redux
      const dispatch = useDispatch();
      const inHome = useSelector(homeNavAction);
      const contact = useSelector(ContactNavAction);
      const ref = useSelector(RefNavAction);
      const actu = useSelector(ActuNavAction);
      const about = useSelector(AboutNavAction);
      const pos = useSelector(state => state.nav);
  // on scroll fix navbar
  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  useEffect(() => {
    offset >= 150 ? setFixed("top") : setFixed(null);
  }, [offset]);

  function GoToHomePage()
  {
    window.location = '/';   
  }
  // console.log(offset, "offset");
  return (
    <>
      <Desktop>
        <Navbar bg="white" expand="lg" fixed={fixed}>
          <Container style={{ marginLeft: "135px" }}>
            <Navbar.Brand>
              <img alt="logo" src={logo} style={{ marginTop: "12px" }} onClick={GoToHomePage}/>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse
              id="basic-navbar-nav"
              style={{
                position: "absolute",
                right: "135px",
                marginTop: "30px",
              }}
            >
              
              <Nav className="me-auto">
                {/* //Acceuil */}
                <Nav.Link
                  className="navtext"
                  onClick={() => {
                    dispatch(inHome);
                    navigate("/");
                    window.scrollTo(0, 0);
                  }}
                >
                  {" "}
                  {t("navbar.Accueil")}
                </Nav.Link>
                 {/* //Solution et services */}
                <Nav.Link
                  className="navtext"
                  onClick={() => {
                    if (pos.nav === "Boarding") {
                      document.getElementById("service").scrollIntoView();
                    } else {
                      dispatch(inHome);
                      navigate("/");
                    }
                  }}
                >
                  {t("navbar.Services")}{" "}
                </Nav.Link>
                <Nav.Link
                  className="navtext"
                  onClick={() => {
                    if (pos.nav === "Boarding") {
                      document.getElementById("solution").scrollIntoView();
                    } else {
                      dispatch(inHome);
                      navigate("/");
                    }
                  }}
                >
                  {t("navbar.Solutions")}{" "}
                </Nav.Link>
                  {/* //References */}
                <Nav.Link className="navtext" 
                onClick={() => {
                    dispatch(ref);
                    navigate("/");
                    window.scrollTo(0, 0);
              }}
              >
                  {t("navbar.Réferences")}
                </Nav.Link>
         {/* //About*/}
                <Nav.Link
                  className="navtext"
                  onClick={() => {
                    dispatch(about)
                    window.scrollTo(0, 0);
                    navigate('/')
                  }}
                >
                  {t("navbar.A propos")}
                </Nav.Link>
    {/* //Actualités*/}
                <Nav.Link
                  className="navtext"
                  onClick={() => {
                    dispatch(actu)
                    window.scrollTo(0, 0);
                    navigate('/actualites')
                  }}
                >
                  {t("navbar.Actualités")}
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </Desktop>
      <Tablet>
        <Navbar bg="wihte" expand="lg">
          <Container style={{ marginTop: "26px" }}>
            <Navbar.Brand>
              <img alt="logo" src={logo} />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse
              id="basic-navbar-nav"
              style={{ position: "absolute", right: "100px" }}
            >
              <Nav className="me-auto">
                <Nav.Link
                  className="navtext"
                  onClick={() => {
                    dispatch(inHome);
                    navigate("/");
                    window.scrollTo(0, 0);
                  }}
                >
                  {" "}
                  Accueil
                </Nav.Link>
                <Nav.Link
                  className="navtext"
                  onClick={() => {
                    if (pos.nav === "Boarding") {
                      document.getElementById("service").scrollIntoView();
                    } else {
                      dispatch(inHome);
                      navigate("/");
                    }
                  }}
                >
                  Services{" "}
                </Nav.Link>
                <Nav.Link className="navtext" 
                       onClick={() => {
                        dispatch(ref);
                        navigate("/");
                        window.scrollTo(0, 0);
                  }}
                >
                  Réferences
                </Nav.Link>
                <Nav.Link
                  className="navtext"
                  onClick={() => {
                    if (pos.nav === "Boarding") {
                      document.getElementById("solution").scrollIntoView();
                    } else {
                      dispatch(inHome);
                      navigate("/");
                    }
                  }}
                >
                  Solutions{" "}
                </Nav.Link>
                <Nav.Link
                  className="navtext"
                  onClick={() => {
                    dispatch(about)
                    window.scrollTo(0, 0);
                    navigate('/')
                  }}
                >
                  A propos
                </Nav.Link>
                <Nav.Link
                  className="navtext"
                  onClick={() => {
                    dispatch(actu)
                    window.scrollTo(0, 0);
                    navigate('/')
                  }}
                >
                  Actualités{" "}
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </Tablet>
      <Mobile>
        <Navbar
          key="lg"
          bg="white"
          expand="lg"
          className="mb-3"
          style={{ marginTop: "30px" }}
        >
          <Container
            fluid
            style={{ display: "flex", justifyContent: "space-evenly" }}
          >
            <Navbar.Brand>
              {" "}
              <img alt="logo" src={logo} style={{ marginTop: "-10px" }} />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-lg`} />

            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-lg`}
              aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
              placement="end"
              style={{ width: "80%" }}
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-lg`}>
                  Prisma
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav
                  className="me-auto"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "26px",
                  }}
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      <Nav.Link
                        className="navtext"
                        style={{ marginTop: "26px" }}
                        onClick={() =>{
                          dispatch(inHome);
                          navigate("/");
                          window.scrollTo(0, 0);}
                          }
                      >
                        {" "}
                        Accueil
                      </Nav.Link>
                      <Nav.Link
                        className="navtext"
                        style={{ marginTop: "26px" }}
                        onClick={() => {
                          dispatch(ref);
                          navigate("/");
                          window.scrollTo(0, 0);
                    }}
                      >
                        Réferences
                      </Nav.Link>
                      <Nav.Link
                        className="navtext"
                        style={{ marginTop: "26px" }}
                        onClick={() => {
                          if (pos.nav === "Boarding") { 
                            document.getElementById("service")?document.getElementById("service").scrollIntoView() :navigate("/");
                          } else {
                            dispatch(inHome);
                            navigate("/");
                          }
                        }}
                      >
                        Services{" "}
                      </Nav.Link>

                      <Nav.Link
                        className="navtext"
                        style={{ marginTop: "26px" }}
                        onClick={() => {
                          if (pos.nav === "Boarding"   ) {  
                             document.getElementById("solution") ?document.getElementById("solution").scrollIntoView():
                            navigate("/");
                          } else {
                            dispatch(inHome);
                            navigate("/");
                          }
                        }}
                      >
                        Solutions{" "}
                      </Nav.Link>
                      <Nav.Link
                        className="navtext"
                        style={{ marginTop: "26px" }}
                        onClick={() => {
                          dispatch(about)
                          window.scrollTo(0, 0);
                          navigate('/')
                        }}
                      >
                        A propos
                      </Nav.Link>
                      <Nav.Link
                        className="navtext"
                        style={{ marginTop: "26px" }}
                        onClick={() => {
                          dispatch(actu)
                          window.scrollTo(0, 0);
                          navigate('/')
                        }}
                      >
                        Actualités{" "}
                      </Nav.Link>
                    </div>
                  </div>

                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <button
                      className="buttonmenbarone"
                      onClick={() => {
                        navigate("/contact");
                      }}
                    >
                      <div
                        className="textbuttonmenbarone"
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        Contact
                      </div>
                    </button>
                    <button
                      className="buttonmenbarone2"
                      onClick={() => {
                        navigate("/recurtement");
                      }}
                    >
                      <div
                        className="textbuttonmenbarone"
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        Recrutement
                      </div>
                    </button>
                  </div>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      </Mobile>
    </>
  );
};

export default MenuBar;
