import React, { useState, useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Recrutement from "../../assets/Recrutement.png";
import MenuBar from "../../Component/Navbar/MenuBar";
import Menubarone from "../../Component/Navbar/Menubarone";
import localisation from "../../assets/loca.png";
import LoadingButton from '@mui/lab/LoadingButton';
import { useTranslation } from "react-i18next";
import "bootstrap/dist/css/bootstrap.min.css";
import Pagination from "react-bootstrap/Pagination";
import "./Recrutement.css";
import iconpdf from "../../assets/iconpdf.png";
import Footer from "../../Component/Footer/Footer";
import { Desktop, Mobile, Tablet } from "../../constant/AppConfig";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import useAuth from "../../Api/hooks/useAuth";

const Recurtement = ({setSelectedCountry,selectedCountry,setServ,selectedFunct}) => {
  const [loading,setLoading] = useState(false);
  const [data, setData] = useState({
    email: "",
    nom: "",
    prenom: "",
    file: null,
    telephone: "",
    message: "",
  });
  const [t] = useTranslation("common");
  const [show, setShow] = useState(false);
  const [id, setID] = useState(0);
  const [disabled,setDisabled] = useState(true);
  const [error,setError] = useState('');
  const [fileUploadedname, setfileUploadename] = useState(
    "Attachez votre CV* Ou déposez-le ici "
  );
  const {
    datarecutement,
    dataonerecrutement,
    postContactrecutement,
    getOneRecrutement,
    getRecrutement,
    postrecutement
  } = useAuth();
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = (id) => {
    // console.log(id);
    getOneRecrutement(id);
    
    setShow(true);
  };
  const hiddenFileInput = React.useRef(null);

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    setData({ ...data, file: fileUploaded });
    setfileUploadename(fileUploaded.name);
    // console.log(data,'data changed')
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    getRecrutement();
  }, []);

  return (
    <>
      <Desktop>
     
        <Row>
          <Col style={{ paddingRight: "0px" }}>
            <img
              alt="logo"
              src={Recrutement}
              style={{ width: "100%", paddingRight: "0px", height: "450px" }}
            />
          </Col>
        </Row>

        <Row>
          <Row
            style={{
              justifyContent: "center",
              paddingLeft: "58px",
              paddingRight: "58px",
              marginTop: "124px",
            }}
          >
            {datarecutement &&
              datarecutement.map((item,key) => {
                return (
                  <div key={key}
                    className="cardrecrutement"
                    style={{
                      width: "377.9px",
                      height: "315.63px",
                      marginLeft: "60px",
                      marginTop: "150px",
                    }}
                  >
                    <div className="titrecardrecrutement">{item.titre}</div>
                    <p className="soustitrecardrecrutement">
                      Contrat :<span className="textcardrecrutement">CDI</span>
                    </p>
                    <p className="soustitrecardrecrutement">
                      Date de postulation :
                      <span className="textcardrecrutement">
                        {" "}
                        {item.date_debut}
                      </span>
                    </p>
                    <div style={{ overflowY: "scroll", height: "150px" }}>
                      <div
                        dangerouslySetInnerHTML={{ __html: item.description }}
                      />
                    </div>

                    <Row>
                      <Col>
                        <div>
                          <img alt="logo" src={localisation} className="loc" />
                          <span
                            className="textcardrecrutement"
                            style={{ marginLeft: "7px" }}
                          >
                            Tunisie
                          </span>
                        </div>
                      </Col>
                      <Col>
                        <button
                          className="buttoncardrecrutement"
                          onClick={() => handleShow(item.id)}
                        >
                          <div className="textbuttoncardrecrutement">
                            Postuler
                          </div>
                        </button>
                      </Col>
                    </Row>
                  </div>
                );
              })}
          </Row>
        </Row>
        <Row>
          <div className="titlerecutemment">{t("Recrutement.candidature")}</div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div style={{ marginRight: "0px", width: "90%" }}>
              <Row
                style={{
                  marginTop: "57px",
                }}
              >
                <Form.Group as={Col}>
                  <input
                    type="text"
                    placeholder={t("Serivces.nom")}
                    style={{
                      borderTop: "none",
                      borderRight: "none",
                      borderLeft: "none",
                      borderRadius: "0px",
                      width: "100%",
                    }}
                    value={data.nom}
                    onChange={(e) => setData({ ...data, nom: e.target.value })}
                  />
                </Form.Group>
                <Form.Group as={Col}>
                  <input
                    type="text"
                    placeholder={t("Serivces.prenom")}
                    style={{
                      borderTop: "none",
                      borderRight: "none",
                      borderLeft: "none",
                      width: "100%",
                    }}
                    value={data.prenom}
                    onChange={(e) =>
                      setData({ ...data, prenom: e.target.value })
                    }
                  />
                </Form.Group>
              </Row>

              <Form.Group
                style={{
                  marginTop: "36px",
                }}
              >
                <input
                  type="email"
                  placeholder={t("Serivces.email")}
                  value={data.email}
                  onChange={(e) => {
                  if (!isValidEmail(e.target.value)) {
                    setError("Email is invalid");
                  } else { 
                    setError(null);
                    setDisabled(false);
                  }
                  setData({ ...data, email: e.target.value });
                }}
                  style={{
                    borderTop: "none",
                    borderRight: "none",
                    borderLeft: "none",
                    borderColor: "gray",
                    width: "100%",
                  }}
                />
              </Form.Group>{error}
              <Form.Group
                style={{
                  marginTop: "36px",
                }}
              >
                <input
                  type="number"
                  value={data.telephone}
                  onChange={(e) =>
                    setData({ ...data, telephone: e.target.value })
                  }
                  placeholder={t("Serivces.telephone")}
                  style={{
                    borderTop: "none",
                    borderRight: "none",
                    borderLeft: "none",
                    width: "100%",
                  }}
                />
              </Form.Group>
              <Form.Group
                style={{
                  marginTop: "36px",
                }}
              >
                <div className="buttonpdfrecrutement" onClick={handleClick}>
                  <div className="textbuttonpdfrecrutement">
                    {fileUploadedname}
                  </div>
                  <input
                    type="file"
                    ref={hiddenFileInput}
                    onChange={handleChange}
                    style={{ display: "none" }}
                    // postContactrecutement
                  />
                  <img
                    alt="logo"
                    src={iconpdf}
                    style={{ position: "absolute", right: "20px", top: "25px" }}
                  />
                </div>
                <input
                  type="file"
                  ref={hiddenFileInput}
                  onChange={handleChange}
                  style={{ display: "none" }}
                />
              </Form.Group>
              <Form.Group
                style={{
                  marginTop: "36px",
                }}
              >
                <textarea
                  placeholder="Message"
                  value={data.message}
                  onChange={(e) =>
                    setData({ ...data, message: e.target.value })
                  }
                  style={{
                    borderTop: "none",
                    borderRight: "none",
                    borderLeft: "none",
                    width: "100%",
                  }}
                />{" "}
              </Form.Group>

              <button
                className="buttonsubmitservice"
                style={{
                  marginBottom: "30px",
                  marginTop: "20px",
                  marginRight: "45px",
                }}  
                disabled={disabled}
                onClick={() => {
                  const formData = new FormData();
                  formData.append("nom", data.nom);
                  formData.append("prenom", data.prenom);
                  formData.append("file", data.file);
                  formData.append("email", data.email);
                  formData.append("telephone", data.telephone);
                  formData.append("message", data.message);
                  // console.log(formData);
                
                  postContactrecutement(formData);
                  setfileUploadename("Attachez votre CV* Ou déposez-le ici ");
                  setData({
                    email: "",
                    nom: "",
                    prenom: "",
                    file: null,
                    telephone: "",
                    message: "",
                  });
                }}
              >
                <div className="textsubmitservice"    >{t("Recrutement.Postuler")}</div>
              </button>
            </div>
          </div>
        </Row>
        {/* //Modal  */}
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header>
              <Modal.Title>
                <div className="titrecardrecrutement">
                  {dataonerecrutement && dataonerecrutement.titre}
                </div>
                <p className="soustitrecardrecrutement">
                  Contrat :<span className="textcardrecrutement">CDI</span>
                </p>
                <p className="soustitrecardrecrutement">
                  Date de postulation :
                  <span className="textcardrecrutement">
                    {dataonerecrutement && dataonerecrutement.date_debut}
                  </span>
                </p>
                <div style={{ overflowY: "scroll", height: "50px" }}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        dataonerecrutement && dataonerecrutement.description,
                    }}
                  />
                </div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div style={{ width: "100%" }}>
                  <Form style={{ marginRight: "0px" }}>
                    <Row
                      style={{
                        marginTop: "57px",
                        marginLeft: "0px",
                        marginRight: "30px",
                      }}
                    >
                      <Form.Group as={Col}>
                        <input
                          type="text"
                          placeholder="Nom"
                          style={{
                            borderTop: "none",
                            borderRight: "none",
                            borderLeft: "none",
                            borderRadius: "0px",
                            width: "100%",
                          }}
                          value={data.nom}
                          onChange={(e) =>
                            setData({ ...data, nom: e.target.value })
                          }
                        />
                      </Form.Group>
                      <Form.Group as={Col}>
                        <input
                          type="text"
                          placeholder="Prenom"
                          style={{
                            borderTop: "none",
                            borderRight: "none",
                            borderLeft: "none",
                            width: "100%",
                          }}
                          value={data.prenom}
                          onChange={(e) =>
                            setData({ ...data, prenom: e.target.value })
                          }
                        />
                      </Form.Group>
                    </Row>

                    <Form.Group
                      style={{
                        marginTop: "36px",
                        marginRight: "30px",
                        marginLeft: "30px",
                      }}
                    >
                      <input
                        type="email"
                        placeholder="Email"
                        style={{
                          borderTop: "none",
                          borderRight: "none",
                          borderLeft: "none",
                          borderColor: "gray",
                          width: "100%",
                        }}
                        value={data.email}
                        onChange={(e) => {
                          if (!isValidEmail(e.target.value)) {
                            setError("Email is invalid");
                          } else { 
                            setError(null);
                          }
                          setData({ ...data, email: e.target.value });
                        }}
                      />
                    </Form.Group>{error}
                    <Form.Group
                      style={{
                        marginTop: "36px",
                        marginRight: "30px",
                        marginLeft: "30px",
                      }}
                    >
                      <input
                        type="number"
                        placeholder="Téléphone"
                        style={{
                          borderTop: "none",
                          borderRight: "none",
                          borderLeft: "none",
                          width: "100%",
                        }}
                        value={data.telephone}
                        onChange={(e) =>
                          setData({ ...data, telephone: e.target.value })
                        }
                      />
                    </Form.Group>
                    <Form.Group
                      style={{
                        marginTop: "36px",
                      }}
                    >
                      <div
                        className="buttonpdfrecrutement"
                        onClick={handleClick}
                      >
                        <div className="textbuttonpdfrecrutement">
                          {fileUploadedname}
                        </div>
                        <input
                          type="file"
                          ref={hiddenFileInput}
                          onChange={handleChange}
                          style={{ display: "none" }}
                        />
                        <img
                          alt="logo"
                          src={iconpdf}
                          style={{
                            position: "absolute",
                            right: "20px",
                            top: "25px",
                          }}
                        />
                      </div>
                      <input
                        type="file"
                        ref={hiddenFileInput}
                        onChange={handleChange}
                        style={{ display: "none" }}
                      />
                    </Form.Group>
                    <Form.Group
                      style={{
                        marginTop: "36px",
                        marginRight: "30px",
                        marginLeft: "30px",
                      }}
                    >
                      <textarea
                        placeholder="Message"
                        style={{
                          borderTop: "none",
                          borderRight: "none",
                          borderLeft: "none",
                          width: "100%",
                        }}
                        value={data.message}
                        onChange={(e) =>
                          setData({ ...data, message: e.target.value })
                        }
                      />{" "}
                    </Form.Group>
                  </Form>{" "}
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <LoadingButton
                variant="outlined"
            
                loading={loading}
                loadingIndicator="En cours"
                onClick={() => {
                  const formData = new FormData();
                  formData.append("nom", data.nom);
                  formData.append("prenom", data.prenom);
                  formData.append("file", data.file);
                  formData.append("email", data.email);
                  formData.append("telephone", data.telephone);
                  formData.append("message", data.message);
                  formData.append("recrutements_id", dataonerecrutement.id);
                  // console.log(formData,'frome Data show');
                  setLoading(true);
                  postrecutement(formData);
                  setfileUploadename("Attachez votre CV* Ou déposez-le ici ");
                  setData({
                    email: "",
                    nom: "",
                    prenom: "",
                    file: null,
                    telephone: "",
                    message: "",
                  });
                }}
              >
                Postuler
              </LoadingButton>
            </Modal.Footer>
          </Modal>
        </div>
      </Desktop>
      <Tablet>
        {/* <Row
          style={{
            backgroundColor: "#253D8A",
            minHeight: "60px",
            height: "auto",
          }}
        >
          <Col>
            <Menubarone />
          </Col>
        </Row>
        <Row style={{ minHeight: "120px", height: "auto", width: "100%" }}>
          <Col>
            <MenuBar />
          </Col>
        </Row> */}
        <Row>
          <Col style={{ paddingRight: "0px" }}>
            <img
              alt="logo"
              src={Recrutement}
              style={{ width: "100%", paddingRight: "0px" }}
            />
          </Col>
        </Row>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "-40px",
            width: "100%",
            height: "71.69px",
          }}
        >
          <div className="sliderRecrutement">
            <input placeholder="Localisation .." className="filterrecurtemnt" />
            <input placeholder="Métier .." className="filterrecurtemnt" />
            <input placeholder="Contrat .." className="filterrecurtemnt" />
            <input placeholder="Fonction .." className="filterrecurtemnt" />
          </div>
        </div>
        <Row>
          <Row
            style={{
              justifyContent: "center",
              paddingLeft: "58px",
              paddingRight: "58px",
              marginTop: "124px",
            }}
          >
            {datarecutement &&
              datarecutement.map((item,key) => {
                return (
                  <div key={key}
                    className="cardrecrutement"
                    style={{
                      width: "377.9px",
                      height: "315.63px",
                      marginLeft: "60px",
                      marginTop: "150px",
                    }}
                  >
                    <div className="titrecardrecrutement">{item.titre}</div>
                    <p className="soustitrecardrecrutement">
                      Contrat :<span className="textcardrecrutement">CDI</span>
                    </p>
                    <p className="soustitrecardrecrutement">
                      Date de postulation :
                      <span className="textcardrecrutement">
                        {" "}
                        {item.date_debut}
                      </span>
                    </p>
                    <div style={{ overflowY: "scroll", height: "150px" }}>
                      <div
                        dangerouslySetInnerHTML={{ __html: item.description }}
                      />
                    </div>

                    <Row>
                      <Col>
                        <div>
                          <img alt="logo" src={localisation} className="loc" />
                          <span
                            className="textcardrecrutement"
                            style={{ marginLeft: "7px" }}
                          >
                            Tunisie
                          </span>
                        </div>
                      </Col>
                      <Col>
                        <button
                          className="buttoncardrecrutement"
                          onClick={() => handleShow(item.id)}
                        >
                          <div className="textbuttoncardrecrutement">
                            Postuler
                          </div>
                        </button>
                      </Col>
                    </Row>
                  </div>
                );
              })}
          </Row>
          <div
            style={{
              marginTop: "55px",
              justifyContent: "center",
              display: "flex",
            }}
          ></div>
        </Row>
        <Row>
          <div className="titlerecutemment">Candidature spontanée </div>
          <Form style={{ marginRight: "130px" }}>
            <Row
              style={{
                marginTop: "57px",
                marginLeft: "30px",
                marginRight: "30px",
              }}
            >
              <Form.Group as={Col}>
                <input
                  type="text"
                  placeholder={t("Serivces.nom")}
                  style={{
                    borderTop: "none",
                    borderRight: "none",
                    borderLeft: "none",
                    borderRadius: "0px",
                    width: "100%",
                  }}
                />
              </Form.Group>
              <Form.Group as={Col}>
                <input
                  type="text"
                  placeholder={t("Serivces.prenom")}
                  style={{
                    borderTop: "none",
                    borderRight: "none",
                    borderLeft: "none",
                    width: "100%",
                  }}
                />
              </Form.Group>
            </Row>

            <Form.Group
              style={{
                marginTop: "36px",
                marginRight: "30px",
                marginLeft: "30px",
              }}
            >
              <input
                type="email"
                placeholder={t("Serivces.email")}
                style={{
                  borderTop: "none",
                  borderRight: "none",
                  borderLeft: "none",
                  borderColor: "gray",
                  width: "100%",
                }}
              />
            </Form.Group>
            <Form.Group
              style={{
                marginTop: "36px",
                marginRight: "30px",
                marginLeft: "30px",
              }}
            >
              <input
                type="number"
                placeholder={t("Serivces.telephone")}
                style={{
                  borderTop: "none",
                  borderRight: "none",
                  borderLeft: "none",
                  width: "100%",
                }}
              />
            </Form.Group>
            <Form.Group
              style={{
                marginTop: "36px",
                marginRight: "30px",
                marginLeft: "30px",
              }}
            >
              <button className="buttonpdfrecrutement" onClick={handleClick}>
                <div className="textbuttonpdfrecrutement">
                {t("Recrutement.AttachCv")}
                </div>
                <input
                  type="file"
                  ref={hiddenFileInput}
                  onChange={handleChange}
                  style={{ display: "none" }}
                />
                <img
                  alt="logo"
                  src={iconpdf}
                  style={{ position: "absolute", right: "20px", top: "25px" }}
                />
              </button>
              <input
                type="file"
                ref={hiddenFileInput}
                onChange={handleChange}
                style={{ display: "none" }}
              />
            </Form.Group>
            <Form.Group
              style={{
                marginTop: "36px",
                marginRight: "30px",
                marginLeft: "30px",
              }}
            >
              <textarea
                placeholder="Message"
                style={{
                  borderTop: "none",
                  borderRight: "none",
                  borderLeft: "none",
                  width: "100%",
                }}
              />{" "}
            </Form.Group>

            <button
              className="buttonsubmitservice"
              type="submit"
              style={{ marginBottom: "30px" }}
            >
              <div className="textsubmitservice">{t("Recrutement.Postuler")}</div>
            </button>
          </Form>
        </Row>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header>
            <Modal.Title>
              <div className="titrecardrecrutement">Titre du poste</div>
              <p className="soustitrecardrecrutement">
                Contrat :<span className="textcardrecrutement">CDI</span>
              </p>
              <p className="soustitrecardrecrutement">
                Date de postulation :
                <span className="textcardrecrutement">12/08 - 8H GMT</span>
              </p>
              <div className="textcardrecrutement">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div style={{ width: "100%" }}>
                <Form style={{ marginRight: "0px" }}>
                  <Row
                    style={{
                      marginTop: "57px",
                      marginLeft: "0px",
                      marginRight: "30px",
                    }}
                  >
                    <Form.Group as={Col}>
                      <input
                        type="text"
                        placeholder={t("Serivces.nom")}
                        style={{
                          borderTop: "none",
                          borderRight: "none",
                          borderLeft: "none",
                          borderRadius: "0px",
                          width: "100%",
                        }}
                      />
                    </Form.Group>
                    <Form.Group as={Col}>
                      <input
                        type="text"
                        placeholder={t("Serivces.prenom")}
                        style={{
                          borderTop: "none",
                          borderRight: "none",
                          borderLeft: "none",
                          width: "100%",
                        }}
                      />
                    </Form.Group>
                  </Row>

                  <Form.Group
                    style={{
                      marginTop: "36px",
                      marginRight: "30px",
                      marginLeft: "30px",
                    }}
                  >
                    <input
                      type="email"
                      placeholder={t("Serivces.email")}
                      style={{
                        borderTop: "none",
                        borderRight: "none",
                        borderLeft: "none",
                        borderColor: "gray",
                        width: "100%",
                      }}
                    />
                  </Form.Group>
                  <Form.Group
                    style={{
                      marginTop: "36px",
                      marginRight: "30px",
                      marginLeft: "30px",
                    }}
                  >
                    <input
                      type="number"
                      placeholder={t("Serivces.telephone")}
                      style={{
                        borderTop: "none",
                        borderRight: "none",
                        borderLeft: "none",
                        width: "100%",
                      }}
                    />
                  </Form.Group>
                  <Form.Group
                    style={{
                      marginTop: "36px",
                      marginRight: "30px",
                      marginLeft: "30px",
                    }}
                  >
                    <button
                      className="buttonpdfrecrutement"
                      onClick={handleClick}
                    >
                      <div className="textbuttonpdfrecrutement">
                      {t("Recrutement.AttachCv")}
                      </div>
                      <input
                        type="file"
                        ref={hiddenFileInput}
                        onChange={handleChange}
                        style={{ display: "none" }}
                      />
                      <img
                        alt="logo"
                        src={iconpdf}
                        style={{
                          position: "absolute",
                          right: "20px",
                          top: "25px",
                        }}
                      />
                    </button>
                    <input
                      type="file"
                      ref={hiddenFileInput}
                      onChange={handleChange}
                      style={{ display: "none" }}
                    />
                  </Form.Group>
                  <Form.Group
                    style={{
                      marginTop: "36px",
                      marginRight: "30px",
                      marginLeft: "30px",
                    }}
                  >
                    <textarea
                      placeholder="Message"
                      style={{
                        borderTop: "none",
                        borderRight: "none",
                        borderLeft: "none",
                        width: "100%",
                      }}
                    />{" "}
                  </Form.Group>
                </Form>{" "}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleClose}>
              Postuler
            </Button>
          </Modal.Footer>
        </Modal>
      </Tablet>
      <Mobile>
        {/* <Row
          style={{
            backgroundColor: "#253D8A",
            minHeight: "60px",
            height: "auto",
          }}
        >
          <Col>
            <Menubarone />
          </Col>
        </Row>
        <Row style={{ minHeight: "120px", height: "auto", width: "100%" }}>
          <Col>
            <MenuBar />
          </Col>
        </Row> */}
        <Row>
          <Col style={{ paddingRight: "0px" }}>
            <img
              alt="logo"
              src={Recrutement}
              style={{ width: "100%", paddingRight: "0px" }}
            />
          </Col>
        </Row>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "-40px",
            width: "100%",
            height: "71.69px",
          }}
        >
          <div className="sliderRecrutement">
            <input placeholder="Localisation .." className="filterrecurtemnt" />
            <input placeholder="Métier .." className="filterrecurtemnt" />
            <input placeholder="Contrat .." className="filterrecurtemnt" />
            <input placeholder="Fonction .." className="filterrecurtemnt" />
          </div>
        </div>
        <Row>
          <Row
            style={{
              justifyContent: "center",
              paddingLeft: "58px",
              paddingRight: "58px",
              marginTop: "124px",
            }}
          >
            {datarecutement &&
              datarecutement.map((item,key) => {
                return (
                  <div key={key}
                    className="cardrecrutement"
                    style={{
                      width: "377.9px",
                      height: "315.63px",
                      marginLeft: "60px",
                      marginTop: "150px",
                    }}
                  >
                    <div className="titrecardrecrutement">{item.titre}</div>
                    <p className="soustitrecardrecrutement">
                      Contrat :<span className="textcardrecrutement">CDI</span>
                    </p>
                    <p className="soustitrecardrecrutement">
                      Date de postulation :
                      <span className="textcardrecrutement">
                        {" "}
                        {item.date_debut}
                      </span>
                    </p>
                    <div style={{ overflowY: "scroll", height: "150px" }}>
                      <div
                        dangerouslySetInnerHTML={{ __html: item.description }}
                      />
                    </div>

                    <Row>
                      <Col>
                        <div>
                          <img alt="logo" src={localisation} className="loc" />
                          <span
                            className="textcardrecrutement"
                            style={{ marginLeft: "7px" }}
                          >
                            Tunisie
                          </span>
                        </div>
                      </Col>
                      <Col>
                        <button
                          className="buttoncardrecrutement"
                          onClick={() => handleShow(item.id)}
                        >
                          <div className="textbuttoncardrecrutement">
                            {t("Recrutement.Postuler")}
                          </div>
                        </button>
                      </Col>
                    </Row>
                  </div>
                );
              })}
          </Row>
          <div
            style={{
              marginTop: "55px",
              justifyContent: "center",
              display: "flex",
            }}
          ></div>
        </Row>
        <Row>
          <div className="titlerecutemment">{t("Recrutement.candidature")}</div>
          <Form style={{ marginRight: "130px" }}>
            <Row
              style={{
                marginTop: "57px",
                marginLeft: "30px",
                marginRight: "30px",
              }}
            >
              <Form.Group as={Col}>
                <input
                  type="text"
                  placeholder={t("Serivces.nom")}
                  style={{
                    borderTop: "none",
                    borderRight: "none",
                    borderLeft: "none",
                    borderRadius: "0px",
                    width: "100%",
                  }}
                />
              </Form.Group>
              <Form.Group as={Col}>
                <input
                  type="text"
                  placeholder={t("Serivces.prenom")}
                  style={{
                    borderTop: "none",
                    borderRight: "none",
                    borderLeft: "none",
                    width: "100%",
                  }}
                />
              </Form.Group>
            </Row>

            <Form.Group
              style={{
                marginTop: "36px",
                marginRight: "30px",
                marginLeft: "30px",
              }}
            >
              <input
                type="email"
                placeholder={t("Serivces.email")}
                style={{
                  borderTop: "none",
                  borderRight: "none",
                  borderLeft: "none",
                  borderColor: "gray",
                  width: "100%",
                }}
              />
            </Form.Group>
            <Form.Group
              style={{
                marginTop: "36px",
                marginRight: "30px",
                marginLeft: "30px",
              }}
            >
              <input
                type="number"
                placeholder={t("Serivces.telephone")}
                style={{
                  borderTop: "none",
                  borderRight: "none",
                  borderLeft: "none",
                  width: "100%",
                }}
              />
            </Form.Group>
            <Form.Group
              style={{
                marginTop: "36px",
                marginRight: "30px",
                marginLeft: "30px",
              }}
            >
              <button className="buttonpdfrecrutement" onClick={handleClick}>
                <div className="textbuttonpdfrecrutement">
                  {t("Recrutement.AttachCv")}
                </div>
                <input
                  type="file"
                  ref={hiddenFileInput}
                  onChange={handleChange}
                  style={{ display: "none" }}
                />
                <img
                  alt="logo"
                  src={iconpdf}
                  style={{ position: "absolute", right: "20px", top: "25px" }}
                />
              </button>
              <input
                type="file"
                ref={hiddenFileInput}
                onChange={handleChange}
                style={{ display: "none" }}
              />
            </Form.Group>
            <Form.Group
              style={{
                marginTop: "36px",
                marginRight: "30px",
                marginLeft: "30px",
              }}
            >
              <textarea
                placeholder="Message"
                style={{
                  borderTop: "none",
                  borderRight: "none",
                  borderLeft: "none",
                  width: "100%",
                }}
              />{" "}
            </Form.Group>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              {" "}
              <button
                className="buttonsubmitservice"
                type="submit"
                style={{ marginBottom: "30px" }}
              >
                <div className="textsubmitservice">{t('Recrutement.Postuler')}</div>
              </button>
            </div>
          </Form>
        </Row>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header>
            <Modal.Title>
              {" "}
              <div className="titrecardrecrutement">Titre du poste</div>
              <p className="soustitrecardrecrutement">
                Contrat :<span className="textcardrecrutement">CDI</span>
              </p>
              <p className="soustitrecardrecrutement">
                Date de postulation :
                <span className="textcardrecrutement">12/08 - 8H GMT</span>
              </p>
              <div className="textcardrecrutement">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div style={{ width: "100%" }}>
                <Form style={{ marginRight: "0px" }}>
                  <Row
                    style={{
                      marginTop: "57px",
                      marginLeft: "18px",
                      marginRight: "30px",
                    }}
                  >
                    <Form.Group as={Col}>
                      <input
                        type="text"
                        placeholder="Name"
                        style={{
                          borderTop: "none",
                          borderRight: "none",
                          borderLeft: "none",
                          borderRadius: "0px",
                          width: "100%",
                        }}
                      />
                    </Form.Group>
                    <Form.Group as={Col}>
                      <input
                        type="text"
                        placeholder="Prenom"
                        style={{
                          borderTop: "none",
                          borderRight: "none",
                          borderLeft: "none",
                          width: "100%",
                        }}
                      />
                    </Form.Group>
                  </Row>

                  <Form.Group
                    style={{
                      marginTop: "36px",
                      marginRight: "30px",
                      marginLeft: "30px",
                    }}
                  >
                    <input
                      type="email"
                      placeholder="Email"
                      style={{
                        borderTop: "none",
                        borderRight: "none",
                        borderLeft: "none",
                        borderColor: "gray",
                        width: "100%",
                      }}
                    />
                  </Form.Group>
                  <Form.Group
                    style={{
                      marginTop: "36px",
                      marginRight: "30px",
                      marginLeft: "30px",
                    }}
                  >
                    <input
                      type="number"
                      placeholder="Téléphone"
                      style={{
                        borderTop: "none",
                        borderRight: "none",
                        borderLeft: "none",
                        width: "100%",
                      }}
                    />
                  </Form.Group>
                  <Form.Group
                    style={{
                      marginTop: "36px",
                      marginRight: "30px",
                      marginLeft: "30px",
                    }}
                  >
                    <button
                      className="buttonpdfrecrutement"
                      onClick={handleClick}
                    >
                      <div className="textbuttonpdfrecrutement">
                      {t("Recrutement.AttachCv")}
                      </div>
                      <input
                        type="file"
                        ref={hiddenFileInput}
                        onChange={handleChange}
                        style={{ display: "none" }}
                      />
                      <img
                        alt="logo"
                        src={iconpdf}
                        style={{
                          position: "absolute",
                          right: "20px",
                          top: "25px",
                        }}
                      />
                    </button>
                    <input
                      type="file"
                      ref={hiddenFileInput}
                      onChange={handleChange}
                      style={{ display: "none" }}
                    />
                  </Form.Group>
                  <Form.Group
                    style={{
                      marginTop: "36px",
                      marginRight: "30px",
                      marginLeft: "30px",
                    }}
                  >
                    <textarea
                      placeholder="Message"
                      style={{
                        borderTop: "none",
                        borderRight: "none",
                        borderLeft: "none",
                        width: "100%",
                      }}
                    />{" "}
                  </Form.Group>
                </Form>{" "}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleClose}>
              Postuler
            </Button>
          </Modal.Footer>
        </Modal>
      </Mobile>
 
    </>
  );
};

export default Recurtement;
