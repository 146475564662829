import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import arrow from "../../assets/down-arrow.png";
import "./Home.css";
import React, { useEffect, useState } from "react";


import Slider from "./Slider";
import Presence from "./Presence/Presence";
import Chiffres from "./Chiffres/Chiffres";
import Partenaires from "./Partenaires/Partenaires";
import Service from "./Services/Service";
import Solution from "./Solution/Solution";
import Metier from "./Metier/metier";

import { useLocation } from "react-router-dom";


const Boarding = (props) => {
    // const [home, setHome] = useState(0);
    const [showButton,setShowButton] = useState('false');
    const { state } = useLocation();
    useEffect(() => {
      console.log('home==============>Boarding')
        // setHome(1);
        // console.log(state);
        if (state !== null) {
          if (state.serivce === true) {
            document.getElementById("service").scrollIntoView();
          } else if (state.solution === true) {
            document.getElementById("solution").scrollIntoView();
          }
        }
      }, []);


const handleSrollToTop = () => {
  window.scrollTo({top: 0,behabior: 'smooth'});
}


    return (
        <>
    

<Row>
<Col style={{ paddingLeft: "0px", paddingRight: "0px" }}>
<Slider />
</Col>
{/* {showButton && 
(<div className={`scrollToTop`}>
  <button
  className="fixed button-5 right-7 z-50 cursor-pointer p-4" onClick={handleSrollToTop}>
<img src={arrow} style={{width : "100px",height:"100px",marginLeft:"80%"}}></img>
  </button>
</div>)} */}
</Row>
<Row>
<Metier />
</Row>
<Row>
<Presence />
</Row>
<Row>
<Chiffres
selectedCountry={props.selectedCountry}
setSelectedCountry={props.setSelectedCountry}
/>
</Row>
<Row id="part">
<Partenaires />
</Row>
<Row id="service">
<Service
setServ={props.setServ}
selectedCountry={props.selectedCountry}
/>
</Row>
<Row id="solution">
<Solution
setSol={props.setSol}
selectedCountry={props.selectedCountry}
/>
</Row>
</>
    )}
    export default Boarding ;