import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Rectangle158 from "../../assets/Rectangle158.png";
import Rectangle13 from "../../assets/Rectangle13.png";
import Rectangle27 from "../../assets/Rectangle27.png";
//redux
import SolutionsIndex from '../../features/solutions/solutions';

import FormulaireC from "./FormulaireContact.js"
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Marker,
  InfoWindow,
} from "react-google-maps";
import parkData from "./skateboard-parks.json";
import mapStyles from "./mapStyles";

import "./Contact.css";
import Footer from "../../Component/Footer/Footer";
import { Desktop, Mobile, Tablet } from "../../constant/AppConfig";
import useAuth from "../../Api/hooks/useAuth";


function Map() {
  const [selectedPark, setSelectedPark] = useState(null);
 
  useEffect(() => {
    const listener = (e) => {
      if (e.key === "Escape") {
        setSelectedPark(null);
      }
    };
    window.addEventListener("keydown", listener);

    return () => {
      window.removeEventListener("keydown", listener);
    };
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <GoogleMap
      defaultZoom={10}
      defaultCenter={{ lat: 36.844095, lng: 10.277899 }}
      defaultOptions={{ styles: mapStyles }}
    >
      {parkData.features.map((park) => (
        <Marker
          key={park.properties.PARK_ID}
          position={{ lat: 36.844095, lng: 10.277899 }}
          onClick={() => {
            setSelectedPark(park);
          }}
          // icon={{
          //   url: `https://upload.wikimedia.org/wikipedia/commons/9/9a/Gull_portrait_ca_usa.jpg`,
          //   scaledSize: new window.google.maps.Size(25, 25),
          // }}
        />
      ))}

  
    </GoogleMap>
  );
}
const MapWrapped = withScriptjs(withGoogleMap(Map));

const Contact = ({selectedFunct,setSelectedCountry,selectedCountry,setServ}) => {
  const {postContact}=useAuth()
  const position  = "contact";
  const [t] = useTranslation("common");
  const [data, setData] = useState({
    nom_prenom: "",
    email: "",
    telephone: "",
    ville: "",
    pays: "",
    societe: "",
    message: "",
  });
  
useEffect(()=>{
// console.log(props)
},[])
  return (
    <>
      <Desktop>
   
        <Row>
          <Col style={{ paddingRight: "0px" }}>
            <img alt="logo" src={Rectangle158} style={{ width: "100%" }} />
          </Col>
        </Row>
        {/* <Row><SolutionsIndex/></Row> */}
        <Row>
          <Col>
            <div style={{ marginTop: "100px" }}>
              <img
                alt="logo"
                className="lineserviceconsulting"
                src={Rectangle13} 
                onClick={() => {console.log("clicked"); } }
              />
            </div>
            <div className="title2serviceconsulting">{t("Serivces.bureaux")} </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Row style={{ justifyContent: "center" }}>
              <div
                className="cardrecrutement"
                style={{
                  width: "377.9px",
                  height: "215.63px",
                  marginLeft: "60px",
                  marginTop: "50px",
                }}
              >
                <button className="buttoncontact">
                  <div className="textbuttoncontact">Tunis, Tunisia</div>
                </button>
                <img
                  alt="logo"
                  src={Rectangle27}
                  style={{ marginTop: "-28px" }}
                />
                <p className="textcardcontact">
                  Immeuble Ichbilia Bloc A, 3ème étage
                </p>
                <p className="textcardcontact">
                  1053 Les Berges du Lac II - Tunisie
                </p>

                <p className="soustextcardcontact">Phone: (+216) 71 967 021</p>
                <p className="soustextcardcontact">Fax: (+216) 71 967 027</p>
              </div>
              <div
                className="cardrecrutement"
                style={{
                  width: "377.9px",
                  height: "215.63px",
                  marginLeft: "60px",
                  marginTop: "50px",
                }}
              >
                <button className="buttoncontact">
                  <div className="textbuttoncontact">Casablanca, Morocco</div>
                </button>
                <img
                  alt="logo"
                  src={Rectangle27}
                  style={{ marginTop: "-28px", width: "345.23px" }}
                />
                <p className="textcardcontact">
                  24, Rue Abou Al Mahassine Rouyani{" "}
                </p>
                <p className="textcardcontact">
                  Quartier Maârif - Casablanca- Maroc
                </p>

                <p className="soustextcardcontact">Phone: (+212) 5 2298 2687</p>
              </div>
              <div
                className="cardrecrutement"
                style={{
                  width: "377.9px",
                  height: "215.63px",
                  marginLeft: "60px",
                  marginTop: "50px",
                }}
              >
                <button className="buttoncontact">
                  <div className="textbuttoncontact">Abidjan, Ivory Coast</div>
                </button>
                <img
                  alt="logo"
                  src={Rectangle27}
                  style={{ marginTop: "-28px" }}
                />
                <p className="textcardcontact">
                  Abidjan Deux – Plateaux, 7ème tranche, lot 3626 ,
                </p>
                <p className="textcardcontact">
                  ilot 293, 06 BP 60 Abidjan 06.
                </p>

                <p className="soustextcardcontact">
                  Phone: (+225 ) 22 45 89 96
                </p>
              </div>
            </Row>
          </Col>
        </Row>
        <Row>
          <Row>
            <Col>
              <div style={{ marginTop: "100px" }}>
                <img
                  alt="logo"
                  className="lineserviceconsulting"
                  src={Rectangle13}
                />
              </div>
              <div className="title2serviceconsulting">
              {t("Serivces.location")}{" "}
              </div>
            </Col>
          </Row>
          <Row style={{ height: `568.11px` }}>
            <Col>
              <MapWrapped
                googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyCLlUOzlHgHoaGp68z5FEkupiFX6LU2XlQ`}
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `100%` }} />}
                mapElement={<div style={{ height: `100%` }} />}
              />
            </Col>
          </Row>
        </Row>
        <FormulaireC position={position}/>
     

        {/* <Row className="footer">
          <Footer setServ={setServ} />
        </Row> */}
      </Desktop>
      <Tablet>
        {/* <Row
          style={{
            backgroundColor: "#253D8A",
            minHeight: "60px",
            height: "auto",
          }}
        >
          <Col>
            <Menubarone
              setSelectedCountry={setSelectedCountry}
              selectedCountry={selectedCountry}
            />
          </Col>
        </Row>
        <Row style={{ minHeight: "120px", height: "auto", width: "100%" }}>
          <Col>
            <MenuBar />
          </Col>
        </Row> */}
        <Row>
          <Col style={{ paddingRight: "0px" }}>
            <img alt="logo" src={Rectangle158} style={{ width: "100%" }} />
          </Col>
        </Row>
        <Row>
          <Col>
            <div style={{ marginTop: "100px" }}>
              <img
                alt="logo"
                className="lineserviceconsulting"
                src={Rectangle13}
              />
            </div>
            <div className="title2serviceconsulting">Nos bureaux </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Row style={{ justifyContent: "center" }}>
              <div
                className="cardrecrutement"
                style={{
                  width: "377.9px",
                  height: "215.63px",
                  marginLeft: "60px",
                  marginTop: "50px",
                }}
              >
                <button className="buttoncontact">
                  <div className="textbuttoncontact">Tunis, Tunisia</div>
                </button>
                <img
                  alt="logo"
                  src={Rectangle27}
                  style={{ marginTop: "-28px" }}
                />
                <p className="textcardcontact">
                  Immeuble Ichbilia Bloc A, 3ème étage
                </p>
                <p className="textcardcontact">
                  1053 Les Berges du Lac II - Tunisie
                </p>

                <p className="soustextcardcontact">Phone: (+216) 71 967 021</p>
                <p className="soustextcardcontact">Fax: (+216) 71 967 027</p>
              </div>
              <div
                className="cardrecrutement"
                style={{
                  width: "377.9px",
                  height: "215.63px",
                  marginLeft: "60px",
                  marginTop: "50px",
                }}
              >
                <button className="buttoncontact">
                  <div className="textbuttoncontact">Casablanca, Morocco</div>
                </button>
                <img
                  alt="logo"
                  src={Rectangle27}
                  style={{ marginTop: "-28px", width: "345.23px" }}
                />
                <p className="textcardcontact">
                  24, Rue Abou Al Mahassine Rouyani{" "}
                </p>
                <p className="textcardcontact">
                  Quartier Maârif - Casablanca- Maroc
                </p>

                <p className="soustextcardcontact">Phone: (+212) 5 2298 2687</p>
              </div>
              <div
                className="cardrecrutement"
                style={{
                  width: "377.9px",
                  height: "215.63px",
                  marginLeft: "60px",
                  marginTop: "50px",
                }}
              >
                <button className="buttoncontact">
                  <div className="textbuttoncontact">Abidjan, Ivory Coast</div>
                </button>
                <img
                  alt="logo"
                  src={Rectangle27}
                  style={{ marginTop: "-28px" }}
                />
                <p className="textcardcontact">
                  Abidjan Deux – Plateaux, 7ème tranche, lot 3626 ,
                </p>
                <p className="textcardcontact">
                  ilot 293, 06 BP 60 Abidjan 06.
                </p>

                <p className="soustextcardcontact">
                  Phone: (+225 ) 22 45 89 96
                </p>
              </div>
            </Row>
          </Col>
        </Row>
        <Row>
          <Row>
            <Col>
              <div style={{ marginTop: "100px" }}>
                <img
                  alt="logo"
                  className="lineserviceconsulting"
                  src={Rectangle13}
                />
              </div>
              <div className="title2serviceconsulting">
                Localisation des bureaux{" "}
              </div>
            </Col>
          </Row>
          <Row style={{ height: `568.11px` }}>
            <Col>
              <MapWrapped
                googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyCLlUOzlHgHoaGp68z5FEkupiFX6LU2XlQ`}
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `100%` }} />}
                mapElement={<div style={{ height: `100%` }} />}
              />
            </Col>
          </Row>
        </Row>
        <Row>
          <Col>
            <div>
              <div style={{ marginTop: "100px" }}>
                <img
                  alt="logo"
                  className="lineserviceconsulting"
                  src={Rectangle13}
                />
              </div>
              <div className="title2serviceconsulting">Contact</div>
            </div>
            <FormulaireC position={position}/>
          </Col>
        </Row>
        {/* <Row className="footer">
          <Footer setServ={setServ} />
        </Row> */}
      </Tablet>
      <Mobile>
        {/* <Row
          style={{
            backgroundColor: "#253D8A",
            minHeight: "60px",
            height: "auto",
          }}
        >
          <Col>
            <Menubarone
              setSelectedCountry={setSelectedCountry}
              selectedCountry={selectedCountry}
            />
          </Col>
        </Row>
        <Row style={{ minHeight: "120px", height: "auto", width: "100%" }}>
          <Col>
            <MenuBar />
          </Col>
        </Row> */}
        <Row>
          <Col style={{ paddingRight: "0px" }}>
            <img alt="logo" src={Rectangle158} style={{ width: "100%" }} />
          </Col>
        </Row>
        <Row>
          <Col>
            <div
              style={{
                marginTop: "42px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div className="titleserviceconsulting">
                <img
                  alt="logo"
                  src={Rectangle13}
                  className="lineserviceconsulting"
                  style={{ marginLeft: "-30px" }}
                />{" "}
                <div style={{ marginLeft: "30px" }}>Nos bureaux</div>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Row style={{ justifyContent: "center" }}>
              <div
                className="cardrecrutement"
                style={{
                  width: "377.9px",
                  height: "215.63px",
                  marginLeft: "60px",
                  marginTop: "50px",
                }}
              >
                <button className="buttoncontact">
                  <div className="textbuttoncontact">Tunis, Tunisia</div>
                </button>
                <img
                  alt="logo"
                  src={Rectangle27}
                  style={{ marginTop: "-28px" }}
                />
                <p className="textcardcontact">
                  Immeuble Ichbilia Bloc A, 3ème étage
                </p>
                <p className="textcardcontact">
                  1053 Les Berges du Lac II - Tunisie
                </p>

                <p className="soustextcardcontact">Phone: (+216) 71 967 021</p>
                <p className="soustextcardcontact">Fax: (+216) 71 967 027</p>
              </div>
              <div
                className="cardrecrutement"
                style={{
                  width: "377.9px",
                  height: "215.63px",
                  marginLeft: "60px",
                  marginTop: "50px",
                }}
              >
                <button className="buttoncontact">
                  <div className="textbuttoncontact">Casablanca, Morocco</div>
                </button>
                <img
                  alt="logo"
                  src={Rectangle27}
                  style={{ marginTop: "-28px", width: "345.23px" }}
                />
                <p className="textcardcontact">
                  24, Rue Abou Al Mahassine Rouyani{" "}
                </p>
                <p className="textcardcontact">
                  Quartier Maârif - Casablanca- Maroc
                </p>

                <p className="soustextcardcontact">Phone: (+212) 5 2298 2687</p>
              </div>
              <div
                className="cardrecrutement"
                style={{
                  width: "377.9px",
                  height: "215.63px",
                  marginLeft: "60px",
                  marginTop: "50px",
                }}
              >
                <button className="buttoncontact">
                  <div className="textbuttoncontact">Abidjan, Ivory Coast</div>
                </button>
                <img
                  alt="logo"
                  src={Rectangle27}
                  style={{ marginTop: "-28px" }}
                />
                <p className="textcardcontact">
                  Abidjan Deux – Plateaux, 7ème tranche, lot 3626 ,
                </p>
                <p className="textcardcontact">
                  ilot 293, 06 BP 60 Abidjan 06.
                </p>

                <p className="soustextcardcontact">
                  Phone: (+225 ) 22 45 89 96
                </p>
              </div>
            </Row>
          </Col>
        </Row>
        <Row>
          <Row>
            <Col>
              <div
                style={{
                  marginTop: "42px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div className="titleserviceconsulting">
                  <img
                    alt="logo"
                    src={Rectangle13}
                    className="lineserviceconsulting"
                    style={{ marginLeft: "-30px" }}
                  />{" "}
                  <div style={{ marginLeft: "30px" }}>
                    Localisation des bureaux
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row style={{ height: `568.11px`, paddingRight: "0px" }}>
            <Col style={{ paddingRight: "0px" }}>
              <MapWrapped
                googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyCLlUOzlHgHoaGp68z5FEkupiFX6LU2XlQ`}
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `100%` }} />}
                mapElement={<div style={{ height: `100%` }} />}
              />
            </Col>
          </Row>
        </Row>
        <Row>
          <Col>
            <div
              style={{
                // marginTop: "42px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {/* <div className="titleserviceconsulting">
                <img
                  alt="logo"
                  src={Rectangle13}
                  className="lineserviceconsulting"
                  style={{ marginLeft: "-30px" }}
                />{" "}
                <div style={{ marginLeft: "30px" }}>Contact</div>
              </div> */}
            {/* </div> */}
            {/* <div style={{ display: "flex", justifyContent: "center" ,backgroundColor:"red"}}> */}
              {/* <div style={{ width: "100%" }}> */}
              <FormulaireC position={position} />
              </div>
            {/* </div> */}
          </Col>
        </Row>
        {/* <Row className="footer">
          <Footer setServ={setServ} />
        </Row> */}
      </Mobile>
    </>
  );
};

export default Contact;
