import { useMediaQuery } from 'react-responsive'


// Config :
// const config = {
//     //url : 'https://afsac-bo.switch.tn/api/',
//     //url : 'http://afsac-back.test/api/',
//     //url : 'https://preprod.afsac-tunisie.com/api/',
// 	url : 'https://manager.afsac-tunisie.com/api/',
//     siteName: 'AFSAC',
// };

export const images = {
image8 : require("../assets/image8.png"),
image9 : require("../assets/image9.png"),
 image10 : require("../assets/image10.png"),
 image11 : require("../assets/image11.png"),
 image12 : require("../assets/image12.png"),
 image13 : require("../assets/image13.png"),
 image14 : require("../assets/image14.png"),
 image16 : require("../assets/Proboscope.png"),
 image17 : require("../assets/image17.png"),
 image18 : require("../assets/image18.png"),
 image19 : require("../assets/image19.png"),
 image20 : require("../assets/image20.png"),
 image21 : require("../assets/image21.png"),
 image22 : require("../assets/image22.png"),
 image23 : require("../assets/image23.png"),
 image24 : require("../assets/image24.jpg"),
 Threat : require("../assets/ThreatSimulator.png"),
 DPI : require("../assets/DPI.jpg"),
 Rectangle13 : require("../assets/Rectangle13.png"),
}
export const solutions = {
    3 : {},
    // image9 : require("../assets/image9.png"),
    //  image10 : require("../assets/image10.png"),
    //  image11 : require("../assets/image11.png"),
    //  image12 : require("../assets/image12.png"),
    //  image13 : require("../assets/image13.png"),
    //  image14 : require("../assets/image14.png"),
    //  image16 : require("../assets/Proboscope.png"),
    //  image17 : require("../assets/image17.png"),
    //  image18 : require("../assets/image18.png"),
    //  image19 : require("../assets/image19.png"),
    //  image20 : require("../assets/image20.png"),
    //  image21 : require("../assets/image21.png"),
    //  image22 : require("../assets/image22.png"),
    //  image23 : require("../assets/image23.png"),
    //  image24 : require("../assets/image24.jpg"),
    //  Threat : require("../assets/ThreatSimulator.png"),
    //  DPI : require("../assets/DPI.jpg"),
    //  Rectangle13 : require("../assets/Rectangle13.png"),
    }
//     // Header
//     // logo : require(`../assets/images/Header/logo.png`),
//     logo : require(`../assets/images/Header/poly-logo.png`),
//     logo_fr : require(`../assets/images/Header/poly-logo-fr.png`),
//     mobile_logo : require(`../assets/images/Header/mobile-logo.png`),
//     logo_2 : require(`../assets/images/Header/logo2.png`),
//     logo_3 : require(`../assets/images/Header/logo3.png`),
//     twitter_logo : require(`../assets/images/Header/twitter.png`),
//     fb_icon : require(`../assets/images/Header/fb.png`),
//     lkin_logo : require(`../assets/images/Header/lkin.png`),
//     youtube_logo : require(`../assets/images/Header/youtube.png`),
//     search_icon : require(`../assets/images/Header/search.png`),
//     fr_flag : require(`../assets/images/Header/fr.png`),
//     en_flag : require(`../assets/images/Header/uk.png`),

//     // Home   
//     home_carousel_img : require(`../assets/images/Home/ban1.png`),
//     blue_logo : require(`../assets/images/Home/blueLogo.png`),
//     orange_divider : require(`../assets/images/Home/dividerOrange.png`),
//     center_divider : require(`../assets/images/Home/centerDivider.png`),
//     //security_img : require(`../assets/images/Home/sec.png`),
//     security_img : require(`../assets/images/Home/sec.jpg`),
//     big_search_icon : require(`../assets/images/Home/big-loop.png`),
//     session_img: require(`../assets/images/Home/session.png`),
//     next_slider: require(`../assets/images/Home/next-slider.png`),
//     prev_slider: require(`../assets/images/Home/prev-slider.png`),
    
//     // Footer
//     blue_divider: require(`../assets/images/Footer/dividerBlue.png`),
//     white_logo: require(`../assets/images/Footer/whitelogo.png`),
//     white_logo_fr: require(`../assets/images/Footer/whitelogo-fr.png`),
//     white_fb: require(`../assets/images/Footer/f.png`),
//     white_lkin: require(`../assets/images/Footer/i.png`),
//     white_twitter: require(`../assets/images/Footer/t.png`),
//     white_youtube: require(`../assets/images/Footer/y.png`),
    
//     // Contact
//     contact_ban: require(`../assets/images/Contact/contactBan.png`),
//     afsac_building: require(`../assets/images/Contact/LOCAL AFSAC.png`),
//     phone: require(`../assets/images/Contact/Contact us.png`),
//     tablet: require(`../assets/images/Contact/social media.png`),
//     laptop: require(`../assets/images/Contact/Email.png`),
    
    
//     // About Us
//     plane_banner: require(`../assets/images/AboutUs/plane-banner.png`),
//     about_1: require(`../assets/images/AboutUs/about_11.jpg`),
//     about_2: require(`../assets/images/AboutUs/about_2.png`),
//     about_3: require(`../assets/images/AboutUs/about_3.png`),
//     about_4: require(`../assets/images/AboutUs/about_4.png`),
//     //about_5: require(`../assets/images/AboutUs/about_5.png`),
//     about_5: require(`../assets/images/AboutUs/about_5.png`),
//     icon_divider: require(`../assets/images/AboutUs/dividerIcon.png`),
//     timeline: require(`../assets/images/AboutUs/timeline.png`),
//     air_craft: require(`../assets/images/AboutUs/aircraft.png`),
//     radar: require(`../assets/images/AboutUs/radar.png`),
//     partners: require(`../assets/images/AboutUs/partners.png`),
//     accomodation: require(`../assets/images/AboutUs/accomodation.png`),
//     accomodation1: require(`../assets/images/AboutUs/accomodation-1.png`),
//     timeline_hr: require(`../assets/images/AboutUs/timeline-hr.png`),
//     timeline_icon: require(`../assets/images/AboutUs/timeline-icon.png`),
//     timeline_1: require(`../assets/images/AboutUs/timeline_1.png`),
//     timeline_2: require(`../assets/images/AboutUs/timeline_2.png`),
    
//     // Authorization:
//     security_train: require(`../assets/images/Authorization/security-train.png`),
//     full_membership: require(`../assets/images/Authorization/full-membership.png`),
//     slogan_banner: require(`../assets/images/Authorization/authto_banner.png`),
//     slogan_banner_responsive: require(`../assets/images/Authorization/authto_banner-responsive.png`),
//     vision: require(`../assets/images/Authorization/vision.png`),
//     vision_icon: require(`../assets/images/Authorization/radar.png`),
//     mission: require(`../assets/images/Authorization/mission.png`),
//     mission_icon: require(`../assets/images/Authorization/aircraft-icon.png`),
    
//     // Courses :
//     courses_banner: require(`../assets/images/Courses/courses_banner.png`),
//     course_img: require(`../assets/images/Courses/course_img.png`),

    
//     // Sessions :
//     sessions_banner: require(`../assets/images/Sessions/sessions_banner.png`),
//     sessions_header_img: require(`../assets/images/Sessions/sessions-header.png`),
//     sessions_catalogue: require(`../assets/images/Sessions/catalogue.png`),
     

//     // Solo Session:
//     solo_session_img: require('../assets/images/SoloSession/solo-session-img.png'),
//     solo_responsive: require('../assets/images/SoloSession/solo-session-responsive.png'),
//     solo_plane:require('../assets/aproposban.png'),

//     // Program
//     program_banner: require('../assets/images/Program/program-banner.png'),
//     program_plane: require('../assets/images/Program/program-plane.png'),
//     program_arrow: require('../assets/images/Program/program-arrow.png'),
//     program_train: require('../assets/images/Program/program-train.png'),
//     program_sec: require('../assets/images/Program/program-sec.png'),
//     program_classroom: require('../assets/images/Program/program-classroom.png'),
//     program_online: require('../assets/images/Program/program-online.png'),

//     // Media
//     media_banner: require('../assets/images/Media/media-banner.png'),
//     media_media: require('../assets/images/Media/media-media.png'),
//     media_une: require('../assets/images/Media/media-alaune.png'),
//     media_album: require('../assets/images/Media/media-album.png'),
//     media_video: require('../assets/images/Media/media-videos.png'),
//     media_com: require('../assets/images/Media/media-communique.png'),
//    accreditations_banner:require('../assets/images/banner-our-accreditations')

// };

const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1280 })
    return isDesktop ? children : null
}

const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: 992, maxWidth: 1280 })
    return isTablet ? children : null
}

const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 992 })
    return isMobile ? children : null
}
const Mobile1 = ({ children }) => {
    const isMobile = useMediaQuery({ minWidth:775 ,maxWidth: 992 })
    return isMobile ? children : null
}
const Mobile2 = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 500 })
    return isMobile ? children : null
}

export {
  
    Desktop,
    Tablet,
    Mobile,
    Mobile1,
    Mobile2
};
