import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Rectangle13 from "../../assets/Rectangle13.png";
import image54 from "../../assets/image54.png";
import Layer9 from "../../assets/Layer9.png";
import image61 from "../../assets/image61.png";
import image59 from "../../assets/image59.png";
import image47 from "../../assets/image47.png";
import image60 from "../../assets/image60.png";
import image72 from "../../assets/image72.png";
import imageedu from "../../assets/imageedu.png";
import Layer10 from "../../assets/Layer10.png";
import Layer11 from "../../assets/Layer11.png";
import Layer12 from "../../assets/Layer12.png";
import Layer13 from "../../assets/Layer13.png";
import Layer14 from "../../assets/Layer14.png";
import Layer15 from "../../assets/Layer15.png";
import image66 from "../../assets/image66.png";
import image67 from "../../assets/image67.png";
import image68 from "../../assets/image68.png";
import image69 from "../../assets/image69.png";
import image70 from "../../assets/image70.png";
import image41 from "../../assets/image41.png";
import image56 from "../../assets/image56.png";

import image71 from "../../assets/image71.png";
import Form from "react-bootstrap/Form";

import Carousel from "react-material-ui-carousel";
import CarouselSlide from "react-material-ui-carousel";
import "./serviceconsultiong.css";
import FormulaireC from "../Contact/FormulaireContact.js";


import { useLocation, useNavigate ,useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Desktop, Mobile, Tablet } from "../../constant/AppConfig";
import {
  getServicesStatus,
  fetchServices,
  selectAllServices,
  getServicesError,
} from "../../features/services/servicesSlice";

import { useTranslation } from "react-i18next";
import useAuth from "../../Api/hooks/useAuth";


const Serviceconsulting = (props) => {
  const [t] = useTranslation("common");
  const [home,setHome]=useState("0")
  const position = "services";
  const navigate = useNavigate();
  const { state } = useLocation();
  const path = "https://manager.prisma-intl.com/storage";

  //redux
  const dispatch = useDispatch();
  const serviceStatus = useSelector(getServicesStatus);
  const services = useSelector(selectAllServices);
  const error = useSelector(getServicesError);
  const { id } = useParams();
// const id = 3 ;
  console.log(services,"services ==>",Number(id))

  useEffect(() => {
    if (serviceStatus === "idle") {
      dispatch(fetchServices());
    }
  }, [serviceStatus, dispatch]);





  const { data, getServices,
    getServicesen,
    postContact, } = useAuth()
  const [dataservice, setDataservice] = useState({
    nom: "",
    email: "",
    telephone: "",
    ville: "",
    pays: "",
    societe: "",
    message: "",
    error: "",
    prenom: "",
    service: "",
    position :"service consulting"
  });


  const emailValidation = () => {
    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!dataservice.email || regex.test(dataservice.email) === false) {
      setDataservice({
        ...dataservice,
        error: "Email is not valid",
      });
      return false;
    }
    return true;
  };
 

  useEffect(() => {
    if (props.selectedCountry === "fr") {
      getServices();
    } else {
      getServicesen();
    }
  }, [ props.selectedCountry]);
  useEffect(() => {
    // console.log(state.id, props.serv);
    // props.setServ(state.id);
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);

  return (
    <>
      <Desktop>
     
        <Row>
          <Col>
            <Row>
              <Col>
                {services &&
                  services
                    .filter((e, index) => e.id === Number(id))
                    .map((e, index) => (
                      <>
                        <div key={index}>
                          <div>
                            <img
                              alt="logo"
                              className="lineserviceconsulting"
                              src={Rectangle13}
                            />
                          </div>
                          <div className="titleserviceconsulting">{e.nom} </div>
                        </div>
                        <div className="textserviceconsulting">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: e.description,
                            }}
                          />
                        </div>
                        <div className="imageserviceconsulting">
                          <img
                            alt="logo"
                            src={e.image}
                            style={{ width: "550px" }}
                          />
                        </div>
                    
           {/* //Clients using this  */}
                <div >
                  <div>
                    <img
                      alt="logo"
                      className="lineserviceconsulting"
                      src={Rectangle13}
                    />
                  </div>
                  <div className="title2serviceconsulting" style={{}}>
                    {t('Serivces.client')}
                  </div>
                  <div style={{marginLeft:"20%"}}>
                    {
                      e.clients && e.clients.map((client,key)=>(
                 
                        <img key={key} style={{marginLeft:"5%"}} src={"https://manager.prisma-intl.com/storage/"+client}></img>
                      ))
                    }
                  </div>
                </div> 
                 </>
                    ))}
              </Col>
 {/* //Autre Services  */}
 <Row>
 <div className="title2serviceconsulting" style={{marginTop:"150px"}}>{t("Serivces.other")} </div>
            <Row style={{ justifyContent: "center" }}>
              {services &&
                services
                  .filter((e, index) => e.id !== Number(id))
                  .map((e, index) => (
                    <div key={index}
                      style={{
                        width: "248px",
                        marginTop: "50px",
                        marginLeft: "30px",
                      }}
                    >
                      <div className="textAutreservice">{e && e.nom}</div>
                      <button
                        className="buttonAutreservice"
                        style={{
                          marginTop:
                            e.nom === "Audit & Mesures" ? "67px" :e.nom==="Outsourcing & Missions in management"? "5px": e.nom === "Audit & Measures" ? "70px": "35px",
                        }}
                      >
                        {" "}
                        <div
                          className="textbuttoncardservice"
                          onClick={() => {
                            setDataservice({
                              nom: "",
                              email: "",
                              telephone: "",
                              ville: "",
                              pays: "",
                              societe: "",
                              message: "",
                              error: "",
                              prenom: "",
                              service: "",
                              erro: "",
                            });
                            props.setServ(e.id);
                            navigate(`/serviceconsulting/${e.id}`);
                          }}
                        >
                         {t("Voirs.title")}
                        </div>
                      </button>
                    </div>
                  ))}
            </Row> 
            </Row>
              {/* //Forme contact  */}
       <FormulaireC position={position}/>
            </Row>
            {/* {id === 1 ? (
              <Carousel>
                <CarouselSlide>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      height: "75px",
                    }}
                  >
                    <img
                      alt="logo"
                      src={Layer10}
                      style={{ marginLeft: "75px" }}
                    />
                    <img
                      alt="logo"
                      src={Layer11}
                      style={{
                        marginLeft: "105px",
                        width: "200px",
                        height: "50px",
                        marginTop: "20px",
                      }}
                    />
                    <img
                      alt="logo"
                      src={Layer12}
                      style={{ marginLeft: "105px" }}
                    />
                    <img
                      alt="logo"
                      src={Layer13}
                      style={{ marginLeft: "105px" }}
                    />
                  </div>
                </CarouselSlide>
                <CarouselSlide>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      height: "75px",
                    }}
                  >
                    <img
                      alt="logo"
                      src={Layer14}
                      style={{ marginLeft: "105px" }}
                    />
                    <img
                      alt="logo"
                      src={Layer15}
                      style={{ marginLeft: "105px" }}
                    />
                    <img
                      alt="logo"
                      src={image66}
                      style={{ marginLeft: "75px" }}
                    />
                    <img
                      alt="logo"
                      src={image67}
                      style={{ marginLeft: "105px" }}
                    />
                  </div>
                </CarouselSlide>
                <CarouselSlide>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      height: "75px",
                    }}
                  >
                    <img
                      alt="logo"
                      src={image69}
                      style={{ marginLeft: "105px" }}
                    />
                    <img
                      alt="logo"
                      src={image68}
                      style={{ marginLeft: "105px" }}
                    />
                  </div>
                </CarouselSlide>
              </Carousel>
            ) : id === 2 ? (
              <Carousel>
                <CarouselSlide>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      height: "75px",
                    }}
                  >
                    <img
                      alt="logo"
                      src={Layer10}
                      style={{ marginLeft: "75px" }}
                    />
                    <img
                      alt="logo"
                      src={Layer12}
                      style={{ marginLeft: "105px" }}
                    />
                    <img
                      alt="logo"
                      src={Layer13}
                      style={{ marginLeft: "105px" }}
                    />
                    <img
                      alt="logo"
                      src={Layer14}
                      style={{ marginLeft: "105px" }}
                    />
                    <img
                      alt="logo"
                      src={image41}
                      style={{ marginLeft: "105px" }}
                    />

                    <img
                      alt="logo"
                      src={image56}
                      style={{ marginLeft: "105px" }}
                    />
                  </div>
                </CarouselSlide>
                <CarouselSlide>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      height: "75px",
                    }}
                  >
                    <img
                      alt="logo"
                      src={image66}
                      style={{ marginLeft: "75px" }}
                    />
                    <img
                      alt="logo"
                      src={image67}
                      style={{ marginLeft: "105px" }}
                    />
                    <img
                      alt="logo"
                      src={image70}
                      style={{ marginLeft: "105px" }}
                    />
                    <img
                      alt="logo"
                      src={image68}
                      style={{ marginLeft: "105px" }}
                    />
                  </div>
                </CarouselSlide>
                <CarouselSlide>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      height: "75px",
                    }}
                  >
                    <img
                      alt="logo"
                      src={image69}
                      style={{ marginLeft: "75px" }}
                    />
                    <img
                      alt="logo"
                      src={image71}
                      style={{ marginLeft: "105px" }}
                    />
                    <img
                      alt="logo"
                      src={imageedu}
                      style={{ marginLeft: "105px" }}
                    />
                    <img
                      alt="logo"
                      src={image72}
                      style={{ marginLeft: "105px" }}
                    />
                  </div>
                </CarouselSlide>
              </Carousel>
            ) : id === 3 ? (
              <Carousel>
                <CarouselSlide>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      height: "75px",
                    }}
                  >
                    <img
                      alt="logo"
                      src={Layer10}
                      style={{ marginLeft: "75px" }}
                    />
                    <img
                      alt="logo"
                      src={Layer11}
                      style={{
                        marginLeft: "105px",
                        height: "50px",
                        marginTop: "20px",
                      }}
                    />
                    <img
                      alt="logo"
                      src={Layer14}
                      style={{ marginLeft: "105px" }}
                    />
                    <img
                      alt="logo"
                      src={Layer15}
                      style={{ marginLeft: "105px" }}
                    />
                  </div>
                </CarouselSlide>
                <CarouselSlide>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      height: "75px",
                    }}
                  >
                    <img
                      alt="logo"
                      src={image66}
                      style={{ marginLeft: "75px" }}
                    />
                    <img
                      alt="logo"
                      src={image60}
                      style={{ marginLeft: "105px" }}
                    />
                    <img
                      alt="logo"
                      src={image59}
                      style={{
                        marginLeft: "105px",
                        height: "50px",
                        marginTop: "20px",
                      }}
                    />
                    <img
                      alt="logo"
                      src={image61}
                      style={{ marginLeft: "105px" }}
                    />
                  </div>
                </CarouselSlide>
                <CarouselSlide>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      height: "75px",
                    }}
                  >
                    <img
                      alt="logo"
                      src={image47}
                      style={{ marginLeft: "75px" }}
                    />
                    <img
                      alt="logo"
                      src={Layer9}
                      style={{ marginLeft: "105px" }}
                    />
                  </div>
                </CarouselSlide>
              </Carousel>
            ) : id === 4 ? (
              <Carousel>
                <CarouselSlide>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      height: "75px",
                    }}
                  >
                    <img
                      alt="logo"
                      src={Layer10}
                      style={{ marginLeft: "75px" }}
                    />
                    <img
                      alt="logo"
                      src={Layer12}
                      style={{ marginLeft: "105px" }}
                    />
                    <img
                      alt="logo"
                      src={image59}
                      style={{
                        marginLeft: "105px",
                        height: "50px",
                        width: "200px",
                        marginTop: "20px",
                      }}
                    />
                  </div>
                </CarouselSlide>
              </Carousel>
            ) : (
              id === 5 && (
                <Carousel>
                  <CarouselSlide>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        height: "75px",
                      }}
                    >
                      <img
                        alt="logo"
                        src={Layer10}
                        style={{ marginLeft: "75px" }}
                      />
                      <img
                        alt="logo"
                        src={Layer12}
                        style={{ marginLeft: "105px" }}
                      />
                      <img
                        alt="logo"
                        src={Layer13}
                        style={{ marginLeft: "105px" }}
                      />
                      <img
                        alt="logo"
                        src={Layer14}
                        style={{ marginLeft: "105px" }}
                      />
                    </div>
                  </CarouselSlide>
                  <CarouselSlide>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        height: "75px",
                      }}
                    >
                      <img
                        alt="logo"
                        src={image54}
                        style={{ marginLeft: "75px" }}
                      />
                      <img
                        alt="logo"
                        src={image59}
                        style={{
                          marginLeft: "105px",
                          width: "200px",
                          height: "50px",
                          marginTop: "20px",
                        }}
                      />
                      <img
                        alt="logo"
                        src={image69}
                        style={{ marginLeft: "105px" }}
                      />
                      <img
                        alt="logo"
                        src={image68}
                        style={{ marginLeft: "105px" }}
                      />
                    </div>
                  </CarouselSlide>
                </Carousel>
              )
            )}

            <div style={{ marginTop: "100px" }}>
              <img
                alt="logo"
                className="lineserviceconsulting"
                src={Rectangle13}
              />
            </div>
            <div className="title2serviceconsulting">{t("Serivces.other")} </div>
            <Row style={{ justifyContent: "center" }}>
              {services &&
                services
                  .filter((e, index) => e.id !== Number(id))
                  .map((e, index) => (
                    <div
                      style={{
                        width: "248px",
                        marginTop: "50px",
                        marginLeft: "30px",
                      }}
                    >
                      <div className="textAutreservice">{e && e.nom}</div>
                      <button
                        className="buttonAutreservice"
                        style={{
                          marginTop:
                            e.nom === "Audit & Mesures" ? "67px" :e.nom==="Outsourcing & Missions in management"? "5px": e.nom === "Audit & Measures" ? "70px": "35px",
                        }}
                      >
                        {" "}
                        <div
                          className="textbuttoncardservice"
                          onClick={() => {
                            setDataservice({
                              nom: "",
                              email: "",
                              telephone: "",
                              ville: "",
                              pays: "",
                              societe: "",
                              message: "",
                              error: "",
                              prenom: "",
                              service: "",
                              erro: "",
                            });
                            props.setServ(e.id);
                            navigate(`/serviceconsulting/${e.id}`,
                             {
                              state: {
                                id: e.id,
                              },
                            });
                          }}
                        >
                         {t("Voirs.title")}
                        </div>
                      </button>
                    </div>
                  ))}
            </Row> */}
          </Col>
        </Row>
      </Desktop>
      <Tablet>
        {/* <Row
          style={{
            backgroundColor: "#253D8A",
            minHeight: "60px",
            height: "auto",
          }}
        >
          <Col>
            <Menubarone />
          </Col>
        </Row>
        <Row style={{ minHeight: "120px", height: "auto", width: "100%" }}>
          <Col>
            <MenuBar home={home}/>
          </Col>
        </Row> */}
        <Row>
          <Col>
            <Row>
              <Col>
                <div>
                  <div>
                    <img
                      alt="logo"
                      className="lineserviceconsulting"
                      src={Rectangle13}
                    />
                  </div>
                  <div className="titleserviceconsulting">
                   {data[Number(id) - 1] && data[Number(id) - 1].nom}{" "}
                  </div>
                </div>
                <div >
                 {/* <div   dangerouslySetInnerHTML={{
                              __html: ,
                            }} 
                            /> */}
                            { data[Number(id) - 1] ?
                              <div className="textserviceconsulting"
                            dangerouslySetInnerHTML={{
                              __html: data[Number(id) - 1].description,
                            }}
                          /> : <></>
                            }
                   
                </div>
                <div className="imageserviceconsulting">
                  <img
                    alt="logo"
                    src={data[Number(id) - 1] && data[Number(id) - 1].image}
                  />
                </div>
                {/* <div style={{ marginTop: "50px" }}>
                  <div>
                    <img
                      alt="logo"
                      className="lineserviceconsulting"
                      src={Rectangle13}
                    />
                  </div> */}
                  {/* <div className="title2serviceconsulting">
                    Clients utilisant ce service{" "}
                  </div> */}
                {/* </div> */}
              </Col>
              <Col style={{ marginLeft: "106px" }}>
                <div>
                  <div className="titleserviceconsultingcontact">
                    {t("Serivces.contact")}
                  </div>
                </div>


              {/* //forumlaire contact */}
                <FormulaireC position={position}/>
              </Col>
            </Row>
            <div style={{ marginTop: "150px" }}>
              <Carousel cols={1} rows={1} gap={10} loop>
                <CarouselSlide>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img
                      alt="logo"
                      src={Layer10}
                      style={{ marginLeft: "75px" }}
                    />
                    <img
                      alt="logo"
                      src={Layer11}
                      style={{ marginLeft: "105px" }}
                    />
                    <img
                      alt="logo"
                      src={Layer12}
                      style={{ marginLeft: "105px" }}
                    />
                    <img
                      alt="logo"
                      src={Layer13}
                      style={{ marginLeft: "105px" }}
                    />
                    <img
                      alt="logo"
                      src={Layer14}
                      style={{ marginLeft: "105px" }}
                    />
                    <img
                      alt="logo"
                      src={Layer15}
                      style={{ marginLeft: "105px" }}
                    />
                  </div>
                </CarouselSlide>
                <CarouselSlide>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img
                      alt="logo"
                      src={image66}
                      style={{ marginLeft: "75px" }}
                    />
                    <img
                      alt="logo"
                      src={image67}
                      style={{ marginLeft: "105px" }}
                    />
                    <img
                      alt="logo"
                      src={image69}
                      style={{ marginLeft: "105px" }}
                    />
                    <img
                      alt="logo"
                      src={image68}
                      style={{ marginLeft: "105px" }}
                    />
                  </div>
                </CarouselSlide>
              </Carousel>
            </div>

            <div style={{ marginTop: "100px" }}>
              <img
                alt="logo"
                className="lineserviceconsulting"
                src={Rectangle13}
              />
            </div>
            <div className="title2serviceconsulting">Autres services </div>
            <Row style={{ justifyContent: "center" }}>
              {services &&
                services
                  .filter((e, index) => e.id !== Number(id))
                  .map((e, index) => (
                    <div
                      key={index}
                      style={{
                        width: "248px",
                        marginTop: "50px",
                        marginLeft: "30px",
                      }}
                    >
                      <div className="textAutreservice">{e && e.nom}</div>
                      <button
                        className="buttonAutreservice"
                        style={{
                          marginTop:
                            e.nom === "Audit & Mesures" ? "67px" : "35px",
                        }}
                      >
                        {" "}
                        <div
                          className="textbuttoncardservice"
                          onClick={() => {
                            // props.setServ(e.id);
                            navigate(`/serviceconsulting/${Number(id)}`);
                          }}
                        >
                          Voir plus
                        </div>
                      </button>
                    </div>
                  ))}
            </Row>
          </Col>
        </Row>
      </Tablet>
      <Mobile>
        {/* <Row
          style={{
            backgroundColor: "#253D8A",
            minHeight: "60px",
            height: "auto",
          }}
        >
          <Col>
            <Menubarone />
          </Col>
        </Row>
        <Row style={{ minHeight: "120px", height: "auto", width: "100%" }}>
          <Col>
            <MenuBar home={home}/>
          </Col>
        </Row> */}
        <Row>
          <Col>
            <Row>
              <Col xs={12}>
                <div>
                  <div className="titleserviceconsulting">
                    <img
                      alt="logo"
                      src={Rectangle13}
                      className="lineserviceconsulting"
                      style={{ marginLeft: "-30px" }}
                    />{" "}
                    <div style={{ marginLeft: "30px" }}>
                      {data[Number(id)- 1] && data[Number(id) - 1].nom}{" "}
                    </div>
                  </div>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div className="textserviceconsulting">

                  { data[Number(id) - 1] ?
                              <div className="textserviceconsulting"
                            dangerouslySetInnerHTML={{
                              __html: data[Number(id) - 1].description,
                            }}
                          /> : <></>
                            }
                  </div>
                </div>
              </Col>
              <Col xs={12}>
                <div className="imageserviceconsulting">
                  <img
                    alt="logo"
                    src={data[Number(id) - 1] && data[Number(id) - 1].image}
                  />
                </div>
                {/* <div style={{ marginTop: "40px" }}>
                  <div className="title2serviceconsulting">
                    <img
                      alt="logo"
                      className="lineserviceconsulting"
                      src={Rectangle13}
                      style={{ marginLeft: "-30px" }}
                    />

                    <div style={{ marginLeft: "30px" }}>
                      Clients utilisant ce service{" "}
                    </div>
                  </div>
                </div> */}
              </Col>
              <Col>
                <div>
                  <div className="titleserviceconsultingcontact">
                  {t("Serivces.contact")}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginLeft: "20px",
                  }}
                >
              
                  <FormulaireC position={position}/>
                </div>
              </Col>
            </Row>
            <div style={{ marginTop: "50px" }}>
              <Carousel cols={1} rows={1} gap={10} loop>
                <CarouselSlide>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img alt="logo" src={Layer10} />
                    <img
                      alt="logo"
                      src={Layer11}
                      style={{ marginLeft: "15px" }}
                    />
                  </div>
                </CarouselSlide>
                <CarouselSlide>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img
                      alt="logo"
                      src={Layer12}
                      style={{ marginLeft: "15px" }}
                    />
                    <img
                      alt="logo"
                      src={Layer13}
                      style={{ marginLeft: "15px" }}
                    />
                  </div>
                </CarouselSlide>
                <CarouselSlide>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img
                      alt="logo"
                      src={Layer14}
                      style={{ marginLeft: "15px" }}
                    />
                    <img
                      alt="logo"
                      src={Layer15}
                      style={{ marginLeft: "15px" }}
                    />
                  </div>
                </CarouselSlide>
                <CarouselSlide>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img
                      alt="logo"
                      src={image66}
                      style={{ marginLeft: "15px" }}
                    />
                    <img
                      alt="logo"
                      src={image67}
                      style={{ marginLeft: "15px" }}
                    />
                  </div>
                </CarouselSlide>
                <CarouselSlide>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img
                      alt="logo"
                      src={image69}
                      style={{ marginLeft: "15px" }}
                    />
                    <img alt="logo" src={image68} style={{ marginLeft: "" }} />
                  </div>
                </CarouselSlide>
              </Carousel>
            </div>
            <div style={{ marginTop: "100px" }}>
              <div className="titleserviceconsulting">
                <img
                  alt="logo"
                  src={Rectangle13}
                  className="lineserviceconsulting"
                  style={{ marginLeft: "-30px" }}
                />{" "}
                <div style={{ marginLeft: "30px" }}>Autres services</div>
              </div>
            </div>

            <Row style={{ justifyContent: "center" }}>
              {services &&
                services
                  .filter((e, index) => e.id !== Number(id))
                  .map((e, index) => (
                    <div key={index} style={{ width: "248px", marginTop: "50px" }}>
                      <div className="textAutreservice">{e.nom}</div>
                      <button
                        className="buttonAutreservice"
                        style={{
                          marginTop:
                            e.nom === "Audit & Mesures" ? "67px" : "35px",
                        }}
                      >
                        {" "}
                        <div
                          className="textbuttoncardservice"
                          onClick={() => {
                            // props.setServ(e.id);
                            navigate(`/serviceconsulting/${Number(id)}`);
                          }}
                        >
                          Voir plus
                        </div>
                      </button>
                    </div>
                  ))}
            </Row>
          </Col>
        </Row>
      </Mobile>
    
    </>
  );
};

export default Serviceconsulting;
