import React from "react";
import AfricaMap from "react-africa-map";
import Col from "react-bootstrap/Col";
import Rectangle8 from "../../../assets/Rectangle8.svg";
import Rectangle9 from "../../../assets/Rectangle9.png";
import Ellipse15 from "../../../assets/Ellipse15.png";
import Ellipse16 from "../../../assets/Ellipse16.png";
import Rectangle10 from "../../../assets/Rectangle10.png";

import Rectangle11 from "../../../assets/Rectangle11.png";
import Ellipse14 from "../../../assets/Ellipse14.png";
import { Desktop, Mobile, Tablet } from "../../../constant/AppConfig";
import { useTranslation } from "react-i18next";
import useAuth from "../../../Api/hooks/useAuth";
import { useEffect ,useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getPresencesStatus,
  fetchPresences,
  selectAllPresences,
  getPresencesError,
} from "../../../features/presence/presencesSlice";
const Presence = () => {
  const [t] = useTranslation("common");
  // const { getAllPresence, datapresence } = useAuth();
  const [selectColor,setSelectColor] = useState("");
  var seconds = new Date().getTime() / 1000;
  const d = new Date();
  var minutes = d.getMinutes();
  const [timer,setTimer]= useState(minutes)
  const [selected,setSelected] = useState([]);
  const [presenceTable,setPresenceTable] = useState([]);
  const [filiale,setFiliale]= useState(['MA', 'CI', 'TN']);
  const [commercial,setCommercial]= useState(['DZ', 'BF', 'LY', 'ML', 'NE', 'RW']);
  const [projets,setProjets]= useState(['BJ', 'CM', 'GA', 'GH', 'GN', 'MR', 'MZ', 'SN', 'SD', 'MT']);
  var com = [];
  //redux 
  const dispatch = useDispatch();
  const presencesStatus = useSelector(getPresencesStatus);
  const datapresence = useSelector(selectAllPresences);
  const error = useSelector(getPresencesError);

  useEffect(() => {
    if (presencesStatus === "idle") {
      dispatch(fetchPresences());
    }
  }, [presencesStatus, dispatch]);

  let contentToDisplay = "";
  if (presencesStatus === "loading") {
    contentToDisplay = <h2>Loading...</h2>;
  } 
  else if (presencesStatus === "succeeded") { 
    contentToDisplay = <></>  
  //   datapresence.map((data) => (
  //     <div key={data.id}>
  //       <h2>{data.titre}</h2>
    
  //       <hr />
  //     </div>
      
  // )): <></>;
  } else if (presencesStatus === "failed") {
    contentToDisplay = <p>{error}</p>;
  }

  var randomNum = Math.floor(Math.random() * 2);
  const changePresence = () => {
   if (projets.length >0 && commercial.length>0 && filiale.length>0) { 
    // console.log(datapresence,"test2")
    setTimeout(() => {
      setSelectColor("#8BAEE4");
      setSelected( projets)
    }, 5000);
    setTimeout(() => {
      setSelectColor("#253D8A");
      setSelected( commercial)
          
    }, 10000);
    setTimeout(() => {
      setSelectColor("#30C0C6");
      setSelected( filiale);
          
    }, 15000);
    // console.log('changed')
  }
    setTimeout(changePresence.callee, 30000);
  }
  // changePresence();
// useEffect(()=>{ console.log('test avant redner',datapresence)
//  datapresence && datapresence.filiales && datapresence.filiales.identifiant ? 
//  setFiliale ({...datapresence.filiales.identifiant})
//    : console.log('test',com);
//    datapresence && datapresence.presenceCommerciale && datapresence.presenceCommerciale.identifiant ? 
//    setCommercial ({...datapresence.presenceCommerciale.identifiant})
//    : console.log('test',com);
//    datapresence && datapresence.projets && datapresence.projets.identifiant ? 
//    setProjets ({...datapresence.projets.identifiant})
//    : console.log('test',com);
//    console.log(filiale,"test",commercial,"comm")
// },[datapresence])

useEffect(()=>{ 
changePresence();console.log('test',timer);
setTimer(minutes);

},[datapresence])
useEffect(()=>{ 
  console.log('test',timer);
  changePresence();
  },[timer])
  changePresence();
  return (
    <>
      <Desktop>
        <Col>
          <div>
            <div>
              <img alt="logo" className="line" src={Rectangle8} />
            </div>
            <div className="titleprecence">{t("Precesnce.title")}</div>
          </div>
          <div style={{ width: "672.23px", marginLeft: "77px" }}>
            <AfricaMap
              // selected = {
              //   datapresence &&
              //   datapresence.filiales && [
              //     // ...datapresence.filiales.identifiant,
              //     // ...datapresence.projets.identifiant,
              //     ...datapresence.presenceCommerciale.identifiant,
              //   ]
              // }
              selected = {selected}
              size={5}
              borderColor="#FFFFFF"
              fillColor="#D9D9D9"
              selectColor = {selectColor}
            />
          </div>
        </Col>
        <Col style={{ marginLeft: "-100px" }}>
          <div 
           onMouseOver={ ()=>{  setSelectColor("#30C0C6");
           setSelected( datapresence &&
             datapresence.filiales && [
               ...datapresence.filiales.identifiant,]);}}
          onClick={()=>{
            setSelectColor("#30C0C6");
            setSelected( datapresence &&
              datapresence.filiales && [
                ...datapresence.filiales.identifiant,]);
          }}>
            <img alt="logo" src={Ellipse14} style={{ marginTop: "321px" }} />
            <img alt="logo" className="line2" src={Rectangle9} />
            <div className="titlePrecense1">{t("Precesnce.soustitle")}</div>

            <div className="soustitlePrecense1">
              {datapresence &&
                datapresence.filiales &&
                datapresence.filiales.countries &&
                datapresence.filiales.countries.map((e1, index) =>
                  index < datapresence.filiales.countries.length - 1
                    ? `${e1}-`
                    : `${e1}`
                )}
            </div>
          </div>
          <div 
          onMouseOver={ ()=>{  setSelectColor("#8BAEE4");
          setSelected( datapresence &&
              datapresence.filiales && [
                ...datapresence.projets.identifiant,])}}
          onClick={()=>{
            setSelectColor("#8BAEE4");
            setSelected( datapresence &&
                datapresence.filiales && [
                  ...datapresence.projets.identifiant,]);
          }}>
            <img
              alt="logo"
              src={Ellipse15}
              style={{ marginTop: "-105px", marginLeft: "72px" }}
            />
            <img
              alt="logo"
              className="line3"
              src={Rectangle10}
              style={{ marginTop: "-105px" }}
            />
            <div className="titlePrecense2">{t("Precesnce.soustitle2")}</div>
            <div className="soustitlePrecense2">
              {datapresence &&
                datapresence.projets &&
                datapresence.projets.countries &&
                datapresence.projets.countries.map((e1, index) =>
                  index < datapresence.projets.countries.length - 1
                    ? `${e1}-`
                    : `${e1}`
                )}
            </div>
          </div>
          <div
           onMouseOver={ ()=>{  setSelectColor("#253D8A");
           setSelected( datapresence &&
             datapresence.filiales && [
               ...datapresence.presenceCommerciale.identifiant,]);}}
          
          onClick={()=>{
            setSelectColor("#253D8A");
            setSelected( datapresence &&
              datapresence.filiales && [
                ...datapresence.presenceCommerciale.identifiant,]);
          }}>
            <img alt="logo" src={Ellipse16} style={{ marginTop: "-55px" }} />
            <img
              alt="logo"
              className="line4"
              src={Rectangle11}
              style={{ marginTop: "-55px" }}
            />
            <div className="titlePrecense3">{t("Precesnce.soustitle3")}</div>
            <div className="soustitlePrecense3">
              {datapresence &&
                datapresence.presenceCommerciale &&
                datapresence.presenceCommerciale.countries &&
                datapresence.presenceCommerciale.countries.map((e1, index) =>
                  index < datapresence.projets.countries.length - 1
                    ? `${e1}-`
                    : `${e1}`
                )}{" "}
            </div>
          </div>
        </Col>
      </Desktop>
      <Tablet>
        <Col xs={12}>
          <div className="titleprecence">
            <img alt="logo" src={Rectangle8} />
            <div style={{ marginLeft: "35px" }}>{t("Precesnce.title")}</div>
          </div>
          <div style={{ width: "100%", marginTop: "200px" }}>
            <AfricaMap 
            size={5}   
            selected = {selected}
            borderColor="#FFFFFF"
            fillColor="#D9D9D9"
            selectColor = {selectColor}/>
          </div>
        </Col>
        <Col style={{ display: "flex", justifyContent: "center" }} xs={12}>
          <div style={{ marginTop: "100px" }}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img alt="logo" src={Ellipse14} style={{ height: "29px" }} />
              <img alt="logo" className="line2" src={Rectangle9} />
              <Col>
                <div className="titlePrecense1">Filiales de Prisma</div>
                <div className="soustitlePrecense1">
                  Tunisie - Marroc - Côte d’Ivoire
                </div>
              </Col>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img
                alt="logo"
                src={Ellipse15}
                style={{ marginTop: "0px", marginLeft: "72px", height: "29px" }}
              />
              <img alt="logo" className="line3" src={Rectangle10} />

              <Col>
                <div className="titlePrecense2">Projets</div>
                <div className="soustitlePrecense2">
                  Malta - Mauritanie - Sénégal - Guinée - Bénin - Ghana -
                  Cameroun - Gabon - Soudan - Mozambique
                </div>
              </Col>
            </div>
            <div>
              <img alt="logo" src={Ellipse16} style={{ height: "29px" }} />
              <img alt="logo" className="line4" src={Rectangle11} />
              <div className="titlePrecense3">Présence commerciale</div>
              <div className="soustitlePrecense3">
                Algérie - Libye - Mali - Niger -Burkina Faso - Ruanda{" "}
              </div>
            </div>
          </div>
        </Col>
      </Tablet>
      <Mobile>
        <Col xs={12}>
          <div className="titleprecence">
            <img alt="logo" src={Rectangle8} />
            <div style={{ marginLeft: "35px" }}>{t("Precesnce.title")}</div>
          </div>
          <div style={{ width: "100%", marginTop: "100px" }}>
            <AfricaMap size={1} 
             selected = {selected}
             borderColor="#FFFFFF"
             fillColor="#D9D9D9"
             selectColor = {selectColor} 
            />
          </div>
        </Col>
        <Col style={{ display: "flex", justifyContent: "center" }} xs={12}>
          <div style={{ marginTop: "100px", marginLeft: "150px" }}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img
                alt="logo"
                src={Ellipse14}
                style={{ height: "20px", marginTop: "20px" }}
              />
              <img alt="logo" className="line2" src={Rectangle9} />
              <Col>
                <div className="titlePrecense1">Filiales de Prisma</div>
                <div className="soustitlePrecense1">
                  Tunisie - Marroc - Côte d’Ivoire
                </div>
              </Col>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img
                alt="logo"
                src={Ellipse15}
                style={{ marginTop: "20px", marginLeft: "0px", height: "20px" }}
              />
              <img alt="logo" className="line3" src={Rectangle10} />

              <Col>
                <div className="titlePrecense2">Projets</div>
                <div className="soustitlePrecense2">
                  Malta - Mauritanie - Sénégal - Guinée - Bénin - Ghana -
                  Cameroun - Gabon - Soudan - Mozambique
                </div>
              </Col>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img
                alt="logo"
                src={Ellipse16}
                style={{ height: "20px", marginTop: "20px" }}
              />
              <img alt="logo" className="line4" src={Rectangle11} />
              <Col>
                <div className="titlePrecense3">Présence commerciale</div>
                <div className="soustitlePrecense3">
                  Algérie - Libye - Mali - Niger -Burkina Faso - Ruanda{" "}
                </div>
              </Col>
            </div>
          </div>
        </Col>
      </Mobile>
    </>
  );
};

export default Presence;
