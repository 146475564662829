
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const SERVICE_URL = 'https://manager.prisma-intl.com/api/services/fr';

export const fetchServices = createAsyncThunk(
  'services/fetchServices',
  async () => {
    const response = await axios.get(SERVICE_URL);
    // console.log(response.data,'services Data')
    return response.data;
  },
);


const initialState = {
    services: [],
    status: 'idle',
    error: null,
};

const servicesSlice = createSlice({
  name: 'services',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addCase(fetchServices.pending, (state) => {
    state.status = 'loading';
    })
    .addCase(fetchServices.fulfilled, (state, action) => {
    state.status = 'succeeded';
    state.services = action.payload;
    })
    .addCase(fetchServices.rejected, (state, action) => {
    state.status = 'failed';
    state.error = action.error.message;
    });
    },
});

export const selectAllServices = (state) => state.services.services;
export const getServicesStatus = (state) => state.services.status;
export const getServicesError = (state) => state.services.error;

export default servicesSlice.reducer;