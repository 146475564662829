
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const PRESENCES_URL = 'https://manager.prisma-intl.com/api/presence/fr';

export const fetchPresences = createAsyncThunk(
  'presences/fetchPresences',
  async () => {
    const response = await axios.get(PRESENCES_URL);
    console.log(response.data,'Presences Data')
    return response.data;
  },
);


const initialState = {
    presences: [],
    status: 'idle',
    error: null,
};

const presencesSlice = createSlice({
  name: 'presences',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addCase(fetchPresences.pending, (state) => {
    state.status = 'loading';
    })
    .addCase(fetchPresences.fulfilled, (state, action) => {
    state.status = 'succeeded';
    state.presences = action.payload;
    })
    .addCase(fetchPresences.rejected, (state, action) => {
    state.status = 'failed';
    state.error = action.error.message;
    });
    },
});

export const selectAllPresences = (state) => state.presences.presences;
export const getPresencesStatus = (state) => state.presences.status;
export const getPresencesError = (state) => state.presences.error;

export default presencesSlice.reducer;