import React from "react";
import { Col, Row } from "react-bootstrap";
import Chiffresim from "../../../assets/Rectangle12.png";
import image4 from "../../../assets/image4.svg";
import image5 from "../../../assets/image5.svg";
import image6 from "../../../assets/image6.svg";
import image7 from "../../../assets/image7.svg";
import Chiffreexpertenglais from "../../../assets/Chiffreexpertenglais.png";
import Chiffreexprienceenglais from "../../../assets/Chiffreexprienceenglais.png";
import Chiffresubsidiariesenglais from "../../../assets/Chiffresubsidiariesenglais.png";
import Chiffrecountriesenglais from "../../../assets/Chiffrecountriesenglais.png";
import { Desktop, Mobile, Tablet } from "../../../constant/AppConfig";
import { useTranslation } from "react-i18next";

const Chiffres = (props) => {
  const { selectedCountry} = props;
  const [t] = useTranslation("common");

  return (
    <>
      <Desktop>
        <Col style={{ paddingLeft: "0px", paddingRight: "0px" }}>
          <div className="chiffresback">
            <img
              alt="logo"
              src={Chiffresim}
              style={{ height: "295px", width: "100%" }}
            />
            <div
              className="titrechiffre"
              style={{
                zIndex: "1px",
                position: "absolute",
                marginTop: "-175px",
              }}
            >
              {t("Chiffres.title")}
            </div>
            {(selectedCountry!=="fr")?(
 <Row style={{ zIndex: "1px", position: "absolute", width: "90%" }}>
 <Col style={{marginTop:"33px"}}> 
   <img alt="logo" src={Chiffreexpertenglais} />
 </Col>
 <Col>
   <img alt="logo" style={{marginTop:"35px"}}src={Chiffreexprienceenglais} />
 </Col>
 <Col style={{marginTop:"33px"}}>
   <img alt="logo" src={Chiffresubsidiariesenglais} />
 </Col>
 <Col style={{marginTop:"33px"}}>
   <img alt="logo" src={Chiffrecountriesenglais} />
 </Col>
</Row>
            ):(
              <Row style={{ zIndex: "1px", position: "absolute", width: "90%" }}>
              <Col>
                <img alt="logo" src={image4} />
              </Col>
              <Col>
                <img alt="logo" src={image5} />
              </Col>
              <Col>
                <img alt="logo" src={image7} />
              </Col>
              <Col>
                <img alt="logo" src={image6} />
              </Col>
             </Row>
            )}
           
          </div>
        </Col>
      </Desktop>
      <Tablet>
        <Col style={{ paddingLeft: "0px", paddingRight: "0px" }}>
          <div className="chiffresback">
            <Row
              style={{
                zIndex: "1px",
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <img
                alt="logo"
                src={Chiffresim}
                style={{ height: "295px", width: "100%" }}
              />
              <div
                className="titrechiffre"
                style={{
                  zIndex: "1px",
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "-250px",
                }}
              >
                Nos chiffres
              </div>

              <Col
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "-250px",
                }}
              >
                <img alt="logo" src={image4} style={{ width: "120px" }} />

                <img alt="logo" src={image5} style={{ width: "210px" }} />

                <img alt="logo" src={image7} style={{ width: "200px" }} />

                <img alt="logo" src={image6} style={{ width: "180px" }} />
              </Col>
            </Row>
          </div>
        </Col>
      </Tablet>
      <Mobile>
        <Col style={{ paddingLeft: "0px", paddingRight: "0px" }}>
          <div className="chiffresback">
            <Row
              style={{
                zIndex: "1px",
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <img
                alt="logo"
                src={Chiffresim}
                style={{ height: "135px", width: "100%" }}
              />
              <div
                className="titrechiffre"
                style={{
                  zIndex: "1px",
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "-140px",
                }}
              >
                Nos chiffres
              </div>

              <Col
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "-110px",
                }}
              >
                <img
                  alt="logo"
                  src={image4}
                  style={{ width: "70px", height: "70px" }}
                />

                <img
                  alt="logo"
                  src={image5}
                  style={{ width: "70px", height: "70px", marginLeft: "20px" }}
                />

                <img
                  alt="logo"
                  src={image7}
                  style={{ width: "70px", height: "70px", marginLeft: "20px" }}
                />

                <img
                  alt="logo"
                  src={image6}
                  style={{ width: "70px", height: "70px", marginLeft: "20px" }}
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Mobile>
    </>
  );
};

export default Chiffres;
