import React from "react";


import { Box, Button, Typography } from '@mui/material';
import { purple } from '@mui/material/colors';
import { useNavigate } from "react-router-dom";
//REdux
import { useSelector, useDispatch } from "react-redux";
import {
  homeAction,
  notHomeAction
} from "../../features/navigationHome/navhomeSlice";
// import "./Reference.css";
import { Desktop, Mobile, Tablet } from "../../constant/AppConfig";
import { useEffect } from "react";
import useAuth from "../../Api/hooks/useAuth";
const primary = "#0072b1"; // #f44336
const ErrorPage = (props) => {
  const { datarefernces, getReference, getReferenceenglais } = useAuth();
    const dispatch = useDispatch();
    const inHome = useSelector(homeAction);
    const outHome = useSelector(notHomeAction);
    const pos = useSelector(state => state.nav);
    const navigate = useNavigate();
  useEffect(() => {
   
    if (props.selectedCountry === "fr") {
      getReference();
    } else {
      getReferenceenglais();
    }
  }, [[props.selectedCountry]]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Desktop>
  
      <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundColor: primary,
      }}
    >
      <Typography variant="h1" style={{ color: 'white' }}>
        404
      </Typography>
      <Typography variant="h6" style={{ color: 'white' }}>
        The page you’re looking for doesn’t exist.
      </Typography>
      <Button variant="contained" onClick={()=>{dispatch(inHome)}}>Back Home</Button>
    </Box>
      </Desktop>
      <Tablet>
       
      <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundColor: primary,
      }}
    >
      <Typography variant="h1" style={{ color: 'white' }}>
        404
      </Typography>
      <Typography variant="h6" style={{ color: 'white' }}>
        The page you’re looking for doesn’t exist.
      </Typography>
      <Button variant="contained" onClick={()=>{dispatch(inHome)}}>Back Home</Button>
    </Box>
      </Tablet>
      <Mobile>
   
      <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundColor: primary,
      }}
    >
      <Typography variant="h1" style={{ color: 'white' }}>
        404
      </Typography>
      <Typography variant="h6" style={{ color: 'white' }}>
        The page you’re looking for doesn’t exist.
      </Typography>
      <Button variant="contained" onClick={()=>dispatch(inHome)}>Back Home</Button>
    </Box>
      </Mobile>
   
    </>
  );
};

export default ErrorPage;

