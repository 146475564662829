import React from "react";
import { Col, Row } from "react-bootstrap";
import Rectangle8 from "../../assets/Rectangle8.png";
import Rectangle23 from "../../assets/Rectangle23.png";
import Rectangle13 from "../../assets/Rectangle13.png";
import Ellipse12 from "../../assets/Ellipse12.png";
import logokpmg from "../../assets/logokpmg.png";
import logoutc from "../../assets/logoutc.png";
import circleabout from "../../assets/circleabout.png";
import Lineabout from "../../assets/Lineabout.png";
import dialogue from "../../assets/dialogue.png";
import video from "../../assets/video.png";
import about from "../../assets/about.jpg";

import "./About.css";
import Menubarone from "../../Component/Navbar/Menubarone";
import MenuBar from "../../Component/Navbar/MenuBar";
import Footer from "../../Component/Footer/Footer";
import { Desktop, Mobile, Tablet } from "../../constant/AppConfig";
import { useTranslation } from "react-i18next";
const About = (props) => {
  const [t] = useTranslation("common");

  return (
    <>
      <Desktop>
       
        <Row>
          <Col>
            <div>
              <img
                className="lineserviceconsulting"
                src={Rectangle13}
                alt="logo"
              />
            </div>
            <div className="titleserviceconsulting">
              {t("About.Métier PRISMA")}
            </div>
            <div className="textabout">
              {t("About.text Métier PRISMA")}
              <div></div>
            </div>
            <img src={Ellipse12} style={{ marginLeft: "139px" }} alt="logo" />
            <div className="textitemmetier">
              {t("About.soustitle1")}
              <p
                style={{
                  marginTop: "-32px",
                  marginLeft: "110px",
                  fontWeight: 700,
                }}
              >
                UIT - SG12
              </p>
              <img
                src={logoutc}
                style={{ marginTop: "-100px", marginLeft: "269px" }}
                alt="logo"
              />
            </div>
            <img
              src={Ellipse12}
              style={{ marginTop: "31px", marginLeft: "139px" }}
              alt="logo"
            />
            <div className="textitemmetier2">
              {t("About.soustitle2")}
              <p
                style={{
                  marginTop: "-32px",
                  marginLeft: "140px",
                  fontWeight: 700,
                }}
              >
                KPMG
              </p>
              <img
                src={logokpmg}
                style={{ marginTop: "-100px", marginLeft: "239px" }}
                alt="logo"
              />
            </div>
            <button className="buttondemo" style={{ marginLeft: "139px" }}>
              <div className="textbuttondemo">Video demo</div>
              <img src={video} alt="logo" />
            </button>
          </Col>
          <Col>
            <div
              style={{
                diplay: "flex",
                justifyContent: "center",
                marginTop: "56px",
              }}
            >
              <img src={about} style={{ width: "90%" }} alt="logo" />
            </div>
          </Col>
        </Row>
        <Row style={{ marginTop: "92px" }}>
          <Col>
            <div>
              <img
                className="lineserviceconsulting"
                src={Rectangle13}
                alt="logo"
              />
            </div>
            <div className="titleserviceconsulting" style={{ width: "546px" }}>
              {t("About.Parcours de PRISMA")}
            </div>
            <Row>
              <Col>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div className="numabout">2011</div>
                  <div>
                    <img src={circleabout} alt="logo" />
                  </div>
                  <div>
                    <img src={Lineabout} alt="logo" />
                  </div>
                  <div className="text2about">{t("About.CRÉATION")}</div>
                  <div className="text3about">
                    {t("About.Siège en Tunisie")}
                  </div>
                  <div>
                    <img
                      src={Lineabout}
                      style={{ marginTop: "10px" }}
                      alt="logo"
                    />
                  </div>
                  <div className="text4about">{t("About.text 2011")}</div>
                </div>
              </Col>
              <Col>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div className="numabout">2015</div>
                  <div>
                    <img src={circleabout} alt="logo" />
                  </div>
                  <div>
                    <img src={Lineabout} alt="logo" />
                  </div>
                  <div className="text2about">{t("About.BUREAU DU MAROC")}</div>
                  <div>
                    <img
                      src={Lineabout}
                      style={{ marginTop: "10px" }}
                      alt="logo"
                    />
                  </div>
                  <div className="text4about" style={{ width: "171.9px" }}>
                    {t("About.text 2015")}
                  </div>{" "}
                </div>
              </Col>

              <Col>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div className="numabout">2017</div>
                  <div>
                    <img src={circleabout} alt="logo" />
                  </div>
                  <div>
                    <img src={Lineabout} alt="logo" />
                  </div>
                  <div className="text2about" style={{ textAlign: "center" }}>
                    {t("About.LANCEMENT DE LA DIGITAL JOURNEY")}
                  </div>
                  <div>
                    <img
                      src={Lineabout}
                      style={{ marginTop: "10px" }}
                      alt="logo"
                    />
                  </div>
                  <div className="text4about" style={{ width: "176.48px" }}>
                    {t("About.text 2017")}
                  </div>{" "}
                </div>
              </Col>

              <Col>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div className="numabout">2018</div>
                  <div>
                    <img src={circleabout} alt="logo" />
                  </div>
                  <div>
                    <img src={Lineabout} alt="logo" />
                  </div>
                  <div className="text2about" style={{ marginLeft: "0px" }}>
                    {t("About.BUREAU DE LA CÔTE D'IVOIRE")}{" "}
                  </div>
                  <div>
                    <img
                      src={Lineabout}
                      style={{ marginTop: "10px" }}
                      alt="logo"
                    />
                  </div>
                  <div className="text4about" style={{ width: "216.6px" }}>
                    {t("About.text 2018")}
                  </div>{" "}
                </div>
              </Col>
              <Col>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div className="numabout">2019</div>
                  <div>
                    <img src={circleabout} alt="logo" />
                  </div>
                  <div>
                    <img src={Lineabout} alt="logo" />
                  </div>
                  <div className="text2about" style={{ marginLeft: "0px" }}>
                    {t("About.VISION : DIGITALENABLER")}
                  </div>
                  <div>
                    <img
                      src={Lineabout}
                      style={{ marginTop: "10px" }}
                      alt="logo"
                    />
                  </div>
                  <div className="text4about" style={{ width: "330.85px" }}>
                    {t("About.text 2019")}
                  </div>{" "}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row style={{ marginTop: "85px" }}>
          <Col>
            <div>
              <img
                className="lineserviceconsulting"
                src={Rectangle13}
                alt="logo"
              />
            </div>
            <div className="titleserviceconsulting" style={{ width: "505px" }}>
              {t("About.Mot de la direction Générale")}
            </div>

            <div>
              <div className="text5about">
                <img
                  src={dialogue}
                  style={{
                    height: "80px",
                    marginRight: "30px",
                    width: "122.51px",
                  }}
                  alt="logo"
                />
                {t("About.text Mot de la direction Générale")}
              </div>
              <div className="text5about">
                {t("About.text2 Mot de la direction Générale")}
              </div>
            </div>
          </Col>
        </Row>
        <Row
          style={{
            background: "#30C0C6",
            height: "300px",
            marginTop: "133px",
          }}
        >
          <Col>
            <div>
              <img className="linevisionabout" src={Rectangle8} alt="logo" />
            </div>
            <div className="textvisionabout">{t("About.Notre Vision")}</div>
            <img
              src={Ellipse12}
              style={{ marginTop: "67px", marginLeft: "63px" }}
              alt="logo"
            />
            <div className="textvision">{t("About.text Notre Vision")}</div>
            <img
              src={Ellipse12}
              style={{ marginTop: "20px", marginLeft: "63px" }}
              alt="logo"
            />
            <div className="textvision">{t("About.text2 Notre Vision")}</div>
          </Col>
        </Row>
        <Row style={{ marginTop: "85px" }}>
          <Col>
            <div>
              <img
                className="lineserviceconsulting"
                src={Rectangle13}
                alt="logo"
              />
            </div>

            <div className="titleserviceconsulting" style={{ width: "505px" }}>
              {t("About.Nos Valeurs")}
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div>
                <img
                  src={Ellipse12}
                  style={{ marginTop: "16px", marginLeft: "133px" }}
                  alt="logo"
                />
              </div>
              <div>
                <p className="textvaleurvision1">
                  {t("About.Orientation client")}
                </p>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div>
                <img
                  src={Ellipse12}
                  style={{ marginTop: "16px", marginLeft: "133px" }}
                  alt="logo"
                />
              </div>
              <div>
            <p className="textvaleurvision1">{t("About.Responsabilité")}</p>
            </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div>
                <img
                  src={Ellipse12}
                  style={{ marginTop: "16px", marginLeft: "133px" }}
                  alt="logo"
                />
              </div>
              <div>
            <p className="textvaleurvision1">{t("About.Innovation")}</p>
            </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div>
                <img
                  src={Ellipse12}
                  style={{ marginTop: "16px", marginLeft: "133px" }}
                  alt="logo"
                />
              </div>
              <div>
            <p className="textvaleurvision1">{t("About.Engagement")}</p>
            </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div>
                <img
                  src={Ellipse12}
                  style={{ marginTop: "16px", marginLeft: "133px" }}
                  alt="logo"
                />
              </div>
              <div>
            <p className="textvaleurvision1">{t("About.Esprit d’équipe")}</p>
            </div>
            </div>
          </Col>
        </Row>
      </Desktop>
      <Tablet>
        <Row
          style={{
            backgroundColor: "#253D8A",
            minHeight: "60px",
            height: "auto",
          }}
        >
          <Col>
            <Menubarone />
          </Col>
        </Row>
        <Row style={{ minHeight: "120px", height: "auto", width: "100%" }}>
          <Col>
            <MenuBar />
          </Col>
        </Row>
        <Row>
          <Col>
            <div>
              <img
                className="lineserviceconsulting"
                src={Rectangle13}
                alt="logo"
              />
            </div>
            <div className="titleserviceconsulting">Métier Prisma</div>
            <div className="textabout">
              PRISMA est une société d’ingénierie spécialisée dans l’évaluation
              de la QoS / QoE , l’optimisation des performances et la
              monétisation des réseaux et des systèmes de télécommunication
              <div></div>
            </div>
            <img src={Ellipse12} style={{ marginLeft: "139px" }} alt="logo" />
            <div className="textitemmetier">
              Membre{" "}
              <p
                style={{
                  marginTop: "-32px",
                  marginLeft: "110px",
                  fontWeight: 700,
                }}
              >
                UIT - SG12
              </p>
              <img
                src={logoutc}
                style={{ marginTop: "-100px", marginLeft: "269px" }}
                alt="logo"
              />
            </div>
            <img
              src={Ellipse12}
              style={{ marginTop: "31px", marginLeft: "139px" }}
              alt="logo"
            />
            <div className="textitemmetier2">
              Audité par{" "}
              <p
                style={{
                  marginTop: "-32px",
                  marginLeft: "140px",
                  fontWeight: 700,
                }}
              >
                KPMG
              </p>
              <img
                src={logokpmg}
                style={{ marginTop: "-100px", marginLeft: "239px" }}
                alt="logo"
              />
            </div>
            <button className="buttondemo" style={{ marginLeft: "139px" }}>
              <div className="textbuttondemo">Video demo</div>
              <img src={video} alt="logo" />
            </button>
          </Col>
          <Col>
            <div
              style={{
                diplay: "flex",
                justifyContent: "center",
                marginTop: "56px",
              }}
            >
              <img src={Rectangle23} alt="logo" />
            </div>
          </Col>
        </Row>
        <Row style={{ marginTop: "92px" }}>
          <Col>
            <div>
              <img
                className="lineserviceconsulting"
                src={Rectangle13}
                alt="logo"
              />
            </div>
            <div className="titleserviceconsulting" style={{ width: "546px" }}>
              PRISMA Journey & Milestones
            </div>
            <Row>
              <Col>
                <div className="numabout">2011</div>
                <div>
                  <img
                    src={circleabout}
                    style={{ marginLeft: "67.56px" }}
                    alt="logo"
                  />
                </div>
                <div>
                  <img
                    src={Lineabout}
                    style={{ marginLeft: "78.25px" }}
                    alt="logo"
                  />
                </div>
                <div className="text2about">CRÉATION</div>
                <div className="text3about">Siège en Tunisie</div>
                <div>
                  <img
                    src={Lineabout}
                    style={{ marginLeft: "78.25px", marginTop: "10px" }}
                    alt="logo"
                  />
                </div>
                <div className="text4about">
                  Activité principaleSpécialiste QoE & QoS
                </div>
              </Col>
              <Col>
                <div className="numabout">2015</div>
                <div>
                  <img
                    src={circleabout}
                    style={{ marginLeft: "67.56px" }}
                    alt="logo"
                  />
                </div>
                <div>
                  <img
                    src={Lineabout}
                    style={{ marginLeft: "78.25px" }}
                    alt="logo"
                  />
                </div>
                <div className="text2about" style={{ marginLeft: "0px" }}>
                  BUREAU DU MAROC
                </div>

                <div>
                  <img
                    src={Lineabout}
                    style={{ marginLeft: "78.25px", marginTop: "10px" }}
                    alt="logo"
                  />
                </div>
                <div className="text4about" style={{ width: "171.9px" }}>
                  PRISMA a fondéNetPerf en tantque filiale localeau Maroc{" "}
                </div>
              </Col>

              <Col>
                <div className="numabout">2017</div>
                <div>
                  <img
                    src={circleabout}
                    style={{ marginLeft: "67.56px" }}
                    alt="logo"
                  />
                </div>
                <div>
                  <img
                    src={Lineabout}
                    style={{ marginLeft: "78.25px" }}
                    alt="logo"
                  />
                </div>
                <div className="text2about" style={{ marginLeft: "0px" }}>
                  Lancement de laDigital Journey
                </div>

                <div>
                  <img
                    src={Lineabout}
                    style={{ marginLeft: "78.25px", marginTop: "10px" }}
                    alt="logo"
                  />
                </div>
                <div
                  className="text4about"
                  style={{ width: "176.48px", marginLeft: "0px" }}
                >
                  PRISMA a commencéla Digital Journeyen déployant 4DPIs dans le
                  NA{" "}
                </div>
              </Col>

              <Col>
                <div className="numabout">2018</div>
                <div>
                  <img
                    src={circleabout}
                    style={{ marginLeft: "67.56px" }}
                    alt="logo"
                  />
                </div>
                <div>
                  <img
                    src={Lineabout}
                    style={{ marginLeft: "78.25px" }}
                    alt="logo"
                  />
                </div>
                <div className="text2about" style={{ marginLeft: "0px" }}>
                  BUREAU DE LACÔTE DIVOIRE{" "}
                </div>

                <div>
                  <img
                    src={Lineabout}
                    style={{ marginLeft: "78.25px", marginTop: "10px" }}
                    alt="logo"
                  />
                </div>
                <div
                  className="text4about"
                  style={{ width: "216.6px", marginLeft: "-20px" }}
                >
                  PRISMA CI a étéfondée en tantque joint-ventureentre PRISMA et
                  SCET{" "}
                </div>
              </Col>
              <Col>
                <div className="numabout">2019</div>
                <div>
                  <img
                    src={circleabout}
                    style={{ marginLeft: "67.56px" }}
                    alt="logo"
                  />
                </div>
                <div>
                  <img
                    src={Lineabout}
                    style={{ marginLeft: "78.25px" }}
                    alt="logo"
                  />
                </div>
                <div className="text2about" style={{ marginLeft: "0px" }}>
                  VISION : DIGITALENABLER
                </div>

                <div>
                  <img
                    src={Lineabout}
                    style={{ marginLeft: "78.25px", marginTop: "10px" }}
                    alt="logo"
                  />
                </div>
                <div
                  className="text4about"
                  style={{ width: "330.85px", marginLeft: "-60px" }}
                >
                  PRISMA sera un DigitalEnabler clé pour les secteursdes
                  télécommunications,de l'énergie et des banquesen Afrique.{" "}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row style={{ marginTop: "85px" }}>
          <Col>
            <div>
              <img
                className="lineserviceconsulting"
                src={Rectangle13}
                alt="logo"
              />
            </div>
            <div className="titleserviceconsulting" style={{ width: "505px" }}>
              Mot de la direction Générale
            </div>

            <div>
              <div className="text5about">
                <img
                  src={dialogue}
                  style={{
                    height: "80px",
                    marginRight: "30px",
                    width: "122.51px",
                  }}
                  alt="logo"
                />
                Depuis notre création, PRISMA a toujours privilégié le rôle de
                mettre un sens à ses actions En plus de nos propre produits,
                nous avons construit des partenariats forts avec les concepteurs
                et éditeurs leader sur le marché de la métrologie et de la
                qualité de service.Le développement de nos compétences est un
                choix stratégique établit grâce aux retours d’expériences et aux
              </div>
              <div className="text5about">
                programmes de certifications ambitieux existants. Nous sommes
                toujours vigilants afin de vous servir au mieux et de satisfaire
                vos attentes.
              </div>
            </div>
          </Col>
        </Row>
        <Row
          style={{
            background: "#30C0C6",
            minHeight: "647px",
            marginTop: "133px",
            height: "auto",
          }}
        >
          <Col>
            <div>
              <img className="linevisionabout" src={Rectangle8} alt="logo" />
            </div>
            <div className="textvisionabout">Métier Prisma</div>
            <img
              src={Ellipse12}
              style={{ marginTop: "46px", marginLeft: "63px" }}
              alt="logo"
            />
            <div className="textvision">
              Nous aspirons à être un acteur incontournable en monitoring et en
              analyse de trafic auprès des clients et des partenaires dans la
              région d’Afrique et du Moyen Orient
            </div>
            <img
              src={Ellipse12}
              style={{ marginTop: "20px", marginLeft: "63px" }}
              alt="logo"
            />
            <div className="textvision">
              Notre vocation première consiste à offrir la meilleure solution et
              d’excellente prestations
            </div>
            <img
              src={Ellipse12}
              style={{ marginTop: "20px", marginLeft: "63px" }}
              alt="logo"
            />
            <div className="textvision">
              Nous traitons nos actionnaires, nos clients, nos partenaires et
              nos collaborateurs avec respect
            </div>
            <img
              src={Ellipse12}
              style={{ marginTop: "20px", marginLeft: "63px" }}
              alt="logo"
            />
            <div className="textvision">
              Nous respectons la parole donnée et honorons nos engagements{" "}
            </div>
            <img
              src={Ellipse12}
              style={{ marginTop: "20px", marginLeft: "63px" }}
              alt="logo"
            />
            <div className="textvision">
              Nous nous engageons à faire de la qualité dans tout ce que nous
              entreprenons{" "}
            </div>
            <img
              src={Ellipse12}
              style={{ marginTop: "20px", marginLeft: "63px" }}
              alt="logo"
            />
            <div className="textvision">
              Notre philosophie d’entreprise est de toujours mieux faire et de
              viser l’excellence dans tout ce que nous entreprenons{" "}
            </div>
            <img
              src={Ellipse12}
              style={{ marginTop: "20px", marginLeft: "63px" }}
              alt="logo"
            />
            <div className="textvision">
              Le développement du capital humain est au cœur de notre
              préoccupation au quotidien{" "}
            </div>
            <img
              src={Ellipse12}
              style={{ marginTop: "20px", marginLeft: "63px" }}
              alt="logo"
            />
            <div className="textvision">
              Nous nous inscrivons dans la durée pour mieux satisfaire nos
              clients{" "}
            </div>
            <img
              src={Ellipse12}
              style={{ marginTop: "20px", marginLeft: "63px" }}
              alt="logo"
            />
            <div className="textvision">
              Nous refusons tout compromis entre la qualité, l’excellence et
              l’éthique.{" "}
            </div>
          </Col>
        </Row>
        <Row style={{ marginTop: "85px" }}>
          <Col>
            <div>
              <img
                className="lineserviceconsulting"
                src={Rectangle13}
                alt="logo"
              />
            </div>
            <div className="titleserviceconsulting" style={{ width: "505px" }}>
              Nos Valeurs
            </div>
            <p className="textvaleurvision1">
              Orientation client :
              <span
                style={{ color: "rgba(37, 61, 138, 1)", marginLeft: "10px" }}
              >
                Nous aspirons à être un acteur incontournable en monitoring et
                en analyse de trafic auprès des clients et des partenaires dans
                la région d’Afrique et du Moyen Orient
              </span>
            </p>
            <p className="textvaleurvision1">
              Responsabilité :
              <span
                style={{ color: "rgba(37, 61, 138, 1)", marginLeft: "10px" }}
              >
                Nous prenons nos décisions en toute conscience, nous donnons les
                motifs de nos actes et nous sommes jugés sur eux.
              </span>
            </p>
            <p className="textvaleurvision1">
              Innovation :
              <span
                style={{ color: "rgba(37, 61, 138, 1)", marginLeft: "10px" }}
              >
                {" "}
                Nous nous engageons à offrir de nouveaux services et solutions
                relatifs à de nouveaux domaines de compétences. Nous identifions
                les besoins implicites lors du traitement de la demande client
                et nous restons disponibles et réactifs.
              </span>
            </p>
            <p className="textvaleurvision1">
              Engagement :
              <span
                style={{ color: "rgba(37, 61, 138, 1)", marginLeft: "10px" }}
              >
                {" "}
                Nous mettons nos compétences et notre savoir être à profit de
                PRISMA. Nous participons activement à son progrès et à son
                succès et nous assumons nos actes.
              </span>
            </p>
            <p className="textvaleurvision1">
              Esprit d’équipe :
              <span
                style={{ color: "rgba(37, 61, 138, 1)", marginLeft: "10px" }}
              >
                Nous fournissons des services de qualité à l’ensemble de PRISMA
                : nous partageons nos connaissances et notre expérience, nous
                répondons aux besoins explicites et implicites de l’équipe et
                nous sommes disponibles pour tous les membres de l’équipe.
              </span>
            </p>
          </Col>
        </Row>
      </Tablet>
      <Mobile>
        {/* <Row
          style={{
            backgroundColor: "#253D8A",
            minHeight: "60px",
            height: "auto",
          }}
        >
          <Col>
            <Menubarone />
          </Col>
        </Row>
        <Row style={{ minHeight: "120px", height: "auto", width: "100%" }}>
          <Col>
            <MenuBar />
          </Col>
        </Row> */}
        <Row>
          <Col>
            <div
              style={{
                marginTop: "50px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div className="titleserviceconsulting">
                <img
                  src={Rectangle13}
                  alt="logo"
                  className="lineserviceconsulting"
                  style={{ marginLeft: "-30px" }}
                />{" "}
                <div style={{ marginLeft: "30px" }}>Métier Prisma</div>
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div className="textabout">
                PRISMA est une société d’ingénierie spécialisée dans
                l’évaluation de la QoS / QoE , l’optimisation des performances
                et la monétisation des réseaux et des systèmes de
                télécommunication
                <div></div>
              </div>
            </div>
            <img
              src={Ellipse12}
              style={{ marginLeft: "40px", marginTop: "40px" }}
              alt="logo"
            />
            <div
              className="textitemmetier"
              style={{ marginTop: "-25px", marginLeft: "40px" }}
            >
              Membre{" "}
              <p
                style={{
                  marginTop: "0px",
                  marginLeft: "10px",
                  fontWeight: 700,
                }}
              >
                UIT - SG12
              </p>
              <img
                src={logoutc}
                alt="logo"
                style={{
                  marginTop: "0px",
                  marginLeft: "10px",
                  width: "40px",
                  height: "40px",
                }}
              />
            </div>
            <img
              src={Ellipse12}
              alt="logo"
              style={{ marginTop: "70px", marginLeft: "40px" }}
            />
            <div
              className="textitemmetier2"
              style={{ marginTop: "-25px", marginLeft: "40px" }}
            >
              Audité par{" "}
              <p
                style={{
                  marginTop: "0px",
                  marginLeft: "10px",
                  fontWeight: 700,
                }}
              >
                KPMG
              </p>
              <img
                src={logokpmg}
                alt="logo"
                style={{
                  marginTop: "-10px",
                  marginLeft: "10px",
                  width: "80px",
                  height: "40px",
                }}
              />
            </div>
            <button
              className="buttondemo"
              style={{ marginLeft: "139px", marginTop: "60px" }}
            >
              <div className="textbuttondemo">Video demo</div>
              <img src={video} alt="logo" />
            </button>
          </Col>
          <Col>
            <div
              style={{
                diplay: "flex",
                justifyContent: "center",
                marginTop: "56px",
              }}
            >
              <img src={Rectangle23} alt="logo" />
            </div>
          </Col>
        </Row>
        <Row style={{ marginTop: "92px" }}>
          <Col>
            <div
              style={{
                marginTop: "20px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div className="titleserviceconsulting">
                <img
                  src={Rectangle13}
                  className="lineserviceconsulting"
                  alt="logo"
                />{" "}
                <div style={{ marginLeft: "30px" }}>
                  PRISMA Journey & Milestones
                </div>
              </div>
            </div>

            <Row>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <Col xs={12}>
                    <div style={{ marginTop: "50px" }}>
                      <div className="numabout">2011</div>
                      <div>
                        <img
                          src={circleabout}
                          style={{ marginLeft: "67.56px" }}
                          alt="logo"
                        />
                      </div>
                      <div>
                        <img
                          src={Lineabout}
                          style={{ marginLeft: "78.25px" }}
                          alt="logo"
                        />
                      </div>
                      <div className="text2about">CRÉATION</div>
                      <div className="text3about">Siège en Tunisie</div>
                      <div>
                        <img
                          src={Lineabout}
                          style={{ marginLeft: "78.25px", marginTop: "10px" }}
                          alt="logo"
                        />
                      </div>
                      <div className="text4about">
                        Activité principaleSpécialiste QoE & QoS
                      </div>
                    </div>
                  </Col>
                  <Col xs={12}>
                    <div style={{ marginTop: "50px" }}>
                      <div className="numabout">2015</div>
                      <div>
                        <img
                          src={circleabout}
                          style={{ marginLeft: "67.56px" }}
                          alt="logo"
                        />
                      </div>
                      <div>
                        <img
                          src={Lineabout}
                          style={{ marginLeft: "78.25px" }}
                          alt="logo"
                        />
                      </div>
                      <div className="text2about" style={{ marginLeft: "0px" }}>
                        BUREAU DU MAROC
                      </div>

                      <div>
                        <img
                          src={Lineabout}
                          style={{ marginLeft: "78.25px", marginTop: "10px" }}
                          alt="logo"
                        />
                      </div>
                      <div className="text4about" style={{ width: "171.9px" }}>
                        PRISMA a fondéNetPerf en tantque filiale localeau Maroc{" "}
                      </div>
                    </div>
                  </Col>

                  <Col xs={12}>
                    <div style={{ marginTop: "50px" }}>
                      <div className="numabout">2017</div>
                      <div>
                        <img
                          src={circleabout}
                          style={{ marginLeft: "67.56px" }}
                          alt="logo"
                        />
                      </div>
                      <div>
                        <img
                          src={Lineabout}
                          style={{ marginLeft: "78.25px" }}
                          alt="logo"
                        />
                      </div>
                      <div
                        className="text2about"
                        style={{ marginLeft: "-33px" }}
                      >
                        Lancement de la Digital Journey
                      </div>
                      <div>
                        <img
                          src={Lineabout}
                          style={{ marginLeft: "78.25px", marginTop: "10px" }}
                          alt="logo"
                        />
                      </div>
                      <div
                        className="text4about"
                        style={{ width: "176.48px", marginLeft: "0px" }}
                      >
                        PRISMA a commencéla Digital Journeyen déployant 4DPIs
                        dans le NA{" "}
                      </div>{" "}
                    </div>
                  </Col>

                  <Col xs={12}>
                    <div style={{ marginTop: "50px" }}>
                      <div className="numabout">2018</div>
                      <div>
                        <img
                          src={circleabout}
                          style={{ marginLeft: "67.56px" }}
                          alt="logo"
                        />
                      </div>
                      <div>
                        <img
                          src={Lineabout}
                          style={{ marginLeft: "78.25px" }}
                          alt="logo"
                        />
                      </div>
                      <div
                        className="text2about"
                        style={{ marginLeft: "-40px" }}
                      >
                        BUREAU DE LA CÔTE DIVOIRE{" "}
                      </div>

                      <div>
                        <img
                          src={Lineabout}
                          style={{ marginLeft: "78.25px", marginTop: "10px" }}
                          alt="logo"
                        />
                      </div>
                      <div
                        className="text4about"
                        style={{ width: "216.6px", marginLeft: "-20px" }}
                      >
                        PRISMA CI a étéfondée en tantque joint-ventureentre
                        PRISMA et SCET{" "}
                      </div>
                    </div>
                  </Col>
                  <Col xs={12}>
                    <div style={{ marginTop: "50px" }}>
                      <div className="numabout">2019</div>
                      <div>
                        <img
                          src={circleabout}
                          style={{ marginLeft: "67.56px" }}
                          alt="logo"
                        />
                      </div>
                      <div>
                        <img
                          src={Lineabout}
                          style={{ marginLeft: "78.25px" }}
                          alt="logo"
                        />
                      </div>
                      <div
                        className="text2about"
                        style={{ marginLeft: "-30px" }}
                      >
                        VISION : DIGITALENABLER
                      </div>
                      <div>
                        <img
                          src={Lineabout}
                          style={{ marginLeft: "78.25px", marginTop: "10px" }}
                          alt="logo"
                        />
                      </div>
                      <div
                        className="text4about"
                        style={{ width: "250.85px", marginLeft: "-40px" }}
                      >
                        PRISMA sera un DigitalEnabler clé pour les secteursdes
                        télécommunications,de l'énergie et des banquesen
                        Afrique.{" "}
                      </div>{" "}
                    </div>
                  </Col>
                </div>
              </div>
            </Row>
          </Col>
        </Row>
        <Row style={{ marginTop: "85px" }}>
          <Col>
            <div
              style={{
                marginTop: "42px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div className="titleserviceconsulting">
                <img
                  src={Rectangle13}
                  className="lineserviceconsulting"
                  style={{ marginLeft: "-30px" }}
                  alt="logo"
                />{" "}
                <div style={{ marginLeft: "30px" }}>Métier Prisma</div>
              </div>
            </div>

            <div>
              <div className="text5about" style={{ marginTop: "42px" }}>
                <img
                  src={dialogue}
                  style={{
                    height: "80px",
                    marginRight: "30px",
                    width: "122.51px",
                  }}
                  alt="logo"
                />
                Depuis notre création, PRISMA a
              </div>
              <div className="text5about">
                toujours privilégié le rôle de mettre un sens à ses actions En
                plus de nos propre produits, nous avons construit des
                partenariats forts avec les concepteurs et éditeurs leader sur
                le marché de la métrologie et de la qualité de service.Le
                développement de nos compétences est un choix stratégique
                établit grâce aux retours d’expériences et aux programmes de
                certifications ambitieux existants. Nous sommes toujours
                vigilants afin de vous servir au mieux et de satisfaire vos
                attentes.
              </div>
            </div>
          </Col>
        </Row>
        <Row
          style={{
            background: "#30C0C6",
            minHeight: "647px",
            marginTop: "133px",
            height: "auto",
          }}
        >
          <Col>
            <div
              style={{
                marginTop: "42px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                className="titleserviceconsulting"
                style={{
                  color: "#FFFFFF",
                  fontSize: " 37.669px",
                }}
              >
                <img
                  src={Rectangle8}
                  className="lineserviceconsulting"
                  alt="logo"
                  style={{ marginLeft: "-30px" }}
                />{" "}
                <div style={{ marginLeft: "30px" }}>Métier Prisma</div>
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img
                src={Ellipse12}
                style={{
                  marginTop: "60px",
                  width: "10.35px",
                  height: "10.35px",
                }}
                alt="logo"
              />
              <div className="textvision" style={{ marginTop: "46px" }}>
                Nous aspirons à être un acteur incontournable en monitoring et
                en analyse de trafic auprès des clients et des partenaires dans
                la région d’Afrique et du Moyen Orient
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img
                src={Ellipse12}
                style={{
                  marginTop: "40px",
                  width: "10.35px",
                  height: "10.35px",
                }}
                alt="logo"
              />
              <div className="textvision" style={{ marginTop: "25px" }}>
                Notre vocation première consiste à offrir la meilleure solution
                et d’excellente prestations
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img
                src={Ellipse12}
                style={{
                  marginTop: "40px",
                  width: "10.35px",
                  height: "10.35px",
                }}
                alt="logo"
              />
              <div className="textvision" style={{ marginTop: "25px" }}>
                Nous traitons nos actionnaires, nos clients, nos partenaires et
                nos collaborateurs avec respect
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img
                src={Ellipse12}
                style={{
                  marginTop: "40px",
                  width: "10.35px",
                  height: "10.35px",
                }}
                alt="logo"
              />
              <div className="textvision" style={{ marginTop: "25px" }}>
                Nous respectons la parole donnée et honorons nos engagements{" "}
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img
                src={Ellipse12}
                style={{
                  marginTop: "40px",
                  width: "10.35px",
                  height: "10.35px",
                }}
                alt="logo"
              />
              <div className="textvision" style={{ marginTop: "25px" }}>
                Nous nous engageons à faire de la qualité dans tout ce que nous
                entreprenons{" "}
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img
                src={Ellipse12}
                style={{
                  marginTop: "40px",
                  width: "10.35px",
                  height: "10.35px",
                }}
                alt="logo"
              />
              <div className="textvision" style={{ marginTop: "25px" }}>
                Notre philosophie d’entreprise est de toujours mieux faire et de
                viser l’excellence dans tout ce que nous entreprenons{" "}
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img
                src={Ellipse12}
                style={{
                  marginTop: "40px",
                  width: "10.35px",
                  height: "10.35px",
                }}
                alt="logo"
              />
              <div className="textvision" style={{ marginTop: "25px" }}>
                Le développement du capital humain est au cœur de notre
                préoccupation au quotidien{" "}
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img
                src={Ellipse12}
                style={{
                  marginTop: "40px",
                  width: "10.35px",
                  height: "10.35px",
                }}
                alt="logo"
              />
              <div className="textvision" style={{ marginTop: "25px" }}>
                <div className="textvision">
                  Nous nous inscrivons dans la durée pour mieux satisfaire nos
                  clients{" "}
                </div>
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img
                src={Ellipse12}
                style={{
                  marginTop: "40px",
                  width: "10.35px",
                  height: "10.35px",
                }}
                alt="logo"
              />
              <div className="textvision" style={{ marginTop: "25px" }}>
                <div className="textvision">
                  Nous refusons tout compromis entre la qualité, l’excellence et
                  l’éthique.{" "}
                </div>{" "}
              </div>
            </div>
          </Col>
        </Row>
        <Row style={{ marginTop: "85px" }}>
          <Col>
            <div
              style={{
                marginTop: "42px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div className="titleserviceconsulting">
                <img
                  src={Rectangle13}
                  className="lineserviceconsulting"
                  style={{ marginLeft: "-30px" }}
                  alt="logo"
                />{" "}
                <div style={{ marginLeft: "30px" }}>Nos Valeurs</div>
              </div>
            </div>
            <div
              style={{
                marginTop: "42px",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <p className="textvaleurvision1">
                Orientation client :
                <span
                  style={{ color: "rgba(37, 61, 138, 1)", marginLeft: "10px" }}
                >
                  Nous aspirons à être un acteur incontournable en monitoring et
                  en analyse de trafic auprès des clients et des partenaires
                  dans la région d’Afrique et du Moyen Orient
                </span>
              </p>
              <p className="textvaleurvision1">
                Responsabilité :
                <span
                  style={{ color: "rgba(37, 61, 138, 1)", marginLeft: "10px" }}
                >
                  Nous prenons nos décisions en toute conscience, nous donnons
                  les motifs de nos actes et nous sommes jugés sur eux.
                </span>
              </p>
              <p className="textvaleurvision1">
                Innovation :
                <span
                  style={{ color: "rgba(37, 61, 138, 1)", marginLeft: "10px" }}
                >
                  {" "}
                  Nous nous engageons à offrir de nouveaux services et solutions
                  relatifs à de nouveaux domaines de compétences. Nous
                  identifions les besoins implicites lors du traitement de la
                  demande client et nous restons disponibles et réactifs.
                </span>
              </p>
              <p className="textvaleurvision1">
                Engagement :
                <span
                  style={{ color: "rgba(37, 61, 138, 1)", marginLeft: "10px" }}
                >
                  {" "}
                  Nous mettons nos compétences et notre savoir être à profit de
                  PRISMA. Nous participons activement à son progrès et à son
                  succès et nous assumons nos actes.
                </span>
              </p>
              <p className="textvaleurvision1">
                Esprit d’équipe :
                <span
                  style={{ color: "rgba(37, 61, 138, 1)", marginLeft: "10px" }}
                >
                  Nous fournissons des services de qualité à l’ensemble de
                  PRISMA : nous partageons nos connaissances et notre
                  expérience, nous répondons aux besoins explicites et
                  implicites de l’équipe et nous sommes disponibles pour tous
                  les membres de l’équipe.
                </span>
              </p>
            </div>
          </Col>
        </Row>
      </Mobile>

    </>
  );
};

export default About;
