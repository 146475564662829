import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Carousel from "better-react-carousel";
import Footer from "../../Component/Footer/Footer";
import MenuBar from "../../Component/Navbar/MenuBar";
import Menubarone from "../../Component/Navbar/Menubarone";
import Slider from "../Accueil/Slider";
import Rectangle8 from "../../assets/Rectangle8.svg";

import "./Reference.css";
import { Desktop, Mobile, Tablet } from "../../constant/AppConfig";
import { useEffect } from "react";
import useAuth from "../../Api/hooks/useAuth";
const ReferencePage = (props) => {
  const { datarefernces, getReference, getReferenceenglais } = useAuth();

  useEffect(() => {
   
    if (props.selectedCountry === "fr") {
      getReference();
      console.log(datarefernces,"reff page")
    } else {
      getReferenceenglais();
    }
  }, [[props.selectedCountry]]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Desktop>
  
        <Row>
          <Col style={{ paddingLeft: "0px", paddingRight: "0px" }}>
            <Slider />
          </Col>
        </Row>
        <Row>
          <Col>
            {datarefernces &&
              datarefernces.map((e, index) => (
                <>
                  <div key={index}>
                    <img className="line" src={Rectangle8} alt="logo" />
                  </div>
                  <div className="titlereference">{e.titre}</div>
                  <Carousel
                    cols={1}
                    rows={1}
                    gap={10}
                    loop
                    autoplay= {1000}
                    style={{ marginTop: "20px" }}
                  >
                    <Carousel.Item>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        {e.references.map((e1, index1) =>
                          e1.titre === "Orange Cameroun" ? (
                            <div key={index1}
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <img
                                src={e1.logo}
                                style={{
                                  marginLeft: "60px",
                                  width: "50px",
                                  height: "50px",
                                }}
                                alt="logo"
                              />
                              <div className="textorangecameroun">
                                Cameroun{" "}
                              </div>
                            </div>
                          ) : e1.titre === "Orange Maroc" ? (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <img
                                src={e1.logo}
                                style={{
                                  marginLeft: "60px",
                                  width: "50px",
                                  height: "50px",
                                }}
                                alt="logo"
                              />
                              <div className="textorangemaroc">Maroc </div>
                            </div>
                          ) : e1.titre === "Orange Guinée" ? (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <img
                                src={e1.logo}
                                style={{
                                  marginLeft: "60px",
                                  width: "50px",
                                  height: "50px",
                                }}
                                alt="logo"
                              />
                              <div className="textorangeguinée">Guinée </div>
                            </div>
                          ) : e1.titre === "Orange Tunisie" ? (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <img
                                src={e1.logo}
                                style={{
                                  marginLeft: "60px",
                                  width: "50px",
                                  height: "50px",
                                }}
                                alt="logo"
                              />
                              <div className="textorangetunisie">Tunisie </div>
                            </div>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <img
                                src={e1.logo}
                                style={{
                                  marginLeft: "60px",
                                  width:
                                    (e1.titre === "Sagemcom" ||
                                      e1.titre === "Ooredoo") &&
                                    "150px",
                                  height: "50px",
                                }}
                                alt="logo"
                              />
                              <div> </div>
                            </div>
                          )
                        )}
                      </div>
                    </Carousel.Item>
                  </Carousel>
                </>
              ))}
          </Col>
        </Row>
      </Desktop>
      <Tablet>
        <Row>
          <Col style={{ paddingLeft: "0px", paddingRight: "0px" }}>
            <Slider />
          </Col>
        </Row>
        <Row>
          <Col>
            {datarefernces &&
              datarefernces.map((e, index) => (
                <>
                  <div key={index}>
                    <img className="line" src={Rectangle8} alt="logo" />
                  </div>
                  <div className="titlereference">{e.titre}</div>
                  <Carousel
                    cols={1}
                    rows={1}
                    gap={10}
                    loop
                    style={{ marginTop: "20px" }}
                  >
                    <Carousel.Item>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        {e.references.map((e1, index1) => (
                          <img key={index1}
                            src={e1.logo}
                            style={{ marginLeft: "15px" }}
                            alt="logo"
                          />
                        ))}
                      </div>
                    </Carousel.Item>
                  </Carousel>
                </>
              ))}
          </Col>
        </Row>
      </Tablet>
      <Mobile>

        <Row>
          <Col style={{ paddingLeft: "0px", paddingRight: "0px" }}>
            <Slider />
          </Col>
        </Row>
        <Row>
          <Col>
            {datarefernces &&
              datarefernces.map((e, index) => (
                <>
                  <div key={index} style={{ display: "flex", justifyContent: "center" }}>
                    <div>
                      <img className="line" src={Rectangle8} alt="logo" />
                    </div>
                    <div className="titlereference">{e.titre}</div>
                  </div>
                  <div style={{ marginTop: "120px" }}>
                    <Carousel cols={1} rows={1} gap={10} loop>
                      {e.references.map((e1, index1) => (
                        <Carousel.Item>
                          <div key={index1}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              src={e1.logo}
                              style={{
                                marginLeft: "15px",
                                marginTop: e1.titre === "Sagemcom" && "40px",
                              }}
                              alt="logo"
                            />
                          </div>
                        </Carousel.Item>
                      ))}
                    </Carousel>
                  </div>
                </>
              ))}
          </Col>
        </Row>
      </Mobile>
   
    </>
  );
};

export default ReferencePage;
