import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import ITU from "../../../assets/itu-logo2.svg";
import Rectangle8 from "../../../assets/Rectangle8.svg";
import KPMG from "../../../assets/KPMG1.svg";
import Ellipse12 from "../../../assets/Ellipse12.png";
import { Desktop, Mobile, Tablet } from "../../../constant/AppConfig";
import { useTranslation } from "react-i18next";

const Metier = () => {
  const [t] = useTranslation("common");

  return (
    <Row className="metier">
      <Desktop>
        <Col>
          <div>
            <div>
              <img alt="logo" className="line" src={Rectangle8} />
            </div>
            <div className="titlemetier">{t("metier.title")}</div>
          </div>
          <div className="textmetier">
          {t("metier.text")}
          </div>
          <div>
            <img
              alt="logo"
              src={Ellipse12}
              style={{ marginTop: "41px", marginLeft: "139px" }}
            />
            <div className="textitemmetier">
            {t("metier.soustitle1")}
              <p
                style={{
                  marginTop: "-32px",
                  marginLeft: "110px",
                  fontWeight: 700,
                }}
              >
                UIT - SG12
              </p>
            </div>
          </div>
          <img
            alt="logo"
            src={Ellipse12}
            style={{ marginTop: "41px", marginLeft: "139px" }}
          />
          <div className="textitemmetier2">
          {t("metier.soustitle2")}
            <p
              style={{
                marginTop: "-32px",
                marginLeft: "140px",
                fontWeight: 700,
              }}
            >
              KPMG
            </p>
          </div>
        </Col>
        <Col>
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img alt="logo" src={ITU} className="ITU" />
            <img alt="logo" src={KPMG} className="KPMG" />
          </div>
        </Col>
      </Desktop>
      <Tablet>
        <Col xs={12}>
          <div>
            <div>
              <img alt="logo" className="line" src={Rectangle8} />
            </div>
            <div className="titlemetier">Notre métier</div>
          </div>
          <div className="textmetier">
            PRISMA est une société d’ingénierie spécialisée dans l’évaluation de
            la QoS / QoE , l’optimisation des performances et la monétisation
            des réseaux et des systèmes de télécommunication
          </div>
          <div>
            <img
              alt="logo"
              src={Ellipse12}
              style={{ marginTop: "41px", marginLeft: "139px" }}
            />
            <div className="textitemmetier">
              Membre{" "}
              <p
                style={{
                  marginTop: "-32px",
                  marginLeft: "110px",
                  fontWeight: 700,
                }}
              >
                UIT - SG12
              </p>
            </div>
          </div>
          <img
            alt="logo"
            src={Ellipse12}
            style={{ marginTop: "41px", marginLeft: "139px" }}
          />
          <div className="textitemmetier2">
            Audité par{" "}
            <p
              style={{
                marginTop: "-32px",
                marginLeft: "140px",
                fontWeight: 700,
              }}
            >
              KPMG
            </p>
          </div>
        </Col>
        <Col xs={12}>
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img alt="logo" src={ITU} className="ITU" />
            <img alt="logo" src={KPMG} className="KPMG" />
          </div>
        </Col>
      </Tablet>
      <Mobile>
        <Col xs={12}>
          <div className="titlemetier">
            <img alt="logo" src={Rectangle8} />
            Notre métier
          </div>
          <div className="textmetier">
            PRISMA est une société d’ingénierie spécialisée dans l’évaluation de
            la QoS / QoE , l’optimisation des performances et la monétisation
            des réseaux et des systèmes de télécommunication
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "85px",
            }}
          >
            <img alt="logo" src={Ellipse12} style={{ height: "15px" }} />
            <p className="textitemmetier">
              Membre{" "}
              <p style={{ fontWeight: 700, marginLeft: "15px" }}>UIT - SG12</p>
            </p>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "25px",
              marginLeft: "-35px",
            }}
          >
            <img alt="logo" src={Ellipse12} style={{ height: "15px" }} />
            <p className="textitemmetier2">
              Audité par{" "}
              <p style={{ fontWeight: 700, marginLeft: "15px" }}>KPMG</p>{" "}
            </p>
          </div>
        </Col>
        <Col xs={12}>
          <div style={{ justifyContent: "center", display: "flex" }}>
            <img alt="logo" src={ITU} className="ITU" />
            <img alt="logo" src={KPMG} className="KPMG" />
          </div>
        </Col>
      </Mobile>
    </Row>
  );
};

export default Metier;
