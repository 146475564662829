import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Carousel from "better-react-carousel";
import CircularProgress from '@mui/material/CircularProgress';

import Slider from "../Accueil/Slider";
import Rectangle8 from "../../assets/Rectangle8.svg";
// import Rectangle13 from "../../assets/Rectangle13.svg";
import searchblog from "../../assets/searchblog.png";
import { useTranslation } from "react-i18next";
import eyeblog from "../../assets/eyeblog.png";

import "../RefrencePage/Reference.css";
import { Desktop, Mobile, Tablet } from "../../constant/AppConfig";
import { useEffect } from "react";

import { useNavigate } from "react-router-dom";
import useAuth from "../../Api/hooks/useAuth";

const Actualites = (props) => {
  const {  dataaccteuilté,  getAllAcctuelite,changeIdAccteuilté,getActualiteEn} = useAuth();
  const navigate = useNavigate();
  const [t] = useTranslation("common");

  useEffect(() => {
    console.log(props.selectedCountry,"langueActualité");
    if (props.selectedCountry === "fr") {
      getAllAcctuelite();
      console.log(dataaccteuilté,"reff page")
    } else {
      console.log('eng')
      getActualiteEn();
    }
    
  }, [props.selectedCountry]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Desktop>
        <Row>
          <Col>
            {/* {/* <div> */}
              <img 
              className="line"
               src={Rectangle8} 
                 style={{ marginTop: "26px" , height: "60px" ,marginLeft: "108px"}}
               alt="logo" />
            {/* </div>  */}
            {/* <img
                  src={Rectangle13}
                  alt="logo"
                  className="lineserviceconsulting"
                  style={{ marginLeft: "-30px" }}
                />{" "} */}
            <div className="titlereference" 
            // style={{ marginTop: "-139px" ,marginLeft: "126px"}}
            >
              {t("navbar.Actualités")}
           </div>

            {/* <div className="text2accteuilte"> */}

              {/* Lorem ipsum dolor sit amet consectetur. Pharetra morbi et
              pellentesque lectus feugiat. A orci quis tincidunt porttitor quis
              egestas. Eleifend tristique mauris ipsum in et facilisi commodo
              odio enim. */}
            {/* </div> */}
            {/* <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "50px",
                flexDirection: "row",
              }}
            >
              <div class="input-icons">
                <img class="icon" src={searchblog} />
                <input
                  class="input-field"
                  type="text"
                  placeholder="Recherche par post"
                />
              </div>
            </div> */}
          </Col>
        </Row>
        <Row style={{ 
          // marginTop: "126px"
       }}>
          <Col>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginLeft: "760px"
              }}
            >
              {/* <div
                className="sousbaraccteuilte"
                style={{
                  marginTop: "25px",
                  // marginRight: "10px",
                }}
              >
                {/* <div>Voir tout</div> */}
              {/* </div> */} 
              <div
                style={{
                  marginTop: "32px",
                  marginRight: "20px",
                }}
                className="petittextblog"
              >
                <div>
                  {/* Lorem */}
                  </div>
              </div>
              <div
                style={{
                  marginTop: "32px",
                  marginRight: "20px",
                }}
                className="petittextblog"
              >
                <div>
                  {/* Lorem */}
                  </div>
              </div>
              <div
                style={{
                  marginTop: "32px",
                  marginRight: "20px",
                }}
                className="petittextblog"
              >
                <div>
                  {/* Lorem */}
                  </div>
              </div>
              <div
                style={{
                  marginTop: "32px",
                  marginRight: "20px",
                }}
                className="petittextblog"
              >
                <div>
                  {/* Lorem */}
                  </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              {
              
            dataaccteuilté ?   dataaccteuilté && dataaccteuilté.map((e)=>(
               
                <div 
                className="containerBlog"
                style={{ width: "401px", height: "316.67px" ,margin : "10px" , borderRadius:20+"px!important",  overflow: 'hidden'}}
                onClick={() => {
                  changeIdAccteuilté(e.id)
                  navigate("../dernierblog")}}
              > 
                <img     src={`https://manager.prisma-intl.com/storage/${JSON.parse(e.files)[0]}`}                 
                style={{ width: "401px", height: "316.67px" , borderRadius:20+"px!important",  overflow: 'hidden'}} 
/>
                <div className="textBlog">
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div
                      style={{
                        marginLeft: "17px",
                        display: "flex",
                        alignItems: "center",
                        height: "56.82px",
                        width: "80%",
                        textAlign: "left",
                      }}
                    >
                      {e.titre}
                    </div>
                    <div
                      style={{
                        marginRight: "17px",
                        display: "flex",
                        alignItems: "center",
                        height: "56.82px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        changeIdAccteuilté(e.id)
                        navigate("../dernierblog")}}
                    >
                      <img src={eyeblog} />
                    </div>
                  </div>
                </div>
              </div>
              
              ))
              :
             <CircularProgress /> 
              }
              
            </div>
          </Col>
        </Row>
      </Desktop>


     <Mobile>
     <Row>
          <Col>
            {/* {/* <div> */}
        
            <div className="titlereference" style={{marginBottom:"10px"}}
    
            >
              {t("navbar.Actualités")}</div>

            {/* <div className="text2accteuilte"> */}

              {/* Lorem ipsum dolor sit amet consectetur. Pharetra morbi et
              pellentesque lectus feugiat. A orci quis tincidunt porttitor quis
              egestas. Eleifend tristique mauris ipsum in et facilisi commodo
              odio enim. */}
            {/* </div> */}
            {/* <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "50px",
                flexDirection: "row",
              }}
            >
              <div class="input-icons">
                <img class="icon" src={searchblog} />
                <input
                  class="input-field"
                  type="text"
                  placeholder="Recherche par post"
                />
              </div>
            </div> */}
          </Col>
        </Row>
        <Row style={{ 
          // marginTop: "126px"
       }}>
          <Col>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginLeft: "760px"
              }}
            >
              {/* <div
                className="sousbaraccteuilte"
                style={{
                  marginTop: "25px",
                  // marginRight: "10px",
                }}
              >
                {/* <div>Voir tout</div> */}
              {/* </div> */} 
              <div
                style={{
                  marginTop: "32px",
                  marginRight: "20px",
                }}
                className="petittextblog"
              >
                <div>
                  {/* Lorem */}
                  </div>
              </div>
              <div
                style={{
                  marginTop: "32px",
                  marginRight: "20px",
                }}
                className="petittextblog"
              >
                <div>
                  {/* Lorem */}
                  </div>
              </div>
              <div
                style={{
                  marginTop: "32px",
                  marginRight: "20px",
                }}
                className="petittextblog"
              >
                <div>
                  {/* Lorem */}
                  </div>
              </div>
              <div
                style={{
                  marginTop: "32px",
                  marginRight: "20px",
                }}
                className="petittextblog"
              >
                <div>
                  {/* Lorem */}
                  </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              {
              
            dataaccteuilté ?   dataaccteuilté && dataaccteuilté.map((e)=>(
               
                <div 
                className="containerBlog"
                style={{ width: "401px", height: "316.67px" ,margin : "10px" , borderRadius:20+"px!important",  overflow: 'hidden'}}
                onClick={() => {
                  changeIdAccteuilté(e.id)
                  navigate("../dernierblog")}}
              > 
                <img     src={`https://manager.prisma-intl.com/storage/${JSON.parse(e.files)[0]}`}                 
                style={{ width: "401px", height: "316.67px" , borderRadius:20+"px!important",  overflow: 'hidden'}} 
/>
                <div className="textBlog">
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div
                      style={{
                        marginLeft: "17px",
                        display: "flex",
                        alignItems: "center",
                        height: "56.82px",
                        width: "80%",
                        textAlign: "left",
                      }}
                    >
                      {e.titre}
                    </div>
                    <div
                      style={{
                        marginRight: "17px",
                        display: "flex",
                        alignItems: "center",
                        height: "56.82px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        changeIdAccteuilté(e.id)
                        navigate("../dernierblog")}}
                    >
                      <img src={eyeblog} />
                    </div>
                  </div>
                </div>
              </div>
              
              ))
              :
             <CircularProgress /> 
              }
              
            </div>
          </Col>
        </Row>
     </Mobile>
  
    </>
  );
};

export default Actualites;
