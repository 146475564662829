import React from "react";
import { Col } from "react-bootstrap";
import Rectangle8 from "../../../assets/Rectangle8.svg";
import image28 from "../../../assets/image28.png";
import image35 from "../../../assets/image35.png";
import image36 from "../../../assets/image36.png";
import image37 from "../../../assets/image37.png";
import image38 from "../../../assets/partlogo.png";
import image39 from "../../../assets/image39.png";
import image40 from "../../../assets/image40.png";
import { Desktop, Mobile, Tablet } from "../../../constant/AppConfig";
import Carousel from "react-material-ui-carousel";
import CarouselSlide from "react-material-ui-carousel";
import { useTranslation } from 'react-i18next';
import useAuth from "../../../Api/hooks/useAuth";
import { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'
import 'swiper/modules/navigation/navigation.min.css'
import './Partenaires.css'
const Partenaires = () => {
  const [t] = useTranslation("common");
  const {getAllPartenaires,datapartenaires,}=useAuth()
  useEffect(()=>{
    getAllPartenaires()
  },[])
  return (
    <>
      <Desktop>
        <Col>
          <div>
            <div>
              <img alt="logo" className="line" src={Rectangle8} />
            </div>
            <div className="titleprecence">{t("Partenaires.Title")}</div>
          </div>
          {/* <Carousel cols={1} rows={1} gap={10}  autoPlay={1000}  >
      <Carousel.Item >
      
    
      <div style={{display:"flex",justifyContent:"center"}}>            <img alt='logo' src={image28} style={{marginLeft:"115px"}}/>
            <img alt='logo' src={image35} style={{marginLeft:"115px"}}/>
            <img alt='logo' src={image36} style={{marginLeft:"115px"}}/>
            <img alt='logo' src={image37} style={{marginLeft:"115px"}}/>

        </div>    
          </Carousel.Item>
          <Carousel.Item >
      
    
          <div style={{display:"flex",justifyContent:"center"}}>
       
          <img alt='logo' src={image38} style={{marginLeft:"115px"}}/>
          <img alt='logo' src={image39} style={{marginLeft:"115px"}}/>
          <img alt='logo' src={image40} style={{marginLeft:"115px"}}/>

      </div>    
        </Carousel.Item>
    
    </Carousel> */}

<Swiper
        slidesPerView={1}
        spaceBetween={30}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        loop={true}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
  {datapartenaires.map((e,index)=>index < datapartenaires.length-3 ?(
 <SwiperSlide>
 <div style={{ display: "flex", justifyContent: "center",alignItems:"center" }}>
   <img
     alt="logo"
     src={`https://manager.prisma-intl.com/storage/${datapartenaires[index].image}`}
     style={{ marginLeft: "95px",height:"50px" }}
   />
   <img
     alt="logo"
     src={`https://manager.prisma-intl.com/storage/${datapartenaires[index+1].image}`}
     style={{ marginLeft: "95px",height:"50px" }}
   />
   <img
     alt="logo"
     src={`https://manager.prisma-intl.com/storage/${datapartenaires[index+2].image}`}
     style={{ marginLeft: "95px",height:"50px"}}
   />
   <img
     alt="logo"
     src={`https://manager.prisma-intl.com/storage/${datapartenaires[index+3].image}`}
     style={{ marginLeft: "95px",height:"50px" }}
   />
 </div>
</SwiperSlide>
  ):index === datapartenaires.length-3 ?(
    <SwiperSlide>
    <div style={{ display: "flex", justifyContent: "center",alignItems:"center" }}>
      <img
        alt="logo"
        src={`https://manager.prisma-intl.com/storage/${datapartenaires[datapartenaires.length-3].image}`}
        style={{ marginLeft: "95px",height:"50px" }}
      />
      <img
        alt="logo"
        src={`https://manager.prisma-intl.com/storage/${datapartenaires[datapartenaires.length-2].image}`}
        style={{ marginLeft: "95px",height:"50px" }}
      />
      <img
        alt="logo"
        src={`https://manager.prisma-intl.com/storage/${datapartenaires[datapartenaires.length-1].image}`}
        style={{ marginLeft: "95px",height:"50px"}}
      />
      <img
        alt="logo"
        src={`https://manager.prisma-intl.com/storage/${datapartenaires[0].image}`}
        style={{ marginLeft: "95px",height:"50px" }}
      />
    </div>
   </SwiperSlide>
  ):index === datapartenaires.length-2 ?(
   <SwiperSlide>
   <div style={{ display: "flex", justifyContent: "center",alignItems:"center" }}>
   <img
        alt="logo"
        src={`https://manager.prisma-intl.com/storage/${datapartenaires[datapartenaires.length-2].image}`}
        style={{ marginLeft: "95px",height:"50px" }}
      />
      <img
        alt="logo"
        src={`https://manager.prisma-intl.com/storage/${datapartenaires[datapartenaires.length-1].image}`}
        style={{ marginLeft: "95px",height:"50px" }}
      />
      <img
        alt="logo"
        src={`https://manager.prisma-intl.com/storage/${datapartenaires[0].image}`}
        style={{ marginLeft: "95px",height:"50px"}}
      />
      <img
        alt="logo"
        src={`https://manager.prisma-intl.com/storage/${datapartenaires[1].image}`}
        style={{ marginLeft: "95px",height:"50px" }}
      />
   </div>
  </SwiperSlide>):index === datapartenaires.length-1 &&(
  <SwiperSlide>
  <div style={{ display: "flex", justifyContent: "center",alignItems:"center" }}>
    <img
      alt="logo"
      src={`https://manager.prisma-intl.com/storage/${datapartenaires[datapartenaires.length-1].image}`}
      style={{ marginLeft: "95px",height:"50px" }}
    />
    <img
      alt="logo"
      src={`https://manager.prisma-intl.com/storage/${datapartenaires[0].image}`}
      style={{ marginLeft: "95px",height:"50px" }}
    />
    <img
      alt="logo"
      src={`https://manager.prisma-intl.com/storage/${datapartenaires[1].image}`}
      style={{ marginLeft: "95px",height:"50px"}}
    />
    <img
      alt="logo"
      src={`https://manager.prisma-intl.com/storage/${datapartenaires[2].image}`}
      style={{ marginLeft: "95px",height:"50px" }}
    />
  </div>
 </SwiperSlide>
 
  ))}
           
          
          </Swiper>
        </Col>
      </Desktop>
      <Tablet>
        <Col>
          <div>
            <div className="titleprecence">
              <img alt="logo" src={Rectangle8} />

              <div style={{ marginLeft: "35px" }}>Nos Partenaires</div>
            </div>
          </div>
          <div style={{ marginTop: "150px", height: "200px" }}>
            <Carousel>
              <CarouselSlide>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {" "}
                  <img
                    alt="logo"
                    src={image28}
                    style={{ width: "115px", marginLeft: "50px" }}
                  />
                </div>
              </CarouselSlide>
              <CarouselSlide>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    alt="logo"
                    src={image38}
                    style={{ width: "115px", marginLeft: "50px" }}
                  />
                  <img
                    alt="logo"
                    src={image39}
                    style={{ width: "165px", marginLeft: "50px" }}
                  />
                  <img
                    alt="logo"
                    src={image40}
                    style={{ width: "115px", marginLeft: "50px" }}
                  />
                </div>
              </CarouselSlide>
            </Carousel>
          </div>
        </Col>
      </Tablet>
      <Mobile>
        <Col>
          <div>
            <div className="titleprecence">
              <img alt="logo" src={Rectangle8} />

              <div style={{ marginLeft: "35px" }}>Nos Partenaires</div>
            </div>
          </div>
          <div style={{ marginTop: "150px" }}>
            <Carousel cols={1} rows={1} gap={10} loop>
              <CarouselSlide>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    alt="logo"
                    src={image28}
                    style={{ width: "115px", marginLeft: "50px" }}
                  />
                  <img
                    alt="logo"
                    src={image35}
                    style={{ width: "115px", marginLeft: "50px" }}
                  />
                </div>
              </CarouselSlide>
              <CarouselSlide>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    alt="logo"
                    src={image36}
                    style={{ width: "115px", marginLeft: "50px" }}
                  />
                  <img
                    alt="logo"
                    src={image37}
                    style={{ width: "115px", marginLeft: "50px" }}
                  />
                </div>
              </CarouselSlide>
              <CarouselSlide>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    alt="logo"
                    src={image38}
                    style={{ width: "215px", marginLeft: "50px" }}
                  />
                </div>
              </CarouselSlide>
              <CarouselSlide>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    alt="logo"
                    src={image39}
                    style={{ width: "165px", marginLeft: "50px" }}
                  />
                  <img
                    alt="logo"
                    src={image40}
                    style={{ width: "115px", marginLeft: "50px" }}
                  />
                </div>
              </CarouselSlide>
            </Carousel>
          </div>
        </Col>
      </Mobile>
    </>
  );
};

export default Partenaires;
