import React, { useEffect, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import slider from "../../assets/Slider.png";
import slider2 from "../../assets/Slider2.png";
import slider3 from "../../assets/slider3.png";
import slider4 from "../../assets/slider4.png";
import slider5 from "../../assets/slider5.png";
import slider6 from "../../assets/slider6.png";
import slider7 from "../../assets/slider7.png";
import slider8 from "../../assets/slider8.png";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Group23 from "../../assets/Group23.png";
import Group24 from "../../assets/Group24.png";
import { Col, Form, Row } from "react-bootstrap";

import { Desktop, Mobile, Tablet } from "../../constant/AppConfig";

import { useTranslation } from "react-i18next";
import useAuth from "../../Api/hooks/useAuth";

const Slider = () => {
  const {postContact}=useAuth()
  const [t] = useTranslation("common");

  const [showcontact, setShowcontact] = useState(false);
  const [intervalle, setIntervalle] = useState(1000);

  const [data, setData] = useState({
    nom: "",
    email: "",
    telephone: "",
    position : "",
    ville: "",
    pays: "",
    societe: "",
    message: "",
    error: "",
    prenom: "",
  });
  useEffect(() => {
    setData(data);
  }, []);
  const handleClose = () => {
    setIntervalle(1000);
    setShowcontact(false);
  };
  const handleShow = (id) => {
    setIntervalle(null);
    setData({ ...data, position: id });
    setShowcontact(true);
  };
  const onChangeemail = (e) => {
    setData({ ...data, email: e.target.value });
  };
  const emailValidation = () => {
    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!data.email || regex.test(data.email) === false) {
      setData({
        ...data,
        error: "Email is not valid",
      });
      return false;
    }
    return true;
  };
  const onChangeNom = (e) => {
    setData({ ...data, nom: e.target.value });
  };
  const onChangePrenom = (e) => {
    setData({ ...data, prenom: e.target.value });
  };
  const onChangeTelephone = (e) => {
    setData({ ...data, telephone: e.target.value });
  };
  const onChangeMessage = (e) => {
    setData({ ...data, message: e.target.value });
  };
  const onSumbit = () => {
    if (emailValidation()) {
      setData({ ...data, email: data.email });
      postContact(data);
      setShowcontact(false);
      setData({
        nom: "",
        email: "",
        telephone: "",
        position : "",
        ville: "",
        pays: "",
        societe: "",
        message: "",
        error: "",
        prenom: "",
      });
    } else {
      console.log("email not send");
    }
  };
  return (
    <>
      <Desktop>
        <Carousel
          prevIcon={<img alt="logo" src={Group23} />}
          nextIcon={<img alt="logo" src={Group24} />}
          interval={intervalle}
        >
          <Carousel.Item>
            <img alt="logo" src={slider6} style={{ width: "100%" }} />

            <Carousel.Caption>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    fontSize: "50px",
                    fontWeight: "700",

                    fontFamily: "Tw Cen MT",
                  }}
                >
                  {t("slider.title1")}
                </div>
                <button
                  className="buttonslider"
                  style={{ marginTop: "0px" }}
                  onClick={() => handleShow("ARTP")}
                >
                  {t("slider.button")}
                </button>
              </div>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item>
            <img alt="logo" src={slider2} style={{ width: "100%" }} />

            <Carousel.Caption>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "space-evenly",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    fontSize: "50px",
                    fontWeight: "700",
                    width: "50%",
                    alignSelf: "flex-end",
                    fontFamily: "Tw Cen MT",
                    marginLeft: "20px",
                  }}
                >
                  {t("slider.title2")}
                </div>
                <button
                  className="buttonslider"
                  onClick={() => handleShow("Ooredoo Tunisie")}
                >
                  {t("slider.button")}
                </button>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              alt="logo"
              src={slider}
              style={{ width: "100%", height: "100%" }}
            />

            <Carousel.Caption>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    fontSize: "50px",
                    fontWeight: "700",

                    fontFamily: "Tw Cen MT",
                  }}
                >
                  {t("slider.title3")}
                </div>
                <div
                  className="buttonslider"
                  onClick={() => handleShow("Orange Tunisie ")}
                >
                  {t("slider.button")}
                </div>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img alt="logo" src={slider3} style={{ width: "100%" }} />

            <Carousel.Caption>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    fontSize: "50px",
                    fontWeight: "700",

                    fontFamily: "Tw Cen MT",
                  }}
                >
                  {t("slider.title4")}
                </div>
                <button
                  className="buttonslider"
                  style={{ marginTop: "70px" }}
                  onClick={() => handleShow("Orange Maroc")}
                >
                  {t("slider.button")}
                </button>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img alt="logo" src={slider4} style={{ width: "100%" }} />

            <Carousel.Caption>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    fontSize: "50px",
                    fontWeight: "700",

                    fontFamily: "Tw Cen MT",
                  }}
                >
                  {t("slider.title5")}{" "}
                </div>
                <button
                  className="buttonslider"
                  style={{ marginTop: "20px" }}
                  onClick={() => handleShow("Tunisie Télécom")}
                >
                  {t("slider.button")}
                </button>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img alt="logo" src={slider5} style={{ width: "100%" }} />

            <Carousel.Caption>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    fontSize: "50px",
                    fontWeight: "700",

                    fontFamily: "Tw Cen MT",
                  }}
                >
                  {t("slider.title6")}{" "}
                </div>
                <button
                  className="buttonslider"
                  onClick={() => handleShow("INTT")}
                >
                  {t("slider.button")}
                </button>
              </div>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item>
            <img alt="logo" src={slider7} style={{ width: "100%" }} />

            <Carousel.Caption>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    fontSize: "50px",
                    fontWeight: "700",

                    fontFamily: "Tw Cen MT",
                  }}
                >
                  {t("slider.title7")}{" "}
                </div>
                <button
                  className="buttonslider"
                  onClick={() => handleShow("ARCEP Bénin")}
                >
                  {t("slider.button")}
                </button>
              </div>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item>
            <img alt="logo" src={slider8} style={{ width: "100%" }} />

            <Carousel.Caption>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    fontSize: "50px",
                    fontWeight: "700",

                    fontFamily: "Tw Cen MT",
                  }}
                >
                  {t("slider.title8")}{" "}
                </div>
                <button
                  className="buttonslider"
                  onClick={() => handleShow("Crédit Airtime ")}
                >
                  {t("slider.button")}
                </button>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
        <Modal show={showcontact} onHide={handleClose}>
          <Modal.Header>
            <Modal.Title>
              <div className="titrecardrecrutement">CONTACT D'INFORMATION</div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div style={{ width: "100%" }}>
                <Form style={{ marginRight: "0px" }}>
                  <Row
                    style={{
                      marginTop: "57px",
                      marginLeft: "20px",
                      marginRight: "30px",
                    }}
                  >
                    <Form.Group as={Col}>
                      <input
                        type="text"
                        placeholder={t("Serivces.nom")}
                        style={{
                          borderTop: "none",
                          borderRight: "none",
                          borderLeft: "none",
                          borderRadius: "0px",
                          width: "100%",
                        }}
                        value={data.nom}
                        onChange={onChangeNom}
                      />
                    </Form.Group>
                    <Form.Group as={Col}>
                      <input
                        type="text"
                        placeholder={t("Serivces.prenom")}
                        style={{
                          borderTop: "none",
                          borderRight: "none",
                          borderLeft: "none",
                          width: "100%",
                        }}
                        value={data.prenom}
                        onChange={onChangePrenom}
                      />
                    </Form.Group>
                  </Row>

                  <Form.Group
                    style={{
                      marginTop: "36px",
                      marginRight: "30px",
                      marginLeft: "30px",
                    }}
                  >
                    <input
                      type="email"
                      placeholder={t("Serivces.email")}
                      style={{
                        borderTop: "none",
                        borderRight: "none",
                        borderLeft: "none",
                        borderColor: "gray",
                        width: "100%",
                      }}
                      value={data.email}
                      onChange={onChangeemail}
                    />
                    <span className="text-danger">{data.error}</span>
                  </Form.Group>
                  <Form.Group
                    style={{
                      marginTop: "36px",
                      marginRight: "30px",
                      marginLeft: "30px",
                    }}
                  >
                    <input
                      type="number"
                      placeholder={t("Serivces.telephone")}
                      style={{
                        borderTop: "none",
                        borderRight: "none",
                        borderLeft: "none",
                        width: "100%",
                      }}
                      value={data.telephone}
                      onChange={onChangeTelephone}
                    />
                  </Form.Group>

                  <Form.Group
                    style={{
                      marginTop: "36px",
                      marginRight: "30px",
                      marginLeft: "30px",
                    }}
                  >
                    <textarea
                      placeholder={t("Serivces.message")}
                      style={{
                        borderTop: "none",
                        borderRight: "none",
                        borderLeft: "none",
                        width: "100%",
                      }}
                      value={data.message}
                      onChange={onChangeMessage}
                    />{" "}
                  </Form.Group>
                </Form>{" "}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
            {t("Serivces.fermer")}
            </Button>
            <Button variant="primary" onClick={onSumbit}>
            {t("Serivces.envoyer")}
            </Button>
          </Modal.Footer>
        </Modal>
      </Desktop>
      <Tablet>
        <Carousel
          prevIcon={<img alt="logo" src={Group23} />}
          nextIcon={<img alt="logo" src={Group24} />}
          fade
        >
          <Carousel.Item>
            <img
              alt="logo"
              src={slider}
              style={{ width: "100%", height: "100%" }}
            />

            <Carousel.Caption>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    fontSize: "50px",
                    fontWeight: "700",

                    fontFamily: "Tw Cen MT",
                  }}
                >
                  Le régulateur ARCEP Bénin désigne PRISMA pour effectuer la
                  mission d'Audit des FAI
                </div>
                <button className="buttonslider">
                  <div className="textbuttonslider">Plus d’informations</div>
                </button>
              </div>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item>
            <img alt="logo" src={slider2} style={{ width: "100%" }} />

            <Carousel.Caption>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "space-evenly",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    fontSize: "50px",
                    fontWeight: "700",
                    width: "60%",
                    alignSelf: "flex-end",
                    fontFamily: "Tw Cen MT",
                    marginLeft: "20px",
                  }}
                >
                  PRISMA a réalisé des tests de charges pour le compte de
                  Ooredoo Tunisie
                </div>
                <button className="buttonslider">
                  <div className="textbuttonslider">Plus d’informations</div>
                </button>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img alt="logo" src={slider3} style={{ width: "100%" }} />

            <Carousel.Caption>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    fontSize: "50px",
                    fontWeight: "700",

                    fontFamily: "Tw Cen MT",
                  }}
                >
                  Depuis 2015 , PRISMA assiste Orange Maroc lors de
                  l'utilisation de DPI
                </div>
                <button className="buttonslider">
                  <div className="textbuttonslider">Plus d’informations</div>
                </button>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img alt="logo" src={slider4} style={{ width: "100%" }} />

            <Carousel.Caption>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    fontSize: "50px",
                    fontWeight: "700",

                    fontFamily: "Tw Cen MT",
                  }}
                >
                  PRISMA synchronise les réseaux de Tunisie Télécom{" "}
                </div>
                <button className="buttonslider">
                  <div className="textbuttonslider">Plus d’informations</div>
                </button>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img alt="logo" src={slider5} style={{ width: "100%" }} />

            <Carousel.Caption>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    fontSize: "50px",
                    fontWeight: "700",

                    fontFamily: "Tw Cen MT",
                  }}
                >
                  Depuis 2012 , PRISMA assiste l'INTT à mesurer la QOS Internet
                  mobile et fixe en Tunisie{" "}
                </div>
                <button className="buttonslider">
                  <div className="textbuttonslider">Plus d’informations</div>
                </button>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img alt="logo" src={slider6} style={{ width: "100%" }} />

            <Carousel.Caption>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    fontSize: "50px",
                    fontWeight: "700",

                    fontFamily: "Tw Cen MT",
                  }}
                >
                  En 2021, le régulateur sénégalais ARTP choisit PRISMA pour
                  réaliser un benchmark de la Qos entre les 3 opérateurs{" "}
                </div>
                <button className="buttonslider" style={{ marginTop: "-61px" }}>
                  <div className="textbuttonslider">Plus d’informations</div>
                </button>
              </div>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item>
            <img alt="logo" src={slider7} style={{ width: "100%" }} />

            <Carousel.Caption>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    fontSize: "50px",
                    fontWeight: "700",

                    fontFamily: "Tw Cen MT",
                  }}
                >
                  Le régulateur ARCEP Bénin désigne PRISMA pour effectuer la
                  mission d'Audit des FAI
                </div>
                <button className="buttonslider">
                  <div className="textbuttonslider">Plus d’informations</div>
                </button>
              </div>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item>
            <img alt="logo" src={slider8} style={{ width: "100%" }} />

            <Carousel.Caption>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    fontSize: "50px",
                    fontWeight: "700",

                    fontFamily: "Tw Cen MT",
                  }}
                >
                  Les services Crédit Airtime et Beep Call offerts par Mattel en
                  Mauritanie sont assurés par PRISMA
                </div>
                <button className="buttonslider">
                  <div className="textbuttonslider">Plus d’informations</div>
                </button>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </Tablet>
      <Mobile>
        <Carousel
          prevIcon={<img alt="logo" src={Group23} />}
          nextIcon={<img alt="logo" src={Group24} />}
          fade
        >
          <Carousel.Item>
            <img
              alt="logo"
              src={slider}
              style={{ width: "100%", height: "100%" }}
            />

            <Carousel.Caption>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    fontSize: "15px",
                    fontWeight: "700",
                    marginTop: "60px",
                    fontFamily: "Tw Cen MT",
                  }}
                >
                  Le régulateur ARCEP Bénin désigne PRISMA pour effectuer la
                  mission d'Audit des FAI
                </div>
                <button className="buttonslider">
                  <div className="textbuttonslider">Plus d’informations</div>
                </button>
              </div>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item>
            <img alt="logo" src={slider2} style={{ width: "100%" }} />

            <Carousel.Caption>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "space-evenly",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    fontSize: "15px",
                    fontWeight: "700",
                    marginTop: "60px",
                    width: "60%",
                    alignSelf: "flex-end",
                    fontFamily: "Tw Cen MT",
                    marginLeft: "20px",
                  }}
                >
                  PRISMA a réalisé des tests de charges pour le compte de
                  Ooredoo Tunisie
                </div>
                <button className="buttonslider">
                  <div className="textbuttonslider">Plus d’informations</div>
                </button>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img alt="logo" src={slider3} style={{ width: "100%" }} />

            <Carousel.Caption>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    fontSize: "15px",
                    fontWeight: "700",
                    marginTop: "60px",
                    fontFamily: "Tw Cen MT",
                  }}
                >
                  Depuis 2015 , PRISMA assiste Orange Maroc lors de
                  l'utilisation de DPI
                </div>
                <button className="buttonslider" style={{ marginTop: "48px" }}>
                  <div className="textbuttonslider">Plus d’informations</div>
                </button>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img alt="logo" src={slider4} style={{ width: "100%" }} />

            <Carousel.Caption>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    fontSize: "15px",
                    fontWeight: "700",
                    marginTop: "60px",
                    fontFamily: "Tw Cen MT",
                  }}
                >
                  PRISMA synchronise les réseaux de Tunisie Télécom{" "}
                </div>
                <button className="buttonslider" style={{ marginTop: "48px" }}>
                  <div className="textbuttonslider">Plus d’informations</div>
                </button>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img alt="logo" src={slider5} style={{ width: "100%" }} />

            <Carousel.Caption>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    fontSize: "15px",
                    fontWeight: "700",
                    marginTop: "60px",
                    fontFamily: "Tw Cen MT",
                  }}
                >
                  Depuis 2012 , PRISMA assiste l'INTT à mesurer la QOS Internet
                  mobile et fixe en Tunisie{" "}
                </div>
                <button className="buttonslider">
                  <div className="textbuttonslider">Plus d’informations</div>
                </button>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img alt="logo" src={slider6} style={{ width: "100%" }} />

            <Carousel.Caption>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    fontSize: "15px",
                    fontWeight: "700",
                    marginTop: "60px",
                    fontFamily: "Tw Cen MT",
                  }}
                >
                  En 2021, le régulateur sénégalais ARTP choisit PRISMA pour
                  réaliser un benchmark de la Qos entre les 3 opérateurs{" "}
                </div>
                <button className="buttonslider" style={{ marginTop: "25px" }}>
                  <div className="textbuttonslider">Plus d’informations</div>
                </button>
              </div>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item>
            <img alt="logo" src={slider7} style={{ width: "100%" }} />

            <Carousel.Caption>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    fontSize: "15px",
                    fontWeight: "700",
                    marginTop: "60px",
                    fontFamily: "Tw Cen MT",
                  }}
                >
                  Le régulateur ARCEP Bénin désigne PRISMA pour effectuer la
                  mission d'Audit des FAI
                </div>
                <button className="buttonslider">
                  <div className="textbuttonslider">Plus d’informations</div>
                </button>
              </div>
            </Carousel.Caption>
          </Carousel.Item>

          <Carousel.Item>
            <img alt="logo" src={slider8} style={{ width: "100%" }} />

            <Carousel.Caption>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    fontSize: "15px",
                    fontWeight: "700",
                    marginTop: "60px",
                    fontFamily: "Tw Cen MT",
                  }}
                >
                  Les services Crédit Airtime et Beep Call offerts par Mattel en
                  Mauritanie sont assurés par PRISMA
                </div>
                <button className="buttonslider">
                  <div className="textbuttonslider">Plus d’informations</div>
                </button>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </Mobile>
    </>
  );
};

export default Slider;
