import React, { useEffect, useState } from "react";
import "./Menubarone.css";
import Form from "react-bootstrap/Form";
import Dropdown from "react-bootstrap/Dropdown";
import FlagIcon from "./FlagIcon.js";
import searchbaricon from "../../assets/searchicon.png";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Desktop, Mobile, Tablet } from "../../constant/AppConfig";
import { useTranslation } from "react-i18next";
//REdux
import { useSelector, useDispatch } from "react-redux";
import {
  homeAction,
  notHomeAction,homeNavAction , ContactNavAction ,RecrutNavAction,
} from "../../features/navigationHome/navhomeSlice";


const Menubarone = ({ selectedCountry, setSelectedCountry }) => {
  const [countries] = useState([
    { code: "fr", title: "Fr" },
    { code: "gb", title: "En" },
  ]);
  const [toggleContents, setToggleContents] = useState("Select a country");
  const [t, i18n] = useTranslation("common");
     //redux
     const dispatch = useDispatch();
     const inHome = useSelector(homeNavAction);
     const contact = useSelector(ContactNavAction);
     const recrut = useSelector(RecrutNavAction);

  const navigate = useNavigate();
  useEffect(() => {
    console.log(toggleContents);
    
  }, []);
  return (
    <Container >
      <Desktop>
        <div
          className="buttonmenbarone" 
          onClick={() => {
           dispatch(contact);
            navigate("/contact");
          }}
        >
          <div
            className="textbuttonmenbarone"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            Contact
          </div>
        </div>
        <div
          className="buttonmenbarone2"
          onClick={() => {
            dispatch(recrut);
            navigate("/recurtement");
          }}
        >
          <div
            className="textbuttonmenbarone"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            Recrutement
          </div>
        </div>
        <Form className="country">
          <Dropdown
            onSelect={(eventKey) => {
              const { code } = countries.find(({ code }) => eventKey === code);

              setSelectedCountry(eventKey);
              setToggleContents(
                <>
                  <FlagIcon code={code} />{" "}
                </>
              );
            }}
          >
            <Dropdown.Toggle
              id="dropdown-flags"
              style={{
                width: 21.53,
                height: 21.53,
                backgroundColor: "transparent",
                border: "none",
              }}
            >
              <FlagIcon code={selectedCountry} />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                key={countries[0].code}
                eventKey={countries[0].code}
                onClick={() => i18n.changeLanguage("fr")}
              >
                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                  <FlagIcon code={countries[0].code} />
                  <div style={{ marginLeft: "10px" }}>
                    {countries[0].title}
                  </div>{" "}
                </div>
              </Dropdown.Item>
              <Dropdown.Item
                key={countries[1].code}
                eventKey={countries[1].code}
                onClick={() => i18n.changeLanguage("en")}
              >
                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                  <FlagIcon code={countries[1].code} />
                  <div style={{ marginLeft: "10px" }}>
                    {countries[1].title}
                  </div>{" "}
                </div>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Form>
      </Desktop>
      <Tablet>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <button
            className="buttonmenbarone"
            onClick={() => {
              dispatch(contact);
               navigate("/contact");
             }}
          >
            <div
              className="textbuttonmenbarone"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                width: "100%",
              }}
            >
              Contact
            </div>
          </button>
          <button
            className="buttonmenbarone2"
            onClick={() => {
              dispatch(recrut);
              navigate("/recurtement");
            }}
          >
            <div
              className="textbuttonmenbarone"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Recrutement
            </div>
          </button>
          <Form className="country">
            <Dropdown
              onSelect={(eventKey) => {
                const { code } = countries.find(
                  ({ code }) => eventKey === code
                );

                setSelectedCountry(eventKey);
                setToggleContents(
                  <>
                    <FlagIcon code={code} />{" "}
                  </>
                );
              }}
            >
              <Dropdown.Toggle
                id="dropdown-flags"
                style={{
                  width: 21.53,
                  height: 21.53,
                  backgroundColor: "transparent",
                  border: "none",
                }}
              >
                <FlagIcon code={countries[0].code} />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {countries &&
                  countries.map(({ code, title }) => (
                    <Dropdown.Item key={code} eventKey={code}>
                      <FlagIcon code={code} />{" "}
                    </Dropdown.Item>
                  ))}
              </Dropdown.Menu>
            </Dropdown>
          </Form>
          <img alt="logo" className="searchbar" src={searchbaricon} />
        </div>
      </Tablet>
      <Mobile>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <button
            className="buttonmenbarone"
            onClick={() => {
              dispatch(contact);
               navigate("/contact");
             }}
          >
            <div
              className="textbuttonmenbarone"
              style={{ display: "flex", justifyContent: "center" }}
            >
              Contact
            </div>
          </button>
          <button
            className="buttonmenbarone2"
            onClick={() => {
              dispatch(recrut);
              navigate("/recurtement");
            }}
          >
            <div
              className="textbuttonmenbarone"
              style={{ display: "flex", justifyContent: "center" }}
            >
              Recrutement
            </div>
          </button>
          <Form className="country">
            <Dropdown
              onSelect={(eventKey) => {
                const { code } = countries.find(
                  ({ code }) => eventKey === code
                );

                setSelectedCountry(eventKey);
                setToggleContents(
                  <>
                    <FlagIcon code={code} />{" "}
                  </>
                );
              }}
            >
              <Dropdown.Toggle
                id="dropdown-flags"
                style={{
                  width: 21.53,
                  height: 21.53,
                  backgroundColor: "transparent",
                  border: "none",
                }}
              >
                <FlagIcon code={countries[0].code} />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {countries &&
                  countries.map(({ code, title }) => (
                    <Dropdown.Item key={code} eventKey={code}>
                      <FlagIcon code={code} />{" "}
                    </Dropdown.Item>
                  ))}
              </Dropdown.Menu>
            </Dropdown>
          </Form>
          <img alt="logo" className="searchbar" src={searchbaricon} />
        </div>

        {/* <input className='searchbarinput'></input> */}
      </Mobile>
    </Container>
  );
};

export default Menubarone;
