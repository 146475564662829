import React from 'react'
import {
  Page,

  Document,
  StyleSheet
} from "@react-pdf/renderer";
import image14 from '../../assets/image14.png'

import { Col, Row } from 'react-bootstrap';
const styles = StyleSheet.create({
  page: {
    backgroundColor: "#ffffff"
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  movieContainer: {
    backgroundColor: "#f6f6f5",
    display: "flex",
    flexDirection: "row",
    padding: 5
  },
  movieDetails: {
    display: "flex",
    marginLeft: 5
  },
  movieTitle: {
    fontSize: 15,
    marginBottom: 10
  },
  movieOverview: {
    fontSize: 10
  },

  image: {
    height: 200,
    width: 150
  },
  subtitle: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    width: 150,
    alignItems: "center",
    marginBottom: 12
  },
  vote: {
    display: "flex",
    flexDirection: "row"
  },
  rating: {
    height: 10,
    width: 10
  },
  vote_text: {
    fontSize: 10
  },
  vote_pop: {
    fontSize: 10,
    padding: 2,
    backgroundColor: "#61C74F",
    color: "#fff"
  },
  vote_pop_text: {
    fontSize: 10,
    marginLeft: 4
  },
  overviewContainer: {
    minHeight: 110
  },
  detailsFooter: {
    display: "flex",
    flexDirection: "row"
  },
  lang: {
    fontSize: 8,
    fontWeight: 700
  },
  vote_average: {
    fontSize: 8,
    marginLeft: 4,
    fontWeight: "bold"
  }
});

export function  PdfDocument ()  {
  return (
    <Document>
    <Page style={styles.page}>
    <Row style={{marginTop:"87px",marginRight:"121px"}} id="SyncServer 80" >
        
       <Col >
   <div className="titlesolutionresauxbox">
   SyncServer 80
   </div>
    <div className='textsolutionresauxbox' style={{marginTop:"30px"}}>

    Le SyncServer S80 est une antenne GPS/GLONASS composée par un récepteur, un serveur NTP et une interface Power over Ethernet (PoE) qui s'intègre facilement dans l'infrastructure PoE existante pour être immédiatement la source d'horodatages précis, sécurisés et fiables pour tous les appareils connectés au réseau. Les systèmes de sécurité physique isolés du réseau en bénéficient car le serveur de temps réseau renforcé Stratum 1 est idéal pour la synchronisation temporelle des caméras de sécurité IP, des dispositifs de contrôle d'accès et des enregistreurs vidéo numériques/réseau.
    </div>

       </Col>
          
       
       </Row>
    </Page>
  </Document>
  )
}

