import React from "react";
import { Col, Row } from "react-bootstrap";
import Rectangle8 from "../../../assets/Rectangle8.svg";
import Card from "react-bootstrap/Card";
import CircularProgress from '@mui/material/CircularProgress';


import { useNavigate } from "react-router-dom";
import { Desktop, Mobile, Tablet } from "../../../constant/AppConfig";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import useAuth from "../../../Api/hooks/useAuth";
//REdux
import { useSelector, useDispatch } from "react-redux";
import {
  getSolutionsStatus,
  fetchSolutions,
  selectAllSolutions,
  getSolutionsError,
} from "../../../features/solutions/solutionsSlice";
const Solution = (props) => {
  //redux
  const dispatch = useDispatch();
  const solutionStatus = useSelector(getSolutionsStatus);
  const solutions = useSelector(selectAllSolutions);
  const error = useSelector(getSolutionsError);
  useEffect(() => {
    if (solutionStatus === "idle") {
      dispatch(fetchSolutions());
    }
  }, [solutionStatus, dispatch]);


  const { setSol, selectedCountry } = props;
  const navigate = useNavigate();
  const { datasolution, getsolution, getsolutionen } = useAuth();
  const [t] = useTranslation("common");
  const position = "solution";

  useEffect(() => { console.log(selectedCountry,"lang=============")
    if (selectedCountry === "fr") {
      getsolution();
      console.log(datasolution,"solutiondata")
    } else {
      getsolutionen();
    }
  }, [selectedCountry]);

  let cardToDisplay = "";
  let cardTabToDisplay = "";
  let cardMobileToDisplay = "";

  if (solutionStatus === "loading") {
    cardToDisplay = <CircularProgress/>;
  } 
  else if (solutionStatus === "succeeded") { 
    cardToDisplay = solutions.map((data,i) => (
      <>
      <Card key={i}>
                <Card.Img
                  variant="top"
                  src={`https://manager.prisma-intl.com/storage/${data.image}`}
                  style={{
                    width: "56px",
                    height: "56px",
                    marginTop: "26px",
                    marginLeft: "115px",
                  }}
                />
                <Card.Body
                  style={{
                    marginTop: "20px",
                  }}
                >
                  <Card.Text>
                    <p>{data.titre}</p>
                  </Card.Text>
                  <button className="buttoncardservice">
                    {" "}
                    <div
                      className="textbuttoncardservice"
                      onClick={() => {
                        // setSol(e.id);
                        navigate(`/solution/${data.id}`, {
                          state : {
                            id: data.id,
                          },
                        });
                      }}
                    >
                      {t("Voirs.title")}
                    </div>
                  </button>
                </Card.Body>
              </Card>
              </>
  ));
  } else if (solutionStatus === "failed") {
    cardToDisplay = <><CircularProgress/>{console.log(error)}</>;
  }

  return (
    <>
      <Desktop>
        <Col>
          <div>
            <img className="line" src={Rectangle8} alt="logo" />

            <div className="titleprecence">{t("Solutions.title")}</div>
          </div>
          <Row style={{ justifyContent: "center", marginTop: "0px" }}>
             {cardToDisplay}
          </Row>
        </Col>
      </Desktop>
      <Tablet>
        <Col>
          <div className="titleprecence">
            <img src={Rectangle8} alt="logo" />

            <div style={{ marginLeft: "35px" }}>Nos Solutions </div>
          </div>
          <Row style={{ justifyContent: "center", marginTop: "100px" }}>
          {cardToDisplay}
            {/* <Card>
              <Card.Img
                variant="top"
                src={AnalyseIcon}
                style={{
                  width: "36px",
                  height: "36px",
                  marginTop: "26px",
                  marginLeft: "31px",
                }}
              />
              <Card.Body>
                <Card.Text>
                  <p>Analyse, optimisation et monétisation du trafic - DPI</p>
                </Card.Text>
                <button className="buttoncardservice">
                  {" "}
                  <div
                    className="textbuttoncardservice"
                    onClick={() => {
                      setSol(7);
                      navigate("/solution", {
                        state: {
                          id: 7,
                        },
                      });
                    }}
                  >
                    Voir plus
                  </div>
                </button>
              </Card.Body>
            </Card>
            <Card>
              <Card.Img
                variant="top"
                src={synchrnoresauIcon}
                style={{
                  width: "36px",
                  height: "36px",
                  marginTop: "26px",
                  marginLeft: "31px",
                }}
              />
              <Card.Body>
                <Card.Text>
                  <p>Synchronisation des Réseaux</p>
                </Card.Text>
                <button className="buttoncardservice">
                  {" "}
                  <div
                    className="textbuttoncardservice"
                    onClick={() => {
                      setSol(1);
                      navigate("/solution", {
                        state: {
                          id: 1,
                        },
                      });
                    }}
                  >
                    Voir plus
                  </div>
                </button>
              </Card.Body>
            </Card>  */}

            {/* <Card>
              <Card.Img
                variant="top"
                src={TestIcon}
                style={{
                  width: "36px",
                  height: "36px",
                  marginTop: "26px",
                  marginLeft: "31px",
                }}
              />
              <Card.Body>
                <Card.Text>
                  <p>Tests de charge</p>
                </Card.Text>
                <button className="buttoncardservice">
                  {" "}
                  <div
                    className="textbuttoncardservice"
                    onClick={() => {
                      setSol(3);
                      navigate("/solution", {
                        state: {
                          id: 3,
                        },
                      });
                    }}
                  >
                    Voir plus
                  </div>
                </button>
              </Card.Body>
            </Card> */}
            {/* <Card>
              <Card.Img
                variant="top"
                src={OutilIcon}
                style={{
                  width: "36px",
                  height: "36px",
                  marginTop: "26px",
                  marginLeft: "31px",
                }}
              />
              <Card.Body>
                <Card.Text>
                  <p>Outils de mesures</p>
                </Card.Text>
                <button className="buttoncardservice">
                  {" "}
                  <div
                    className="textbuttoncardservice"
                    onClick={() => {
                      setSol(5);
                      navigate("/solution", {
                        state: {
                          id: 5,
                        },
                      });
                    }}
                  >
                    Voir plus
                  </div>
                </button>
              </Card.Body>
            </Card> */}

            {/* <Card>
              <Card.Img
                variant="top"
                src={MobileFinanceIcon}
                style={{
                  width: "36px",
                  height: "36px",
                  marginTop: "26px",
                  marginLeft: "31px",
                }}
              />
              <Card.Body>
                <Card.Text>
                  <p>Mobile Finance</p>
                </Card.Text>
                <button className="buttoncardservice">
                  {" "}
                  <div
                    className="textbuttoncardservice"
                    onClick={() => {
                      setSol(6);
                      navigate("/solution", {
                        state: {
                          id: 6,
                        },
                      });
                    }}
                  >
                    Voir plus
                  </div>
                </button>
              </Card.Body>
            </Card> */}
            {/* <Card>
              <Card.Img
                variant="top"
                src={CyberIcon}
                style={{
                  width: "36px",
                  height: "36px",
                  marginTop: "26px",
                  marginLeft: "31px",
                }}
              />
              <Card.Body>
                <Card.Text>
                  <p>Cyber Securité </p>
                </Card.Text>
                <button className="buttoncardservice">
                  {" "}
                  <div
                    className="textbuttoncardservice"
                    onClick={() => {
                      setSol(4);
                      navigate("/solution", {
                        state: {
                          id: 4,
                        },
                      });
                    }}
                  >
                    Voir plus
                  </div>
                </button>
              </Card.Body>
            </Card> */}
          </Row>
        </Col>
      </Tablet>
      <Mobile>
        <Col>
          <div className="titleprecence">
            <img src={Rectangle8} alt="logo" />
            Nos Solutions{" "}
          </div>

          <Row style={{ justifyContent: "center", marginTop: "100px" }}>
          {cardToDisplay}
          </Row>
        </Col>
      </Mobile>
    </>
  );
};

export default Solution;
