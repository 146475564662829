import { createSlice } from "@reduxjs/toolkit";

// Create the initial state of the counter
const initialState = {
    counter : 0 ,
    nav : "Boarding"
}
// Create the slice of the state
const counterSlice = createSlice({
    // The name of the slice
    name : 'counter',
    // The initial state of the slice
    initialState,
    // The reducers of the slice
    reducers : {
        // Increment the counter by 1 when the increment action is dispatched
        homeAction : (state) => {
            // Increment the counter by 1
            state.counter =  1;
        },
        // Decrement the counter by 1 when the decrement action is dispatched
        notHomeAction : (state) => {
            // Decrement the counter by 1
            state.counter = 0;
        }
    }
});

const navSlice = createSlice({
    // The name of the slice
    name : 'nav',
    // The initial state of the slice
    initialState,
    // The reducers of the slice
    reducers : {
        // Increment the counter by 1 when the increment action is dispatched
        homeNavAction : (state) => {
            // Increment the counter by 1
            state.nav =  "Boarding";
        },
        // Decrement the counter by 1 when the decrement action is dispatched
        ContactNavAction : (state) => {
            // Decrement the counter by 1
            state.nav = "Contact";
        },
        RecrutNavAction : (state) => {
            // Decrement the counter by 1
            state.nav = "Recrutement";
        },
        RefNavAction : (state) => {
            // Decrement the counter by 1
            state.nav = "Reference";
        },
        ActuNavAction : (state) => {
            // Decrement the counter by 1
            state.nav = "Actualités";
        },
        SolutionsNavAction : (state) => {
            // Decrement the counter by 1
            state.nav = "Solutions";
        },
        ServiceNavAction : (state) => {
            // Decrement the counter by 1
            state.nav = "Services";
        },
        AboutNavAction : (state) => {
            // Decrement the counter by 1
            state.nav = "About";
        },
    }
});
// Export the actions of the slice
export const {homeAction , notHomeAction } = counterSlice.actions;
export const {homeNavAction , ContactNavAction ,RefNavAction,AboutNavAction,RecrutNavAction,ActuNavAction,SolutionsNavAction,ServiceNavAction} = navSlice.actions;
// Export the reducer of the slicel

export default navSlice.reducer;