import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "react-bootstrap/Button";
import Bluesky from "../../assets/DS/Bluesky.pdf";
import TP4100 from "../../assets/DS/TP 4100.pdf";
import S600 from "../../assets/DS/S600.pdf";
import TimeCesium4400 from "../../assets/DS/TimeCesium4400.pdf";
import TP5000 from "../../assets/DS/TP 5000.pdf";
import SSU2000 from "../../assets/DS/SSU 2000.pdf";
import S80 from "../../assets/DS/S80.pdf";
import QUALIT from "../../assets/DS/QUALIT.pdf";
import IxLoad from "../../assets/DS/IxLoad.pdf";
import IxNetwork from "../../assets/DS/IxNetwork.pdf";
import BreakingPoint from "../../assets/DS/BreakingPoint.pdf";
import { useTranslation } from "react-i18next";
//new download pdf 
import axios from 'axios'
import fileDownload from 'js-file-download'

import bluesky from "../../assets/DS/Bluesky.pdf";

import { Col, Form, Row } from "react-bootstrap";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { PdfDocument } from "./Movie";
import { useState, useEffect , useReducer} from "react";
import useAuth from "../../Api/hooks/useAuth";

const style = {
  position: "absolute",

  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  height: 300,
  bgcolor: " rgba(255, 255, 255, 0.76)",
  borderRadius: "25.9015px",
  border: "none",
  backdropFilter: "blur(5px)",
  boxShadow: 24,
  p: 4,
};
const style1 = {
  position: "absolute",

  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  height: 600,
  bgcolor: " rgba(255, 255, 255, 0.76)",
  borderRadius: "25.9015px",
  border: "none",
  backdropFilter: "blur(5px)",
  boxShadow: 24,
  p: 4,
};
const Modal1 = (props) => {
  const { handleClose, open, opennew, setOpennew, nameficher,setOpen } = props;
  const {postContact}= useAuth()
  const [t] = useTranslation("common");
  const handleClosenew = () => {
    setOpennew(false);
  };
  
  //handle download pdf 
  const handleDownload = (url, filename) => {
  console.log('telecharger')
    axios.get(url, {
      responseType: 'blob',
    })
    .then((res) => {
      console.log('test res')
      fileDownload(res.data, filename)
    })
  }
//new onchange 
const [loading,setLoading] = useState(false);
const [inputValues, setInputValues] = useReducer(
  (state, newState) => ({ ...state, ...newState }),
  {nom: '', prenom: '',email:'',telephone:'',ville:'',pays:'',societe:'',message:'',position :"dataSheet",error:''}
);
const handleOnChange = event => {
  // console.log(props,'position')
  const { name, value } = event.target;
  setInputValues({ [name]: value });
  console.log(inputValues,"input")
};
  const emailValidation = () => {
    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!inputValues.email || regex.test(inputValues.email) === false) {
      setInputValues({
        ...inputValues,
        error: "Verfifier votre Email",
      });
      return false;
    }
    return true;
  };
  
  const onSumbit = (e) => {
    if (emailValidation()) {
      setInputValues({ ...inputValues, email: inputValues.email });
     postContact(inputValues);
      setInputValues({
    nom: "",
    email: "",
    telephone: "",
    ville: "",
    pays: "",
    societe: "",
    message: "",
    error: "",
    prenom: "",
      })
    } else {
      alert("Verifier votre email");
    }
  };
  const onButtonClick = () => {
    if (emailValidation()) {
      setInputValues({ ...inputValues, email: inputValues.email,position :"Tp 4100" });
      postContact(inputValues);
      fetch('Tp 4100.pdf').then(response => {
        response.blob().then(blob => {
            // Creating new object of PDF file
            const fileURL = window.URL.createObjectURL(blob);
            // Setting various property values
            let alink = document.createElement('a');
            alink.href = TP4100;
            alink.download = 'Tp 4100.pdf';
            alink.click();
        })
       
        setInputValues({
          nom:"",email:"",prenom:"",position:""
        })
        setOpen(false)
        
    })
    } else {
      alert("email not send");
    }
    // using Java Script method to get PDF file
   
}
const onButtonClick1= () => {
  // using Java Script method to get PDF file
  
  if (emailValidation()) {
    setInputValues({ ...inputValues, email: inputValues.email ,position:'S600'});
      postContact(inputValues);
      handleDownload()
    fetch('S600.pdf').then(response => {
      response.blob().then(blob => {
          // Creating new object of PDF file
          const fileURL = window.URL.createObjectURL(blob);
          // Setting various property values
          let alink = document.createElement('a');
          alink.href = S600;
          alink.download = 'S600.pdf';
          alink.click();
      })
      setInputValues({
        nom:"",email:"",prenom:""
      })
      setOpen(false)
  })
  } else {
    alert("email not send");
  }
}
const onButtonClick2= () => {
   
  if (emailValidation()) {
    setInputValues({ ...inputValues, email: inputValues.email ,position:"TimeCesium4400.pdf"});
      postContact(inputValues);
     // using Java Script method to get PDF file
  fetch('TimeCesium4400.pdf').then(response => {
    response.blob().then(blob => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement('a');
        alink.href = TimeCesium4400;
        alink.download = 'TimeCesium4400.pdf';
        alink.click();
    })
    setInputValues({
      nom:"",email:"",prenom:""
    })
    setOpen(false)
})
  } else {
    alert("email not send");
  }

}
const onButtonClick3= () => {
  if (emailValidation()) {
    setInputValues({ ...inputValues, email: inputValues.email ,position: 'TP 5000.pdf'});
     postContact(inputValues);
    fetch('TP 5000.pdf').then(response => {
      response.blob().then(blob => {
          // Creating new object of PDF file
          const fileURL = window.URL.createObjectURL(blob);
          // Setting various property values
          let alink = document.createElement('a');
          alink.href = TP5000;
          alink.download = 'TP 5000.pdf';
          alink.click();
      })
      setInputValues({
        nom:"",email:"",prenom:""
      })
      setOpen(false)
  })
 } else {
   alert("email not send");
 }
  // using Java Script method to get PDF file

}
const onButtonClick4= () => {
  if (emailValidation()) {
    setInputValues({ ...inputValues, email: inputValues.email ,position:"SSU 2000"});
     postContact(inputValues);
    // using Java Script method to get PDF file
  fetch('SSU 2000.pdf').then(response => {
    response.blob().then(blob => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement('a');
        alink.href = SSU2000;
        alink.download = 'SSU 2000.pdf';
        alink.click();
    })
    setInputValues({
      nom:"",email:"",prenom:""
    })
    setOpen(false)
})
 } else {
   alert("email not send");
 }
 
}
const onButtonClick5= () => {
  if (emailValidation()) {
    setInputValues({ ...inputValues, email: inputValues.email,position:"BlueSky GNSS Firewall" });
      postContact(inputValues);
  // using Java Script method to get PDF file
  fetch('BlueSky GNSS Firewall.pdf').then(response => {
    response.blob().then(blob => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement('a');
        alink.href = bluesky;
        alink.download = 'BlueSky GNSS Firewall.pdf';
        alink.click();
    })
    setInputValues({
      nom:"",email:"",prenom:""
    })
    setOpen(false)
})
 } else {
   alert("email not send");
 }
  
}
const onButtonClick6= () => {
  // using Java Script method to get PDF file

  if (emailValidation()) {
    setInputValues({ ...inputValues, email: inputValues.email ,position:"SyncServer 80"});
    postContact(inputValues);
    fetch('SyncServer 80.pdf').then(response => {
      response.blob().then(blob => {
          // Creating new object of PDF file
          const fileURL = window.URL.createObjectURL(blob);
          // Setting various property values
          let alink = document.createElement('a');
          alink.href = S80;
          alink.download = 'SyncServer 80.pdf';
          alink.click();
      })

      setInputValues({
        nom:"",email:"",prenom:""
      })
      setOpen(false)
  })
   } else {
     alert("email not send");
   }
}
const onButtonClick7= () => {
  // using Java Script method to get PDF file

  if (emailValidation()) {
    setInputValues({ ...inputValues, email: inputValues.email ,position:"QUALIT"});
    postContact(inputValues);
    // let url = "https://manager.prisma-intl.com/storage/description-solutions/February2023/ZVGCz2kTGGgnMAsFFkXZ.pdf"; 
    // let filename= "QUALIT - FR.pdf";
    // handleDownload(url,filename)
    // setInputValues({nom:"",email:"",prenom:""}); setOpen(false)
    fetch('QUALIT.pdf').then(response => {
      response.blob().then(blob => {
          // Creating new object of PDF file
          const fileURL = window.URL.createObjectURL(blob);
          // Setting various property values
          let alink = document.createElement('a');
          alink.href = QUALIT;
          alink.download = 'QUALIT.pdf';
          alink.click();
      })

      setInputValues({
        nom:"",email:"",prenom:""
      })
      setOpen(false)
  })
   } else {
     alert("email not send");
   }
}
const onButtonClick8= () => {
  // using Java Script method to get PDF file

  if (emailValidation()) {
    setInputValues({ ...inputValues, email: inputValues.email,position:"IxLoad" });
    postContact(inputValues);
    fetch('IxLoad.pdf').then(response => {
      response.blob().then(blob => {
          // Creating new object of PDF file
          const fileURL = window.URL.createObjectURL(blob);
          // Setting various property values
          let alink = document.createElement('a');
          alink.href = IxLoad;
          alink.download = 'IxLoad.pdf';
          alink.click();
      })

      setInputValues({
        nom:"",email:"",prenom:""
      })
      setOpen(false)
  })
   } else {
     alert("email not send");
   }
}

const onButtonClick9= () => {
  // using Java Script method to get PDF file

  if (emailValidation()) {
    setInputValues({ ...inputValues, email: inputValues.email ,position:"IxNetwork"});
    postContact(inputValues);
    fetch('IxNetwork.pdf').then(response => {
      response.blob().then(blob => {
          // Creating new object of PDF file
          const fileURL = window.URL.createObjectURL(blob);
          // Setting various property values
          let alink = document.createElement('a');
          alink.href = IxNetwork;
          alink.download = 'IxNetwork.pdf';
          alink.click();
      })

      setInputValues({
        nom:"",email:"",prenom:""
      })
      setOpen(false)
  })
   } else {
     alert("email not send");
   }
}
const onButtonClick10= () => {
  // using Java Script method to get PDF file

  if (emailValidation()) {
    setInputValues({ ...inputValues, email: inputValues.email ,position:"BreakingPoint"});
    postContact(inputValues);
    fetch('BreakingPoint.pdf').then(response => {
      response.blob().then(blob => {
          // Creating new object of PDF file
          const fileURL = window.URL.createObjectURL(blob);
          // Setting various property values
          let alink = document.createElement('a');
          alink.href = BreakingPoint;
          alink.download = 'BreakingPoint.pdf';
          alink.click();
      })

      setInputValues({
        nom:"",email:"",prenom:""
      })
      setOpen(false)
  })
   } else {
     alert("email not send");
   }
}
  return (
    <div>
      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div style={{ width: "100%" }}>
                <Form style={{ marginRight: "0px" }}>
                  <Row
                    style={{
                      marginTop: "57px",
                      marginLeft: "20px",
                      marginRight: "30px",
                    }}
                  >
                    <Form.Group as={Col}>
                      <input
                        type="text"
                        name= "nom"
                        placeholder={t("Serivces.nom")}
                        style={{
                          borderTop: "none",
                          borderRight: "none",
                          borderLeft: "none",
                          borderRadius: "0px",
                          width: "100%",
                        }}
                        value={inputValues.nom}
                        onChange={handleOnChange}
                      />
                    </Form.Group>
                    <Form.Group as={Col}>
                      <input
                        type="text"
                        name= "prenom"
                        placeholder="Prénom"
                        style={{
                          borderTop: "none",
                          borderRight: "none",
                          borderLeft: "none",
                          width: "100%",
                        }}
                        value={inputValues.prenom}
                        onChange={handleOnChange}
                      />
                    </Form.Group>
                  </Row>

                  <Form.Group
                    style={{
                      marginTop: "36px",
                      marginRight: "30px",
                      marginLeft: "30px",
                    }}
                  >
                    <input
                      type="email"
                      name= "email"
                      placeholder="Email"
                      style={{
                        borderTop: "none",
                        borderRight: "none",
                        borderLeft: "none",
                        borderColor: "gray",
                        width: "100%",
                      }}
                      value={inputValues.email}
                      onChange={handleOnChange}
                    />
                    <span className="text-danger">{inputValues.error}</span>
                  </Form.Group>

                  <Form.Group
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "350px",
                      marginLeft: "50px",
                      marginTop: "50px",
                    }}
                  >
                    <Button variant="secondary" onClick={handleClose}>
                      {" "}
                      <div className="textbuttoncardservice">Fermer​</div>
                    </Button>
                    {inputValues.email !== "" &&
                    
                    inputValues.nom !== "" &&
                    inputValues.prenom !== "" ? (
                      <Button variant="primary" onClick={nameficher ==="TP 4100" ? onButtonClick :nameficher ==="S600/S650" ? onButtonClick1:nameficher ==="TimeCesium 4400" ? onButtonClick2 : nameficher==="TP 5000" ?onButtonClick3:nameficher ==="SSU 2000" ? onButtonClick4 : nameficher==="BlueSky GNSS Firewall" ?onButtonClick5 :nameficher==="S80" ?onButtonClick6:nameficher ==="QUALIT" ? onButtonClick7 :nameficher==="IxLoad"?onButtonClick8:nameficher==="IxNetwork"?onButtonClick9:nameficher==="BreakingPoint"&&onButtonClick10 }>
                      {" "}
                      <div className="textbuttoncardservice">Téléchargez</div>
                    </Button>
                    ) : (
                      <Button variant="primary" disabled>
                        {" "}
                        <div className="textbuttoncardservice">Téléchargez</div>
                      </Button>
                    )}
                  </Form.Group>
                </Form>{" "}
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <Modal
        keepMounted
        open={opennew}
        onClose={handleClosenew}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style1}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div style={{ width: "100%" }}>
                <Form style={{ marginRight: "0px" }}>
                  <Form.Group
                    style={{
                      marginTop: "36px",
                      marginRight: "30px",
                      marginLeft: "30px",
                    }}
                  >
                    <div className="titrecardrecrutement">CONTACT</div>
                  </Form.Group>
                  <Row
                    style={{
                      marginTop: "57px",
                      marginLeft: "20px",
                      marginRight: "30px",
                    }}
                  >
                    <Form.Group as={Col}>
                      <input
                        type="text"
                        placeholder="Nom"
                        style={{
                          borderTop: "none",
                          borderRight: "none",
                          borderLeft: "none",
                          borderRadius: "0px",
                          width: "100%",
                        }}
                        value={inputValues.nom}
                        onChange={handleOnChange}
                      />
                    </Form.Group>
                    <Form.Group as={Col}>
                      <input
                        type="text"
                        name="prenom"
                        placeholder={t("Serivces.prenom")}
                        style={{
                          borderTop: "none",
                          borderRight: "none",
                          borderLeft: "none",
                          width: "100%",
                        }}
                        value={inputValues.prenom}
                        onChange={handleOnChange}
                      />
                    </Form.Group>
                  </Row>

                  <Form.Group
                    style={{
                      marginTop: "36px",
                      marginRight: "30px",
                      marginLeft: "30px",
                    }}
                  >
                    <input
                      type="email"
                      placeholder="Email"
                      style={{
                        borderTop: "none",
                        borderRight: "none",
                        borderLeft: "none",
                        borderColor: "gray",
                        width: "100%",
                      }}
                      value={inputValues.email}
                      onChange={handleOnChange}
                    />
                    <span className="text-danger">{inputValues.error}</span>
                  </Form.Group>
                  <Form.Group
                    style={{
                      marginTop: "36px",
                      marginRight: "30px",
                      marginLeft: "30px",
                    }}
                  >
                    <input
                     type="text"
                      placeholder="Société"
                      style={{
                        borderTop: "none",
                        borderRight: "none",
                        borderLeft: "none",
                        borderColor: "gray",
                        width: "100%",
                      }}
                      value={inputValues.societe}
                      onChange={handleOnChange}
                    />
                    {/* <span className="text-danger">{inputValues.error}</span> */}
                  </Form.Group>
                  <Form.Group
                    style={{
                      marginTop: "36px",
                      marginRight: "30px",
                      marginLeft: "30px",
                    }}
                  >
                    <input
                      type="number"
                      placeholder="Téléphone"
                      style={{
                        borderTop: "none",
                        borderRight: "none",
                        borderLeft: "none",
                        width: "100%",
                      }}
                      value={inputValues.telephone}
                      onChange={handleOnChange}
                    />
                  </Form.Group>

                  <Form.Group
                    style={{
                      marginTop: "36px",
                      marginRight: "30px",
                      marginLeft: "30px",
                    }}
                  >
                    <textarea
                      placeholder="Message"
                      style={{
                        borderTop: "none",
                        borderRight: "none",
                        borderLeft: "none",
                        width: "100%",
                      }}
                      value={inputValues.message}
                      onChange={handleOnChange}
                    />{" "}
                  </Form.Group>
                  <Form.Group
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "200px",
                      }}
                    >
                      <Button variant="secondary" onClick={handleClosenew}>
                        Fermer​
                      </Button>
                      <Button variant="primary" onClick={onSumbit}>
                        Envoyer​
                      </Button>
                    </div>
                  </Form.Group>
                </Form>{" "}
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default Modal1;
