import React, { useEffect, useState, useReducer , useRef} from "react";
import { Col, Form, Row } from "react-bootstrap";

// import { loadReCaptcha } from "react-recaptcha-google";
import ReCAPTCHA from "react-google-recaptcha";
//Countries 
import countries from "i18n-iso-countries" ;
import enLocal from "i18n-iso-countries/langs/en.json";
import "./Contact.css";
//Phone Input React
import ReactPhoneInput from 'react-phone-input-material-ui';
import { TextField, withStyles } from '@material-ui/core';

import Rectangle158 from "../../assets/Rectangle158.png";
import Rectangle13 from "../../assets/Rectangle13.png";
import Rectangle27 from "../../assets/Rectangle27.png";
import LoadingButton from "@mui/lab/LoadingButton";
import { useTranslation } from "react-i18next";
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Marker,
  InfoWindow,
} from "react-google-maps";
import parkData from "./skateboard-parks.json";
import mapStyles from "./mapStyles";
import "./Contact.css";
import Footer from "../../Component/Footer/Footer";
import { Desktop, Mobile, Tablet } from "../../constant/AppConfig";
import useAuth from "../../Api/hooks/useAuth";
import { Select,MenuItem } from "@mui/material";
import PhoneField from './PhoneField';

const FormulaireC = (props) => {
  var captchaT ;
  const { postContact } = useAuth();
  const recaptchaRef = useRef();
  const [t] = useTranslation("common");
  const [loading, setLoading] = useState(false);
  const [disabled,setDisabled] = useState(true);
  const [disableCapt,setDisableCapt] = useState(false);
  const [error,setError] = useState('');
  const [selectedCountry,setSelectedCountry]= useState('fr');
//Window width check 
const [windowWidth, setWindowWidth] = useState(window.innerWidth);
const  selectedCountryHandler = (value) => setSelectedCountry(value);
useEffect(() => {
  const handleWindowResize = () => {
    setWindowWidth(window.innerWidth);
  };

  window.addEventListener('resize', handleWindowResize);

  return () => {
    window.removeEventListener('resize', handleWindowResize);
  };
});


  const [inputValues, setInputValues] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      nom: "",
      prenom: "",
      email: "",
      telephone: "",
      ville: "",
      pays: "",
      societe: "",
      message: "",
      position: props.position,
    }
  );
  const handleOnChange = (event) => {
    // console.log(props, "position");
    const { name, value } = event.target;
    if (name === "email" ){
      if (!isValidEmail(value)) {
        setError("Email is invalid");
      } else { 
        setError(null);
        setDisabled(false);
      }
      setInputValues({email: value});
    }
    setInputValues({ [name]: value });
    console.log(inputValues, "input");
  };
  const  onChange =(value) => {
    setInputValues({'telephone':value})
  }
  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  useEffect(() => {
    generate();
    // console.log("===========window width",countryArr)
  },[])
  function generate() {

    // Clear old input
    document.getElementById("submit").value = "";
 
    // Access the element to store
    // the generated captcha
   var   captcha = document.getElementById("image");
    var uniquechar = "";
  
    const randomchar =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  
    // Generate captcha for length of
    // 5 with random character
    for (let i = 1; i < 5; i++) {
      uniquechar += randomchar.charAt(
        Math.random() * randomchar.length)
    }
  
    // Store generated input
    captcha.innerHTML= uniquechar; ;
    captchaT = uniquechar ;
  }
  
   function printmsg() {
    if (document.getElementById("submit") != null){
      const usrInput = document.getElementById("submit").value;
    
     
     
     console.log(usrInput,'prinettted==============',captchaT)
    // Check whether the input is equal
    // to generated captcha or not
    if(document.getElementById("key") != null  ){
    if (usrInput == captchaT) {
      var s = document.getElementById("key")
      s.innerHTML = "Matched";
      setDisableCapt(true);
      setDisabled(false);
      generate();
    }
    else {
      var s = document.getElementById("key")
        s.innerHTML = "not Matched";
      generate();
    }}}
  }
  

  //coutries get all 
  countries.registerLocale(enLocal);
  // Returns an object not a list 
const countryObj = countries.getNames("en",{select:"official"});
const countryArr = Object.entries(countryObj).map(([key,value])=>{
  return {
    label : value ,
    value : key
  }
});



  return (
    <>
      <Row>
        <Col>
          <div>
            <div style={{ marginTop: "100px" }}>
              <img
                alt="logo"
                className="lineserviceconsulting"
                src={Rectangle13}
              />
            </div>
            <div className="title2serviceconsulting">Contact</div>
          </div>
          <Form style={{ margin :"50px"
            // marginLeft: "106px"
             }}>
            <Form.Group
              style={{
                marginTop: "36px",
                marginRight: "30px",
                display: "flex",
                flexDirection: "col",
              }}
            >
              <input
                type="text"
                name="nom"
                value={inputValues.nom}
                placeholder={t("Serivces.nom")}
                required
                style={{
                  borderTop: "none",
                  borderRight: "none",
                  borderLeft: "none",
                  width: "100%",
                }}
                onChange={handleOnChange}
              />{" "}
              {/* </Form.Group> */}
              {/* <Form.Group class="col-sm-6" style={{ marginTop: "36px", marginRight: "30px" }}> */}
              <input
                type="text"
                name="prenom"
                required
                value={inputValues.prenom}
                placeholder={t("Serivces.prenom")}
                style={{
                  borderTop: "none",
                  borderRight: "none",
                  borderLeft: "none",
                  width: "100%",
                }}
                onChange={handleOnChange}
              />{" "}
            </Form.Group>
            <Form.Group style={{ marginTop: "36px", marginRight: "30px" }}>
              <input
                type="email"
                name="email"
                required
                placeholder={t("Serivces.email")}
                value={inputValues.email}
                style={{
                  borderTop: "none",
                  borderRight: "none",
                  borderLeft: "none",
                  borderColor: "gray",
                  width: "100%",
                }}
                onChange={handleOnChange}
              />
            </Form.Group>{error}
            <Form.Group style={{ marginTop: "36px", marginRight: "30px" }}>
              {/* <input
                type="number"
                name="telephone"
                required
                value={inputValues.telephone}
                placeholder={t("Serivces.telephone")}
                style={{
                  borderTop: "none",
                  borderRight: "none",
                  borderLeft: "none",
                  width: "100%",
                }}
                onChange={handleOnChange}
              /> */}
              <React.Fragment>
                <ReactPhoneInput
       name='telephone'
        value={inputValues.telephone}
        defaultCountry={selectedCountry || 'fr'}
        onChange={onChange} 
        placeholder={t("Serivces.telephone")}
        // inputClass={classes.field}
        // dropdownClass={classes.countryList}
        component={TextField}
      />
    </React.Fragment>
            </Form.Group>
            {/* <div style={{ marginTop: "36px", marginRight: "30px" }}> */}
              {/* <input
                type="text"
                name="ville"
                value={inputValues.ville}
                placeholder="Villes"
                style={{
                  borderTop: "none",
                  borderRight: "none",
                  borderLeft: "none",
                  width: "100%",
                }}
                onChange={handleOnChange}
              />{" "} */}
             
            {/* </div> */}
            <Form.Group style={{ marginTop: "36px", marginRight: "30px" ,      display: "flex",
                flexDirection: "col",}}>
              {/* <input
                type="text"
                name="pays"
                value={inputValues.pays}
                placeholder="Pays"
                style={{
                  borderTop: "none",
                  borderRight: "none",
                  borderLeft: "none",
                  width: "100%",
                }}
                onChange={handleOnChange}
              />{" "} */}
              <div style={{color:"#152171",fontSize:18,margin:"10px"}}>{t("Serivces.pays")}</div>
               <Select style={{width : "200px"}}
                placeholder={t("Serivces.pays")}
               name='pays'
              value = {inputValues.pays}
              onChange={handleOnChange}
              >
               {!!countryArr.length && 
               countryArr.map(({label,value})=>(
                <MenuItem key={value} value={label}>{label}</MenuItem>
               )
                
               )}
              </Select>
            </Form.Group>
            <Form.Group style={{ marginTop: "36px", marginRight: "30px" }}>
              <input
                type="text"
                name="societe"
                required
                value={inputValues.societe}
                placeholder={t("Serivces.société")}
                style={{
                  borderTop: "none",
                  borderRight: "none",
                  borderLeft: "none",
                  width: "100%",
                }}
                onChange={handleOnChange}
              />{" "}
            </Form.Group>
            <Form.Group style={{ marginTop: "36px", marginRight: "30px" }}>
              <textarea
                value={inputValues.message}
                name="message"
                required
                placeholder={t("Serivces.message")}
                style={{
                  borderTop: "none",
                  borderRight: "none",
                  borderLeft: "none",
                  width: "100%",
                }}
                onChange={handleOnChange}
              />{" "}
            </Form.Group>
          </Form>
          
      <div style={{marginLeft:windowWidth>1090 ? windowWidth/2 : windowWidth/3}}>
      <div id="user-input" className="inline" style={{borderRadius:"8px"}}>
        <input type="text" id="submit"
            placeholder="Captcha code" />
    </div>
    {disabled && <div className="inline" onClick={()=>generate()}>
        <div className="fas fa-sync" style={{padding:'4px'}}>Generate</div>
    </div>}
    
 
     <div id="image" className="inline" selectable="False" style={{borderRadius:"8px"}}> 
    </div> <br></br>
    <input type="submit" id="btn" value="I am not Robot"onClick={()=>  printmsg()} disabled={disableCapt}></input>
 
    <p id="key" style={{borderRadius:"8px"}}></p>
    </div>
          <LoadingButton
            className="buttonsubmitservice"
            variant="outlined"
            loading={loading}
            disabled={disabled}
            loadingIndicator="En cours"
            onClick={() => {
              console.log("post");
            
              setLoading(true);
              postContact(inputValues);
              setTimeout(() => {
                setLoading(false);
                setInputValues({
                  nom: "",
                  prenom: "",
                  email: "",
                  telephone: "",
                  ville: "",
                  pays: "",
                  societe: "",
                  message: "",
                  position: props.position,
                });
              }, 2000);
            }}
            style={{
              marginTop: "37px",
              marginLeft: "55%",
              variant: "outlined",
            }}
          >
           { t("Serivces.envoyer")}
          </LoadingButton>
        </Col>
      </Row>
    </>
  );
};
export default FormulaireC;
