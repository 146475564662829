import React ,{useState,useEffect} from "react";
import { Col, Row } from "react-bootstrap";
import logo from "../../assets/Layer2.png";
import iconinput from "../../assets/iconinput.png";
import footerline from "../../assets/footerline.png";
import IconFacebook from "../../assets/IconFacebook.png";
import textfooter from "../../assets/textfooter.png";
import { useTranslation } from "react-i18next";
import IconInstgrame from "../../assets/IconInstgrame.png";
import validator from "validator";
import "./Footer.css";
import CircularProgress from '@mui/material/CircularProgress';

//REdux
import { useSelector, useDispatch } from "react-redux";
import {
  homeAction,
  notHomeAction,homeNavAction , ContactNavAction ,RefNavAction,RecrutNavAction,ActuNavAction,AboutNavAction
} from "../../features/navigationHome/navhomeSlice";

import {
  getServicesStatus,
  fetchServices,
  selectAllServices,
  getServicesError,
} from "../../features/services/servicesSlice";



import { useNavigate } from "react-router-dom";
import { Desktop, Mobile, Tablet } from "../../constant/AppConfig";
import useAuth from "../../Api/hooks/useAuth";


const Footer = (props) => {
  const navigate = useNavigate();
  const { data , postEmail} = useAuth()
  const [email,setEmail] = useState("");
  const [t] = useTranslation("common");
      //redux
      const dispatch = useDispatch();
      const inHome = useSelector(homeNavAction);
      const contact = useSelector(ContactNavAction);
      const ref = useSelector(RefNavAction);
      const actu = useSelector(ActuNavAction);
      const about = useSelector(AboutNavAction);
      const pos = useSelector(state => state.nav);
  //validation Email 
  const [message, setMessage] = useState("");
  const validateEmail = (e) => {
    var email = e.target.value;

    if (validator.isEmail(email)) { 
    postEmail(email);
      setMessage("Thank you");
    } else {
      setMessage("Please, enter valid Email!");
    }
  };

  //Redux Services
const serviceStatus = useSelector(getServicesStatus);
const services = useSelector(selectAllServices);
const error = useSelector(getServicesError);

useEffect(() => {
  if (serviceStatus === "idle") {
    dispatch(fetchServices());
  }
}, [serviceStatus, dispatch]);


let cardToDisplay = "";
let cardTabToDisplay = "";
let cardMobileToDisplay = "";

if (serviceStatus === "loading") {
  cardToDisplay = <CircularProgress/>;
} 
else if (serviceStatus === "succeeded") { 
  cardToDisplay = 
    <>


          <div
            className="footertext2"
            style={{ marginTop: "37.5px", width: "188px" }}
            onClick={() => {
             
              navigate("/serviceconsulting/1")
            }}
          >
            {services[0] && services[0].nom}{" "}
          </div>
          <div
            className="footertext2"
            style={{ marginTop: "13px" }}
            onClick={() => {
             
              navigate("/serviceconsulting/2", {
            
              });
            }}
          >
            {services[1] && services[1].nom}{" "}
          </div>
          <div
            className="footertext2"
            style={{ marginTop: "13px" }}
            onClick={() => {
           
              navigate("/serviceconsulting/3");
            }}
          >
            {services[2] && services[2].nom}
          </div>
          <div
            className="footertext2"
            style={{ marginTop: "18px" }}
            onClick={() => {
             
              navigate("/serviceconsulting/4", {
            
              });
            }}
          >
            {services[3] && services[3].nom}
          </div>
          <div
            className="footertext2"
            style={{ marginTop: "13px" }}
            onClick={() => {
            
              navigate("/serviceconsulting/5", {
             
              });
            }}
          >
           {services[4] && services[4].nom}
          </div>
         

            </>
;
} else if (serviceStatus === "failed") {
  cardToDisplay = <p style={{alignContent:"center"}}>{error}</p>;
}


  return (
    <Row style={{ diplay: "flex", justifyContent: "center" }}>
      <Desktop>
        <Col style={{marginTop:"4px"}}>
          <div>
            <img
              alt="logo"
              src={logo}
              style={{ marginTop: "50px", marginLeft: "252px" ,width:"150px"}}
            />{" "}
          </div>
          <div>
            <a>
              <img
                alt="logo"
                src={IconFacebook}
                href=""
                style={{
                  marginLeft: "302px",
                  marginTop: "16px",
                  cursor: "pointer",
                }}
              />{" "}
            </a>
            <a href="https://www.linkedin.com/company/prisma-intl/ ">
              <img
                alt="logo"
                src={IconInstgrame}
                style={{
                  marginLeft: "42px",
                  marginTop: "16px",
                  cursor: "pointer",
                }}
              />{" "}
            </a>
          </div>
{/* // Pages  */}
          <div className="footertitre1" >Pages</div>
          <div
            className="footertext1"
            style={{ marginTop: "37.5px" }}
            onClick={() => {
              dispatch(inHome);
              navigate("/");
              window.scrollTo(0, 0);
            }}
          >
            {t("navbar.Accueil")}
          </div>
          <div
            className="footertext1"
            style={{ marginTop: "13px" }}
            onClick={() =>{
              if (pos.nav === "Boarding") {
                document.getElementById("service").scrollIntoView();
              } else {
                dispatch(inHome);
                navigate("/");
              }
            }}
          >
            {t("navbar.Services")}
          </div>
          <div
            className="footertext1"
            style={{ marginTop: "13px" }}
            onClick={() =>{     if (pos.nav === "Boarding") {
              document.getElementById("solution").scrollIntoView();
            } else {
              dispatch(inHome);
              navigate("/");
            }}
            }
          >
            {t("navbar.Solutions")}
          </div>

          <div
            className="footertext1"
            style={{ marginTop: "13px" }}
            onClick={() => {
              dispatch(ref);
              navigate("/");
              window.scrollTo(0, 0);
            }}
          >
            {t("navbar.Réferences")}
          </div>
          <div
            className="footertext1"
            style={{ marginTop: "13px" }}
            onClick={() => {
              dispatch(about)
                    window.scrollTo(0, 0);
                    navigate('/')
            }}
          >
            {t("navbar.A propos")}
          </div>
        </Col>
        {/* //Services  */}
          <Col>
          <div className="footertitre2">Services</div>
          {cardToDisplay}
          </Col>
          <Col style={{marginLeft: "50%"}}>
          <div className="footertitre3">Contact</div>
          <div style={{ display: "flex", flexDirection: "row" ,}}>

          <div className="footertext3" style={{  }}><br/>
          {t("Footer.abonne")}
          </div>
          <br></br>
          <div style={{}}>
            <input className="footerinput" 
             type="text"
             placeholder={t('Footer.email')}
             id="userEmail"
             onChange={(e) => validateEmail(e)}
            ></input>
            <img
              alt="logo"
              src={iconinput}
              style={{
                zIndex: "1px",
                marginLeft: "-28px",
                width: "25px",
                height: "25px",
                marginTop: "-3px",
              }}
              onClick={()=>{
                postEmail()
              }}
            />
            </div>
            <br />
      <span
        style={{marginTop:"20px",marginLeft:"10px",
          fontWeight: "bold",
          color: "red"
        }}
      >
        {message}
      </span>
          </div>
        </Col>
        
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img
            alt="logo"
            src={footerline}
            style={{ width: "80%", marginTop: "42px", height: "1px" }}
          />
        </div>
   
        <div
          style={{
            display: "flex",
            marginTop: "15.6px",
            justifyContent: "center",
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "13.1667px",
            lineHeight: "21px",
            color: "#FFFFFF",
          }}
        >
          {" "}
          Copyright © 2021 PRISMA by Switch.tn Tous les droits sont réservés
        </div>
      </Desktop>
      <Tablet>
        <Row style={{ display: "flex", justifyContent: "center" }}>
          <Col xs={1}></Col>
          <Col xs={2}>
            <div>
              <img
                alt="logo"
                src={logo}
                style={{ marginTop: "45px", marginLeft: "52px" }}
              />{" "}
            </div>
            <div>
              <img
                alt="logo"
                src={IconFacebook}
                style={{ marginLeft: "52px", marginTop: "16px" }}
              />{" "}
            </div>
          </Col>
          <Col xs={1}></Col>
          <Col xs={2}>
            <div className="footertitre1">Pages</div>
            <div
              className="footertext1"
              style={{ marginTop: "37.5px" }}
              onClick={() => {
                navigate("/");
              }}
            >
              Accueil
            </div>
            <div
              className="footertext1"
              style={{ marginTop: "13px" }}
              onClick={() =>
              
                document.getElementById("service").scrollIntoView()
              }
            >
              Services
            </div>
            <div
              className="footertext1"
              style={{ marginTop: "13px" }}
              onClick={() =>
             
                document.getElementById("solution").scrollIntoView()
              }
            >
              Solution{" "}
            </div>

            <div
              className="footertext1"
              style={{ marginTop: "13px" }}
              onClick={() => {
                navigate("/");
              }}
            >
              Réferences
            </div>
            <div
              className="footertext1"
              style={{ marginTop: "13px" }}
              onClick={() => {
                navigate("/");
              }}
            >
              A propos
            </div>
          </Col>
          <Col xs={2}>
            <div className="footertitre2">Service</div>
            <div className="footertitrebis" style={{ marginTop: "37.5px" ,}} 
               onClick={() => {
             
                navigate("/serviceconsulting/1");
              }}>
              Consulting & Formation
            </div>
            <div className="footertitrebis" style={{ marginTop: "13px" }}
               onClick={() => {
               
                navigate("/serviceconsulting/2");
              }}>
              Architecture & Intégration
            </div>
            <div className="footertitrebis" style={{ marginTop: "13px" }}
               onClick={() => {
                
                navigate("/serviceconsulting/3");
              }}>
              Audit & Mesures
            </div>
            <div className="footertitrebis" style={{ marginTop: "13px" }}
               onClick={() => {
             
                navigate("/serviceconsulting/4");
              }}>
              Outsourcing & Missions en régie
            </div>
            <div className="footertitrebis" style={{ marginTop: "13px" }}
               onClick={() => {
                // props.setServ(5);
                navigate("/serviceconsulting/5", {
                  state: {
                    id: 5,
                  },
                });
              }}>
              Support & Maintenance
            </div>
          </Col>
          <Col xs={2}>
            <div className="footertitre3">Contact</div>
            <div className="footertext3" style={{ marginTop: "37.5px" }}>
              Vous pouvez nous contacter <br />
              pour nous envoyer un courriel
            </div>
            <br></br>
            <input className="footerinput"
            type="text"
            placeholder={t('Footer.email')}
            id="userEmail"
            onChange={(e) => validateEmail(e)}
            ></input>
            <img
              alt="logo"
              src={iconinput}
              style={{
                zIndex: "1px",
                marginLeft: "182px",
                width: "25px",
                height: "25px",
                marginTop: "-53px",
              }}
              onClick={()=>{
                postEmail()
              }}
            />
            
            <br />
      <span
        style={{marginTop:"20px",marginLeft:"10px",
          fontWeight: "bold",
          color: "red"
        }}
      >
        {message}
      </span>
          </Col>
          <Col xs={2}></Col>
        </Row>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img
            alt="logo"
            src={footerline}
            style={{ width: "80%", marginTop: "56px", height: "1px" }}
          />
        </div>

        <div
          style={{
            display: "flex",
            marginTop: "15.6px",
            justifyContent: "center",
          }}
        >
          <img alt="logo" src={textfooter} style={{ height: "21px" }} />
        </div>
      </Tablet>
      <Mobile>
        <Row style={{ display: "flex", justifyContent: "center" }}>
          <Col xs={12}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "45px",
              }}
            >
              <img alt="logo" src={logo} />
            </div>
          </Col>
          <Col xs={12}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "16px",
              }}
            >
              <img alt="logo" src={IconFacebook} />
            </div>
          </Col>

          <Col xs={12}>
            <div className="footertitre1">Pages</div>
            <div
              className="footertitre2"
              style={{ marginTop: "37.5px" }}
              onClick={() => {
                navigate("/");
              }}
            >
              Accueil
            </div>
            <div
              className="footertitre2"
              style={{ marginTop: "13px" }}
              onClick={() =>
                props.home === 1 &&
                document.getElementById("service").scrollIntoView()
              }
            >
              Services{" "}
            </div>
            <div
              className="footertitre2"
              style={{ marginTop: "13px" }}
              onClick={() =>
                props.home === 1 &&
                document.getElementById("solution").scrollIntoView()
              }
            >
              Solution{" "}
            </div>

            <div
              className="footertitre2"
              style={{ marginTop: "13px" }}
              onClick={() => {
                navigate("/ref");
              }}
            >
              Réferences
            </div>
            <div
              className="footertitre2"
              style={{ marginTop: "13px" }}
              onClick={() => {
                navigate("/about");
              }}
            >
              A propos
            </div>
          </Col>
          <Col xs={12}  style={{}}>
            <div className="footertitre2">Service</div>
            <div className="footertitre2" style={{ marginTop: "37.5px" }}
               onClick={() => {
                // props.setServ(1);
                navigate("/serviceconsulting/1", {
                  state: {
                    id: 1,
                  },
                });
              }}>
              Consulting & Formation
            </div>
            <div className="footertitre2" style={{ marginTop: "13px" }}
               onClick={() => {
                // props.setServ(2);
                navigate("/serviceconsulting/2", {
                  state: {
                    id: 2,
                  },
                });
              }}>
              Architecture & Intégration
            </div>
            <div className="footertitre2" style={{ marginTop: "13px" }}
               onClick={() => {
                // props.setServ(3);
                navigate("/serviceconsulting/3", {
                  state: {
                    id: 3,
                  },
                });
              }}>
              Audit & Mesures
            </div>
            <div className="footertitre2" style={{ marginTop: "13px" }}
               onClick={() => {
                // props.setServ(4);
                navigate("/serviceconsulting/4", {
                  state: {
                    id: 4,
                  },
                });
              }}>
              Outsourcing & Missions en régie
            </div>
            <div className="footertitre2" style={{ marginTop: "13px" }}
               onClick={() => {
                // props.setServ(5);
                navigate("/serviceconsulting/5", {
                  state: {
                    id: 5,
                  },
                });
              }}>
              Support & Maintenance
            </div>
          </Col>
          <Col xs={12}>
            <div className="footertitre3">Contact</div>
            <div className="footertext3" style={{ marginTop: "37.5px" }}>
              Vous pouvez nous contacter <br />
              pour nous envoyer un courriel
            </div>
            <br></br>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <input className="footerinput"
              type="text"
              id="userEmail"
              placeholder={t('Footer.email')}
              onChange={(e) => validateEmail(e)}></input>
              <img
                alt="logo"
                src={iconinput}
                style={{
                  zIndex: "1px",
                  marginLeft: "-28px",
                  width: "25px",
                  height: "25px",
                  marginTop: "5px",
                }}
                onClick={()=>{
                  postEmail()
                }}
              />
              </div>
              <br />
        <span  className="footertitre3"
          style={{marginTop:"20px",marginLeft:"25px",
            fontWeight: "bold",
            color: "red"
          }}
        >
          {message}
        </span>
          </Col>
          <Col xs={12}></Col>
        </Row>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img
            alt="logo"
            src={footerline}
            style={{ width: "80%", marginTop: "56px", height: "1px" }}
          />
        </div>

        <div
          style={{
            display: "flex",
            marginTop: "15.6px",
            justifyContent: "center",
          }}
        >
          <img
            alt="logo"
            src={textfooter}
            style={{ height: "21px", width: "80%" }}
          />
        </div>
      </Mobile>
    </Row>
  );
};

export default Footer;
