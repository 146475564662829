import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import Rectangle8 from "../../../assets/Rectangle8.svg";
import Card from "react-bootstrap/Card";
import icon1 from "../../../assets/icon1.svg";
import { useNavigate } from "react-router-dom";
import { Desktop, Mobile, Tablet } from "../../../constant/AppConfig";
import { useTranslation } from "react-i18next";
import useAuth from "../../../Api/hooks/useAuth";
import CircularProgress from '@mui/material/CircularProgress';
//REdux
import { useSelector, useDispatch } from "react-redux";
import {
  getServicesStatus,
  fetchServices,
  selectAllServices,
  getServicesError,
} from "../../../features/services/servicesSlice";


const Service = (props) => {
  const { selectedCountry } = props;
  const navigate = useNavigate();
 const {data,getServices,getServicesen}=useAuth()
  const [t] = useTranslation("common");

//Redux 
const dispatch = useDispatch();
const serviceStatus = useSelector(getServicesStatus);
const services = useSelector(selectAllServices);
const error = useSelector(getServicesError);

useEffect(() => {
  if (serviceStatus === "idle") {
    dispatch(fetchServices());
  }
}, [serviceStatus, dispatch]);

// {console.log(services,"services =============Q")}

let cardToDisplay = "";
let cardTabToDisplay = "";
let cardMobileToDisplay = "";

if (serviceStatus === "loading") {
  cardToDisplay = <CircularProgress/>;
} 
else if (serviceStatus === "succeeded") { 
  cardToDisplay = services.map((data,i) => (
    <>
    {/* <Card>
              <Card.Img
                variant="top"
                src={`https://manager.prisma-intl.com/storage/${data.image}`}
                style={{
                  width: "56px",
                  height: "56px",
                  marginTop: "26px",
                  marginLeft: "120px",
                }}
              />
              <Card.Body
                style={{
                  marginTop: "20px",
                }}
              >
                <Card.Text>
                  <p>{data.nom}</p>
                </Card.Text>
                <button className="buttoncardservice">
                  {" "}
                  <div
                    className="textbuttoncardservice"
                    onClick={() => {
                      // setSol(e.id);
                      navigate(`/solution/${data.id}`, {
                        state : {
                          id: data.id,
                        },
                      });
                    }}
                  >
                    {t("Voirs.title")}
                  </div>
                </button>
              </Card.Body>
            </Card> */}


            <Card key={i}>
                  <Card.Img
                    variant="top"
                    // src={icon1}
                    src={data.icon}
                    style={{
                  width: "56px",
                  height: "56px",
                  marginTop: "26px",
                  marginLeft: "115px",
                    }}
                  />
                  <Card.Body>
                    <Card.Text>
                      <p>{data.nom}</p>
                    </Card.Text>
                    <button
                      className="buttoncardservice"
                      onClick={() => {
                        // setServ(e.id);
                        navigate(`/serviceconsulting/${data.id}`);
                      }}
                    >
                      {" "}
                      <div className="textbuttoncardservice">     {t("Voirs.title")}</div>
                    </button>
                  </Card.Body>
                </Card>
            </>
));
} else if (serviceStatus === "failed") {
  cardToDisplay = <><CircularProgress/>{console.log(error)}</>;
}




  useEffect(() => {
    if(selectedCountry==="fr"){ console.log(selectedCountry,'lang')
      // getServices()
    }else {
      // getServicesen()
    }
    
  }, [selectedCountry]);
  return (
    <>
      <Desktop>
      <Row>
        <div style={{flexDirection:"row",marginTop:"200px"}}>
          <div>
            <img className="line" src={Rectangle8} alt='logo' />
          </div>
          <div className="titleprecence" style={{}}>{t("Serivces.title")} </div>
        </div>
        </Row>
        <Col>
          <Row style={{ justifyContent: "center" }}>
              {cardToDisplay}
          </Row>
        </Col>
      </Desktop>
      <Tablet>
        <div className="titleprecence">
          <img src={Rectangle8} alt='logo'/>
          <div style={{ marginLeft: "35px" }}>Nos Services</div>
        </div>
        <Col>
          <Row style={{ justifyContent: "center", marginTop: "100px" }}>
            {cardToDisplay}
            {/* {data &&
              data.map((e, index) => ( */}
               
              {/* ))} */}
          </Row>
        </Col>
      </Tablet>
      <Mobile>
        <div className="titleprecence">
          <img src={Rectangle8} alt='logo'/>
          <div style={{ marginLeft: "35px" }}>Nos Services</div>
        </div>
        <Col>
          <Row style={{ justifyContent: "center", marginTop: "100px" }}>{cardToDisplay}
            {/* {data.map((e, index) => ( */}
              {/* <Card>
                <Card.Img
                  variant="top"
                  src={icon1}
                  style={{
                    width: "36px",
                    height: "36px",
                    marginTop: "26px",
                    marginLeft: "31px",
                  }}
                />
                <Card.Body>
                  <Card.Text>
                    <p>{e.nom}</p>
                  </Card.Text>
                  <button
                    className="buttoncardservice"
                    onClick={() => {
                      // setServ(e.id);
                      navigate("/serviceconsulting");
                    }}
                  >
                    {" "}
                    <div className="textbuttoncardservice">Voir plus</div>
                  </button>
                </Card.Body>
              </Card>
            ))} */}
          </Row>
        </Col>
      </Mobile>
    </>
  );
};

export default Service;
