
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const SOLUTION_URL = 'https://manager.prisma-intl.com/api/solutions/fr';

export const fetchSolutions = createAsyncThunk(
  'solutions/fetchSolutions',
  async () => {
    const response = await axios.get(SOLUTION_URL);
    console.log(response.data,'solutions Data')
    return response.data.data;
  },
);


const initialState = {
    solutions: [],
    status: 'idle',
    error: null,
};

const solutionsSlice = createSlice({
  name: 'solutions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addCase(fetchSolutions.pending, (state) => {
    state.status = 'loading';
    })
    .addCase(fetchSolutions.fulfilled, (state, action) => {
    state.status = 'succeeded';
    state.solutions = action.payload;
    })
    .addCase(fetchSolutions.rejected, (state, action) => {
    state.status = 'failed';
    state.error = action.error.message;
    });
    },
});

export const selectAllSolutions = (state) => state.solutions.solutions;
export const getSolutionsStatus = (state) => state.solutions.status;
export const getSolutionsError = (state) => state.solutions.error;

export default solutionsSlice.reducer;