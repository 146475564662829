import React, { createContext, useEffect, useState, useReducer } from "react";
import { useNavigate } from "react-router-dom";
import API from "../Api";
import axios from "axios";
import Swal from "sweetalert2";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  
  //create a custom function that will change menucollapse state from false to true and true to false

  const navigate = useNavigate();
const [data,setData]=useState([])
const [datasolution,setDatasolution]=useState([])
const [datarefernces,setDatarefernces]=useState([])
const [datarecutement,setDatarecutement]=useState([])
const [datapartenaires,setDatapartenaires]=useState([])
const [dataonerecrutement,setDataonerecrutement]=useState({})
const [dataaccteuilté,setDataaccteuilté]=useState([])
const [datapresence,setDatapresence]=useState({})
const [IdAccteuilté,setIdAccteuilté]=useState(1)
const [dataoneaccteuilté,setDataoneaccteuilté]=useState({})

  //services
  const getServices = () => {
    API.get("https://manager.prisma-intl.com/api/services/fr")
      .then((response) => {
        setData(response.data)
      })
      .catch((err) => {
       console.log(err)
      });
  };
  const getServicesen = () => {
    API.get("https://manager.prisma-intl.com/api/services/en")
      .then((response) => {
        setData(response.data)
      })
      .catch((err) => {
       console.log(err)
      });
  };
  // const getsolution = () => {
  //   API.get("https://manager.prisma-intl.com/api/solutions/fr")
  //     .then((response) => {
  //       setDatasolution(response.data.data)
  //     })
  //     .catch((err) => {
  //      console.log(err)
  //     });
  // };
  const getsolutionen = () => {
    API.get("https://manager.prisma-intl.com/api/solutions/en")
      .then((response) => {
        setDatasolution(response.data.data)
      })
      .catch((err) => {
       console.log(err)
      });
  };
  const getReference= () => {
    API.get("https://manager.prisma-intl.com/api/famille_references/fr")
      .then((response) => {
        setDatarefernces(response.data)
        console.log(datarefernces,"reffffff")
      })
      .catch((err) => {
       console.log(err)
      });
  };
  const getReferenceenglais= () => {
    API.get("https://manager.prisma-intl.com/api/famille_references/en")
      .then((response) => {
        setDatarefernces(response.data)
      })
      .catch((err) => {
       console.log(err)
      });
  };
  const getRecrutement= () => {
    API.get("https://manager.prisma-intl.com/api/recrutements")
      .then((response) => {
        setDatarecutement(response.data)
      })
      .catch((err) => {
       console.log(err)
      });
  };
  const getOneRecrutement= (id) => {
    API.get(`https://manager.prisma-intl.com/api/recrutements/${id}`)
      .then((response) => {
        console.log(response.data)
        setDataonerecrutement(response.data)
      })
      .catch((err) => {
       console.log(err)
      });
  };
 
  const postContact= (data) => {
    console.log(data,'data contact to send ===')
    API.post( 
       "https://manager.prisma-intl.com/api/contact"
       ,
    data)
      .then((response) => {
        Swal.fire('Formulaire de contact est bien envoyé ');
        // console.log(response.data,'response data')
      })
      .catch((err) => {
       console.log(err)
      });
  };
  const postContactrecutement= (data) => {
    API.post("https://manager.prisma-intl.com/api/candidature-spontanees", data)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status === 500) {
            Swal.fire(response.data.message);
          } else {
            Swal.fire("Cv envoyé");
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const postrecutement= (data) => {
    API.post(  "https://manager.prisma-intl.com/api/candidature",
    data)
      .then((response) => {
        Swal.fire('Cv est bien envoyé ');
        console.log("cv bien envoyé postrecrutement")
      })
      .catch((err) => {
       console.log(err)
      });
  };
  const postEmail= (email) => {
    console.log(email,'email contact to send ===')
    API.post( 
       "https://manager.prisma-intl.com/api/email"
       ,
    {email:email})
      .then((response) => {
        Swal.fire('Email est bien envoyé ');
        console.log(response.data,'response data')
      })
      .catch((err) => {
       console.log(err)
      });
  };
  const getAllPartenaires= () => {
    API.get("https://manager.prisma-intl.com/api/partenaires")
      .then((response) => {
        setDatapartenaires(response.data.data)
      })
      .catch((err) => {
       console.log(err)
      });
  };
  const getAllPresence= () => {
    // API.get("https://manager.prisma-intl.com/api/presence/fr")
    //   .then((response) => {
    //     console.log('presence',response.data)
    //     setDatapresence(response.data)
    //   })
    //   .catch((err) => {
    //    console.log(err)
    //   });
  };
  const getAllAcctuelite = () => {
    API.get("https://manager.prisma-intl.com/api/actualitees/fr")
      .then((response) => {
        console.log('actualité',response.data.data)
        setDataaccteuilté(response.data.data)
      })
      .catch((err) => {
       console.log(err)
      });
  };
  const getActualiteEn = () => {
    API.get("https://manager.prisma-intl.com/api/actualitees/en")
      .then((response) => {
        console.log('actualité anglais',response.data.data)
        setDataaccteuilté(response.data.data)
      })
      .catch((err) => {
       console.log(err)
      });
  };
  const getOneAllAcctuelite = () => {
    API.get(`https://manager.prisma-intl.com/api/actualitees/${IdAccteuilté}/fr`)
      .then((response) => {
        console.log('oneactulaitéFR',response.data.data)
        setDataoneaccteuilté(response.data.data)
      })
      .catch((err) => {
       console.log(err)
      });
  };
  const getOneActualiteEn = () => {
    API.get(`https://manager.prisma-intl.com/api/actualitees/${IdAccteuilté}/en`)
      .then((response) => {
        console.log('oneactualitéEn',response.data.data)
        setDataoneaccteuilté(response.data.data)
      })
      .catch((err) => {
       console.log(err)
      });
  };
  const changeIdAccteuilté = (id) => {
    setIdAccteuilté(id);
  };
  return (
    <AuthContext.Provider
      value={{
        getServices,
        data,
        getServicesen,
        // getsolution,
        datasolution,
        getsolutionen,
        getAllPartenaires,
        datapartenaires,
        postContact,
        postContactrecutement,
        postrecutement,
        postEmail,
        getOneRecrutement,
        dataonerecrutement,
        getRecrutement,
        datarecutement,
        getReferenceenglais,
        getReference,
        datarefernces,
        getAllPresence,
        datapresence,
        dataaccteuilté,
        getAllAcctuelite,
        changeIdAccteuilté,
        IdAccteuilté,
        dataoneaccteuilté,
        getOneAllAcctuelite,
        getOneActualiteEn,
        getActualiteEn
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
