import { Route, Routes } from "react-router-dom";
import Home from "./Pages/Accueil/Home";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ScrollToBottom from "react-scroll-to-bottom";

import React, { useState , Suspense, lazy   } from "react";
import ReferencePage from "./Pages/RefrencePage/ReferencePage";
import Serviceconsulting from "./Pages/services/Serviceconsulting";
import OneSolution from "./Pages/solutions/OneSolution";
import About from "./Pages/About/About";
import Recurtement from "./Pages/Recrutement/Recurtement";
import Contact from "./Pages/Contact/Contact";
import Blog from "./Pages/Actualites/Blog";
import Actualites from "./Pages/Actualites/Actualites";
import MenuBar from "./Component/Navbar/MenuBar";
import Menubarone from "./Component/Navbar/Menubarone";
import Footer from "./Component/Footer/Footer";
import { jsx, css, Global, ClassNames } from '@emotion/react'

function App() {

  const [serv, setServ] = useState(0);
  const [sol, setSol] = useState(0);
  const [selectedCountry, setSelectedCountry] = useState("fr");
  const ROOT_CSS = css({
    height: 600,
    width: 600,backgroundColor :"red",position :"relative"
  });

  return (
    <Container
   
      style={{
        maxHeight: '100%',
        overflowY: "auto",
        minWidth: "100%",
        paddingLeft: "0px",
        paddingRight: "0px",
        overflowX: "hidden",
      }}
      // sx={{ overflowY: 'auto', maxHeight: '100%',overflowX: "auto", minWidth: "100%", }}
      className="px-0 py-0"
    >
       {/* /Header  */}
       <Row >
        <Col
          xs={12}
          style={{
            backgroundColor: "#253D8A",
            minHeight: "60px",
            height: "60px",
          }}
        >
          <Menubarone
            selectedCountry={selectedCountry}
            setSelectedCountry={setSelectedCountry}
       
          />
        </Col>
        <Row>
      {/* / white navbar  */}
          <Col
            xs={12}
            style={{ minHeight: "60px", height: "80px", width: "100%" }}
          >
            <MenuBar   />
          </Col>
        </Row>
      </Row>
      <ScrollToBottom  className={ROOT_CSS}/>
      <Routes>
        
        <Route
          exact
          path="/"
          element={
            <Home
              serv={serv}
              setServ={setServ}
              setSol={setSol}
              selectedCountry={selectedCountry}
              setSelectedCountry={setSelectedCountry}
            />
          }
        />
        <Route
          path="/refernce"
          element={<ReferencePage />}
          selectedCountry={selectedCountry}
          setSelectedCountry={setSelectedCountry}
        />
        <Route
          path="/serviceconsulting/:id"
          element={
            <Serviceconsulting
              serv={serv}
              setServ={setServ}
              setSelectedCountry={setSelectedCountry}
              selectedCountry={selectedCountry}
            />
          }
        />
        <Route
          path="/solution/:id"
          element={
            <OneSolution
              sol={sol}
              setSol={setSol}
              setSelectedCountry={setSelectedCountry}
              selectedCountry={selectedCountry}
            />
          }
        />
        <Route
          path="/about"
          element={
            <About
              setServ={setServ}
              setSelectedCountry={setSelectedCountry}
              selectedCountry={selectedCountry}
            />
          }
        />
        <Route
          path="/dernierblog"
          element={
            <Blog
              setServ={setServ}
              setSelectedCountry={setSelectedCountry}
              selectedCountry={selectedCountry}
            />
          }
        />
        <Route
          path="/actualites"
          element={
            <Actualites
              setServ={setServ}
              setSelectedCountry={setSelectedCountry}
              selectedCountry={selectedCountry}
            />
          }
        />
        <Route
          path="/recurtement"
          element={
            <Recurtement
              setServ={setServ}
              setSelectedCountry={setSelectedCountry}
              selectedCountry={selectedCountry}
            />
          }
        />
        <Route
          path="/contact"
          element={
            <Contact
              setServ={setServ}
              setSelectedCountry={setSelectedCountry}
              selectedCountry={selectedCountry}
            />
          }
        />
        <Route
          path="/ref"
          element={
            <ReferencePage
              setServ={setServ}
              setSelectedCountry={setSelectedCountry}
              selectedCountry={selectedCountry}
            />
          }
        />
      </Routes>

          {/* //Footer */}
          <Row className="footer">
        <Footer  />
      </Row>
    </Container>
  );
}

export default App;
