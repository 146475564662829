import { configureStore } from '@reduxjs/toolkit';
import solutionReducer from '../features/solutions/solutionsSlice.js';
import serviceReducer from '../features/services/servicesSlice.js';
// import navHomeReducer from "../features/navigationHome/navhomeSlice.js";
import navReducer from "../features/navigationHome/navhomeSlice.js";
import presencesReducer from '../features/presence/presencesSlice.js';

const store = configureStore({
  reducer: {
    solutions: solutionReducer,
    services : serviceReducer,
    nav: navReducer,
    presences : presencesReducer
    // nav : navReducer
  },
});

export default store;