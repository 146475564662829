import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Carousel from "better-react-carousel";
import Footer from "../../Component/Footer/Footer";

import Slider from "../Accueil/Slider";
import Rectangle8 from "../../assets/Rectangle8.svg";

import { useTranslation } from "react-i18next";
import eyeblog from "../../assets/eyeblog.png";

import "../RefrencePage/Reference.css";
import { Desktop, Mobile, Tablet } from "../../constant/AppConfig";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../../Api/hooks/useAuth";

const Blog = (props) => {
  const {
    changeIdAccteuilté,
    getOneActualiteEn,
    getReference,
    IdAccteuilté,
    getOneAllAcctuelite,
    dataoneaccteuilté,
    getAllAcctuelite,
    getActualiteEn,
    dataaccteuilté
  } = useAuth();
  const [t] = useTranslation("common");
  const navigate = useNavigate();
  useEffect(() => {
    console.log(props.selectedCountry,"langueBlog");
    if (props.selectedCountry === "fr") {
      getOneAllAcctuelite();
      getAllAcctuelite();
      console.log(dataaccteuilté,"reff blog page")
    } else {
      console.log('eng')
      getOneActualiteEn();
      getActualiteEn();
    }
  }, [props.selectedCountry]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Desktop>

        <Row>
          <Col>
            <div>
              <img className="line" src={Rectangle8} alt="logo" />
            </div>
            <div className="titlereference">{t("navbar.Actualités")}</div>

            <div className="soustitleaccteuilte">
              {/* Lorem ipsum dolor sit amet consectetur. */}
            </div>
            <div
              style={{
                fontFamily: "Roboto",
                fontStyle: "normal",
                color: "#253D8A",
                fontWeight: 600,
                fontSize: "30px",
                lineHeight: "35px",
                width: "70%",
                alignItems: "center",
                textAlign: "center",
                marginLeft: "160px",
              }}
            >
              {dataoneaccteuilté && dataoneaccteuilté.titre}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "50px",
                flexDirection: "row",
              }}
            >
              {/* <div className="sousbaraccteuilte"> */}
              {/* Lorem */}
              {/* </div> */}
              {/* <div className="sousbaraccteuilte" style={{ marginLeft: "50px" }}> */}
              {/* Lorem */}
              {/* </div> */}
              {/* <div className="sousbaraccteuilte" style={{ marginLeft: "50px" }}> */}
              {/* Lorem */}
              {/* </div> */}
            </div>
            <div
              style={{
                display: "flex",
                // ,backgroundColor:"red"
                justifyItems: "center",
                justifyContent: "center",
                marginLeft: "80px",
                marginRight: "80px",
                // justifyContent: "center",
                // marginTop: "50px",
              }}
            >
              <Carousel>
                {dataoneaccteuilté &&
                  dataoneaccteuilté.files &&
                  dataoneaccteuilté.files.map((e, index) => (
                    <Carousel.Item>
                      <div
                        style={{
                          // width: "50%",
                          justifyContent: "center",
                          justifyItems: "center",
                          // marginLeft:"11%"
                        }}
                      >
                        <img
                          style={{ borderRadius: "10px", width: "50%" }}
                          className="d-block w-100"
                          src={`https://manager.prisma-intl.com/storage/${e}`}
                          alt="First slide"
                        />
                      </div>
                    </Carousel.Item>
                  ))}
              </Carousel>
            </div>
            <div className="soustitleaccteuilte2">
              {/* Lorem ipsum dolor sit */}
            </div>
            <div className="textaccteuilte2" style={{ position: "relative" }}>
              {dataoneaccteuilté && dataoneaccteuilté.description}
            </div>
          </Col>
        </Row>

        <Row
          style={
            {
              // marginTop: "126px"
            }
          }
        >
          <Col>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div className="soustitleaccteuilte2" style={{ display: "flex" }}>
                Les derniers articles du blog
              </div>
              {/* <div
                className="sousbaraccteuilte"
                style={{
                  marginTop: "25px",
                  marginRight: "119px",
                }}
              > 
                 <div>Voir tous</div> 
               </div>  */}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              {dataaccteuilté &&
                dataaccteuilté.map(
                  (e, index) =>
                    e.id < 7 && (
                      <div
                        className="containerBlog"
                        onClick={() => {
                          changeIdAccteuilté(e.id);
                          navigate("../dernierblog");
                        }}
                        style={{
                          width: "401px",
                          height: "316.67px",
                          margin: "10px",
                          borderRadius: 10 + "px!important",
                        }}
                      >
                        <img
                          src={`https://manager.prisma-intl.com/storage/${
                            JSON.parse(e.files)[0]
                          }`}
                          style={{ width: "401px", height: "316.67px" }}
                        />
                        <div className="textBlog">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              style={{
                                marginLeft: "17px",
                                display: "flex",
                                alignItems: "center",
                                height: "56.82px",
                                width: "80%",
                                textAlign: "left",
                              }}
                            >
                              {e.titre}
                            </div>
                            <div
                              style={{
                                marginRight: "17px",
                                display: "flex",
                                alignItems: "center",
                                height: "56.82px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                changeIdAccteuilté(e.id);
                                navigate("../dernierblog");
                              }}
                            >
                              <img src={eyeblog} />
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                )}
            </div>
          </Col>
        </Row>
      </Desktop>
      <Mobile>
        {/* <Row>
          <Col
            xs={12}
            style={{
              backgroundColor: "#253D8A",
              minHeight: "60px",
              height: "auto",
            }}
          >
            <Menubarone
              setSelectedCountry={props.setSelectedCountry}
              selectedCountry={props.selectedCountry}
            />
          </Col>

          <Col
            xs={12}
            style={{ minHeight: "120px", height: "auto", width: "100%" }}
          >
            <MenuBar setService={props.setService} />
          </Col>
        </Row> */}

        <Row>
          <Col style={{ margin: "10%", alignContent: "center" }}>
            {/* <div>
              <img className="line" src={Rectangle8} alt="logo" />
            </div> */}
            {/* <div className="titlereference" >Actualités</div> */}

            {/* <div className="soustitleaccteuilte"> */}
            {/* Lorem ipsum dolor sit amet consectetur. */}
            {/* </div> */}
            <div
              style={{
                fontFamily: "Roboto",
                fontStyle: "normal",
                color: "#253D8A",
                fontWeight: 600,
                fontSize: "30px",
                lineHeight: "35px",
                width: "70%",
                alignItems: "center",
                textAlign: "center",
                // backgroundColor:'red'
              }}
            >
              {dataoneaccteuilté && dataoneaccteuilté.titre}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "50px",
                flexDirection: "row",
              }}
            >
              {/* <div className="sousbaraccteuilte"> */}
              {/* Lorem */}
              {/* </div> */}
              {/* <div className="sousbaraccteuilte" style={{ marginLeft: "50px" }}> */}
              {/* Lorem */}
              {/* </div> */}
              {/* <div className="sousbaraccteuilte" style={{ marginLeft: "50px" }}> */}
              {/* Lorem */}
              {/* </div> */}
            </div>
            <div
              style={{
                display: "flex",
                // ,backgroundColor:"red"
                justifyItems: "center",
                justifyContent: "center",
                marginLeft: "80px",
                marginRight: "80px",
                // justifyContent: "center",
                // marginTop: "50px",
              }}
            >
              <Carousel>
                {dataoneaccteuilté &&
                  dataoneaccteuilté.files &&
                  dataoneaccteuilté.files.map((e, index) => (
                    <Carousel.Item>
                      <div
                        style={{
                          // width: "50%",
                          justifyContent: "center",
                          justifyItems: "center",
                          // marginLeft:"11%"
                        }}
                      >
                        <img
                          style={{ borderRadius: "10px", width: "50%",height:"100px" }}
                          className="d-block w-100"
                          src={`https://manager.prisma-intl.com/storage/${e}`}
                          alt="First slide"
                        />
                      </div>
                    </Carousel.Item>
                  ))}
              </Carousel>
            </div>
            <div className="soustitleaccteuilte2">
              {/* Lorem ipsum dolor sit */}
            </div>
            <div
              style={{
                position: "relative",
                width: "77%",
                /* height: 63px, */
                // marginleft: "180px",
                /* top:25px, */
                fontFamily: "Arial",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "24px",
                lineHeight: "28px",
                color: "rgba(37, 61, 138, 0.8)",
              }}
            >
              {dataoneaccteuilté && dataoneaccteuilté.description}
            </div>
          </Col>
        </Row>

        <Row
          style={
            {
              // marginTop: "126px"
            }
          }
        >
          <Col style={{  margin: "10%" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                // justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  position: "relative",
                  width: "100%",
                  height: "63px",
                  left: "10px",
                  top: "25px",
                  fontFamily: "Arial",
                  fontStyle: "normal",
                  fontWeight: 700,
                  fontSize: "22.16px",
                  lineHeight: "32px",
                  display: "flex",
                  color: "#253D8A",
                  
                }}
              >
                Les derniers articles du blog
              </div>
              {/* <div
                className="sousbaraccteuilte"
                style={{
                  marginTop: "25px",
                  marginRight: "119px",
                }}
              > 
                 <div>Voir tous</div> 
               </div>  */}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              {dataaccteuilté &&
                dataaccteuilté.map(
                  (e, index) =>
                    e.id < 7 && (
                      <div
                        // className="containerBlog"
                        onClick={() => {
                          changeIdAccteuilté(e.id);
                          navigate("../dernierblog");
                        }}
                        style={{
                          position: "relative",
                          textAlign: "center",
                          color: "white",
                          borderRadius: "10px",
                          width: "401px",
                          height: "316.67px",
                          // backgroundColor:"black",
                          // marginLeft: "10px",
                          marginRight:"60px",
                          borderRadius: 10 + "px!important",
                        }}
                      >
                        <img
                          src={`https://manager.prisma-intl.com/storage/${
                            JSON.parse(e.files)[0]
                          }`}
                          style={{ width: "100%", height: "auto" }}
                        />
                        <div
                          className="textBlog"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width:"100%"
                            // backgroundColor: "black",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between"
                              // backgroundColor: "black",
                            }}
                          >
                            <div
                              style={{
                                marginLeft: "15px",
                                display: "flex",
                                alignItems: "center",
                                height: "56.82px",
                                width: "100%",
                                textAlign: "left",
                              }}
                            >
                              {e.titre}
                            </div>
                            <div
                              style={{
                                // marginRight: "25px",
                                display: "flex",
                                alignItems: "center",
                                height: "56.82px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                changeIdAccteuilté(e.id);
                                navigate("../dernierblog");
                              }}
                            >
                              <img src={eyeblog} />
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                )}
            </div>
          </Col>
        </Row>
      </Mobile>
      {/* <Row className="footer">
        <Footer setServ={props.setServ} />
      </Row> */}
    </>
  );
};

export default Blog;
