import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import "./Home.css";
import React, { useEffect, useState } from "react";

import Contact from "../Contact/Contact.js";
import ReferencePage from "../RefrencePage/ReferencePage.js";
import About from "../About/About.js";
import Actualites from "../Actualites/Actualites.js";
import Boarding from "./Boarding.js";
import ErrorPage from "../Error/ErrorPage";


import Recrutement from "../../Pages/Recrutement/Recurtement";
import { useLocation } from "react-router-dom";
//REdux
import { useSelector, useDispatch } from "react-redux";
import {homeNavAction , ContactNavAction ,RefNavAction,RecrutNavAction,
  homeAction,
  notHomeAction
} from "../../features/navigationHome/navhomeSlice";

const Home = (props) => {
  const [home, setHome] = useState(0);
  const [selectedPart,setSelectedPart]= useState('Boarding')
  // const { state } = useLocation();
    //redux
    const dispatch = useDispatch();
    const inHome = useSelector(homeAction);
    const outHome = useSelector(notHomeAction);
    const pos = useSelector(state => state.nav);

  useEffect(() => {
    console.log(pos.nav,'home==============>',selectedPart)
    // setHome(1);
   dispatch (inHome)
    // console.log(state);
    // if (state !== null) {
      // if (state.serivce === true) {
      //   document.getElementById("service").scrollIntoView();
      // } else if (state.solution === true) {
      //   document.getElementById("solution").scrollIntoView();
      // }
    // }
  }, []);
  const selectedFunct = (part) => {
    console.log('changed part to ',part)
setSelectedPart(part);
  }

  return (
    <>
      {/* /Header  */}
    
      <Row>
        { 
         pos.nav === "Boarding"  ? 
           <Boarding></Boarding> 
           :
           pos.nav === "Reference"  ? 
           <ReferencePage/> 
           :
           pos.nav === "Contact"   ? 
           <Contact /> 
           :
           pos.nav === "Recrutement"  ? 
           <Recrutement /> 
           :
          //  pos.nav === "Actualités" ? 
          //  <Actualites/> 
          //  :
           pos.nav === "About" ? 
           <About></About> 
           :
            <ErrorPage/>
        }
  
      </Row>
  
    </>
  );
};

export default Home;
